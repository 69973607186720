import React, { useEffect, useState } from "react";
import {default as Loader} from "react-spinners/ScaleLoader";

const ScaleLoader = (props) => {
  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <Loader className="my-auto" height={25} width={2} color="#b2b2b2" {...props}/>
    </div>
  )
}

export default ScaleLoader;