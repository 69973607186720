import React, { useState, useEffect, InputHTMLAttributes, useRef, forwardRef } from "react";
import { Form, InputGroup } from "react-bootstrap";
import classNames from "classnames";
import AsyncSelect from 'react-select/async';

import { FieldErrors, Control } from "react-hook-form";

interface FormSelectAsyncProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  type?: string;
  name: string;
  placeholder?: string;
  register?: any;
  errors?: any;
  control?: Control<any>;
  setValue?: any;
  setError?: any;
  clearErrors?: any;
  getValues?: any;
  trigger?: any;
  className?: string;
  labelClassName?: string;
  containerClass?: string;
  refCallback?: any;
  children?: any;
  rows?: string;
  defaultOptions?: any;
  loadOptions: any;
  onChange?: any;
  value?: any;
  defaultValue?: any;
  isClearable?: boolean;
  isMulti?: boolean;
  isDisabled?: boolean;
}

// const FormSelectAsync = ({
const FormSelectAsync = forwardRef<HTMLInputElement, FormSelectAsyncProps>(
  (props, ref) => {
    const {
      label,
      type,
      name,
      placeholder,
      register,
      errors,
      control,
      setValue,
      setError,
      clearErrors,
      getValues,
      trigger,
      className,
      labelClassName,
      containerClass,
      refCallback,
      children,
      rows,
      defaultOptions,
      loadOptions,
      onChange,
      value,
      defaultValue,
      isClearable,
      isMulti,
      isDisabled,
    } = props

  const selectRef = useRef<any>();

  // handle input type
  const comp =
    type === "textarea" ? "textarea" : type === "select" ? "select" : "input";
  const [currentValue, setCurrentValue] = useState("");
  const [isClear, setIsClear] = useState(false);

  const onChangeOption = (opt: any, actionType: any) => {
    if(opt?.value){
      if (setValue !== undefined) {
        setValue(name, opt?.value, true);
      }
      if (opt?.value) {
        setCurrentValue(opt?.value);
        clearErrors(name);
      }
    }
    if (onChange !== undefined) {
      if(opt?.value) {
        onChange(opt, actionType);
      } else {
        // onChange({label: null, value: null} ,actionType);
        if (setValue !== undefined) {
          setValue(name, undefined)
        }
      }
    }
  };
  
  const clearValue = () => {
    // if(!selectRef.current.clearValue()){
    //   selectRef.current.clearValue()
    // }
  };

  const getDefaultValue = () => {
    if(isMulti && defaultOptions){
      return defaultOptions.filter((item: any) => String(defaultValue).split(',').includes(item.value))
    }
    return defaultValue;
  }

  useEffect(() => {
    // console.log(defaultValue)
    if (setValue !== undefined) {
      setValue(name, defaultValue, true);
    }
    setCurrentValue(defaultValue);
    if (defaultValue) {
      clearErrors(name);
    }
    // if(!defaultValue){
    //   clearValue()
    // }
    // setError(name, { type: 'custom', message: 'custom message' })
  }, []);
  
  useEffect(() => {
    if(!defaultValue || Object.keys(defaultValue).length === 0){
      // clearValue()
      selectRef.current.clearValue()
    }
    if(name == 'school_code'){
      console.log(name, defaultValue)
    }
  }, [defaultValue]);

  return (
    <>
      <Form.Group className={containerClass}>
        {label ? (
          <Form.Label className={labelClassName}>{label}</Form.Label>
        ) : null}

        <Form.Control
          // type={type}
          {...props}
          type="hidden"
          value={currentValue}
          placeholder={placeholder}
          name={name}
          id={name}
          as="input"
          ref={(r: HTMLInputElement) => {
            if (refCallback) refCallback(r);
          }}
          className={className}
          isInvalid={errors && errors[name] ? true : false}
          {...(register ? register(name) : {})}
          rows={rows}
          autoComplete={name}
        >
          {children ? children : null}
        </Form.Control>

        <AsyncSelect
          ref={selectRef}
          menuPortalTarget={document.body}
          // noOptionsMessage={() => "Search by name..."}
          noOptionsMessage={() => null}
          // cacheOptions
          className="react-select react-select-container"
          classNamePrefix="react-select"
          placeholder={placeholder}
          // value={
          //   options.filter((opt: any) => opt.value == value) || undefined
          // }
          defaultValue={getDefaultValue() || []}
          styles={{
            control: (base: any, state: any) => ({
              ...base,
              boxShadow: state.isFocused
                ? errors && errors[name]
                  ? "0px 0px 4px 1px rgba(241, 85, 108, 0.4)"
                  : "#f1556c"
                : "#dee2e6",
              // state.isFocused can display different borderColor if you need it
              borderColor: errors && errors[name] ? "#f1556c" : "#dee2e6",
              // overwrittes hover style
              "&:hover": {
                borderColor: errors && errors[name] ? "#f1556c" : "#dee2e6",
              },
            }),
            placeholder: (base: any, state: any) => ({
              ...base,
              color: '#aab0b9'
            }),
            menuPortal: (base: any, state: any) => ({
              ...base,
              zIndex: 9999
            }),
          }}
          defaultOptions={isClear ? defaultOptions : []}
          loadOptions={loadOptions}
          onChange={(opt: any, actionType: any) => {
            onChangeOption(opt || {},actionType);
            if(actionType.action == 'clear'){
              setIsClear(true)
              clearValue()
            } else {
              setIsClear(false)
            }
          }}
          onKeyDown={(e: any) => {
            if (e.key == 'Backspace' || e.key == 'Delete') {
              setIsClear(true)
              clearValue()
            }
          }}
          isClearable={isClearable}
          isMulti={isMulti}
        />

        {errors && errors[name] ? (
          <Form.Control.Feedback type="invalid">
            {errors[name]["message"]}
          </Form.Control.Feedback>
        ) : null}
      </Form.Group>
    </>
  );
});

export default FormSelectAsync;
