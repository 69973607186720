import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Table, InputGroup, Form } from "react-bootstrap";
import { Link, json } from "react-router-dom";
import Select from "react-select";
import queryString from 'query-string'
import { flatten } from 'q-flat'
import ScaleLoader from "components/ScaleLoader";
import Skeleton from "react-loading-skeleton";
import classNames from "classnames";

// components
import PaginationCard from "components/PaginationCard";

import {
  useGetRoleQuery,
  useGetPermissionQuery,
  useGetOptionQuery,
} from "services/api";

const TableHeader = ({
  label,
  name,
  defaultSort,
  onSearch,
  onSort,
  sort,
  setSort,
  width,
  inputFilter,
  filterOptions,
  hideFilter,
} : {
  label: string;
  name: string;
  defaultSort?: string;
  onSearch?: any;
  onSort?: any;
  sort?: any;
  setSort?: any;
  width?: any;
  inputFilter?: any;
  filterOptions?: any;
  hideFilter?: any;
}) => {
  const [search, setSearch] = useState('');

  const isSkipped = () => {
    if(filterOptions){
      if(Array.isArray(filterOptions)){
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  const {
    data: lists,
    isFetching: isFetchingOption,
    isLoading: isLoadingOption,
    isUninitialized: isUninitializedOption,
    isError: isErrorOption,
    error: errorOption,
    refetch: refetchOption,
  } = useGetOptionQuery(
    {
      ...filterOptions
    },
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      skip: isSkipped(),
    }
  );
  
  return  (
    <th style={{width: width || 'unset', minWidth: width || 'unset'}}>
      <div className="d-flex justify-content-start gap-1 cursor-pointer mt-1 mb-1">
        <span>{label}</span>
        <span>
          {
            sort[name] ? (
              <>
              {
                sort[name] == 'asc' &&
                <i className="mdi mdi-sort-ascending text-info" onClick={() => { if(setSort){setSort('desc')}; if(onSort){onSort(name, 'desc')}}} />
              }
              {
                sort[name] == 'desc' &&
                <i className="mdi mdi-sort-descending text-danger" onClick={() => { if(setSort){setSort(undefined)}; if(onSort){onSort(name, undefined)}}} />
              }
              </>
            ) : (
              <i className="mdi mdi-sort" onClick={() => { if(setSort){setSort('asc')}; if(onSort){onSort(name, 'asc')}}}></i>
            )
          }
        </span>
      </div>
      {
        hideFilter !== true &&
        <div className="d-flex justify-content-start gap-1">
          <InputGroup size="sm" className="mt-0" style={{width: '100%', maxWidth: '200px'}}>
            {
              inputFilter == 'select' ? (
                <Select
                  menuPlacement="auto"
                  menuPosition="fixed"
                  className="react-select react-select-container form-control-sm p-0 w-100 fw-normal"
                  classNamePrefix="react-select"
                  styles={{
                    control: (provided: any, state: any) => ({
                      ...provided,
                      minHeight: '29px',
                      height: '29px',
                      fontSize: '12px',
                      boxShadow: "none",
                      border: "1px solid #dee2e6",
                      color: "#6c757d",
                      "&:hover": {
                        border: "1px solid #dee2e6",
                      }
                    }),
                    valueContainer: (provided: any, state: any) => ({
                      ...provided,
                      height: '29px',
                      padding: '0 6px'
                    }),
                    input: (provided: any, state: any) => ({
                      ...provided,
                      margin: '0px',
                    }),
                    indicatorSeparator: (provided: any, state: any) => ({
                      display: 'none',
                    }),
                    indicatorsContainer: (provided: any, state: any) => ({
                      ...provided,
                      height: '29px',
                    }),
                    clearIndicator: (provided: any, state: any) => ({
                      ...provided,
                      paddingRight: '0px'
                    }),
                    dropdownIndicator: (provided: any, state: any) => ({
                      ...provided,
                      paddingLeft: '0px'
                    }),
                    placeholder: (provided: any, state: any) => ({
                      ...provided,
                      top: '14px'
                    }),
                    singleValue: (provided: any, state: any) => ({
                      ...provided,
                      top: '14px'
                    }),
                  }}
                  // placeholder={placeholder}
                  // value={
                  //   options.filter((opt: any) => opt.value == value) || undefined
                  // }
                  defaultValue={undefined}
                  // defaultInputValue={defaultValue}
                  options={Array.isArray(filterOptions) ? filterOptions : lists?.result}
                  onChange={(opt: any, actionType: any) => {
                    if(opt?.value){
                      setSearch(opt.value)
                      onSearch(name, opt.value)
                    }
                    if(actionType.action == 'clear'){
                      setSearch('')
                      onSearch(name, '')
                    }
                  }}
                  isClearable
                  // isMulti={isMulti}
                ></Select>
              ) : (
                <Form.Control
                  placeholder="Search..."
                  aria-label="Search..."
                  className="form-control form-control-sm"
                  onChange={(e) => {
                    setSearch(e.target.value)
                    if(e.target.value == ''){
                      setSearch('')
                      onSearch(name, '')
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onSearch(name, search)
                    }
                    if (e.key === 'Escape') {
                      setSearch('')
                      onSearch(name, '')
                    }
                  }}
                  value={search}
                />
              )
            }
            {
              inputFilter != 'select' &&
              <>
                <Button
                  variant="transparent"
                  className="d-flex align-items-center gap-1 border border-start-0"
                  onClick={() => { setSearch(''); onSearch(name, '');}}
                >
                  <i className="mdi mdi-close"/>
                </Button>
                <Button
                  variant="light"
                  className="d-flex align-items-center gap-1 border"
                  onClick={() => onSearch(name, search)}
                >
                  <i className="mdi mdi-magnify"/>
                </Button>
              </>
            }
          </InputGroup>
        </div>
      }
    </th>
  )
}

const BTable = ({ 
  isLoading,
  data,
  meta,
  columns,
  search,
  setSearch,
  sort,
  setSort,
  activePage,
  setActivePage,
  perPage,
  setPerPage,
  customAction,
  onClickAction,
  tableClassname,
  openModal,
  collapse,
  hideEdit,
  hideDelete,
  hideAction,
}: { 
  isLoading: any;
  data: any;
  meta: any;
  columns: {
    label: any;
    name: any;
    width?: any;
    CustomCell?(data: any): any
  }[];
  search: any;
  setSearch: any;
  sort: any;
  setSort: any;
  activePage: any;
  setActivePage: any;
  perPage: any;
  setPerPage: any;
  customAction?: any;
  onClickAction?: any;
  tableClassname?: any;
  openModal?: any;
  collapse?: boolean;
  hideEdit?: boolean;
  hideDelete?: boolean;
  hideAction?: boolean;
 }) => {
  const random = require('random-array');
  const onSearch = (name: any, value: any) => {
    setSearch({
      ...search,
      [name]: value
    })
  }

  const onSort = (name: any, value: any) => {
    // Single Sorting
    setSort({
      // ...sort,
      [name]: value
    })
  }

  return (
    <>
      <Card>
        <Card.Body className="p-0">
          {/* <div className="table-responsive"> */}
          {/*  table-nowrap */}
          <Table 
            className={classNames(
              "table react-table table-hover mb-0",
              tableClassname
            )}
            responsive
          >
            <thead className="rounded">
              <tr className="">
                {(columns || []).map((column: any) => {
                  return (
                    <TableHeader
                      { ...column }
                      sort={sort}
                      setSort={setSort}
                      onSort={onSort}
                      onSearch={onSearch}
                    />
                  );
                })}
                {
                  !hideAction &&
                  <th style={{width: '80px'}}></th>
                }
              </tr>
            </thead>
            {
              isLoading ? (
                <tbody style={{pointerEvents: 'none'}}>
                  {
                    (random(1,10).oned(perPage) || []).map((item: any) => {
                      return (
                        <tr>
                          {
                            (columns || []).map((column: any) => {
                              return (
                                <td style={{width: column?.width || 'unset'}}>
                                  <div className="row">
                                    <Skeleton width="100%" />
                                  </div>
                                </td>
                              )
                            })
                          }
                        </tr>
                      )
                    })
                  }
                </tbody>
              ) : (
                <tbody>
                  {
                    data.length == 0 ? (
                      <tr style={{pointerEvents: 'none'}}>
                        <td colSpan={columns.length} className="text-center">
                          <div className="d-flex justify-content-center align-items-center" style={{minHeight: '200px'}}>
                            No data
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {(data || []).map((item: any, index: number) => {
                          return (
                            <tr
                              key={index}
                              onDoubleClick={() => onClickAction("edit", item)}
                            >
                              {(columns || []).map((column: any) => {
                                return (
                                  <td>
                                    {column.CustomCell ? (
                                      <>{column.CustomCell(item) || "-"}</>
                                    ) : (
                                      <>{item[column.name]}</>
                                    )}
                                  </td>
                                );
                              })}
          
                              {/* Action Table */}
                                {
                                  !hideAction &&
                                  <td style={{width: '80px'}}>
                                    {isLoading ? (
                                      <div className="row">
                                        <Skeleton width="100%" />
                                      </div>
                                    ) : (
                                      <div className="d-flex">
                                        {
                                          customAction ? customAction(item) : ''
                                        }
                                        {
                                          !hideEdit &&
                                          <button
                                            onClick={() => onClickAction("edit", item)}
                                            type="button"
                                            className="btn btn-link action-icon"
                                          >
                                            <i className="mdi mdi-square-edit-outline"></i>
                                          </button>
                                        }
                                        {
                                          !hideDelete &&
                                          <button
                                            onClick={() => onClickAction("delete", item)}
                                            type="button"
                                            className="btn btn-link action-icon"
                                          >
                                            <i className="mdi mdi-delete"></i>
                                          </button>
                                        }
                                      </div>
                                    )}
                                  </td>
                                }
                            </tr>
                          );
                        })}
                      </>
                    )
                  }
                </tbody>
              )
            }
          </Table>
          {/* </div> */}
        </Card.Body>
      </Card>

      {meta && (
        <PaginationCard
          // stickyBottom
          meta={meta}
          activePage={activePage}
          setActivePage={setActivePage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      )}
    </>
  );
};

export default BTable;
