import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Nav,
  Tab,
  Form,
  Badge,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Navigate, Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import moment from "moment";
import SimpleBar from "simplebar-react";
import { useParams } from "react-router-dom";
import ScaleLoader from "components/ScaleLoader";
import { useGetLeadsQuery } from "services/api";
import { HasAccess } from "@permify/react-role";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { LuPackageOpen } from "react-icons/lu";

// components
import PaginationCard from "components/PaginationCard";
import { FormSelect, BadgeStatus } from "components";
import { CompanyInfoItems } from "./data";

const List = ({
  isLoading,
  data,
  current,
  setCurrent,
  search,
  setSearch,
  filterBranch,
  setFilterBranch,
  sort,
  setSort,
  activePage,
  setActivePage,
  perPage,
  setPerPage,
  layout,
  setLayout,
  isChecked,
  setIsChecked,
  leadsCount,
  setLeadsCount,
  companyInfo,
}: {
  isLoading?: any;
  data?: any;
  current?: any;
  setCurrent?: any;
  search?: any;
  setSearch?: any;
  filterBranch?: any;
  setFilterBranch?: any;
  sort?: any;
  setSort?: any;
  activePage?: any;
  setActivePage?: any;
  perPage?: any;
  setPerPage?: any;
  layout?: any;
  setLayout?: any;
  isChecked?: any;
  setIsChecked?: any;
  leadsCount?: any;
  setLeadsCount?: any;
  companyInfo: CompanyInfoItems[];
}) => {
  const navigate = useNavigate();
  let { applicationCode } = useParams();

  const [comInfo, setComInfo] = useState<Array<CompanyInfoItems>>(companyInfo);
  const [show, setShow] = useState<boolean>(false);

  /*
   * search on data
   */
  const onSearchData = (value: string) => {
    if (value === "") setComInfo(comInfo);
    else {
      var modifiedProducts = comInfo;
      modifiedProducts = modifiedProducts.filter((item) =>
        item.name.toLowerCase().includes(value)
      );
      setComInfo(modifiedProducts);
    }
  };

  /*
   * change order status group
   */
  const changeStatusGroup = (StatusGroup: string) => {
    let updatedData = comInfo;
    //  filter
    updatedData =
      StatusGroup === "All"
        ? comInfo
        : [...comInfo].filter((o) => o.status?.includes(StatusGroup));
    setComInfo(updatedData);
  };

  /*
   *   modal handeling
   */
  const onCloseModal = () => setShow(false);
  const onOpenModal = () => setShow(true);

  /*
    handle form submission
    */
  const onHandleSubmit = () => {
    onCloseModal();
  };

  const onClickView = (item: any) => {
    setCurrent(item);
    setLayout("with-detail");
    navigate(`/lms/applications/${item.leads.code}/${item.code}`);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  };

  return (
    <div 
      className={classNames(
        "position-relative",
        layout == "with-detail" && "d-none d-sm-block"
      )}
    >
      {!isLoading && data?.result?.length == 0 ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center mb-3"
          style={{ minHeight: "200px" }}
        >
          <LuPackageOpen size={64} />
          <p>No data</p>
        </div>
      ) : (
        <>
          {isLoading ? (
            <>
              <div style={{ height: "calc(100vh - 200px)" }}>
                <ScaleLoader />
              </div>
            </>
          ) : (
            <>
              {!isLoading && data?.result?.length == 0 ? (
                <div
                  className="d-flex flex-column justify-content-center align-items-center mb-3"
                  style={{ minHeight: "200px" }}
                >
                  <LuPackageOpen size={64} />
                  <p>No data</p>
                </div>
              ) : (
                <>
                  {(data?.result || []).map((item: any, index: any) => {
                    return (
                      // <Card onDoubleClick={(e) => { onClickView(item) }} key={index} className="mb-2">
                      <Card
                        onDoubleClick={(e) => {
                          onClickView(item);
                        }}
                        key={index}
                        className="mb-2"
                        style={
                          current?.code != item?.code && layout == "with-detail"
                            ? { background: "#ffffff69" }
                            : {}
                        }
                      >
                        {current?.code == item?.code &&
                        layout == "with-detail" ? (
                          <div
                            className="position-absolute h-100 w-100 rounded shadow-lg"
                            style={{ zIndex: "-1" }}
                          ></div>
                        ) : null}
                        <Card.Body>
                          {/* <div className="d-flex justify-content-between align-items-center gap-2"> */}
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="flex-auto align-self-start mt-1 me-1">
                              <div className="d-none d-sm-block">
                                <img
                                  className="avatar-md rounded-circle"
                                  src={item.leads.avatar}
                                  alt=""
                                  // width="64"
                                  style={{ maxHeight: "64px" }}
                                />
                              </div>
                            </div>
                            <div className="flex-fill">
                              <Row>
                                <Col xs={12}>
                                  <div className="d-flex align-items-center">
                                    <div className="w-100">
                                      {/* <h4 className="mt-0 mb-1 font-16 d-flex flex-nowrap flex-sm-wrap justify-content-start align-items-start align-items-sm-center" style={{minHeight: '24px'}}> */}
                                      <h4 className="mt-0 mb-1 font-16 d-flex flex-nowrap flex-sm-wrap justify-content-start align-items-start align-items-sm-center" style={{minHeight: '24px'}}>
                                        <span>{item?.leads?.full_name}</span>
                                        <Badge bg="soft-secondary" className="font-12 text-dark ms-1 font-12 d-none d-md-block">{item?.planning_year}</Badge>
                                        <BadgeStatus
                                          name={item.status_name}
                                          group="Application"
                                          className="font-12 ms-1 d-none d-md-block"
                                        />
                                        {item.counselor_name && (
                                          <Badge
                                            pill
                                            className="px-1 bg-transparent shadow-none d-none d-md-block"
                                          >
                                            <div className="d-flex align-items-center">
                                              <img
                                                className="rounded-circle"
                                                src={item.counselor_avatar}
                                                alt=""
                                                width="25"
                                              />
                                              <span className="ps-1 text-dark">
                                                {item.counselor_name}
                                              </span>
                                            </div>
                                          </Badge>
                                        )}
                                        <div className="d-block d-sm-none ps-1">
                                          <i className="mdi mdi-arrow-right-thick cursor-pointer me-1" onClick={(e) => { onClickView(item) }}/>
                                        </div>
                                      </h4>
                                      {/* <h4
                                        className="mt-0 mb-1 font-16 d-flex flex-wrap justify-content-start align-items-center"
                                        style={{ minHeight: "24px" }}
                                      >
                                        <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center gap-1">
                                          <div className="d-flex align-items-center gap-1">
                                            <span>{item.leads.full_name}</span>
                                            <Badge bg="soft-secondary" className="font-12 text-dark">{item?.planning_year}</Badge>
                                          </div>
                                          <div>
                                            <BadgeStatus
                                              name={item.status_name}
                                              group="Application"
                                              className="font-12"
                                            />
                                            {item.counselor_name && (
                                              <Badge
                                                pill
                                                className="px-1 bg-transparent shadow-none"
                                              >
                                                <div className="d-flex align-items-center">
                                                  <img
                                                    className="rounded-circle"
                                                    src={item.counselor_avatar}
                                                    alt=""
                                                    width="25"
                                                  />
                                                  <span className="ps-1 text-dark">
                                                    {item.counselor_name}
                                                  </span>
                                                </div>
                                              </Badge>
                                            )}
                                          </div>
                                        </div>
                                      </h4> */}
                                      <div className="mb-1">
                                        <div className="d-flex flex-wrap">
                                          <span className="fw-normal">
                                            <OverlayTrigger
                                              placement={"top"}
                                              overlay={
                                                <Tooltip
                                                  style={{ position: "fixed" }}
                                                >
                                                  Copy
                                                </Tooltip>
                                              }
                                            >
                                              <CopyToClipboard
                                                text={item.leads?.code}
                                                onCopy={() => {
                                                  toast.success("Copied", {
                                                    autoClose: 100,
                                                    hideProgressBar: true,
                                                  });
                                                }}
                                              >
                                                <i className="mdi mdi-content-copy cursor-pointer me-1" />
                                              </CopyToClipboard>
                                            </OverlayTrigger>
                                            #{item.leads?.code}<span className="px-1">|</span>
                                          </span>
                                          <span className="fw-normal">
                                            <OverlayTrigger
                                              placement={"top"}
                                              overlay={
                                                <Tooltip
                                                  style={{ position: "fixed" }}
                                                >
                                                  Copy
                                                </Tooltip>
                                              }
                                            >
                                              <CopyToClipboard
                                                text={item.code}
                                                onCopy={() => {
                                                  toast.success("Copied", {
                                                    autoClose: 100,
                                                    hideProgressBar: true,
                                                  });
                                                }}
                                              >
                                                <i className="mdi mdi-content-copy cursor-pointer me-1" />
                                              </CopyToClipboard>
                                            </OverlayTrigger>
                                            #{item.code}
                                          </span>
                                          {item.email && (
                                            <>
                                              <span className="px-1">|</span>
                                              <span className="fw-normal">
                                                {item.email}
                                              </span>
                                            </>
                                          )}
                                          {item.phone && (
                                            <>
                                              <span className="px-1">|</span>
                                              <span className="fw-normal">
                                                {item.phone}
                                              </span>
                                            </>
                                          )}
                                          {item.dob_dMY && (
                                            <>
                                              <span className="px-1">|</span>
                                              <span className="fw-normal">
                                                {item.dob_dMY}
                                              </span>
                                            </>
                                          )}
                                          {item.grade_name && (
                                            <>
                                              <span className="px-1">|</span>
                                              <span className="fw-normal">
                                                {item.grade_name}
                                              </span>
                                            </>
                                          )}
                                          {item.school_name && (
                                            <>
                                              <span className="px-1">|</span>
                                              <span className="fw-normal">
                                                {item.school_name}
                                              </span>
                                            </>
                                          )}
                                          {item.institution_name && (
                                            <>
                                              <span className="px-1">|</span>
                                              <span className="fw-normal">
                                                {item.institution_name}
                                              </span>
                                            </>
                                          )}
                                          {item.course_level_group && (
                                            <>
                                              <span className="px-1">|</span>
                                              <span className="fw-normal">
                                                {item.course_level_group}
                                              </span>
                                            </>
                                          )}
                                          {item.course_level_name && (
                                            <>
                                              <span className="px-1">|</span>
                                              <span className="fw-normal">
                                                {item.course_level_name}
                                              </span>
                                            </>
                                          )}
                                          {item.course_major_name && (
                                            <>
                                              <span className="px-1">|</span>
                                              <span className="fw-normal">
                                                {item.course_major_name}
                                              </span>
                                            </>
                                          )}
                                          {item.course_full_name_name && (
                                            <>
                                              <span className="px-1">|</span>
                                              <span className="fw-normal">
                                                {item.course_full_name_name}
                                              </span>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={12}>
                                  <div className="d-flex flex-wrap gap-1 align-items-center justify-content-start mt-1">
                                    <div className="d-md-none">
                                      <Badge bg="soft-secondary" className="font-12 text-dark ms-1 font-12 d-none d-md-block">{item?.planning_year}</Badge>
                                      <BadgeStatus
                                        name={item.status_name}
                                        group="Application"
                                        className="font-12"
                                      />
                                      {item.counselor_name && (
                                        <Badge
                                          pill
                                          className="px-1 bg-transparent shadow-none"
                                        >
                                          <div className="d-flex align-items-center">
                                            <img
                                              className="rounded-circle"
                                              src={item.counselor_avatar}
                                              alt=""
                                              width="25"
                                            />
                                            <span className="ps-1 text-dark">
                                              {item.counselor_name}
                                            </span>
                                          </div>
                                        </Badge>
                                      )}
                                    </div>
                                    <div className="d-flex gap-1 align-items-center justify-content-start">
                                      <div className="px-1 bg-primary badge rounded-pill bg-primary">
                                        {item?.branch_name}
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <Col sm={"2"} className="d-none d-sm-block">
                              <div className="text-sm-end">
                                {current?.code != item?.code && (
                                  <Button
                                    size="sm"
                                    variant="primary"
                                    className="rounded-pill waves-effect waves-light text-nowrap "
                                    onClick={() => onClickView(item)}
                                  >
                                    <div className="d-flex align-items-center gap-1">
                                      {/* <i className="mdi mdi-account-settings-outline"></i> */}
                                      <span>View</span>
                                    </div>
                                  </Button>
                                )}
                                {/* <Link to="#" className="action-icon text-secondary font-20">
                      {" "}
                      <i className="mdi mdi-square-edit-outline"></i>
                    </Link>
                    <Link to="#" className="action-icon text-secondary font-20">
                      {" "}
                      <i className="mdi mdi-delete"></i>
                    </Link> */}
                              </div>
                            </Col>
                          </div>
                        </Card.Body>
                      </Card>
                    );
                  })}
                </>
              )}
            </>
          )}
        </>
      )}

      {data.meta && (
        <PaginationCard
          stickyBottom
          meta={data.meta}
          activePage={activePage}
          setActivePage={setActivePage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      )}
    </div>
  );
};

export default List;
