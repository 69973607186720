
import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { faker } from '@faker-js/faker';
import { useEffectOnce } from "usehooks-ts";
import moment from "moment";
import ScaleLoader from "components/ScaleLoader";
import { toast } from "react-toastify";
import { IoWarningOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";

// components
import {
  FormDate,
  FormInput,
  FormSelect,
  FormSelectAsync
} from "components/";

import {
  useGetAutocompleteQuery,
  useGetBranchQuery,
  useGetUserQuery,
  useSearchAutocompleteMutation,
  useCreateLeadsMutation,
  useUpdateLeadsMutation,
  useDeleteLeadsMutation,
} from "services/api";

interface ModalLeadsProps {
  data?: any;
  type: string;
  show: boolean;
  onHide: () => void;
  refetch?: any;
  refetchLeads?: any;
}

const yearOptions = () => {
  let sy = 2017;
  let cy = new Date().getFullYear()
  let ey = cy + 5;
  let yrs = [];

  for (var i = sy; i <= ey; i++) {
    yrs.push({
      value: sy,
      label: sy,
    });
    sy++;
  }
  return yrs;
}

const validation = yupResolver(
  yup.object().shape({
    branch_code: yup.string().transform((value) => (value == null ? '' : value)).label('Branch').required(),
    counselor_code: yup.string().transform((value) => (value == null ? '' : value)).label('Counselor').required(''),
    title: yup.string().transform((value) => (value == null ? '' : value)).required('').label('Title'),
    full_name: yup.string().transform((value) => (value == null ? '' : value)).required('').label('Full name'),
    email: yup.string().transform((value) => (value == null ? '' : value)).email().required('').label('Email'),
    phone: yup.string().transform((value) => (value == null ? '' : value)).min(10, 'Please fill a valid phone number').max(20).required('').label('Phone'),
    dob: yup.string().transform((value) => (value == null ? '' : value)).test(
      "date",
      "Invalid date or format. The string should be a valid DD MMMM YYYY format.",
      (val: any) => {
        if (!val) {
          return true;
        }
        return moment(val, true).isValid();
      }
    ).required('').label('Date of birth'),
    address: yup.string().transform((value) => (value == null ? '' : value)).required('').label('Address'),
    // zip_code: yup.string().required('').label('ZIP Code'),
    kecamatan_code: yup.string().transform((value) => (value == null ? '' : value)).required('').label('Subdistrict'),
    kota_name: yup.string().label('City'),
    provinsi_name: yup.string().label('Province'),
    // kota_id: yup.string().required('').label('City'),
    // provinsi_id: yup.string().required('').label('Province'),
    parents_name: yup.string().nullable().label('Parents name'),
    parents_phone: yup.string().max(20).nullable().label('Parents phone'),
    parents_email: yup.string().email().nullable().label('Parents email'),
    
    // institution_code: yup.string().transform((value) => (value == null ? '' : value)).label('Institution').required(''),
    planning_year: yup.string().transform((value) => (value == null ? '' : value)).label('Year of Study').required(''),
    course_level_code: yup.string().transform((value) => (value == null ? '' : value)).label('Course level').required(''),
    course_major_code: yup.string().transform((value) => (value == null ? '' : value)).label('Study Area').required(''),
    // course_full_name_code: yup.string().transform((value) => (value == null ? '' : value)).label('Course full name').required(''),
    marketing_source_code: yup.string().transform((value) => (value == null ? '' : value)).label('Marketing source').required(''),
    
    school_code: yup.string().transform((value) => (value == null ? '' : value)).label('School').required(''),
    grade_code: yup.string().transform((value) => (value == null ? '' : value)).label('Grade').required(''),
  })
);

const ModalManage = ({ data, type, show, onHide, refetch, refetchLeads }: ModalLeadsProps) => {
  const navigate = useNavigate();

  const [current, setCurrent] = useState(data || {})
  const [emailBirthday, setEmailBirthday] = useState(data?.birthday_notification || true);
  const [branchCode, setBranchCode] = useState(data?.branch_code || '')
  const [counselorCode, setCounselorCode] = useState(data?.counselor_code || '')
  const [gradeCode, setGradeCode] = useState(data?.grade_code || '')
  const [schoolCode, setSchoolCode] = useState((data?.school_code) ? {
    value: data?.school_code,
    label: data?.school_name
  } : undefined)
  
  const {
    data: branchList,
    isFetching: isFetchingBranch,
    isLoading: isLoadingBranch,
    isUninitialized: isUninitializedBranch,
    isError: isErrorBranch,
    error: errorBranch,
    refetch: refetchBranch,
  } = useGetBranchQuery(
    {},
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  const [createLeads, { isLoading: isLoadingCreate }] = useCreateLeadsMutation();
  const [updateLeads, { isLoading: isLoadingUpdate }] = useUpdateLeadsMutation();
  const [deleteLeads, { isLoading: isLoadingDelete }] = useDeleteLeadsMutation();

  const {
    data: counselorList,
    isFetching: isFetchingCounselor,
    isLoading: isLoadingCounselor,
    isUninitialized: isUninitializedCounselor,
    isError: isErrorCounselor,
    error: errorCounselor,
    refetch: refetchCounselor,
  } = useGetUserQuery(
    {
      search: {
        role_name: 'Counselor'
      },
      branch_code: [branchCode]
    },
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      skip: !branchCode,
    }
  );
  
  const { data: institutionList, isLoading: isLoadingInstitution, isUninitialized: isUninitializedInstitution, isFetching: isFetchingInstitution } = useGetAutocompleteQuery({type: 'INS'});
  const { data: courseLevelList, isLoading: isLoadingCourseLevel, isUninitialized: isUninitializedCourseLevel, isFetching: isFetchingCourseLevel } = useGetAutocompleteQuery({type: 'CRL'});
  const { data: courseMajorList, isLoading: isLoadingCourseMajor, isUninitialized: isUninitializedCourseMajor, isFetching: isFetchingCourseMajor } = useGetAutocompleteQuery({type: 'CMJ'});
  const { data: courseFullNameList, isLoading: isLoadingCourseFullName, isUninitialized: isUninitializedCourseFullName, isFetching: isFetchingCourseFullName } = useGetAutocompleteQuery({type: 'CFN'});
  const { data: marketingSourceList, isLoading: isLoadingMarketingSource, isUninitialized: isUninitializedMarketingSource, isFetching: isFetchingMarketingSource } = useGetAutocompleteQuery({type: 'MRS'});
  const { data: schoolList, isLoading: isLoadingSchool, isUninitialized: isUninitializedSchool, isFetching: isFetchingSchool } = useGetAutocompleteQuery({type: 'SCH'});
  const { data: gradeList, isLoading: isLoadingGrade, isUninitialized: isUninitializedGrade, isFetching: isFetchingGrade } = useGetAutocompleteQuery({type: 'GRD'});

  const [searchAutocomplete, { isLoading: isLoadingSearchAutocomplete }] = useSearchAutocompleteMutation();
  const loadKecamatanOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    if(inputValue.length >= 2 ){
      searchAutocomplete({
        type: 'KCM',
        search: inputValue,
      })
        .unwrap()
        .then((res: any) => {
          callback(res?.result);
        })
        .then((error: any) => {
          console.log("error");
          callback([]);
        });
    }
  };

  const loadSchoolOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    if(inputValue.length >= 2 ){
      searchAutocomplete({
        type: 'SCH',
        // filter: {
        //   grade_code: getValues()['grade_code'],
        // },
        search: inputValue,
        limit: 100,
      })
        .unwrap()
        .then((res: any) => {
          callback(res?.result);
        })
        .then((error: any) => {
          console.log("error");
          callback([]);
        });
    }
  };

  const methods = useForm({ resolver: validation });
  const {
    handleSubmit,
    register,
    control,
    trigger,
    getFieldState,
    setValue,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
    reset,
  } = methods;

  const onSubmit = async (values: any) => {
    switch (type) {
      case 'add':
        await createLeads({
          ...values,
          branch_code: branchCode,
          counselor_code: counselorCode,
          // dob: moment(values.dob,'dd MMM yyyy').format('YYYY-MM-DD'),
          birthday_notification: emailBirthday,
        })
        .unwrap()
        .then((res: any) => {
          if(res.status){
            onHide()
            refetch()
            toast.success(res.message)
          }
        })
        .catch((error: any) => {
          clearErrors()
          if(error?.data?.validation){
            Object.keys(error?.data?.validation)?.forEach((field) => {
              error?.data?.validation[field]?.forEach((message: string) => {
                setError(field, { type: 'custom', message: message })
              });
            });
          }
          console.log("error");
        });
        break;

      case 'edit':
        await updateLeads({
          ...values,
          leads_code: data?.code,
          branch_code: branchCode,
          counselor_code: counselorCode,
          // dob: moment(values.dob,'dd MMM yyyy').format('YYYY-MM-DD'),
          birthday_notification: emailBirthday,
        })
        .unwrap()
        .then((res: any) => {
          if(res.status){
            onHide()
            refetch()
            toast.success(res.message)
          }
        })
        .catch((error: any) => {
          clearErrors()
          if(error?.data?.validation){
            Object.keys(error?.data?.validation)?.forEach((field) => {
              error?.data?.validation[field]?.forEach((message: string) => {
                setError(field, { type: 'custom', message: message })
              });
            });
          }
          console.log("error");
        });
        break;
        
      case 'delete':
        await deleteLeads({
          leads_code: data?.code,
        })
        .unwrap()
        .then((res: any) => {
          if(res.status){
            refetchLeads()
            onHide()
            navigate('/lms/leads')
            toast.success(res.message)
          }
        })
        .catch((error: any) => {
          clearErrors()
          if(error?.data?.validation){
            Object.keys(error?.data?.validation)?.forEach((field) => {
              error?.data?.validation[field]?.forEach((message: string) => {
                setError(field, { type: 'custom', message: message })
              });
            });
          }
          console.log("error");
        });
        break;
    
      default:
        break;
    }
  };

  useEffectOnce(() => {
    // setCurrent(data);
    if (data) {
      setValue('type', type);
      setBranchCode(data?.branch_code)
      setCounselorCode(data?.counselor_code)
      Object.keys(data).map((key) => {
        setValue(key, data[key]);
      });
    }
  });

  useEffect(() => {
    console.log(errors)
  }, [errors])
  
  

  // useEffectOnce(() => {
  //   // Assignment Info
  //   setBranchCode('BR02')
  //   setCounselorCode('USR24474')
  //   setValue('branch_code','BR02');
  //   setValue('counselor_code','USR24474');
    
  //   // Personal Info
  //   setValue('title','Mrs');
  //   setValue('full_name',faker.person.fullName());
  //   setValue('email',faker.internet.email());
  //   setValue('phone','+6298127398123');
  //   setValue('dob','11-02-2000');
  //   setValue('address','Jl. Pengairan No. 12');
  //   setValue('zip_code','57467');
  //   setValue('kecamatan_code','KEC0003181');
  //   setValue('kota_name','Kota Semarang');
  //   setValue('provinsi_name','Jawa Tengah');

  //   // Parent's Detail
  //   setValue('parents_name',faker.person.fullName());
  //   setValue('parents_phone','+6298127398123');
  //   setValue('parents_email',faker.internet.email());

  //   // Interested In
  //   setValue('institution_code','INS2400'+faker.number.int({ min: 1, max: 9 }));
  //   setValue('planning_year','202'+faker.number.int({ min: 4, max: 9 }));
  //   setValue('course_level_code','CLE240'+faker.number.int({ min: 1, max: 9 }));
  //   setValue('course_major_code','CMJ240'+faker.number.int({ min: 1, max: 9 }));
  //   setValue('course_full_name_code','CFM240'+faker.number.int({ min: 1, max: 9 }));
  //   setValue('marketing_source_code','MKS2400'+faker.number.int({ min: 1, max: 9 }));

  //   // Current Education
  //   setValue('grade_code','GR2400'+faker.number.int({ min: 1, max: 6 }));
  //   setValue('school_code','SC10200001');
  //   setValue('marketing_source_code','MKS2400'+faker.number.int({ min: 1, max: 9 }));
  // })

  return (
    <>
      <Modal
        backdrop="static"
        size={type == 'delete' ? undefined : 'lg'}
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {
          type != 'delete' &&
          <Modal.Header className="" onHide={onHide} closeButton>
            <Modal.Title className="m-0">
              {`${type == 'add' ? 'Add New' : type} Leads`.toUpperCase()}{" "}
            </Modal.Title>
          </Modal.Header>
        }
        <Modal.Body className="p-4">
          {/* {
            (
              isLoadingInstitution ||
              isLoadingCourseLevel ||
              isLoadingCourseMajor ||
              isLoadingCourseFullName ||
              isLoadingMarketingSource ||
              isLoadingSchool ||
              isLoadingGrade ||
              // isLoadingSearchAutocomplete || 
              isLoadingBranch ||
              isLoadingCounselor
            ) ? (
              <div className="d-flex justify-content-start align-items-start" style={{minHeight: '200px'}}>
                <ScaleLoader />
              </div>
            ) : ( */}
              <form
                onSubmit={handleSubmit((values) => onSubmit(values))}
                noValidate
              >
                {(type == "add" || type == "edit") && (
                  <>
                    <Row>
                      <Col xs={12} xl={6}>
                        <h4 className="mt-0">Assignment Info</h4>
                        <p className="sub-header">Please select branch and counselor name to assign this lead</p>
                        <FormSelect
                          label="Branch Name"
                          type="text"
                          name="branch_code"
                          placeholder="e.g. Tangerang"
                          containerClass={"mb-3"}
                          defaultValue={branchCode || undefined}
                          options={(branchList?.result || []).map((item: any) => {
                            return {
                              label: item.name,
                              value: item.code,
                            }
                          })}
                          disabled={isLoadingBranch || isUninitializedBranch || isFetchingBranch}
                          register={register}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          clearErrors={clearErrors}
                          onChange={(opt: any) => {
                            setCounselorCode('')
                            setValue('counselor_code', '')
                            setBranchCode(opt.value)
                            setValue('branch_code', opt.value)
                          }}
                        />
                        <FormSelect
                          label="Counselor Name"
                          type="text"
                          name="counselor_code"
                          placeholder="e.g. Dewi"
                          containerClass={"mb-3"}
                          defaultValue={counselorCode || undefined}
                          // defaultValue={getValues()['counselor_code'] || undefined}
                          // defaultValue={getValues()['counselor_code'] || data?.counselor_code || counselorCode || undefined}
                          options={(counselorList?.result || []).map((item: any) => {
                            return {
                              label: item.name,
                              value: item.code,
                            }
                          })}
                          disabled={isLoadingCounselor || isUninitializedCounselor || isFetchingCounselor}
                          register={register}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          clearErrors={clearErrors}
                          onChange={(opt: any) => {
                            setCounselorCode(opt.value)
                            setValue('counselor_code', opt.value)
                          }}
                          isLoading={isLoadingCounselor || isFetchingCounselor}
                        />
                        <h4 className="header-title">Personal Info</h4>
                        <p className="sub-header">Please enter leads personal details correctly</p>
                        <div className="row">
                          <div className="col-4">
                            <FormSelect
                              label="Title"
                              type="text"
                              name="title"
                              placeholder="Mr"
                              containerClass={"mb-3"}
                              defaultValue={getValues()['title'] || data?.title || undefined}
                              options={[
                                { value: "Mr", label: "Mr" },
                                { value: "Mrs", label: "Mrs" },
                                { value: "Miss", label: "Miss" },
                              ]}
                              // disabled={isLoading}
                              register={register}
                              control={control}
                              errors={errors}
                              setValue={setValue}
                              clearErrors={clearErrors}
                              onChange={(opt: any) => {
                                setValue('title', opt.value);
                              }}
                            />
                          </div>
                          <div className="col-8">
                            <FormInput
                              label="Full Name"
                              type="text"
                              name="full_name"
                              placeholder="e.g. Agus Hanif"
                              containerClass={"mb-3"}
                              register={register}
                              control={control}
                              trigger={trigger}
                              getFieldState={getFieldState}
                              errors={errors}
                              setValue={setValue}
                              // defaultValue={leads?.full_name || undefined}
                            />
                          </div>
                        </div>
                        <FormInput
                          label="Email"
                          type="text"
                          name="email"
                          placeholder="e.g. agushan392@email.com"
                          containerClass={"mb-3"}
                          register={register}
                          control={control}
                          trigger={trigger}
                          getFieldState={getFieldState}
                          errors={errors}
                          setValue={setValue}
                          // defaultValue={leads?.email || undefined}
                        />
                        <FormInput
                          label="Phone"
                          type="text"
                          name="phone"
                          placeholder="e.g. +62813456789876"
                          containerClass={"mb-3"}
                          register={register}
                          control={control}
                          trigger={trigger}
                          getFieldState={getFieldState}
                          errors={errors}
                          setValue={setValue}
                          // defaultValue={leads?.phone || undefined}
                        />
                        <FormDate
                          label="Date of Birth"
                          type="date"
                          name="dob"
                          placeholder="e.g. 12 Jan 2000"
                          containerClass={"mb-3"}
                          register={register}
                          control={control}
                          trigger={trigger}
                          getFieldState={trigger}
                          errors={errors}
                          setValue={setValue}
                          // defaultValue={'' || undefined}
                          defaultValue={getValues()['dob'] || data?.dob || undefined}
                          clearErrors={clearErrors}
                          // isDateMask
                          disabled
                        />
                        <div className="mt-n2 mb-3">
                          <div className="form-check form-switch">
                            <input
                              type="checkbox"
                              id="custom-switch"
                              className="form-check-input border-application"
                              defaultChecked={emailBirthday}
                              onChange={(e) => {
                                setEmailBirthday(e.target.checked);
                              }}
                            />
                            <label
                              title=""
                              htmlFor="custom-switch"
                              className="form-check-label fw-normal"
                            >
                              Send birthday wishes to this person?
                            </label>
                          </div>
                        </div>
                        <FormInput
                          label="Address & Postcode"
                          type="textarea"
                          name="address"
                          placeholder="e.g. Jl. Pengairan No. 12, Kel. Kebon Kacang 10240"
                          containerClass={"mb-3"}
                          register={register}
                          control={control}
                          trigger={trigger}
                          getFieldState={getFieldState}
                          errors={errors}
                          setValue={setValue}
                          // defaultValue={leads?.address || undefined}
                        />
                        <div className="row">
                          {/* <div className="col-4">
                            <FormInput
                              label="Postcode"
                              type="text"
                              name="zip_code"
                              placeholder="e.g. 10270"
                              containerClass={"mb-3"}
                              register={register}
                              control={control}
                              trigger={trigger}
                              getFieldState={getFieldState}
                              errors={errors}
                              setValue={setValue}
                              // defaultValue={leads?.zip_code || undefined}
                              minLength={5}
                              maxLength={5}
                            />
                          </div> */}
                          <div className="col-12">
                            <FormSelectAsync
                              className="w-100"
                              label="Subdistrict (Kecamatan), City & Province"
                              name="kecamatan_code"
                              placeholder="e.g. Tanah Abang, Jakarta Pusat..."
                              containerClass={"mb-3"}
                              defaultValue={data?.kecamatan_code ? {
                                value: data?.kecamatan_code,
                                label: `${data?.kecamatan_name}, ${data?.kota_name}, ${data?.provinsi_name}`
                              } : undefined}
                              // defaultOptions={data?.kecamatan_code ? [{
                              //   value: data?.kecamatan_code,
                              //   label: data?.kecamatan_name
                              // }] : undefined}
                              loadOptions={loadKecamatanOptions}
                              // disabled={isLoading}
                              register={register}
                              control={control}
                              errors={errors}
                              // setValue={setValue}
                              setError={setError}
                              clearErrors={clearErrors}
                              onChange={(option: any) => {
                                setValue('kecamatan_code', option.value)
                                let arr = option.label.split(',');
                                setValue('kota_name',arr[1].trim())
                                setValue('provinsi_name',arr[2].trim())
                              }}
                              isClearable
                            />
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-6">
                            <FormInput
                              className="bg-light"
                              label="City"
                              type="text"
                              name="kota_name"
                              placeholder=""
                              containerClass={"mb-3"}
                              register={register}
                              control={control}
                              trigger={trigger}
                              getFieldState={getFieldState}
                              errors={errors}
                              setValue={setValue}
                              // defaultValue={leads?.kota_name || undefined}
                              disabled
                            />
                          </div>
                          <div className="col-6">
                            <FormInput
                              className="bg-light"
                              label="Province"
                              type="text"
                              name="provinsi_name"
                              placeholder=""
                              containerClass={"mb-3"}
                              register={register}
                              control={control}
                              trigger={trigger}
                              getFieldState={getFieldState}
                              errors={errors}
                              setValue={setValue}
                              // defaultValue={leads?.provinsi_name || undefined}
                              disabled
                            />
                          </div>
                        </div> */}
                        
                        <h4 className="">Parent's Details / Emergency Contact (Optional)</h4>
                        <p className="sub-header">Please enter emergency contact detail</p>
                        <FormInput
                          label="Name"
                          type="text"
                          name="parents_name"
                          placeholder="e.g. Dwi Kuncoro"
                          containerClass={"mb-3 w-75"}
                          register={register}
                          control={control}
                          trigger={trigger}
                          getFieldState={getFieldState}
                          errors={errors}
                          setValue={setValue}
                          // defaultValue={leads?.parents_name || undefined}
                        />
                        <div className="row">
                          <div className="col-6">
                            <FormInput
                              label="Phone"
                              type="text"
                              name="parents_phone"
                              placeholder="e.g. +62813456789876"
                              containerClass={"mb-3"}
                              register={register}
                              control={control}
                              trigger={trigger}
                              getFieldState={getFieldState}
                              errors={errors}
                              setValue={setValue}
                              // defaultValue={leads?.parents_phone || undefined}
                            />
                          </div>
                          <div className="col-6">
                            <FormInput
                              label="Email"
                              type="text"
                              name="parents_email"
                              placeholder="e.g. dwikcr@email.com"
                              containerClass={"mb-3"}
                              register={register}
                              control={control}
                              trigger={trigger}
                              getFieldState={getFieldState}
                              errors={errors}
                              setValue={setValue}
                              // defaultValue={leads?.parents_email || undefined}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} xl={6}>
                        <h4 className="header-title">Interested In</h4>
                        <p className="sub-header">Please select preferenced institution and course details</p>
                        {/* <FormSelect
                          label="Institution"
                          name="institution_code"
                          placeholder="e.g. SIM - Singapore"
                          containerClass={"mb-3"}
                          defaultValue={getValues()['institution_code'] || data?.institution_code || undefined}
                          options={(institutionList?.result || [])}
                          // disabled={isLoading}
                          disabled={isLoadingInstitution || isUninitializedBranch || isFetchingBranch}
                          register={register}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          clearErrors={clearErrors}
                          onChange={(opt: any) => {
                            // console.log(opt)
                            // setCalcMarketingBranding(opt.value);
                          }}
                        /> */}
                        <FormSelect
                          label="Year of Study"
                          type="text"
                          name="planning_year"
                          placeholder="e.g. 2024"
                          containerClass={"mb-3"}
                          defaultValue={getValues()['planning_year'] || data?.planning_year || undefined}
                          options={yearOptions()}
                          // disabled={isLoading}
                          register={register}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          clearErrors={clearErrors}
                          // onChange={(opt: any) => {
                          //   setCalcMarketingBranding(opt.value);
                          // }}
                        />
                        <FormSelect
                          label="Course Level"
                          name="course_level_code"
                          placeholder="e.g. Foundation"
                          containerClass={"mb-3"}
                          defaultValue={getValues()['course_level_code'] || data?.course_level_code || undefined}
                          options={(courseLevelList?.result || [])}
                          // disabled={isLoading}
                          register={register}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          clearErrors={clearErrors}
                          // onChange={(opt: any) => {
                          //   setCalcMarketingBranding(opt.value);
                          // }}
                        />
                        <FormSelect
                          label="Study Area"
                          name="course_major_code"
                          placeholder="e.g. Science"
                          containerClass={"mb-3"}
                          defaultValue={getValues()['course_major_code'] || data?.course_major_code || undefined}
                          options={(courseMajorList?.result || [])}
                          // disabled={isLoading}
                          register={register}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          clearErrors={clearErrors}
                          // onChange={(opt: any) => {
                          //   setCalcMarketingBranding(opt.value);
                          // }}
                        />
                        {/* <FormSelect
                          label="Course Full Name"
                          name="course_full_name_code"
                          placeholder="e.g. Sport Science"
                          containerClass={"mb-3"}
                          defaultValue={getValues()['course_full_name_code'] || data?.course_full_name_code || undefined}
                          options={(courseFullNameList?.result || [])}
                          // disabled={isLoading}
                          register={register}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          clearErrors={clearErrors}
                          // onChange={(opt: any) => {
                          //   setCalcMarketingBranding(opt.value);
                          // }}
                        /> */}
                        <h4 className="header-title">Current Education</h4>
                        <p className="sub-header">Please select current of former education</p>
                        <FormSelect
                          label="Grade"
                          name="grade_code"
                          placeholder="e.g. Year 10 / O Level"
                          containerClass={"mb-3"}
                          // defaultValue={getValues()['grade_code'] || data?.grade_code || undefined}
                          defaultValue={gradeCode || undefined}
                          options={(gradeList?.result || [])}
                          // disabled={isLoading}
                          register={register}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          clearErrors={clearErrors}
                          onChange={(opt: any) => {
                            // setSchoolCode(undefined) // {}
                            // setValue('school_code', undefined)
                            setGradeCode(opt.value)
                            setValue('grade_code', opt.value)
                          }}
                          isClearable
                        />
                        <FormSelectAsync
                          className="w-100"
                          label="School"
                          name="school_code"
                          placeholder="e.g. SMA N 11 Jakarta"
                          containerClass={"mb-3"}
                          defaultValue={schoolCode}
                          // defaultValue={(getValues()['school_code'] || data?.school_code) ? {
                          //   value: getValues()['school_code'] || data?.school_code,
                          //   label: getValues()['school_name'] || data?.school_name
                          // } : undefined}
                          // defaultOptions={data?.school_code ? [{
                          //   value: data?.school_code,
                          //   label: data?.kecamatan_name
                          // }] : undefined}
                          loadOptions={loadSchoolOptions}
                          // disabled={isLoading}
                          register={register}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          setError={setError}
                          clearErrors={clearErrors}
                          trigger={trigger}
                          onChange={(option: any, actionname: any) => {
                            // setValue('school_code', option.value)
                            // setSchoolCode(option)
                            // if(actionname.action == 'clear'){
                            //   setSchoolCode(undefined)
                            //   setValue('school_code', '')
                            //   setValue('school_name', '')
                            // }
                          }}
                          isClearable
                        />
                        <h4 className="header-title">Reference</h4>
                        <p className="sub-header">Where did you hear about JACK Study Abroad?</p>
                        <FormSelect
                          label="Marketing Source"
                          name="marketing_source_code"
                          placeholder="e.g. Google"
                          containerClass={"mb-3"}
                          defaultValue={getValues()['marketing_source_code'] || data?.marketing_source_code || undefined}
                          options={(marketingSourceList?.result || [])}
                          // disabled={isLoading}
                          register={register}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          clearErrors={clearErrors}
                          // onChange={(opt: any) => {
                          //   setCalcMarketingBranding(opt.value);
                          // }}
                        />
                      </Col>
                    </Row>
                    <div className="text-end">
                      <Button
                        variant="success"
                        type="submit"
                        className="waves-effect waves-light me-1"
                        disabled={isLoadingCreate || isLoadingUpdate || isLoadingDelete}
                      >
                        Save
                      </Button>
                      {/* <Button
                        variant="danger"
                        className="waves-effect waves-light"
                        onClick={onHide}
                      >
                        Continue
                      </Button> */}
                    </div>
                  </>
                  )
                }
                {type == "delete" && (
                  <div className="text-center">
                    <div onClick={onHide} className="position-absolute top-0 end-0 m-2 cursor-pointer">
                        <i className="mdi mdi-close font-22"/>
                      </div>
                      <IoWarningOutline size={64} className="text-danger"/>
                    <h4 className="mt-2">Please confirm your action</h4>
                    <p className="mt-3 mb-0">
                      Are you sure to delete <strong>{data.name}</strong>?
                    </p>
                    <p>This action cannot be undone.</p>
                    <Button
                      variant="danger"
                      type="button"
                      className="waves-effect waves-light me-1"
                      disabled={isLoadingCreate || isLoadingUpdate || isLoadingDelete}
                      onClick={() => onSubmit({leads_code: data?.code})}
                    >
                      Confirm
                    </Button>
                  </div>
                )}
              </form>
            {/* )
          } */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalManage;
