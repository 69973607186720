import React, { useState, useEffect } from "react";
import { Row, Col, Card, Tab, Nav, Button, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { faker } from '@faker-js/faker';
import moment from 'moment';
import DocumentUploader from "components/DocumentUploader";
import { FormSelect, BadgeStatus } from "components"
import { useParams } from "react-router-dom";
import ScaleLoader from "components/ScaleLoader";
import { LuPackageOpen } from "react-icons/lu";
import { useEffectOnce } from "usehooks-ts";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { NumericFormat } from 'react-number-format';

import ModalApplication from './ModalApplication'
import ModalStatus from './ModalStatus'
import ModalDocument from './ModalDocument'

import {
  useGetBranchQuery,
  useGetStatusQuery,
  useGetApplicationQuery,
  useGetTopCounselorQuery,
  useGetApplicationHistoryQuery,
  useGetApplicationChecklistQuery,
  useGetApplicationDocumentQuery,
  useUpdateApplicationChecklistMutation,
  useDeleteApplicationDocumentMutation,
  useExportApplicationMutation,
  useGetStorageMutation,
} from "services/api";

const cityName = faker.helpers.arrayElement([
  'Jakarta Barat',
  'Jakarta Utara',
  'Jakarta Selatan',
  'Jakarta Timur',
  'Tangerang',
  'Bekasi',
]);

const checklists = [
  { 
    id: 0,
    title: "Application document", 
    completed: false
  },
  { 
    id: 1,
    title: "Application passport", 
    completed: false
  },
  { 
    id: 2,
    title: "Parent's passport", 
    completed: false
  },
  { 
    id: 3,
    title: "Personal Identity (KTP)", 
    completed: false
  },
  { 
    id: 4,
    title: "Proof of payment", 
    completed: false
  },
  { 
    id: 5,
    title: "Copy of Visa document", 
    completed: false
  },
];

const attachments =[
  { id: 1, filename: "ktp.zip", size: "2.3 MB" },
  { id: 2, filename: "passport.zip", size: "1.3 MB" },
];

const StatusHistory = ({history, application, openModalStatus, isLast} : { history: any, application?: any, openModalStatus: any, isLast: boolean}) => {
  if(history.status_name == application?.status_name){
    return (
      <li className={`${isLast && 'hide-border'}`}>
        <span className="active-dot dot"></span>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h5 className="mt-0 mb-1">
              <span className="bg-soft-primary py-1 px-2 rounded">{history?.status_name}</span>
            </h5>
            <p className="text-muted mb-0">
              <small className="text-muted">{history?.date}</small>
            </p>
            <p className="font-12">{history?.note}</p>
          </div>
          {
            !['Withdrawn','Completed','Cancelled','Rejected'].includes(history.status_name) &&
            <div className="mt-n4">
              <Button
                size="sm"
                variant="primary"
                className="waves-effect waves-light"
                onClick={() => {openModalStatus(history)}}
              >
                Update Status
              </Button>
            </div>
          }
        </div>
      </li>
    )
  }

  if(history?.code){
    return (
      <li className="completed">
        <h5 className="mt-0 mb-1">{history?.status_name}</h5>
        <p className="text-muted mb-0">
          <small className="text-muted">{history?.date}</small>
        </p>
        <p className="font-12">{history?.note}</p>
      </li>
    )
  } else {
    return (
      <li>
        <h5 className="mt-0 mb-1">{history?.status_name}</h5>
      </li>
    );
  }
}

const DocumentItem = ({data, refetch} : {data: any, refetch: any}) => {
  const [url, setUrl] = useState();
  const ext = data.name.substr(data.name.lastIndexOf(".") + 1);

  const [layout, setLayout] = useState('default');
  
  const [deleteApplicationDocument, { isLoading: isLoadingDeleteDocument }] = useDeleteApplicationDocumentMutation();
  const [getStorage, { isLoading: isLoadingStorage }] = useGetStorageMutation();

  useEffectOnce(() => {
    getStorage({
      path: data?.path
    })
    .unwrap()
    .then((res: any) => {
      if(res?.result){
        setUrl(res?.result)
      }
    })
    .catch((error: any) => {
      console.log("error");
    });
  })
  
  const onConfirmDelete = async () => {
    await deleteApplicationDocument({
      application_document_code: data?.code
    })
    .unwrap()
    .then((res: any) => {
      if(res.status){
        refetch()
        setLayout('default')
      }
    })
    .catch((error: any) => {
      console.log("error");
    });
  }

  return (
    <Card className="mb-1 shadow-none border">
      <div className="p-2">
        {
          layout == 'default' &&
          <Row className="align-items-center">
            <Col className="col-auto">
              <div className="avatar-sm">
                <span className="avatar-title badge-soft-primary text-primary rounded">
                  .{ext}
                </span>
              </div>
            </Col>
            <Col className="ps-0">
              <Link to="#" className="text-muted fw-semibold">
                {data.name}
              </Link>
              {/* <p className="mb-0 font-12">{data.name}</p> */}
              <p className="mb-0 font-10 fst-italic">{data.date}</p>
            </Col>
            <Col className="col-auto">
              {
                isLoadingStorage ? (
                  <ScaleLoader height={8} width={1} color="#b7b1dd" />
                ) : (
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip style={{position:"fixed"}}>Remove</Tooltip>}
                    >
                      <Link
                        to=""
                        id="btn-download"
                        className="btn btn-link font-16 text-muted"
                        onClick={(e) => {setLayout('remove')}}
                      >
                        <i className="dripicons-cross"></i>
                      </Link>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip style={{position:"fixed"}}>Download</Tooltip>}
                    >
                      <Link
                        to=""
                        id="btn-download"
                        className="btn btn-link font-16 text-muted"
                        onClick={() => {
                          // window.open(data.url, "_blank");
                          window.open(url, "_blank");
                        }}
                      >
                        <i className="dripicons-download"></i>
                      </Link>
                    </OverlayTrigger>
                  </>
                )
              }
            </Col>
          </Row>
        }

        {
          layout == 'remove' &&
          <div className="d-flex justify-content-center align-items-center flex-column">
            <p className="mb-0">Are you sure to remove this document?</p>
            <p className="font-10">{data.name}</p>
            <div className="d-flex">
              <Button
                size="sm"
                variant="danger"
                className="waves-effect waves-light"
                onClick={(e) => onConfirmDelete()}
                disabled={isLoadingDeleteDocument}
              >
                Yes
              </Button>
              <Button
                size="sm"
                variant="link"
                className="waves-effect waves-light"
                onClick={(e) => setLayout('default')}
              >
                Cancel
              </Button>
            </div>
          </div>
        }
      </div>
    </Card>
  )
}

const Detail = ({
  current,
  setCurrent,
  layout,
  setLayout,
}: {
  current?: ApplicationProps;
  setCurrent: any;
  layout: any;
  setLayout: any;
}) => {
  const navigate = useNavigate();
  const [currentApplication, setCurrentApplication] = useState()
  const [isDisabledAdd, setIsDisabledAdd] = useState(true)
  let { leadsCode, applicationCode } = useParams();

  const [modalTypeApplication, setModalTypeApplication] = useState('');
  const [modalShowApplication, setModalShowApplication] = useState<boolean>(false);
  const closeModalApplication = () => setModalShowApplication(false);
  const openModalApplication = (type: string, data?: any) => {
    setModalTypeApplication(type)
    setCurrentApplication(data)
    setModalShowApplication(true)
  };

  const [modalShowStatus, setModalShowStatus] = useState<boolean>(false);
  const closeModalStatus = () => setModalShowStatus(false);
  const openModalStatus = (data?: any) => {
    setModalShowStatus(true)
  };

  const [modalShowDocument, setModalShowDocument] = useState<boolean>(false);
  const closeModalDocument = () => setModalShowDocument(false);
  const openModalDocument = (data?: any) => {
    setModalShowDocument(true)
  };

  const {
    data: historyList,
    isFetching: isFetchingHistory,
    isLoading: isLoadingHistory,
    isUninitialized: isUninitializedHistory,
    isError: isErrorHistory,
    error: errorHistory,
    refetch: refetchHistory,
  } = useGetApplicationHistoryQuery(
    {
      application_code: applicationCode,
    },
    {
      // pollingInterval: 100,
      refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      skip: applicationCode ? false : true,
    }
  );

  const {
    data: checkList,
    isFetching: isFetchingChecklist,
    isLoading: isLoadingChecklist,
    isUninitialized: isUninitializedChecklist,
    isError: isErrorChecklist,
    error: errorChecklist,
    refetch: refetchChecklist,
  } = useGetApplicationChecklistQuery(
    {
      application_code: applicationCode,
    },
    {
      // pollingInterval: 100,
      refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      skip: applicationCode ? false : true,
    }
  );

  const {
    data: documentList,
    isFetching: isFetchingDocument,
    isLoading: isLoadingDocument,
    isUninitialized: isUninitializedDocument,
    isError: isErrorDocument,
    error: errorDocument,
    refetch: refetchDocument,
  } = useGetApplicationDocumentQuery(
    {
      application_code: applicationCode,
    },
    {
      // pollingInterval: 100,
      refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      skip: applicationCode ? false : true,
    }
  );

  const { application, isUninitialized: isUninitializedCurrent, isLoading: isLoadingCurrent, isFetching: isFetchingCurrent, isError: isErrorCurrent } = useGetApplicationQuery(
    {
      application_code: applicationCode,
    },
    {
      selectFromResult: ({ data, isUninitialized, isLoading, isFetching, isError }) => ({
        application: data?.result
          ? data.result.find((el: any) => el.code == applicationCode)
          : null,
        isUninitialized : isUninitialized ,
        isLoading: isLoading,
        isFetching: isFetching,
        isError: isError,
      }),
      refetchOnMountOrArgChange: true,
      skip: applicationCode ? false : true,
    }
  );

  const {
    data: applicationList,
    isFetching: isFetchingApplication,
    isLoading: isLoadingApplication,
    isUninitialized: isUninitializedApplication,
    isError: isErrorApplication,
    error: errorApplication,
    refetch: refetchApplication,
  } = useGetApplicationQuery(
    {
      leads_code: current?.leads?.code,
    },
    {
      // pollingInterval: 100,
      refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );
  const [updateApplicationChecklist, { isLoading: isLoadingUpdateChecklist }] = useUpdateApplicationChecklistMutation();

  const onClickApplication = (item: any) => {
    navigate(`/lms/applications/${current?.leads?.code}/${item?.code}`)
    // console.log(current?.leads?.code)
    // setCurrent(item);
    // setCurrentApplication(item);
  }

  const onClickChecklist = async (item: any) => {
    await updateApplicationChecklist({
      ...item,
      application_code: applicationCode,
    })
    .unwrap()
    .then((res: any) => {
      if(res.status){
      }
    })
    .catch((error: any) => {
      console.log("error");
    });
  }

  const onCloseDetail = () => {
    setLayout("default");
    setCurrent({});
    // navigate(`/lms/applications`)
    navigate(-1)
  };

  const findHistory = (name: any) => {
    // return current?.history?.find(function(item: any) {
    //   return item.status_name == name
    // });  
  }

  const refetch = () => {
    refetchApplication();
    refetchHistory();
  }

  useEffect(() => {
    console.log('current',current)
  }, [current])

  useEffect(() => {
    if((applicationList?.result || []).filter((item: any) => item.status_name == 'Completed')?.length > 0){
      setIsDisabledAdd(true)
    } else {
      setIsDisabledAdd(false)
    }
  }, [applicationList?.result])
  
  return (
    <>
      <Card className="mt-3 mt-sm-auto">
        <Card.Body>
          <div
            onClick={() => {
              onCloseDetail();
            }}
            className="position-absolute cursor-pointer"
            style={{ top: "17px", right: "20px" }}
          >
            <i className="mdi mdi-window-close font-22" />
          </div>
          <div className="d-flex align-items-start mb-3">
            <img
              className="d-flex me-3 rounded-circle avatar-lg"
              src={current?.leads?.avatar}
              alt=""
            />
            <div className="w-100">
              <h4 className="mt-0 mb-1">{current?.leads?.full_name}</h4>
              <div className="d-flex flex-column">
                <div className="">
                  <span className="fw-normal">
                    <OverlayTrigger
                      placement={'top'}
                      overlay={
                        <Tooltip style={{position:"fixed"}}>
                          Copy
                        </Tooltip>
                      }
                    >
                      <CopyToClipboard text={current?.leads?.code}
                        onCopy={() => {
                          toast.success('Copied', {autoClose: 100, hideProgressBar: true})
                        }}>
                          <i className="mdi mdi-content-copy cursor-pointer me-1"/>
                      </CopyToClipboard>
                    </OverlayTrigger>
                    #{current?.leads?.code}{" "}
                  </span>
                </div>
                <div className="d-flex flex-wrap gap-1">
                  <span>
                    <i className="mdi mdi-email-outline me-1"></i> 
                    <span><a className="text-body text-decoration-underline" target="_blank" href={`mailto:${current?.leads?.email}`}>{current?.leads?.email}</a></span>
                  </span>
                </div>
                <div className="d-flex flex-wrap gap-1">
                  <span>
                    <i className="mdi mdi-whatsapp me-1"></i>
                    <span><a className="text-body text-decoration-underline" target="_blank" href={`https://wa.me/${current?.leads?.phone}`}>{current?.leads?.phone}</a></span>
                  </span>
                </div>
                <div className="d-flex flex-wrap align-items-center justify-content-start gap-1">
                  <div className="d-flex align-items-center">
                    <img
                      className="rounded-circle"
                      src={current?.leads?.counselor_avatar}
                      alt=""
                      width="25"
                    />
                    <span className="ps-1 text-dark">
                      {current?.leads?.counselor_name}
                    </span>
                  </div>
                  {/* <div className="px-1 bg-primary badge rounded-pill bg-primary">
                    {current?.city_name}
                  </div>
                  <div className="me-1 badge-outline-primary badge rounded-pill">
                    {current?.expo_name}
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <Tab.Container defaultActiveKey="appDetail">
            <Nav
              variant="pills"
              as="ul"
              className="nav nav-pills nav-fill navtab-bg"
            >
              <Nav.Item as="li" className="nav-item">
                <Nav.Link
                  href="#"
                  eventKey="appDetail"
                  className="nav-link cursor-pointer"
                >
                  Application Details
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link
                  href="#"
                  eventKey="timeline"
                  className="nav-link cursor-pointer"
                >
                  Documents
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="appDetail">
                    {
                      isUninitializedApplication ||
                      isLoadingApplication ||
                      isFetchingApplication ||
                      isErrorApplication ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ minHeight: "200px" }}
                        >
                          <ScaleLoader />
                        </div>
                      ) : (
                        <>
                          {(applicationList?.result || []).map((item: any) => {
                            return (
                              <div
                                onClick={(e) => onClickApplication(item)}
                                className={`row p-2 rounded border-application m-1 cursor-pointer ${
                                  applicationCode == item?.code &&
                                  "bg-application-active text-dark"
                                }`}
                              >
                                <div className="col-auto px-0">
                                  <img
                                    src={item?.institution_logo}
                                    className="img-fluid mt-1"
                                    alt=""
                                    width={32}
                                  />
                                </div>
                                <div className="col">
                                  <div className="d-flex flex-column align-items-start">
                                    <div className="d-flex flex-wrap justify-content-start align-items-start gap-1">
                                      <span className="fw-semibold">
                                        {item?.institution_name}
                                      </span>
                                      <Badge bg="soft-secondary" className="text-dark">{item?.planning_year}</Badge>
                                      <BadgeStatus
                                        name={item?.status_name}
                                        group="Application"
                                      />
                                      <i className="mdi mdi-square-edit-outline cursor-pointer" onClick={() => {openModalApplication('edit', item)}}></i>
                                    </div>
                                    <span>
                                      {item?.course_level_name} |{" "}
                                      {item?.course_major_name} |{" "}
                                      {item?.course_full_name_name}
                                    </span>
                                    <div className="d-flex flex-wrap gap-1 mt-1">
                                      {item?.course_level_group == "Main Course" && (
                                        <Badge bg="dark">
                                          {item?.course_level_group}
                                        </Badge>
                                      )}
                                      <Badge bg="dark">
                                        {item?.tuition_type}
                                        {item?.tuition_type == "Nomination" && (
                                          <>: {item?.tuition_type_note}</>
                                        )}
                                      </Badge>
                                      <Badge bg="dark">
                                        {item?.tuition_fee_currency}{" "}
                                        <NumericFormat displayType="text" value={item?.tuition_fee_amount} thousandSeparator="." decimalSeparator="," />
                                      </Badge>
                                      {item?.scholarship_currency &&
                                        item?.scholarship_amount && (
                                          <Badge bg="dark">
                                            Scholarship: {item?.scholarship_currency}{" "}
                                            <NumericFormat displayType="text" value={item?.scholarship_amount} thousandSeparator="." decimalSeparator="," />
                                          </Badge>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      )
                    }
                <div className="p-1">
                  <Button
                    variant="primary"
                    className="waves-effect waves-light w-100"
                    onClick={() => {openModalApplication('add')}}
                    disabled={isDisabledAdd || isUninitializedApplication || isLoadingApplication || isFetchingApplication || isErrorApplication}
                  >
                    Add New Application
                  </Button>
                </div>

                <hr />

                <div>
                  <h4>Application status history:</h4>
                  <div className="row">
                    <div className="col d-flex flex-column">
                      <h5>Application ID</h5>
                      <div>#{application?.code}</div>
                    </div>
                    <div className="col d-flex flex-column">
                      <h5>Admission/Assistant</h5>
                      <div>{application?.admission_name || '-'}</div>
                    </div>
                  </div>
                  <div className="track-order-list mt-3">
                    <ul className="list-unstyled">
                      {isUninitializedCurrent ||
                      isLoadingCurrent ||
                      isFetchingCurrent ||
                      isErrorCurrent ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ minHeight: "200px" }}
                        >
                          <ScaleLoader />
                        </div>
                      ) : (
                        <>
                          {(historyList?.result || []).map(
                            (item: any, index: number) => {
                              return (
                                <StatusHistory
                                  history={item}
                                  application={application}
                                  openModalStatus={openModalStatus}
                                  isLast={
                                    index + 1 == historyList?.result?.length
                                  }
                                />
                              );
                            }
                          )}
                        </>
                      )}
                      {/* {
                        findHistory('Applying') &&
                        <li className="completed">
                          <h5 className="mt-0 mb-1">Applying</h5>
                          <p className="text-muted">
                            April 21 2019 <small className="text-muted">07:22 AM</small>{" "}
                          </p>
                        </li>
                      }
                      {
                        findHistory('Applying') &&
                        <li className="completed">
                          <h5 className="mt-0 mb-1">Applying</h5>
                          <p className="text-muted">
                            April 21 2019 <small className="text-muted">07:22 AM</small>{" "}
                          </p>
                        </li>
                      } */}
                      {/* <li>
                        <span className="active-dot dot"></span>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h5 className="mt-0 mb-1">
                              <span className="bg-soft-primary py-1 px-2 rounded">Conditional Offer</span>
                            </h5>
                            <p className="text-muted">
                              April 22 2019 <small className="text-muted">05:16 PM</small>
                            </p>
                          </div>
                          <div className="mt-n4">
                            <Button
                              size="sm"
                              variant="primary"
                              className="waves-effect waves-light"
                            >
                              Update Status
                            </Button>
                          </div>
                        </div>
                      </li>
                      <li>
                        <h5 className="mt-0 mb-1"> Full Letter Offer</h5>
                      </li>
                      <li>
                        <h5 className="mt-0 mb-1"> Visa</h5>
                      </li>
                      <li>
                        <h5 className="mt-0 mb-1"> Completed</h5>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="timeline">
                <h5 className="mt-4 mb-2 font-size-16">Checklists/Sub-tasks</h5>
                {/* checklists */}
                {isUninitializedChecklist ||
                isLoadingChecklist ||
                isFetchingChecklist ||
                isErrorChecklist ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "200px" }}
                  >
                    <ScaleLoader />
                  </div>
                ) : (
                  <>
                    {(checkList?.result || []).map(
                      (chk: any, index: number) => (
                        <div className="form-check mt-1" key={index}>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`app-chk-${index}`}
                            defaultChecked={chk.is_checked}
                            // value={chk.is_checked}
                            onChange={(e: any) => {
                              onClickChecklist({
                                ...chk,
                                is_checked: e.target.checked,
                              });
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`app-chk-${index}`}
                          >
                            {chk.name}
                          </label>
                        </div>
                      )
                    )}
                  </>
                )}
                {/* <h5 className="mt-4 mb-2 font-size-16">Upload document</h5>
                <DocumentUploader applicationCode={applicationCode} refetch={() => { refetchDocument() }}/> */}

                <h5 className="mt-4 mb-2 font-size-16">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>Attachments</div>
                    <Button
                      size="sm"
                      variant="primary"
                      className="btn-sm waves-effect waves-light me-1"
                      onClick={() => {
                        openModalDocument();
                      }}
                      disabled={isUninitializedDocument || isLoadingDocument || isFetchingDocument || isErrorDocument}
                    >
                      Upload
                    </Button>
                  </div>
                </h5>
                {/* attachments */}

                {isUninitializedDocument ||
                isLoadingDocument ||
                isFetchingDocument ||
                isErrorDocument ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "200px" }}
                  >
                    <ScaleLoader />
                  </div>
                ) : (
                  <>
                  {documentList?.result?.length == 0 ? (
                    <div
                      className="d-flex flex-column justify-content-center align-items-center mb-3"
                      style={{ minHeight: "200px" }}
                    >
                      <LuPackageOpen size={32} />
                      <p className="text-muted mt-1 mb-0">No data</p>
                    </div>
                  ) : (
                    <>
                      {(documentList?.result || []).map(
                        (item: any, index: number) => {
                          return (
                            <DocumentItem
                              data={item}
                              refetch={() => {
                                refetchDocument();
                              }}
                            />
                          );
                        }
                      )}
                    </>
                  )}
                  </>
                )}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card.Body>
      </Card>

      {modalShowApplication && (
        <ModalApplication
          type={modalTypeApplication}
          data={currentApplication}
          leads={current?.leads}
          show={modalShowApplication}
          onHide={closeModalApplication}
          refetch={() => {
            refetch();
          }}
        />
      )}

      {modalShowDocument && (
        <ModalDocument
          applicationCode={applicationCode}
          show={modalShowDocument}
          onHide={closeModalDocument}
          refetch={() => {
            refetch();
          }}
        />
      )}

      {modalShowStatus && (
        <ModalStatus
          application={application}
          show={modalShowStatus}
          onHide={closeModalStatus}
          refetch={() => {
            refetch();
          }}
        />
      )}
    </>
  );
};

export default Detail;
