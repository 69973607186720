import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { IoWarningOutline } from "react-icons/io5";
import moment from "moment";

// components
import { VerticalForm, FormSelect, FormInput, FormDate, SingleUpload } from "components/";

import {
  useGetAutocompleteQuery,
  useExportExpoMutation,
} from "services/api";

interface ModalExportProps {
  show: boolean;
  onHide: () => void;
}

const ModalExport = ({
  show,
  onHide,
}: ModalExportProps) => {
  const [expoCodes, setExpoCodes] = useState<Array<string>>([]);
  
  const { data: expoList, isLoading: isLoadingExpo, isUninitialized: isUninitializedExpo, isFetching: isFetchingExpo } = useGetAutocompleteQuery({type: 'EXP'});
  const [exportExpo, { isLoading: isLoadingExport }] = useExportExpoMutation();

  const validation = yupResolver(
    yup.object().shape({
      start_date: yup.string().test(
        "date",
        "Invalid date or format. The string should be a valid DD-MM-YYYY format.",
        (val: any) => {
          if (!val) {
            return true;
          }
          return moment(val, "YYYY-MM-DD", true).isValid();
        }
      ).label('Start Date'),
      end_date: yup.string().test(
        "date",
        "Invalid date or format. The string should be a valid DD-MM-YYYY format.",
        (val: any) => {
          if (!val) {
            return true;
          }
          return moment(val, "YYYY-MM-DD", true).isValid();
        }
      ).label('End Date'),
      venue: yup.string().label('Venue'),
    })
  );
  
  const methods = useForm({ resolver: validation });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
    reset: resetForm,
  } = methods;

  const onSubmit = async (values: any) => {
    // setExport(values);
    onHide();
  };
  
  const onExport = async () => {
    toast.info('Processing your request...', {hideProgressBar: true})
    await exportExpo({
      code: expoCodes,
    })
      .unwrap()
      .then((res: any) => {
        toast.dismiss();
        if(res?.result?.url){
          // toast.success(res.message)
          window.open(res?.result?.url, "_blank");
          onHide();
        }
      })
      .then((error: any) => {
        toast.dismiss();
        // console.log("error");
      });
  };

  useEffect(() => {
    console.log(errors)
  }, [errors])

  useEffect(() => {
    // reset();
    // setCurrent(data);
    // console.log(filter)
    // if (filter) {
    //   Object.keys(filter).map((key) => {
    //     setValue(key, filter[key]);
    //   });
    // }
  }, []);

  return (
    <>
      <Modal
        backdrop="static"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header onHide={onHide} closeButton>
          <Modal.Title className="m-0">
            Export
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <form
            onSubmit={handleSubmit((values: any) => onSubmit({...values}))}
            noValidate
          >
            <FormSelect
              label="Event Name"
              name="expo_code"
              placeholder="All Event"
              containerClass={"mb-3"}
              defaultValue={getValues()['expo_code'] || undefined}
              options={(expoList?.result || [])}
              // disabled={isLoading}
              disabled={isLoadingExpo || isUninitializedExpo || isFetchingExpo}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
              onChange={(opt: any) => {
                console.log(opt,'opt')
                // setCalcMarketingBranding(opt.value);
                // setExpoCodes
                let arr: string[] = [];
                (opt || []).forEach((item: any, index: number) => {
                  arr[index] = item.value
                  console.log(item,'item')
                });
                setExpoCodes(arr);
              }}
              isMulti
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <div>
              <Button
                variant="success"
                type="button"
                className="waves-effect waves-light me-1"
                disabled={isLoadingExport}
                onClick={() => onExport()}
              >
                Export
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalExport;
