import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Badge } from "react-bootstrap";
import ScaleLoader from "components/ScaleLoader";
import { NumericFormat } from 'react-number-format';

// components
import BTable from "components/BTable";

import {
  useGetKotaQuery,
  useGetBranchQuery,
} from "services/api";

const CoverageAreaColumn = ({ data }: { data: any }) => {
  const [count, setCount] = useState(10)
  const {
    data: coverageAreaList,
    isFetching: isFetchingCoverageArea,
    isLoading: isLoadingCoverageArea,
    isUninitialized: isUninitializedCoverageArea,
    isError: isErrorCoverageArea,
    error: errorKota,
    refetch: refetchKota,
  } = useGetKotaQuery(
    {
      selected_option: String(data.coverage_area).split(',')
    },
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  return (
    <div className="d-flex flex-wrap justify-content-start align-items-center gap-1">
      {
        (coverageAreaList?.result || []).slice(0, count).map((item: any) => (
          <Badge bg="secondary" className="fw-normal">
            {item.name}
          </Badge>
        ))
      }
      {
        (!isFetchingCoverageArea && !isLoadingCoverageArea && !isUninitializedCoverageArea && !isErrorCoverageArea) ? (
          <>
            {
              coverageAreaList?.result.length == 0 &&
              <></>
            }
            {
              count <= coverageAreaList?.result.length &&
              <>
                <Button onClick={() => {setCount(count*2)}} variant="link" size="sm" className="fw-semibold font-11 px-1">
                  Load more...
                </Button>
                <span className="mx-n1">|</span>
                <Button onClick={() => {setCount(coverageAreaList?.result.length + 1)}} variant="link" size="sm" className="fw-semibold font-11 px-1">
                  Show all
                </Button>
              </>
            }
          </>
        ) : (
          <div className="d-flex justify-content-start align-items-start" style={{minHeight: '200px'}}>
            <ScaleLoader />
          </div>
        )
      }
    </div>
  );
};

const AnnualTargetColumn = ({ data }: { data: any }) => {
  return (
    <NumericFormat displayType="text" value={data.annual_target} thousandSeparator="." decimalSeparator="," />
  )
}

const columns = [
  {
    label: "ID",
    name: "abbr",
    width: '170px',
  },
  {
    label: "Name",
    name: "name",
    width: '200px',
  },
  {
    label: "Kota",
    name: "kota_name",
    width: '200px',
  },
  {
    label: "Provinsi",
    name: "provinsi_name",
    width: '200px',
  },
  {
    label: "Annual Target",
    name: "annual_target",
    width: '200px',
    CustomCell: (data: any) => <AnnualTargetColumn data={data} />,
  },
  // {
  //   label: "Coverage Area",
  //   name: "coverage_area",
  //   CustomCell: (data: any) => <CoverageAreaColumn data={data} />,
  // },
  // {
  //   label: "Coverage Area",
  //   name: "coverage_area",
  //   CustomCell: (data: any) => <CoverageAreaColumn data={data} />,
  // },
];

const List = ({ 
  onClickAction,
}: { 
  onClickAction?: any;
 }) => {
  const [search, setSearch] = useState({});
  const [sort, setSort] = useState<object>({});
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  
  const [current, setCurrent] = useState();
  const [modalType, setModalType] = useState<string>('');
  const [modalShow, setModalShow] = useState<boolean>(false);

  const closeModal = () => setModalShow(false);
  const openModal = (type: string, data?: any) => {
    setCurrent(data)
    setModalType(type)
    setModalShow(true)
  };

  const {
    data: lists,
    isFetching: isFetchingBranch,
    isLoading: isLoadingBranch,
    isUninitialized: isUninitializedBranch,
    isError: isErrorBranch,
    error: errorBranch,
    refetch: refetchBranch,
  } = useGetBranchQuery(
    {
      search,
      sort,
      page: activePage,
      per_page: perPage,
    },
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      // skip: false,
    }
  );

  return (
    <>
      <BTable 
        isLoading={ isFetchingBranch || isLoadingBranch || isUninitializedBranch || isErrorBranch }
        data={lists?.result}
        meta={lists?.meta}
        columns={columns}
        search={search}
        setSearch={setSearch}
        sort={sort}
        setSort={setSort}
        activePage={activePage}
        setActivePage={setActivePage}
        perPage={perPage}
        setPerPage={setPerPage}
        onClickAction={onClickAction}
      />
    </>
  );
};

export default List;
