import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ScaleLoader from "components/ScaleLoader";
import { toast } from "react-toastify";
import { IoWarningOutline } from "react-icons/io5";

// components
import { VerticalForm, FormSelect, FormSelectAsync, FormInput, FormDate, SingleUpload } from "components/";

import {
  useGetKotaQuery,
  useSearchKotaMutation,
  useCreateBranchMutation,
  useUpdateBranchMutation,
  useDeleteBranchMutation,
} from "services/api";

interface ModalManageProps {
  show: boolean;
  type: string;
  data?: any;
  onHide: () => void;
}

const ModalManage = ({
  show,
  type,
  data,
  onHide,
}: ModalManageProps) => {
  const [logo, setLogo] = useState<File>();
  const [selectedArea, setSelectedArea] = useState<File>();
  const [kotaOptions, setKotaOptions] = useState([]);
  const [defaultKota, setDefaultKota] = useState<object>();

  const [searchKota, { isLoading: isLoadingSearch }] = useSearchKotaMutation();
  const [createBranch, { isLoading: isLoadingCreate }] = useCreateBranchMutation();
  const [updateBranch, { isLoading: isLoadingUpdate }] = useUpdateBranchMutation();
  const [deleteBranch, { isLoading: isLoadingDelete }] = useDeleteBranchMutation();

  const {
    data: kotaList,
    isFetching: isFetchingKota,
    isLoading: isLoadingKota,
    isUninitialized: isUninitializedKota,
    error: errorKota,
    isError: isErrorKota,
    refetch: refetchKota,
  } = useGetKotaQuery(
    {
      'response_type': 'select-option'
    },
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  // const {
  //   data: selectedKotaList,
  //   // isFetching: isFetchingKota,
  //   isLoading: isLoadingSelectedKota,
  //   // isUninitialized: isUninitializedKota,
  //   // error: errorKota,
  //   // isError: isErrorKota,
  //   // refetch: refetchKota,
  // } = useGetKotaQuery(
  //   {
  //     'response_type': 'select-option',
  //     'selected_option': String(data?.coverage_area).split(',') || [],
  //   },
  //   {
  //     // pollingInterval: 100,
  //     // refetchOnMountOrArgChange: true,
  //     // refetchOnFocus: true,
  //     // refetchOnReconnect: true,
  //     // skip: false,
  //   }
  // );

  const validation = yupResolver(
    yup.object().shape({
      name: yup.string().label('Name').required(''),
      abbr: yup.string().min(3).max(6).label('Abbreviation').required(''),
      // annual_target: yup.number().transform((value) => (isNaN(value) ? undefined : value)).label('Annual target').required(''),
      kota_id: yup.string().label('Kota').required(''),
      // coverage_area: yup.string().label('Name').required(''),
    })
  );
  
  const methods = useForm({ resolver: validation });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
    reset: resetForm,
  } = methods;

  const onSubmit = async (values: any) => {
    try {
      switch (type) {
        case 'add':
          await createBranch(values)
          .unwrap()
          .then((res) => {
            if(res.status){
              onHide()
              toast.success(res.message)
            }
          })
          .catch((error: any) => {
            console.log("error", error);
            clearErrors()
            if(error?.data?.validation){
              Object.keys(error?.data?.validation)?.forEach((field) => {
                error?.data?.validation[field]?.forEach((message: string) => {
                  setError(field, { type: 'custom', message: message })
                  console.log(field, message)
                });
              });
            }
          });
          break;

        case 'edit':
          await updateBranch(values)
            .unwrap()
            .then((res) => {
              if(res.status){
                onHide()
                toast.success(res.message)
              }
            })
            .catch((error: any) => {
              console.log("error", error);
              clearErrors()
              if(error?.data?.validation){
                Object.keys(error?.data?.validation)?.forEach((field) => {
                  error?.data?.validation[field]?.forEach((message: string) => {
                    setError(field, { type: 'custom', message: message })
                    console.log(field, message)
                  });
                });
              }
            });
          break;
          
        case 'delete':
          await deleteBranch(values)
            .unwrap()
            .then((res) => {
              if(res.status){
                onHide()
                toast.success(res.message)
              }
            })
            .catch((error: any) => {
              console.log("error");
            });
          break;
      
        default:
          break;
      }
    } catch (err: any) {
      console.log(err);
      // if (err?.data?.errors) {
      //   Object.keys(err?.data?.errors)?.forEach((field) => {
      //     err.data.errors[field]?.forEach((message: string) => {
      //       toast.error(message, { autoClose: 10000 });
      //     });
      //   });
      // }
    }
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    if(inputValue.length >= 2 ){
      searchKota({
        search: inputValue,
        response_type: 'select-option',
      })
        .unwrap()
        .then((res: any) => {
          callback(res?.result);
        })
        .then((error: any) => {
          console.log("error");
          callback([]);
        });
    }
  };

  // useEffect(() => {
  //   console.log(errors)
  // }, [errors])

  useEffect(() => {
    // reset();
    // setCurrent(data);
    console.log('asd')
    setValue('type', type);
    if (data) {
      Object.keys(data).map((key) => {
        setValue(key, data[key]);
      });
      setDefaultKota({
        value: data?.kota_id,
        label: data?.kota_name
      })
    } else {
      setDefaultKota(undefined)
    }
  }, []);

  if(isLoadingKota){
    return <ScaleLoader />
  } else {
    return (
      <>
        <Modal
          backdrop="static"
          show={show}
          onHide={onHide}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size={type == 'delete' ? undefined : 'lg'}
        >
          {
            type != 'delete' &&
            <Modal.Header className="bg-light" onHide={onHide} closeButton>
              <Modal.Title className="m-0">
                {`${type} Branch`.toUpperCase()}{" "}
              </Modal.Title>
            </Modal.Header>
          }
          <Modal.Body className="p-4">
            <form
              onSubmit={handleSubmit((values: any) => onSubmit({ ...values }))}
              noValidate
            >
              {(type == "add" || type == "edit") && (
                  <>
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <FormInput
                          register={register}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          label="Branch Name"
                          type="text"
                          name="name"
                          placeholder=""
                          containerClass={"mb-3"}
                        />
                      </div>
                      <div className="col-12 col-sm-6">
                        <FormInput
                          className="text-uppercase"
                          register={register}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          label="Branch Code"
                          type="text"
                          name="abbr"
                          placeholder=""
                          containerClass={"mb-3"}
                          minLength={6}
                          maxLength={6}
                        />
                      </div>
                      <div className="col-12 col-sm-6">
                        <FormInput
                          className="bg-light"
                          register={register}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          label="Annual Target"
                          type="number"
                          name="annual_target"
                          placeholder=""
                          containerClass={"mb-3"}
                          defaultValue={getValues()['annual_target'] || 0}
                          disabled
                        />
                      </div>
                      <div className="col-12 col-sm-6">
                        <FormSelectAsync
                          className="w-100"
                          label="Kota & Provinsi"
                          name="kota_id"
                          placeholder=""
                          containerClass={"mb-3"}
                          defaultValue={data?.kota_id ? {
                            value: data?.kota_id,
                            label: data?.kota_name
                          } : undefined}
                          defaultOptions={data?.kota_id ? [{
                            value: data?.kota_id,
                            label: data?.kota_name
                          }] : undefined}
                          loadOptions={loadOptions}
                          // disabled={isLoading}
                          register={register}
                          control={control}
                          errors={errors}
                          // setValue={setValue}
                          setError={setError}
                          clearErrors={clearErrors}
                          onChange={(option: any) => {
                            setValue('kota_id', option.value)
                          }}
                        />
                      </div>
                    </div>
                    {/* <FormSelect
                      label="Coverage Area"
                      name="coverage_area"
                      placeholder=""
                      containerClass={"mb-3"}
                      defaultValue={data.coverage_area || undefined}
                      // options={(kotaList?.result || []).map((item: any) => {
                      //   return {
                      //     value: String(item.kota_id),
                      //     label: String(item.name),
                      //   }
                      // })}
                      options={kotaList?.result || []}
                      // disabled={isLoading}
                      register={register}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      // onChange={(opt: any) => {
                      //   setCalcMarketingBranding(opt.value);
                      // }}
                      isMulti
                    /> */}
                    {/* <FormSelectAsync
                      label="Coverage Area"
                      name="coverage_area"
                      placeholder=""
                      containerClass={"mb-3"}
                      defaultValue={(data?.coverage_area && selectedKotaList?.result) || undefined}
                      loadOptions={loadOptions}
                      // disabled={isLoading}
                      register={register}
                      control={control}
                      errors={errors}
                      // setValue={setValue}
                      setError={setError}
                      clearErrors={clearErrors}
                      onChange={(options: any) => {
                        let opts = options.map((item: any) => {
                          return item.value
                        });
                        setValue('coverage_area', opts.join(','))
                      }}
                      isMulti
                    /> */}
                    <div className="text-end">
                      <Button
                        variant="success"
                        type="submit"
                        className="waves-effect waves-light me-1"
                        disabled={isLoadingCreate || isLoadingUpdate || isLoadingDelete}
                      >
                        Submit
                      </Button>
                    </div>
                  </>
                )
              }
              {type == "delete" && (
                <div className="text-center">
                  <div onClick={onHide} className="position-absolute top-0 end-0 m-2 cursor-pointer">
                    <i className="mdi mdi-close font-22"/>
                  </div>
                  <IoWarningOutline size={64} className="text-danger"/>
                  <h4 className="mt-2">Please confirm your action</h4>
                  <p className="mt-3 mb-0">
                    Are you sure to delete <strong>{data.name}</strong>?
                  </p>
                  <p>This action cannot be undone.</p>
                  <Button
                    variant="danger"
                    type="submit"
                    className="waves-effect waves-light me-1"
                    disabled={isLoadingCreate || isLoadingUpdate || isLoadingDelete}
                  >
                    Confirm
                  </Button>
                </div>
              )}
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
};

export default ModalManage;
