import React, { useEffect, useState } from "react";
import HyperDatepicker from "components/Datepicker";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { VerticalForm, FormSelect, FormInput, FormDate, SingleUpload } from "components/";
import moment from "moment";
import Select from "react-select";

const yearOptions = () => {
  let sy = 2017;
  let cy = new Date().getFullYear()
  let ey = cy + 5;
  let yrs = [];

  for (var i = sy; i <= ey; i++) {
    yrs.push({
      value: sy,
      label: sy,
    });
    sy++;
  }
  return yrs;
}

const FilterDate = ({
  onFilter,
} : {
  onFilter: any;
}) => {
  const [filterYear, setFilterYear] = useState<any>(moment().format('YYYY'));

  return (
    <div className="row justify-content-start flex-lg-nowrap justify-content-lg-end gap-1">
      <div 
        className="col-4 pe-0 pe-sm-auto me-2"
        style={{width: '150px'}}
      >
        <Select
          className="react-select react-select-container w-100"
          classNamePrefix="react-select"
          placeholder="Select year"
          styles={{
            control: (provided: any, state: any) => ({
              ...provided,
              boxShadow: "none",
              border: "1px solid #dee2e6",
              color: "#6c757d",
              "&:hover": {
                border: "1px solid #dee2e6",
              }
            }),
          }}
          // defaultValue={options && defaultValue ? options.find((opt: any) => opt.value == defaultValue) : null}
          // value={filterYear}
          defaultValue={yearOptions().find((opt: any) => opt.value == filterYear)}
          options={yearOptions()}
          isClearable
          onChange={(opt: any) => {
            setFilterYear(opt?.value)
            onFilter(opt?.value)
          }}
        ></Select>
      </div>
    </div>
  )
}

export default FilterDate;