import React, { useEffect, useState } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ScaleLoader from "components/ScaleLoader";

// components
import { VerticalForm, FormSelect, FormInput, FormDate, SingleUpload } from "components/";
import Representative from "./Representative"

import {
  useGetCityQuery,
  useCreateInstitutionMutation,
  useUpdateInstitutionMutation,
  useDeleteInstitutionMutation,
} from "services/api";

import { toast } from "react-toastify";
import { IoWarningOutline } from "react-icons/io5";
import { FaDivide } from "react-icons/fa6";

interface ModalManageProps {
  show: boolean;
  type: string;
  data?: any;
  onHide: () => void;
}

const ModalManage = ({
  show,
  type,
  data,
  onHide,
}: ModalManageProps) => {
  const [logo, setLogo] = useState<File>();
  const [isEditRepresentative, setIsEditRepresentative] = useState(false);

  const [createInstitution, { isLoading: isLoadingCreate }] = useCreateInstitutionMutation();
  const [updateInstitution, { isLoading: isLoadingUpdate }] = useUpdateInstitutionMutation();
  const [deleteInstitution, { isLoading: isLoadingDelete }] = useDeleteInstitutionMutation();

  const {
    data: cityList,
    isFetching: isFetchingCity,
    isLoading: isLoadingCity,
    isUninitialized: isUninitializedCity,
    isError: isErrorCity,
    error,
    refetch,
  } = useGetCityQuery(
    {
      per_page: 9999
    },
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  const validation = yupResolver(
    yup.object().shape({
      logo: yup.mixed().required(''),
      name: yup.string().required(''),
      // abbr: yup.string().min(3).max(3).required(''),
      is_partner: yup.mixed().required(''),
      application_fee: yup.mixed().required(''),
      city_code: yup.mixed().required(''),
      notes: yup.string().nullable(),
      booth_email: yup.string().email("Please enter valid email"),
      booth_password: yup.mixed().default('').when('type', {
        is: 'add',
        then: yup.string().required(''),
      }),
    })
  );
  
  const methods = useForm({ resolver: validation });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
    reset: resetForm,
  } = methods;

  const onSubmit = async (values: any) => {
    try {
      let formData = new FormData();
      Object.keys(values).forEach((key: any) => {
        if(values[key]){
          formData.append(key, values[key]);
        }
      });
      if(logo) {
        formData.append("logo", logo)
      } else {
        formData.delete("logo")
      }

      switch (type) {
        case 'add':
          await createInstitution(formData)
          .unwrap()
          .then((res) => {
            if(res.status){
              onHide()
              toast.success(res.message)
            }
          })
          .catch((error: any) => {
            console.log("error");
          });
          break;

        case 'edit':
          await updateInstitution(formData)
            .unwrap()
            .then((res) => {
              if(res.status){
                onHide()
                toast.success(res.message)
              }
            })
            .catch((error: any) => {
              console.log("error");
            });
          break;
          
        case 'delete':
          await deleteInstitution(formData)
            .unwrap()
            .then((res) => {
              if(res.status){
                onHide()
                toast.success(res.message)
              }
            })
            .catch((error: any) => {
              console.log("error");
            });
          break;
      
        default:
          break;
      }
    } catch (err: any) {
      console.log(err);
      // if (err?.data?.errors) {
      //   Object.keys(err?.data?.errors)?.forEach((field) => {
      //     err.data.errors[field]?.forEach((message: string) => {
      //       toast.error(message, { autoClose: 10000 });
      //     });
      //   });
      // }
    }
  };

  useEffect(() => {
    console.log('isLoadingCity',isLoadingCity)
  }, [isLoadingCity])

  useEffect(() => {
    // reset();
    // setCurrent(data);
    console.log(data)
    setValue('type', type);
    if (data) {
      Object.keys(data).map((key) => {
        setValue(key, data[key]);
      });
    } else {
      setValue('booth_password','jackstudybooth')
    }
  }, []);

  return (
    <>
      <Modal
        backdrop="static"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {type != "delete" && (
          <Modal.Header className="bg-light" onHide={onHide} closeButton>
            <Modal.Title className="m-0">
              {`${type} Institution`.toUpperCase()}{" "}
            </Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body className="p-4">
          {
            (isLoadingCity) ? (
              <div className="d-flex justify-content-center align-items-center" style={{minHeight: '200px'}}>
                <ScaleLoader height={20} width={1} color="#b7b1dd" />
              </div>
            ) : (
              <form
                onSubmit={handleSubmit((values: any) => onSubmit({ ...values }))}
                noValidate
              >
                {(type == "add" || type == "edit") && (
                  <>
                    <div className="mb-3">
                      <label className="form-label">Logo</label>
                      <SingleUpload
                        name="logo"
                        errors={errors}
                        // style={{height: '150px', width: '150px'}}
                        onFileUpload={(f) => {
                          setValue("logo", f);
                          setLogo(f);
                        }}
                        onRemove={(f: any) => {
                          setValue("logo", f);
                          setLogo(f);
                          setValue("logo_base64", null);
                        }}
                        // disabled={isLoading}
                        // imagePreview={data?.logo || 'https://placehold.co/100x100.png'}
                        imagePreview={data?.logo || undefined}
                        // imageName={data?.logo_name}
                      />
                    </div>
                    <FormInput
                      register={register}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      label="Institution Name"
                      type="text"
                      name="name"
                      placeholder=""
                      containerClass={"mb-3"}
                    />
                    {/* <FormInput
                        register={register}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        label="Alias (Abbr/Code)"
                        type="text"
                        name="abbr"
                        placeholder=""
                        containerClass={"mb-3"}
                        className="text-uppercase"
                        max={3}
                        maxLength={3}
                      /> */}
                    <FormSelect
                      label="City & Country"
                      name="city_code"
                      placeholder=""
                      containerClass={"mb-3"}
                      defaultValue={data?.city_code || undefined}
                      options={(cityList?.result || []).map((item: any) => {
                        return {
                          label: `${item.name}, ${item.country_name}`,
                          value: item.code,
                        };
                      })}
                      // disabled={isLoading}
                      register={register}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      // onChange={(opt: any) => {
                      //   setCalcMarketingBranding(opt.value);
                      // }}
                    />
                    <div className="d-flex justify-content-between align-items-center gap-2">
                      <div className="w-100">
                        <FormSelect
                          label="Is Partner?"
                          name="is_partner"
                          placeholder=""
                          containerClass={"mb-3"}
                          defaultValue={data?.is_partner || undefined}
                          options={[
                            {
                              label: "Yes",
                              value: "Yes",
                            },
                            {
                              label: "No",
                              value: "No",
                            },
                          ]}
                          // disabled={isLoading}
                          register={register}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          clearErrors={clearErrors}
                          // onChange={(opt: any) => {
                          //   setCalcMarketingBranding(opt.value);
                          // }}
                        />
                      </div>
                      <div className="w-100">
                        <FormSelect
                          label="Application Fee?"
                          name="application_fee"
                          placeholder=""
                          containerClass={"mb-3"}
                          defaultValue={data?.application_fee || undefined}
                          options={[
                            {
                              label: "Yes",
                              value: "Yes",
                            },
                            {
                              label: "No",
                              value: "No",
                            },
                          ]}
                          // disabled={isLoading}
                          register={register}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          clearErrors={clearErrors}
                          // onChange={(opt: any) => {
                          //   setCalcMarketingBranding(opt.value);
                          // }}
                        />
                      </div>
                    </div>
                    <FormInput
                      register={register}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      label="Notes"
                      type="textarea"
                      name="notes"
                      placeholder=""
                      containerClass={"mb-3"}
                    />
                    <h4 className="header-title mt-3">Booth Credential</h4>
                    <p className="sub-header mb-2">
                      These credentials will be used by representatives to access{" "}
                      <strong>https://booth.jackstudy.com</strong>
                    </p>
                    <FormInput
                      className="bg-light"
                      register={register}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      getValues={getValues}
                      label="Booth Email"
                      type="text"
                      name="booth_email"
                      placeholder=""
                      containerClass={"mb-3"}
                      disabled={true}
                      defaultValue={type == 'add' ? 'Email will be automatically generated' : ''}
                      canCopy
                    />
                    <FormInput
                      register={register}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      getValues={getValues}
                      label="Booth Password"
                      type="password"
                      name="booth_password"
                      placeholder=""
                      containerClass={"mb-3"}
                      canCopy
                    />
                    <div className="text-end">
                      <Button
                        variant="success"
                        type="submit"
                        className="waves-effect waves-light me-1"
                        disabled={
                          isLoadingCreate || isLoadingUpdate || isLoadingDelete
                        }
                      >
                        Submit
                      </Button>
                    </div>
                  </>
                )}
                {type == "delete" && (
                  <div className="text-center">
                    <div
                      onClick={onHide}
                      className="position-absolute top-0 end-0 m-2 cursor-pointer"
                    >
                      <i className="mdi mdi-close font-22" />
                    </div>
                    <div
                      onClick={onHide}
                      className="position-absolute top-0 end-0 m-2 cursor-pointer"
                    >
                      <i className="mdi mdi-close font-22" />
                    </div>
                    <IoWarningOutline size={64} className="text-danger" />
                    <h4 className="mt-2">Please confirm your action</h4>
                    <p className="mt-3 mb-0">
                      Are you sure to delete <strong>{data.name}</strong>?
                    </p>
                    <p>This action cannot be undone.</p>
                    <Button
                      variant="danger"
                      type="submit"
                      className="waves-effect waves-light me-1"
                      disabled={
                        isLoadingCreate || isLoadingUpdate || isLoadingDelete
                      }
                    >
                      Confirm
                    </Button>
                  </div>
                )}
              </form>
            )
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalManage;
