import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";

// components
import List from "./List";
import ModalManage from "./ModalManage";

const CourseMajor = () => {
  const [current, setCurrent] = useState();
  const [modalType, setModalType] = useState<string>('');
  const [modalShow, setModalShow] = useState<boolean>(false);

  const closeModal = () => setModalShow(false);
  const openModal = (type: string, data?: any) => {
    setCurrent(data)
    setModalType(type)
    setModalShow(true)
  };

  return (
    <>
      <Row className="mb-2">
        <Col xs md={'auto'}>
          <div className="page-title-box">
            <div className="page-title-right">
              <div className="text-end">
                <Button
                  variant="success"
                  className="waves-effect waves-light"
                  onClick={(e) => openModal("add")}
                >
                  Add Study Area
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      
      <Row>
        <Col xs md={'auto'}>
          <List onClickAction={openModal}/>
        </Col>
      </Row>

      {modalShow && (
        <ModalManage
          show={modalShow}
          type={modalType}
          data={current}
          onHide={closeModal}
        />
      )}
    </>
  );
};

export default CourseMajor;
