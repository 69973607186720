import React,  { useState } from "react";
import { Row, Col, Card, Button, Form, Modal, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from "react-toastify";
import classNames from "classnames";

import { BadgeStatus } from "components"
import moment from "moment";
import { MdOutlineComment } from "react-icons/md";
import { LiaComments } from "react-icons/lia";
import { FaRegComments } from "react-icons/fa";
import { IoIosCheckboxOutline } from "react-icons/io";

interface LeadsItemProps {
  item: any;
  isChecked?: any;
  setIsChecked?: any;
  canComment?: boolean;
  canAssign?: boolean;
  onDoubleClick?: any;
  onClickComment?: any;
}

const LeadsItem = ({
  item,
  isChecked,
  setIsChecked,
  canComment,
  canAssign,
  onDoubleClick,
  onClickComment,
  ...otherProps
}: LeadsItemProps) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <Card 
      onDoubleClick={canAssign ? onDoubleClick : undefined}
      className={classNames(
        "mb-2",
        // isChecked && "ribbon-box border border-1 border-primary"
      )}
      >
      {
        isChecked && 
        // <div className="ribbon-box border border-1 border-primary position-absolute w-100 h-100"></div>
        <div className="border border-1 border-primary position-absolute w-100 h-100 rounded"></div>
      }
      <Card.Body>
        {/* {
          isChecked &&
          <div
            className="ribbon ribbon-primary float-start px-1 py-2px"
          >
            <i className="mdi mdi-check-all"></i>
          </div>
        } */}
        <Row className="align-items-center gap-2">
          {/* <Col sm={'auto'} className="px-1">
            <Form.Check
              className="font-16"
              type="checkbox"
              id={`checkbox-${item.leads_id}`}
              // label="Check this custom checkbox"
              onClick={onDoubleClick}
              checked={isChecked}
            />
          </Col> */}
          {/* <Col sm={'auto'} className="px-1">
            <div>
              <img
                className="avatar-md rounded-circle"
                src={item?.avatar}
                alt=""
                style={{maxHeight: '64px'}}
              />
            </div>
          </Col> */}
          <Col>
            <Row>
              <Col xs={12}>
                  <div className="d-flex align-items-center">
                    <div className="w-100">
                      <h4 className="mt-0 mb-0 font-16 d-flex align-items-center gap-1 mb-1">
                        {
                          canAssign && 
                          <input
                            type="checkbox"
                            className="form-check-input font-12"
                            checked={isChecked}
                            onChange={(e) => {
                              setIsChecked(e.target.checked)
                            }}
                            style={{
                              zIndex: 1
                            }}
                          />
                        }
                        <span>{item?.full_name}</span>
                        <BadgeStatus name={item?.status_name} group="Leads"/></h4>
                        <div>
                          <span className="fw-normal">
                            <OverlayTrigger
                              placement={'top'}
                              overlay={
                                <Tooltip style={{position:"fixed"}}>
                                  Copy
                                </Tooltip>
                              }
                            >
                              <CopyToClipboard text={item?.code}
                                onCopy={() => {
                                  toast.success('Copied', {autoClose: 100, hideProgressBar: true})
                                }}>
                                  <i className="mdi mdi-content-copy cursor-pointer me-1"/>
                              </CopyToClipboard>
                            </OverlayTrigger>
                            #{item?.code}{" "}
                          </span>
                          {
                            item?.email &&
                            <span className="fw-normal">
                              <span className="px-1">|</span>
                              <span><a className="text-body text-decoration-underline" target="_blank" href={`mailto:${item?.email}`}>{item?.email}</a></span>
                            </span>
                          }
                          {
                            item?.phone &&
                            <span className="fw-normal">
                              <span className="px-1">|</span>
                              <span><a className="text-body text-decoration-underline" target="_blank" href={`https://wa.me/${item?.phone}`}>{item?.phone}</a></span>
                            </span>
                          }
                          {/* {
                            item?.code &&
                            <span className="fw-normal">
                              #{item?.code}
                            </span>
                          }
                          {
                            item?.email &&
                            <span className="fw-normal">
                              {" "}|{" "}{item?.email}
                            </span>
                          }
                          {
                            item?.phone &&
                            <span className="fw-normal">
                              {" "}|{" "}{item?.phone}
                            </span>
                          } */}
                          {
                            item?.dob &&
                            <span className="fw-normal">
                              {" "}|{" "}{moment(item?.dob).format('DD MMM YYYY')}
                            </span>
                          }
                          {
                            item?.institution_name &&
                            <span className="fw-normal">
                              {" "}|{" "}{item?.institution_name}
                            </span>
                          }
                          {
                            item?.planning_year &&
                            <span className="fw-normal">
                              {" "}|{" "}{item?.planning_year}
                            </span>
                          }
                          {
                            item?.course_level_name &&
                            <span className="fw-normal">
                              {" "}|{" "}{item?.course_level_name}
                            </span>
                          }
                          {
                            item?.course_major_name &&
                            <span className="fw-normal">
                              {" "}|{" "}{item?.course_major_name}
                            </span>
                          }
                          {
                            item?.course_full_name_name &&
                            <span className="fw-normal">
                              {" "}|{" "}{item?.course_full_name_name}
                            </span>
                          }
                          {
                            item?.institution_name &&
                            <span className="fw-normal">
                              {" "}|{" "}{item?.institution_name}
                            </span>
                          }
                          {
                            item?.kota_name &&
                            <span className="fw-normal">
                              {" "}|{" "}{item?.kota_name}
                            </span>
                          }
                          {
                            item?.provinsi_name &&
                            <span className="fw-normal">
                              {" "}|{" "}{item?.provinsi_name}
                            </span>
                          }
                        </div>
                        {/* <p className="mb-0">
                          <b>Location:</b> {item?.location}
                        </p>
                        <p className="mb-0">
                          <b>Rank: #</b> {Math.floor(Math.random() * 10)}
                        </p> */}
                    </div>
                  </div>
              </Col>
              <Col xs={12}>
                <div className="d-flex gap-1 align-items-center justify-content-start mt-1">
                  {
                    item?.branch_name &&
                    <div className="px-1 badge rounded-pill bg-primary">
                      {item?.branch_name}
                    </div>
                  }
                  {
                    item?.counselor_name &&
                    <div className="px-1 badge rounded-pill text-dark bg-transparent shadow-none">
                      <img
                        className="rounded-circle"
                        src={item?.counselor_avatar}
                        alt={item?.counselor_name}
                        width="20"
                      />
                      <span className="ps-1">{item?.counselor_name}</span>
                    </div>
                  }
                  {/* <div className="me-1 badge-outline-primary badge rounded-pill">
                    {item?.expo}
                  </div> */}
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={'auto'}>
            <div className="d-flex justify-content-end align-items-center gap-2 w-100">
              {/* {
                canComment && 
                <Button
                  variant="link"
                  size="sm"
                  className="waves-effect waves-light px-0"
                  onClick={(e) => {
                    onClickComment(item)
                  }}
                >
                  <LiaComments className="font-20" />
                </Button>
              } */}
              {/* {
                canAssign && 
                <>
                  <Button
                    variant="primary"
                    size="sm"
                    className="waves-effect waves-light"
                    onClick={(e) => {
                      onClickComment(item)
                    }}
                  >
                    Assign
                  </Button>
                </>
              } */}
              {/* <Link to="#" className="action-icon text-secondary font-20">
                {" "}
                <i className="mdi mdi-square-edit-outline"></i>
              </Link>
              <Link to="#" className="action-icon text-secondary font-20">
                {" "}
                <i className="mdi mdi-delete"></i>
              </Link> */}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default LeadsItem;
