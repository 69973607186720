import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { VerticalForm, FormSelect, FormInput, FormDate, SingleUpload } from "components/";
import { faker } from "@faker-js/faker";
import DocumentUploader from "components/DocumentUploader";

import {
  useCreateApplicationDocumentMutation,
} from "services/api";

import { toast } from "react-toastify";
import { IoWarningOutline } from "react-icons/io5";

interface ModalDocumentProps {
  show: boolean;
  applicationCode?: any;
  onHide: () => void;
  refetch?: any;
}

const validation = yupResolver(
  yup.object().shape({
    application_code: yup.mixed().required(''),
    Document_code: yup.string().required(''),
    note: yup.string().required(''),
    application: yup.mixed().when('Document_name', {
      is: 'Applied',
      then: yup.object().shape({
        institution_code: yup.string().label('Institution').required(''),
        planning_year: yup.mixed().label('Year of Study').required(''),
        course_level_code: yup.string().label('Course level').required(''),
        course_major_code: yup.string().label('Study Area').required(''),
        course_full_name_code: yup.string().label('Course full name').required(''),
        tuition_type: yup.string().label('Tuition Type').required(''),
        tuition_type_note: yup.mixed().default('').when('tuition_type', {
          is: 'Nomination',
          then: yup.string().label('Tuition Type Note').required(''),
        }),
        tuition_fee_currency: yup.string().label('Tuition Fee Currency').required(''),
        tuition_fee_amount: yup.number().transform((value) => (isNaN(value) ? undefined : value)).label('Tuition Fee Amount').required(''),
        scholarship_currency: yup.string().label('Scholarship Currency'),
        scholarship_amount: yup.number().transform((value) => (isNaN(value) ? undefined : value)).label('Scholarship Amount'),
      }),
    }),
  })
);

const ModalDocument = ({
  show,
  applicationCode,
  onHide,
  refetch,
}: ModalDocumentProps) => {
  const [files, setFiles] = useState<File[]>()

  const [createApplicationDocument, { isLoading: isLoadingCreateDocument }] = useCreateApplicationDocumentMutation();
  
  
  const methods = useForm({ resolver: validation });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
    reset: resetForm,
  } = methods;

  const onSubmit = async (values: any) => {
    // await changeDocument({
    //   ...values,
    //   application_code: applicationCode,
    // })
    //   .unwrap()
    //   .then((res) => {
    //     if(res.Document){
    //       onHide()
    //       toast.success(res.message)
    //     }
    //   })
    //   .catch((error: any) => {
    //     console.log("error");
    //   });
    // refetch()
  };

  useEffect(() => {
    console.log(errors)
  }, [errors])

  // useEffect(() => {
  //   console.log(application)
  // }, []);

  const onFileUpload = (files: any[]) => {
    setFiles(files)
  }

  const onClickUpload = async () => {
    let formData = new FormData();
    (files || []).map((file: any, index: number) => {
      formData.append("document["+index+"][file]", file)
      formData.append("document["+index+"][name]", file.name)
    });
    
    formData.append('application_code', applicationCode)

    await createApplicationDocument(formData)
    .unwrap()
    .then((res: any) => {
      if(res.status){
        onHide()
        toast.success(res.message)
      }
    })
    .catch((error: any) => {
      console.log("error");
    });
  }

  return (
    <>
      <Modal
        backdrop="static"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header onHide={onHide} closeButton>
          <h4 className="modal-title">Update Application Document</h4>
        </Modal.Header>
        <Modal.Body className="p-4">
          <form
            onSubmit={handleSubmit((values: any) => onSubmit(values))}
            noValidate
          >
            <DocumentUploader onFileUpload={onFileUpload}/>
            <div className="text-end mt-3">
              <Button
                variant="success"
                type="submit"
                className="waves-effect waves-light me-1"
                disabled={isLoadingCreateDocument}
                onClick={() => {onClickUpload()}}
              >
                Upload
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalDocument;
