import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";

// components
import List from "./List";
import ModalManage from "./ModalManage";

const CoverageArea = () => {
  const [current, setCurrent] = useState();
  const [modalType, setModalType] = useState<string>('');
  const [modalShow, setModalShow] = useState<boolean>(false);

  const closeModal = () => setModalShow(false);
  const openModal = (type: string, data?: any) => {
    setCurrent(data)
    setModalType(type)
    setModalShow(true)
  };

  return (
    <>
      <Row className="mt-3">
        <Col xs md={'auto'}>
          <List onClickAction={openModal}/>
        </Col>
      </Row>

      {modalShow && (
        <ModalManage
          show={modalShow}
          type={modalType}
          data={current}
          onHide={closeModal}
        />
      )}
    </>
  );
};

export default CoverageArea;
