import React, { useEffect, useState } from "react";
import { Row, Col, Card, Tab, Nav, ListGroup, Button, Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";
import moment from "moment";
import { FormSelect, BadgeStatus } from "components"
import ModalManage from "./ModalManage";
import { MdOutlineSettings } from "react-icons/md";
import { Navigate, Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { HasAccess } from "@permify/react-role";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/store";
import { toast } from "react-toastify";

import {
  useGetUserQuery,
  useAssignCounselorLeadsMutation,
} from "services/api";

const validation = yupResolver(
  yup.object().shape({
    counselor_code: yup.string().label('Counselor').required(''),
  })
);

const Selection = ({
  current,
  setCurrent,
  layout,
  setLayout,
  checked,
  setChecked,
}: {
  current?: LeadsProps;
  setCurrent: any;
  layout: any;
  setLayout: any;
  checked: any;
  setChecked: any;
}) => {
  const { user, userLoggedIn, loading, error } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      loading: state.Auth.loading,
      error: state.Auth.error,
      userLoggedIn: state.Auth.userLoggedIn,
    })
  );

  const {
    data: counselorList,
    isFetching: isFetchingCounselor,
    isLoading: isLoadingCounselor,
    isUninitialized: isUninitializedCounselor,
    error: errorCounselor,
    isError: isErrorCounselor,
    refetch: refetchCounselor,
  } = useGetUserQuery(
    {
      search: {
        role_name: 'Counselor'
      },
      branch_code: [user?.branch_code]
    },
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  const [assignCounselorLeads, { isLoading: isLoadingAssign }] = useAssignCounselorLeadsMutation();
  
  const methods = useForm({ resolver: validation });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
    reset: resetForm,
  } = methods;

  const onSubmit = async (values: any) => {
    await assignCounselorLeads({
      ...getValues(),
      leads: (checked || []).map((item: any) => {
        return {
          code: item.code,
        }
      }),
    })
      .unwrap()
      .then((res: any) => {
        if(res.status){
          toast.success(res.message)
          setChecked([]);
          setLayout('default')
        }
      })
      .then((error: any) => {
        // console.log("error");
      });
  };

  useEffect(() => {
    console.log(errors)
  }, [errors])

  return (
    <Card className="sticky-top" style={{ top: "80px" }}>
      <Card.Body>
        <span
          className="float-end noti-close-btn text-muted cursor-pointer"
          onClick={() => {setLayout('default') }}
        >
          <i className="mdi mdi-close"></i>
        </span>
        <div className="font-16 fw-bold text-dark">
          Selected Leads
        </div>
        <form
          onSubmit={handleSubmit((values: any) => onSubmit({...values}))}
          noValidate
        >
          <Form.Group>
            <Form.Label className="mb-2" htmlFor="Button">Assign selected leads to Counselor</Form.Label>
            <div className="d-flex flex-row gap-1">
              <div className="flex-fill">
                <FormSelect
                  type="text"
                  name="counselor_code"
                  placeholder=""
                  containerClass={"mb-3"}
                  // defaultValue={getValues()?.counselor_code || undefined}
                  options={(counselorList?.result || []).map((item: any) => {
                    return {
                      label: `${item.name} (${item.branch_name})`,
                      value: item.code,
                    };
                  })}
                  // disabled={isLoadingExport}
                  register={register}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  // onChange={(date: any) => {
                  //   onChangeFilterDate(date);
                  // }}
                />
              </div>
              <div>
                <Button
                  variant="primary"
                  className="waves-effect waves-light"
                  type="submit"
                  disabled={isLoadingAssign}
                >
                  Assign
                </Button>
              </div>
            </div>
          </Form.Group>
        </form>
        <SimpleBar autoHide={false} style={{ height: "calc(100vh - 400px)" }} className="border-top border-2 border-primary mt-3">
          {(checked || []).reverse().map((item: any) => (
            <div className="d-flex align-items-center border-bottom border-1 py-1 my-2">
              <div className="w-100">
                <h5 className="mt-0 mb-0 font-14">{item?.full_name} <BadgeStatus name={item?.status_name} group="Leads"/><span className="fw-semibold">#{item?.code}</span></h5>
                <div className="text-truncate">
                  <span className="fw-semibold">{item?.email}</span>
                  <span className="px-1">|</span>
                  <span className="fw-semibold">{item?.phone}</span>
                  <span className="px-1">|</span>
                  <span className="fw-semibold">{moment(item?.dob).format('DD MMM YYYY')}</span>
                  <span className="px-1">|</span>
                  <span className="fw-semibold">{item?.program_interested}</span>
                </div>
                {/* <p className="mb-0">
                  <b>Location:</b> {item?.location}
                </p>
                <p className="mb-0">
                  <b>Rank: #</b> {Math.floor(Math.random() * 10)}
                </p> */}
              </div>
            </div>
          ))}
        </SimpleBar>
      </Card.Body>
    </Card>
  )
}

export default Selection;