import moment from "moment";
import React, { useEffect, useState } from "react";
import { Row, Col, Card, ListGroup, Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { usePermify } from "@permify/react-role";
import { useSelector } from "react-redux";
import { RootState } from "redux-rtk/store";
import Skeleton from "react-loading-skeleton";

import {
  useGetDExpoQuery,
} from "services/api";

const UpcomingEvent = () => {
  const navigate = useNavigate()
  
  const { permissions } = useSelector(
    (state: RootState) => ({
      permissions: state.Auth.user.permissions,
    })
  );
  
  const { isAuthorized, isLoading } = usePermify();

  const {
    data: expoList,
    isFetching: isFetchingExpoList,
    isLoading: isLoadingExpoList,
    isUninitialized: isUninitializedExpoList,
    isError: isErrorExpoList,
    error: errorExpoList,
    refetch: refetchExpoList,
  } = useGetDExpoQuery(
    {},
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  return (
    <>
      <Card className="h-100">
        <Card.Body>
          {
            (isFetchingExpoList || isLoadingExpoList || isUninitializedExpoList) ? (
              <>
              {
                [...Array(5)].map((number, i) => {
                  return (
                    <Row className="mb-3">
                      <Col xs={3}>
                        <Row className="justify-content-center align-items-center">
                          <Col xs="12">
                            <Skeleton height={20}/>
                          </Col>
                          <Col xs="12">
                            <Skeleton/>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={9}>
                        <Row className="justify-content-center align-items-center">
                          <Col xs="12">
                            <Skeleton height={20}/>
                          </Col>
                          <Col xs="12">
                            <Skeleton/>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )
                })
              }
              </>
            ) : (
              <>
                <div className="font-16 fw-bold mb-3 text-dark">Upcoming Event</div>
                <div>
                  <ListGroup variant="flush">
                    {
                      (expoList?.result || []).map((item: any) => {
                        return (
                          <ListGroup.Item className="px-0">
                            <div
                              onClick={() => {
                                if(permissions.includes('can-open-event')){
                                  navigate(`/lms/events/${item.code}`)
                                }
                              }}
                              className={`d-flex align-items-center ${permissions.includes('can-open-event') && 'cursor-pointer'}`}
                              style={{minHeight: '30px'}}
                            >
                              <div className="d-flex flex-column text-center border-end border-2 border-primary pe-2">
                                <div style={{width: '60px'}} className="fw-semibold font-24">{moment(item?.date,'YYYY-MM-DD').format('DD')}</div>
                                <div style={{width: '60px'}} className="fw-semibold font-12 mt-n1">{moment(item?.date,'YYYY-MM-DD').format('MMM YYYY')}</div>
                              </div>
                              <div className="d-flex flex-column ms-2">
                                <div className="font-16 fw-semibold">
                                  <div className="d-flex gap-1 align-items-start">
                                    <span>{item?.name}</span>
                                    {
                                      item.is_open ? (
                                        <Badge bg="success">
                                          Open
                                        </Badge>
                                      ) : (
                                        <Badge bg="secondary">
                                          Closed
                                        </Badge>
                                      )
                                    }
                                  </div>
                                </div>
                                <div className="">{item?.venue}</div>
                              </div>
                            </div>
                          </ListGroup.Item>
                        )
                      })
                    }
                  </ListGroup>
                </div>
              </>
            )
          }
        </Card.Body>
      </Card>
    </>
  );
};

export default UpcomingEvent;
