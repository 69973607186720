import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Modal,
  InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { faker } from "@faker-js/faker";
import moment from "moment";
import { MdOutlineSend } from "react-icons/md";
import { MdOutlineClose } from "react-icons/md";
import { FiSend } from "react-icons/fi";

import {
  useGetCommentQuery,
} from "services/api";

const CommentChildItem = ({ data } : { data: any[] }) => {
  const [isReply, setIsReply] = useState(false);
  return (
    <>
      {
        data.map((comment, index) => (
          <div className="d-flex align-items-start mb-2">
            {/* <img
              className="me-2 rounded-circle"
              src={comment.avatar}
              alt=""
              height="32"
              style={{minWidth: '32px'}}
            /> */}
            <div className="w-100">
              <h5 className="mt-0 mb-0">
                <span>{comment.caused_by_name}</span>
                <small className="text-muted float-end">5 hours ago</small>
              </h5>
              <div>{comment.note}</div>
              {
                data.length - 1 == index &&
                <div className="mb-1">
                  {
                    !isReply &&
                    <Button
                      variant="link"
                      className="text-muted font-13 d-inline-block p-0"
                      onClick={() => setIsReply(true)}
                    >
                      <i className="mdi mdi-reply"></i> Reply
                    </Button>
                  }
                  {
                    isReply &&
                    <InputGroup size="sm" className="mt-1 mb-2">
                      <Form.Control
                        placeholder="Add comment..."
                        aria-label="Add comment..."
                        aria-describedby="basic-addon2"
                        className="form-control form-control-sm form-control-light"
                      />
                      {/* <Button
                        variant="light"
                        className="d-flex align-items-center gap-1"
                        onClick={() => setIsReply(false)}
                      >
                        Cancel
                      </Button> */}
                      <Button
                        variant="primary"
                        onClick={() => setIsReply(false)}
                      >
                        <div className="d-flex align-items-center gap-1">
                          <FiSend />
                          <span>Send</span>
                        </div>
                      </Button>
                    </InputGroup>
                  }
                </div>
              }
            </div>
          </div>
        ))
      }
    </>
  )
}

const CommentItem = ({data} : { data: any }) => {
  const [isReply, setIsReply] = useState(false);
  return (
    <div className="d-flex align-items-start mb-2">
      {/* <img
        className="me-2 rounded-circle"
        src={data.avatar}
        alt=""
        height="32"
        style={{minWidth: '32px'}}
      /> */}
      <div className="w-100">
        <h5 className="mt-0 mb-0">
          {data.caused_by_name}{" "}
          <small className="text-muted float-end">5 hours ago</small>
        </h5>
        <span>{data.note}</span>
        <br />
        <div className="mb-1">
          {
            !isReply &&
            <Button
              variant="link"
              className="text-muted font-13 d-inline-block p-0"
              onClick={() => setIsReply(true)}
            >
              <i className="mdi mdi-reply"></i> Reply
            </Button>
          }
          {
            isReply &&
            <InputGroup size="sm" className="mt-1 mb-2">
              <Form.Control
                placeholder="Add comment..."
                aria-label="Add comment..."
                aria-describedby="basic-addon2"
                className="form-control form-control-sm form-control-light"
              />
              {/* <Button
                variant="light"
                className="d-flex align-items-center gap-1"
                onClick={() => setIsReply(false)}
              >
                Cancel
              </Button> */}
              <Button
                variant="primary"
                onClick={() => setIsReply(false)}
              >
                <div className="d-flex align-items-center gap-1">
                  <FiSend />
                  <span>Send</span>
                </div>
              </Button>
            </InputGroup>
          }
        </div>
        {
          data.children &&
          <CommentChildItem data={data.children}/>
        }
      </div>
    </div>
  );
};

const ModalComment = ({ leadsCode, show, setShow }: { leadsCode?: string; show: boolean; setShow: any }) => {
  const {
    data: commentList,
    isFetching: isFetchingComment,
    isLoading: isLoadingComment,
    isUninitialized: isUninitializedComment,
    error: errorComment,
    isError: isErrorComment,
    refetch: refetchComment,
  } = useGetCommentQuery(
    {
      leads_code: leadsCode,
    },
    {
      // pollingInterval: 100,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header onHide={() => setShow(false)} closeButton>
        <h4 className="modal-title">Comments</h4>
      </Modal.Header>
      <Modal.Body className="px-3">
        {
          (commentList?.result || []).map((comment: any) => (
            <CommentItem data={comment} />
          ))
        }
        <hr/>
        <div className="d-flex align-items-start mt-3 mb-2">
          <Link to="#" className="pe-2">
            <img
              // src={avatar1}
              src={
                "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/89.jpg"
              }
              className="rounded-circle"
              alt="ge"
              height="31"
            />
          </Link>
          <div className="w-100">
            <InputGroup size="sm">
              <Form.Control
                placeholder="Add comment..."
                aria-label="Add comment..."
                aria-describedby="basic-addon2"
                className="form-control form-control-sm form-control-light"
              />
              {/* <Button
                variant="light"
                className="d-flex align-items-center gap-1"
                // onClick={() => setIsReply(false)}
              >
                Cancel
              </Button> */}
              <Button
                variant="primary"
                // onClick={() => setIsReply(false)}
              >
                <div className="d-flex align-items-center gap-1">
                  <FiSend />
                  <span>Send</span>
                </div>
              </Button>
            </InputGroup>
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="light" onClick={() => setShow(false)}>
          Close
        </Button>{" "}
        <Button variant="primary" onClick={() => setShow(false)}>
          Save changes
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default ModalComment;
