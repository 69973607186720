import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button, Nav, Tab, Form, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Navigate, Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import moment from 'moment';
import SimpleBar from "simplebar-react";
import { useParams } from "react-router-dom";
import ScaleLoader from "components/ScaleLoader";
import { HasAccess } from "@permify/react-role";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from "react-toastify";
import { LuPackageOpen } from "react-icons/lu";

// components
import PaginationCard from "components/PaginationCard";
import ModalManage from "./ModalManage";
import { CompanyInfoItems } from "./data"
import { FormSelect, BadgeStatus } from "components"
import ModalStatus from './Detail/ModalStatus'
interface LeadsProps {
  companyInfo: CompanyInfoItems[];
}

const Item = () => {

}

const List = ({
  isLoading,
  data,
  current,
  setCurrent,
  search,
  setSearch,
  filterBranch,
  setFilterBranch,
  sort,
  setSort,
  activePage,
  setActivePage,
  perPage,
  setPerPage,
  layout,
  setLayout,
  isChecked,
  setIsChecked,
  leadsCount,
  setLeadsCount,
  refetchLeads,
} : {
  isLoading: any;
  data: any;
  current: any;
  setCurrent: any;
  search: any;
  setSearch: any;
  filterBranch: any;
  setFilterBranch: any;
  sort: any;
  setSort: any;
  activePage: any;
  setActivePage: any;
  perPage: any;
  setPerPage: any;
  layout: any;
  setLayout: any;
  isChecked?: any;
  setIsChecked?: any;
  leadsCount?: any;
  setLeadsCount?: any;
  refetchLeads?: any;
}) => {
  const navigate = useNavigate();
  let { leadsCode } = useParams();

  const [show, setShow] = useState<boolean>(false);

  const [modalShowStatus, setModalShowStatus] = useState<boolean>(false);
  const closeModalStatus = () => setModalShowStatus(false);
  const openModalStatus = (data?: any) => {
    setCurrent(data);
    setModalShowStatus(true)
  };

  const onClickView = (item: any) => {
    setCurrent(item);
    setLayout('with-detail')
    navigate(`/lms/leads/${item.code}`)
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  return (
    <div 
      className={classNames(
        "position-relative",
        layout == "with-detail" && "d-none d-sm-block"
      )}
    >
      {
        (!isLoading && data?.result?.length == 0) ? (
          <div className="d-flex flex-column justify-content-center align-items-center mb-3" style={{minHeight: '200px'}}>
            <LuPackageOpen size={64}/>
            <p>No data</p>
          </div>
        ) : (
          <>
            {
              isLoading ? (
                <>
                  <div style={{height: 'calc(100vh - 200px)'}}>
                    <ScaleLoader />
                  </div>
                </>
              ) : (
                <>
                  {(data?.result || []).map((item: any, index: any) => {
                    return (
                      <Card onDoubleClick={(e) => { onClickView(item) }} key={index} className="mb-2" style={(current?.code != item?.code && layout == 'with-detail') ? {background: '#ffffff69'} : {}}>
                        {
                          (current?.code == item?.code && layout == 'with-detail') ? (
                            <div className="position-absolute h-100 w-100 rounded shadow-lg" style={{zIndex: '-1'}}>
                            </div>
                          ) : null
                        }
                        <Card.Body>
                          <div className="d-flex justify-content-between align-items-center gap-2">
                            {
                              item?.status_name == 'Unassigned' &&
                              <div
                                className="flex-auto d-none d-sm-block"
                                style={{
                                  zIndex: 100
                                }}
                              >
                                <HasAccess
                                    permissions={["can-assign-to-counselor"]} 
                                >
                                  <input
                                    type="checkbox"
                                    className="form-check-input font-12"
                                    checked={isChecked(item)}
                                    onChange={(e) => {
                                      setIsChecked(item, e.target.checked)
                                    }}
                                  />
                                </HasAccess>
                              </div>
                            }
                            <div className="flex-auto">
                              <div className="flex-auto align-self-start mt-1 me-1 d-none d-sm-block">
                                <img
                                  className="avatar-md rounded-circle"
                                  src={item?.avatar}
                                  alt=""
                                  // width="64"
                                  style={{maxHeight: '64px'}}
                                />
                              </div>
                              {/* <div className="flex-auto align-self-start mt-1 me-1 d-sm-none">
                                <img
                                  className="avatar-md rounded-circle"
                                  src={item?.avatar}
                                  alt=""
                                  // width="64"
                                  // style={{maxHeight: '32px'}}
                                />
                              </div> */}
                            </div>
                            <div className="flex-fill">
                              <div className="d-flex flex-column">
                                <div className="d-flex flex-column align-items-start">
                                  <h4 className="mt-0 mb-1 font-16 d-flex flex-nowrap flex-sm-wrap justify-content-start align-items-start align-items-sm-center" style={{minHeight: '24px'}}>
                                    <HasAccess
                                        permissions={["can-assign-to-counselor"]} 
                                    >
                                      <div className="me-1 d-sm-none">
                                        <input
                                          type="checkbox"
                                          className="form-check-input font-12"
                                          checked={isChecked(item)}
                                          onChange={(e) => {
                                            setIsChecked(item, e.target.checked)
                                          }}
                                        />
                                      </div>
                                    </HasAccess>
                                    {
                                      item.is_hot &&
                                      // <i className="mdi mdi-fire text-danger"></i>
                                      <>⭐</>
                                    }
                                    <span>{item?.full_name}</span>
                                    <BadgeStatus name={item.status_name} group="Leads" className="ms-1 font-12 d-none d-md-block">
                                      {
                                        !['Unassigned','Applied','Succeed','Cancelled'].includes(item?.status_name) ? (
                                          <>
                                            {item?.status_name}
                                            <i onClick={() => {openModalStatus(item)}} className="ms-1 mdi mdi-square-edit-outline cursor-pointer"></i>
                                          </>
                                        ) : (
                                          <>
                                            {item?.status_name}
                                          </>
                                        )
                                      } 
                                    </BadgeStatus>
                                    {
                                      item?.counselor_name &&
                                      <Badge pill className="px-1 bg-transparent shadow-none d-none d-md-block">
                                        <div className="d-flex align-items-center">
                                          <img
                                            className="rounded-circle"
                                            src={item?.counselor_avatar}
                                            alt=""
                                            width="25"
                                          />
                                          <span className="ps-1 text-dark">{item?.counselor_name}</span>
                                        </div>
                                      </Badge>
                                    }
                                    <div className="d-block d-sm-none ps-1">
                                      <i className="mdi mdi-arrow-right-thick cursor-pointer me-1" onClick={(e) => { onClickView(item) }}/>
                                    </div>
                                  </h4>
                                  <div className="row gx-1">
                                    <div className="fw-normal col-auto">
                                      <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                          <Tooltip style={{position:"fixed"}}>
                                            Copy
                                          </Tooltip>
                                        }
                                      >
                                        <CopyToClipboard text={item?.code}
                                          onCopy={() => {
                                            toast.success('Copied', {autoClose: 100, hideProgressBar: true})
                                          }}>
                                            <i className="mdi mdi-content-copy cursor-pointer me-1"/>
                                        </CopyToClipboard>
                                      </OverlayTrigger>
                                      <span className="pe-1 pe-sm-0">#{item?.code}</span>
                                    </div>
                                    {
                                      item?.email &&
                                      <div className="fw-normal col-auto">
                                        <Row className="gx-0">
                                          <Col xs="auto" className="d-none d-sm-block">
                                            <div className="pe-1">|</div>
                                          </Col>
                                          <Col xs="auto" className="d-sm-none">
                                            <i className="mdi mdi-email-outline pe-1"></i>
                                          </Col>
                                          <Col xs="auto" className="">
                                            <a className="text-body text-decoration-underline" target="_blank" href={`mailto:${item?.email}`}>{item?.email}</a>
                                          </Col>
                                        </Row>
                                      </div>
                                    }
                                    {
                                      item?.phone &&
                                      <div className="fw-normal col-auto">
                                        <Row className="gx-0">
                                          <Col xs="auto" className="d-none d-sm-block">
                                            <div className="pe-1">|</div>
                                          </Col>
                                          <Col xs="auto" className="d-sm-none">
                                            <i className="mdi mdi-whatsapp pe-1"></i>
                                          </Col>
                                          <Col xs="auto" className="">
                                            <a className="text-body text-decoration-underline" target="_blank" href={`https://wa.me/${item?.phone}`}>{item?.phone}</a>
                                          </Col>
                                        </Row>
                                      </div>
                                    }
                                    {
                                      item?.dob_dMY &&
                                      <div className="fw-normal col-auto d-none d-sm-block">
                                        <span className="px-1">|</span>
                                        <span>{item?.dob_dMY}</span>
                                      </div>
                                    }
                                    {
                                      item?.grade_name &&
                                      <div className="fw-normal col-auto d-none d-sm-block">
                                        <span className="px-1">|</span>
                                        <span>{item?.grade_name}</span>
                                      </div>
                                    }
                                    {
                                      item?.school_name &&
                                      <div className="fw-normal col-auto d-none d-sm-block">
                                        <span className="px-1">|</span>
                                        <span>{item?.school_name}</span>
                                      </div>
                                    }
                                    {
                                      item?.course &&
                                      <div className="fw-normal col-auto d-none d-sm-block">
                                        <span className="px-1">|</span>
                                        <span>{item?.course}</span>
                                      </div>
                                    }
                                    {
                                      item?.country_name &&
                                      <div className="fw-normal col-auto d-none d-sm-block">
                                        <span className="px-1">|</span>
                                        <span>{item?.country_name}</span>
                                      </div>
                                    }
                                    {
                                      item?.planning_year &&
                                      <div className="fw-normal col-auto d-none d-sm-block">
                                        <span className="px-1">|</span>
                                        <span>{item?.planning_year}</span>
                                      </div>
                                    }
                                    {/* <span className="px-1">|</span>
                                    <span className="fw-normal col-auto d-none d-sm-block">{item?.degree}</span> */}
                                  </div>
                                  {/* <p className="mb-0">
                                    <b>Location:</b> {item?.location}
                                  </p>
                                  <p className="mb-0">
                                    <b>Rank: #</b> {Math.floor(Math.random() * 10)}
                                  </p> */}
                                </div>
                                <div>
                                  <div className="d-flex flex-wrap gap-1 align-items-center justify-content-start mt-1">
                                    <div className="d-md-none">
                                      <BadgeStatus name={item.status_name} group="Leads" className="font-12"/>
                                      {
                                        item?.counselor_name &&
                                        <Badge pill className="px-1 bg-transparent shadow-none">
                                          <div className="d-flex align-items-center">
                                            <img
                                              className="rounded-circle"
                                              src={item?.counselor_avatar}
                                              alt=""
                                              width="25"
                                            />
                                            <span className="ps-1 text-dark">{item?.counselor_name}</span>
                                          </div>
                                        </Badge>
                                      }
                                    </div>
                                    <div className="order-1 order-md-1 px-2 bg-blue badge rounded-pill fw-semibold">
                                      {item?.branch_name}
                                    </div>
                                    {
                                      item?.expo_name &&
                                      <Badge bg="soft-warning" className="order-3 order-md-2 fw-semibold px-2 text-warning" pill>
                                        {item?.expo_name}
                                      </Badge>
                                    }
                                    {
                                      (item.expo_names || []).map((name: string) => (
                                        <div className="d-flex order-2 order-md-3">
                                          <Badge bg="soft-warning" className="fw-semibold px-2 text-warning" pill>
                                            {name}
                                          </Badge>
                                        </div>
                                      ))
                                    }
                                    {
                                      item?.label &&
                                      <BadgeStatus className="order-2 order-md-4 fw-semibold px-2"
                                        name={item?.label}
                                        group="Event"
                                      />
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex-auto d-none d-sm-block">
                              <div className="text-sm-end">
                                {
                                  current?.code != item?.code &&
                                  <Button
                                    size="sm"
                                    variant="primary"
                                    className="rounded-pill waves-effect waves-light text-nowrap "
                                    onClick={() => onClickView(item)}
                                  >
                                    <div className="d-flex align-items-center gap-1">
                                      {/* <i className="mdi mdi-account-settings-outline"></i> */}
                                      <span>View</span>
                                    </div>
                                  </Button>
                                }
                                {/* <Link to="#" className="action-icon text-secondary font-20">
                                  {" "}
                                  <i className="mdi mdi-square-edit-outline"></i>
                                </Link>
                                <Link to="#" className="action-icon text-secondary font-20">
                                  {" "}
                                  <i className="mdi mdi-delete"></i>
                                </Link> */}
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    );
                  })}
                </>
              )
            }
          </>
        )
      }

      {data.meta && (
        <PaginationCard
          stickyBottom
          meta={data.meta}
          activePage={activePage}
          setActivePage={setActivePage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      )}

      {modalShowStatus && (
        <ModalStatus
          data={current}
          show={modalShowStatus}
          onHide={closeModalStatus}
          refetch={() => { refetchLeads() }}
        />
      )}
    </div>
  );
};

export default List;
