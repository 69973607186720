
import React, { useEffect, useState } from "react";
import {
  Card,
  Tab,
  Nav,
  ListGroup,
  Badge,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import CopyText from "components/CopyText";
import ScaleLoader from "components/ScaleLoader";
import SimpleBar from "simplebar-react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { HasAccess } from "@permify/react-role";
import { LuPackageOpen } from "react-icons/lu";
import { MentionsInput, Mention } from 'react-mentions'

import {
  useGetUserMentionQuery,
} from "services/api";

const users = [
  {
    id: 'walter',
    display: 'Walter White',
  },
  {
    id: 'pipilu',
    display: '皮皮鲁',
  },
  {
    id: 'luxixi',
    display: '鲁西西',
  },
  {
    id: 'satoshi1',
    display: '中本聪',
  },
  {
    id: 'satoshi2',
    display: 'サトシ・ナカモト',
  },
  {
    id: 'nobi',
    display: '野比のび太',
  },
  {
    id: 'sung',
    display: '성덕선',
  },
  {
    id: 'jesse',
    display: 'Jesse Pinkman',
  },
  {
    id: 'gus',
    display: 'Gustavo "Gus" Fring',
  },
  {
    id: 'saul',
    display: 'Saul Goodman',
  },
  {
    id: 'hank',
    display: 'Hank Schrader',
  },
  {
    id: 'skyler',
    display: 'Skyler White',
  },
  {
    id: 'mike',
    display: 'Mike Ehrmantraut',
  },
  {
    id: 'lydia',
    display: 'Lydìã Rôdarté-Qüayle',
  },
];

const CommentInput = ({
  onSubmit,
} : {
  onSubmit: any;
}) => {
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [mention, setMention] = useState<any>();
  const [notify, setNotify] = useState(true);

  const {
    data: mentionList,
    isFetching: isFetchingMention,
    isLoading: isLoadingMention,
    isUninitialized: isUninitializedMention,
    error: errorMention,
    isError: isErrorMention,
    refetch: refetchMention,
  } = useGetUserMentionQuery(
    {},
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  return (
    <>
      <MentionsInput
        singleLine
        className="mentions"
        // style={defaultStyle}
        // markup="@[__display__]{__id__}"
        value={comment}
        onChange={(e, value) => {
          const regex = /[^{}]+(?=})/g;
          setComment(value);
          let user = value && value.match(regex);
          setMention(user);
        }}
        onKeyDown={async (e) => {
          if(e.code == "Enter"){
            setLoading(true);
            await onSubmit(comment.replace(/@\[([^[\]]+)\]{[^{}]+}/g, '[$1]'), mention, notify)
            setComment('');
            setLoading(false);
          }
        }}
        // placeholder={"Mention using '@'"}
        placeholder={"Your comment..."}
      >
        <Mention
          style={{
            position: 'relative',
            top: '-2px',
            left: '5px',
            borderBottom: '2px solid #bfbdbd',
            paddingBottom: '2px',
          }}
          // markup="{{__id__}}"
          markup="@[__display__]{__id__}"
          trigger="@"
          data={(mentionList?.result || []).map((item: any) => {
            return {
              id: item?.code,
              display: `${item?.name} (${item?.role_name})`,
            }
          })}
          renderSuggestion={(suggestion, search, highlightedDisplay) => (
            <div className="user">{highlightedDisplay}</div>
          )}
          // onAdd={(e, a, b) => {
          //   console.log(e, a, b);
          // }}
          appendSpaceOnAdd={true}
          displayTransform={(id,display) => `@${display}`}
        />
      </MentionsInput>
      <div className="p-2 bg-light d-flex justify-content-between align-items-center mt-n1">
        <div>
          {/* <Link to="#" className="btn btn-sm px-1 btn-light">
            <i className="mdi mdi-upload"></i>
          </Link>
          <Link to="#" className="btn btn-sm px-1 btn-light">
            <i className="mdi mdi-at"></i>
          </Link> */}
          {/* <div className="form-check form-switch">
            <input
              type="checkbox"
              id="custom-switch"
              className="form-check-input border-application"
              defaultChecked
            />
            <label
              title=""
              htmlFor="custom-switch"
              className="form-check-label fw-normal"
            >
              Notify this mentioned person?
            </label>
          </div> */}
          
          <div className="form-check form-switch">
            <input
              type="checkbox"
              id="custom-switch"
              className="form-check-input border-application"
              defaultChecked={notify}
              onChange={(e) => {
                setNotify(e.target.checked);
              }}
            />
            <label
              title=""
              htmlFor="custom-switch"
              className="form-check-label fw-normal"
            >
              Notify the mentioned person in this discussion
            </label>
          </div>
        </div>
        <button
          type="button"
          className="btn btn-sm btn-success"
          onClick={async (e) => {
            setLoading(true);
            await onSubmit(comment.replace(/@\[([^[\]]+)\]{[^{}]+}/g, '[$1]'), mention, notify)
            setComment('');
            setLoading(false);
          }}
          disabled={loading || !comment}
        >
          <div className="d-flex">
            <i className="mdi mdi-send"></i>
            <span className="ms-1 d-none d-sm-block">Submit</span>
          </div>
        </button>
      </div>
    </>
  )
}
export default CommentInput;