import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// components
import { VerticalForm, FormInput, FormDate, FormSelect, FormSelectAsync } from "components/";

interface AddLeadsProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (value: any) => void;
}

const validation = yupResolver(
  yup.object().shape({
    name: yup.string().nullable(),
    amount: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Field is required"),
  })
);

const AddLeads = ({
  show,
  onHide,
  onSubmit,
}: AddLeadsProps) => {
  
  const methods = useForm({ resolver: validation });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
    reset,
  } = methods;

  return (
    <>
      <Modal
        backdrop="static"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="bg-light" onHide={onHide} closeButton>
          <Modal.Title className="m-0">Add New Leads</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <form
            onSubmit={handleSubmit((values) => onSubmit(values))}
            noValidate
          >
            <FormInput
              label="Full Name"
              type="text"
              name="full_name"
              placeholder="Enter full name"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
            />
            <FormInput
              label="Email address"
              type="text"
              name="email"
              placeholder="Enter email"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
            />
            <FormInput
              label="Phone"
              type="text"
              name="phone"
              placeholder="Enter phone number"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
            />
            <FormDate
              label="Date of Birth"
              type="date"
              name="dob"
              placeholder="Enter date of birth"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
            />
            <FormInput
              label="Address"
              type="textarea"
              name="address"
              placeholder="Enter address number"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
            />
            <FormInput
              label="City"
              type="text"
              name="city"
              placeholder="Enter city"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
            />
            <FormInput
              label="Province"
              type="text"
              name="province"
              placeholder="Enter province"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
            />
            <FormSelect
              label="Tax/Service"
              type="text"
              name="toe_ids"
              placeholder=""
              containerClass={"mb-3"}
              defaultValue={undefined}
              options={[
                {
                  value: '12345', label: '12345 - Jakarta',
                },
                {
                  value: '67891', label: '67891 - Jakarta',
                },
              ]}
              // disabled={isLoadingAdd || isLoadingUpdate}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
              onChange={(e: any) => {
                // let data = e.map((item: any) => {
                //   return item.value;
                // })
                // setValue("toe_ids", data);
                // setCurrent({
                //   ...current,
                //   toe_ids: data,
                // });
              }}
              isClearable
            />
            <FormInput
              label="Prev Education Name"
              type="text"
              name="last_education_name"
              placeholder="Enter last_education_name"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
            />
            <FormInput
              label="Prev Education Level"
              type="text"
              name="last_education_level"
              placeholder="Enter last_education_level"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
            />
            <FormSelect
              label="Year of Study"
              type="text"
              name="fi_calculate_marketing"
              placeholder=""
              containerClass={"mb-3"}
              // defaultValue={current?.fi_calculate_marketing || undefined}
              options={[
                { value: "2023", label: "2023" },
                { value: "2024", label: "2024" },
                { value: "2025", label: "2025" },
                { value: "2026", label: "2026" },
                { value: "2027", label: "2027" },
              ]}
              // disabled={isLoading}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
              // onChange={(opt: any) => {
              //   setCalcMarketingBranding(opt.value);
              // }}
            />

            <div className="text-end">
              <Button
                variant="success"
                type="submit"
                className="waves-effect waves-light me-1"
              >
                Save
              </Button>
              <Button
                variant="danger"
                className="waves-effect waves-light"
                onClick={onHide}
              >
                Continue
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddLeads;
