import React, { useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { cartItems, CartItemTypes } from "./data";
import Moment from 'react-moment';
import moment from "moment";
import { BadgeStatus } from "components"
import Skeleton from "react-loading-skeleton";

import {
  useGetLeadsActivityQuery,
} from 'services/api'

const LatestLeadsActivity = () => {
  const navigate = useNavigate();

  const {
    data: historyList,
    isFetching: isFetchingHistory,
    isLoading: isLoadingHistory,
    isUninitialized: isUninitializedHistory,
    isError: isErrorHistory,
    error: errorHistory,
    refetch: refetchHistory,
  } = useGetLeadsActivityQuery(
    {},
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  return (
    <>
      <Card className="h-100">
        <Card.Body>
          {/* Trigger dari update status */}
          {
            (isFetchingHistory || isLoadingHistory || isUninitializedHistory) ? (
              <>
                <Row className="mb-3">
                  <Col>
                    <Skeleton height="25px" width="50%"/>
                  </Col>
                  <Col>
                    <Skeleton height="25px" width="50%"/>
                  </Col>
                  <Col>
                    <Skeleton height="25px" width="50%"/>
                  </Col>
                  <Col>
                    <Skeleton height="25px" width="50%"/>
                  </Col>
                </Row>
                {
                  [...Array(4)].map((number, i) => {
                    return (
                      <Row className="align-items-center mb-3">
                        <Col>
                          <Row className="align-items-center">
                            <Col xs="auto">
                              <Skeleton circle height="40px" width="40px"/>
                            </Col>
                            <Col>
                              <Skeleton width="50%"/>
                              <Skeleton height={10} width="75%"/>
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Skeleton width="50%"/>
                        </Col>
                        <Col>
                          <Skeleton width="50%"/>
                        </Col>
                        <Col>
                          <Skeleton width="50%"/>
                        </Col>
                      </Row>
                    )
                  })
                }
              </>
            ) : (
              <>
                <div className="font-16 fw-bold mb-3 text-dark">Latest Leads Activities</div>
                
                <div className="table-responsive">
                  <table className="table table-borderless mb-0">
                    <thead className="table-light">
                      <tr>
                        <th style={{ minWidth: '230px'}}>Name</th>
                        <th style={{ minWidth: '150px'}}>Counselor</th>
                        {/* <th>Event</th> */}
                        <th style={{ minWidth: '300px'}}>Comments</th> {/* Latest Comment, 1 comment */}
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(historyList?.result || []).map((item: any, idx: number) => {
                        return (
                          <tr key={idx} className="cursor-pointer">
                            <td onClick={() => {
                              navigate(`/lms/leads/${item.leads_code}`)
                            }}>
                              <div className="d-flex align-items-start">
                                <img
                                  src={item?.leads_avatar}
                                  alt={item?.full_name}
                                  title="contact-img"
                                  className="avatar-sm rounded-circle me-3"
                                  height="20"
                                />
                                <div className="d-flex flex-column">
                                  <span className="fw-semibold font-14">{item.leads_full_name}</span>
                                  <span className="font-11 text-muted">
                                    {item.date}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>{item.leads_counselor_name}</td>
                            {/* <td>{item.institution}</td> */}
                            <td>{item.note}</td>
                            <td>
                              <BadgeStatus
                                group="Leads"
                                name={item?.status_name}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            )
          }
        </Card.Body>
      </Card>
    </>
  );
};

export default LatestLeadsActivity;
