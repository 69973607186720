import React, { useEffect, useState } from "react";
import { Row, Col, Button, Card, Badge, OverlayTrigger, Tooltip, } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { FormSelect } from "components/";
import Select from "react-select";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from "react-toastify";
import { LuPackageOpen } from "react-icons/lu";
import ScaleLoader from "components/ScaleLoader";

// components
import PaginationCard from "components/PaginationCard";
import { BadgeStatus, FormDate, FormInput } from "components"
import { CompanyInfoItems } from "./data"

import {
  useGetExpoQuery,
} from "services/api";

import CardItem from "pages/LMS/Event/CardItem";

interface LeadsProps {
  // companyInfo: CompanyInfoItems[];
}

const List = ({
  isLoading,
  data,
  current,
  setCurrent,
  search,
  setSearch,
  filterBranch,
  setFilterBranch,
  sort,
  setSort,
  activePage,
  setActivePage,
  perPage,
  setPerPage,
  layout,
  setLayout,
  isChecked,
  setIsChecked,
  leadsCount,
  setLeadsCount,
  openModalManage,
} : {
  isLoading: any;
  data: any;
  current: any;
  setCurrent: any;
  search: any;
  setSearch: any;
  filterBranch: any;
  setFilterBranch: any;
  sort: any;
  setSort: any;
  activePage: any;
  setActivePage: any;
  perPage: any;
  setPerPage: any;
  layout: any;
  setLayout: any;
  isChecked?: any;
  setIsChecked?: any;
  leadsCount?: any;
  setLeadsCount?: any;
  openModalManage?: any;
}) => {
  // const [companyInfo, setCompanyInfo] = useState<Array<CompanyInfoItems>>(
  //   props.companyInfo
  // );

  const [filter, setFilter] = useState({});

  // useEffect(() => {
  //   console.log('error', error)
  // }, [isError])

  return (
    <div className="position-relative">
      {
        (!isLoading && data?.result?.length == 0) ? (
          <div className="d-flex flex-column justify-content-center align-items-center mb-3" style={{minHeight: '200px'}}>
            <LuPackageOpen size={64}/>
            <p>No data</p>
          </div>
        ) : (
          <>
            {
              isLoading ? (
                <>
                  <div style={{height: 'calc(100vh - 200px)'}}>
                    <ScaleLoader />
                  </div>
                </>
              ) : (
                <>
                  {(data?.result || []).map((item: any, index: any) => {
                    return (
                      <CardItem key={index} item={item} openModalManage={openModalManage} canView canEdit canDelete canExport />
                    );
                  })}
                </>
              )
            }
          </>
        )
      }

      {data.meta && (
        <PaginationCard
          stickyBottom
          meta={data.meta}
          activePage={activePage}
          setActivePage={setActivePage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      )}
    </div>
  );

  // return (
  //   <>
      
  //     {
  //       (isLoading || isFetching || isUninitialized || isError) ? (
  //         <>
  //           <ScaleLoader />
  //         </>
  //       ) : (
  //         <>
  //           {
  //             (!isLoading && lists?.result?.length == 0) ? (
  //               <div className="d-flex flex-column justify-content-center align-items-center mb-3" style={{minHeight: '200px'}}>
  //                 <LuPackageOpen size={64}/>
  //                 <p>No data</p>
  //               </div>
  //             ) : (
  //               <>
  //               </>
  //             )
  //           }
  //         </>
  //       )
  //     }
  //   </>
  // );
};

export default List;
