import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { IoWarningOutline } from "react-icons/io5";
import moment from "moment";

// components
import { VerticalForm, FormSelect, FormInput, FormDate, SingleUpload } from "components/";

import {
  useGetLogoQuery,
  useUpdateEventLogoMutation,
} from "services/api";

const baseURL: string = (process.env.REACT_APP_API_BASE as string);

interface ModalLogoProps {
  show: boolean;
  onHide: () => void;
}

const ModalLogo = ({
  show,
  onHide,
}: ModalLogoProps) => {
  const [image, setImage] = useState<File>();

  const {
    data: base64Logo,
    isFetching: isFetchingLogo,
    isLoading: isLoadingLogo,
    isUninitialized: isUninitializedLogo,
    isError: isErrorLogo,
    error: errorLogo,
    refetch: refetchLogo,
  } = useGetLogoQuery(
    {},
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: isSkipped(),
    }
  );
  const [updateEventLogo, { isLoading: isLoadingCreate }] = useUpdateEventLogoMutation();

  const validation = yupResolver(
    yup.object().shape({
    })
  );
  
  const methods = useForm({ resolver: validation });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
    reset: resetForm,
  } = methods;

  const onSubmit = async (values: any) => {
    try {
      let formData = new FormData();
      if(image) {
        formData.append("image", image)
      } else {
        formData.delete("image")
      }
      await updateEventLogo(formData)
        .unwrap()
        .then((res) => {
          if(res.status){
            onHide()
            toast.success(res.message)
            window.location.reload()
          }
        })
        .catch((error: any) => {
          console.log("error");
        });
    } catch (err: any) {
      console.log(err);
      // if (err?.data?.errors) {
      //   Object.keys(err?.data?.errors)?.forEach((field) => {
      //     err.data.errors[field]?.forEach((message: string) => {
      //       toast.error(message, { autoClose: 10000 });
      //     });
      //   });
      // }
    }
  };

  useEffect(() => {
    console.log(errors)
  }, [errors])

  useEffect(() => {
    // reset();
    // setCurrent(data);
    // console.log(data)
    // if (data) {
    //   Object.keys(data).map((key) => {
    //     setValue(key, data[key]);
    //   });
    // }
  }, []);

  return (
    <>
      <Modal
        backdrop="static"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="bg-light" onHide={onHide} closeButton>
          <Modal.Title className="m-0">
            Event Logo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <form
            onSubmit={handleSubmit((values: any) => onSubmit({...values}))}
            noValidate
          >
            <div className="mb-3">
              <label className="form-label">Image</label>
              <SingleUpload
                name="image"
                errors={errors}
                // style={{height: '150px', width: '150px'}}
                onFileUpload={(f) => {
                  setValue("image", f);
                  setImage(f);
                }}
                onRemove={(f: any) => {
                  setValue("image", f);
                  setImage(f);
                  setValue("image_base64", null);
                }}
                // disabled={isLoading}
                imagePreview={base64Logo}
                // imagePreview={data?.image || undefined}
                // imageName={data?.image_name}
              />
            </div>
            <div className="text-end border-top mt-3 pt-3">
              <Button
                variant="success"
                type="submit"
                className="waves-effect waves-light me-1"
                disabled={isLoadingCreate}
              >
                Submit
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalLogo;
