import React, { useEffect, useState } from "react";
import { Row, Col, Button, Card, Badge, OverlayTrigger, Tooltip, } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { FormSelect } from "components/";
import Select from "react-select";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from "react-toastify";

// components
import { BadgeStatus, FormDate, FormInput } from "components"
import ModalManage from "./ModalManage";
import ModalFilter from "./ModalFilter";
import { CompanyInfoItems } from "./data"

import {
  useGetExpoQuery,
} from "services/api";
import moment from "moment";

interface LeadsProps {
  companyInfo: CompanyInfoItems[];
}

const CardItem = ({item, openModalManage, canView, canEdit, canDelete, canExport} : {item: any, openModalManage?: any, canView?: boolean, canDelete?: boolean, canEdit?: boolean, canExport?: boolean}) => {
  const navigate = useNavigate();

  return (
    <Card className="mb-2">
      <Card.Body>
        <Row className="align-items-center">
          <Col>
            <div className="d-flex align-items-center">
              <div className="align-self-start d-none d-md-block" style={{width: '120px'}}>
                <img
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate(`/lms/events/${item.code}`);
                  }}
                  className="d-flex align-self-center me-3 img-fluid mx-auto border cursor-pointer"
                  src={item.image}
                  alt=""
                  // width="64"
                  style={{maxWidth: '100px'}}
                />
              </div>
              <div className="d-flex flex-column gap-1">
                <div className="d-flex flex-column gap-1 mb-1">
                  <h4 
                    className="mt-0 mb-0 font-16 cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      navigate(`/lms/events/${item.code}`);
                    }}
                    >
                      <div className="d-flex flex-nowrap align-items-center">
                        <span className="me-1">{item.name}</span>
                        <div className="d-none d-sm-block">
                        {
                          item.is_open ? (
                            <Badge bg="success">
                              Open
                            </Badge>
                          ) : (
                            <Badge bg="secondary">
                              Closed
                            </Badge>
                          )
                        }
                        </div>
                      </div>
                  </h4>
                  <div className="d-flex flex-column justify-content-start align-items-start gap-0 gap-sm-1 mt-1">
                    <div className="d-flex flex-wrap gap-1">
                      <div className="d-flex justify-content-start align-items-center d-sm-none">
                        {
                          item.is_open ? (
                            <Badge bg="success">
                              Open
                            </Badge>
                          ) : (
                            <Badge bg="secondary">
                              Closed
                            </Badge>
                          )
                        }
                      </div>
                      <div className="d-flex justify-content-start align-items-center">
                        <strong>
                          <i className="mdi mdi-barcode-scan me-1 font-18" />
                        </strong>
                        <span className="">#{item.code}</span>
                      </div>
                      <div className="d-flex justify-content-start align-items-center">
                        <strong>
                          <i className="mdi mdi-timer-outline me-1 font-18" />
                        </strong>
                        <span className="">{item.start_time} - {item.end_time}</span>
                      </div>
                      <div className="d-flex justify-content-start align-items-center">
                        <strong>
                          <i className="mdi mdi-calendar me-1 font-18" />
                        </strong>
                        <span className="">{moment(item.date,'YYYY-MM-DD').format('D MMMM YYYY')}</span>
                      </div>
                      <div className="d-flex justify-content-start align-items-center">
                        <strong>
                          <i className="mdi mdi-google-maps me-1 font-18" />
                        </strong>
                        <span className="">{item.venue}</span>
                      </div>
                    </div>
                    <div className="d-flex gap-1">
                      <div className="pt-1 pt-sm-0">
                        <Badge bg="secondary" text="white" className="">
                          <span className="fw-bold">Registrant:</span>
                          <span className="ms-1">{item.registrant_count}</span>
                        </Badge>
                      </div>
                      <div className="pt-1 pt-sm-0">
                        <Badge bg="success" className="">
                          <span className="fw-bold">Attendees:</span>
                          <span className="ms-1">{item.attendance_count}</span>
                        </Badge>
                      </div>
                    </div>
                  </div>
                  {/* <p className="mb-1 mt-3 mt-sm-0 font-14">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.... 
                  </p> */}
                </div>
                <div className="d-flex flex-column flex-sm-row gap-1">
                  <FormInput
                    size="sm"
                    // className="form-control-sm"
                    // className="bg-light"
                    // label="Registration URL"
                    type="text"
                    name="booth_email"
                    placeholder=""
                    // containerClass={"mb-3"}
                    disabled={true}
                    defaultValue={item.registration_url}
                    canCopy
                    canOpenLink
                    inputPrefix="Registration URL"
                  />
                  <FormInput
                    size="sm"
                    // className="form-control-sm"
                    // className="bg-light"
                    // label="Booth URL"
                    type="text"
                    name="booth_email"
                    placeholder=""
                    // containerClass={"mb-3"}
                    disabled={true}
                    defaultValue={item.booth_url}
                    canCopy
                    canOpenLink
                    inputPrefix="Booth URL"
                  />
                </div>
              </div>
            </div>
          </Col>
          {/* <Col sm={4}>
            <p className="mb-1 mt-3 mt-sm-0">
              <i className="mdi mdi-email me-1"></i> {item.email}
            </p>
            <p className="mb-0">
              <i className="mdi mdi-phone-classic me-1"></i> {item.phone}
            </p>
          </Col> */}
          {/* <Col sm={2}>
            <Row className="mt-3 mt-sm-0 gap-1">
              <Col xs={12}>
                <div
                  className="me-1 badge-outline-secondary badge rounded-pill font-12 py-1 px-2"
                  style={{width: '120px'}}
                >
                  <b>Leads: </b> 0
                </div>
              </Col>
              <Col xs={12}>
                <div
                  className="me-1 bg-success badge rounded-pill bg-primary font-12 py-1 px-2"
                  style={{width: '120px'}}
                >
                  <b>Student: </b> 0
                </div>
              </Col>
            </Row>
          </Col> */}
          <Col xs="auto" sm={5} className="d-none d-lg-block">
            <div className="d-flex justify-content-end gap-1">
              {/* <Link onClick={(e) => openModalManage("edit", item)} to="" className="action-icon text-secondary font-20">
                {" "}
                <i className="mdi mdi-square-edit-outline"></i>
              </Link> */}
              {/* <Link onClick={(e) => openModalManage("delete", item)} to="" className="action-icon text-secondary font-20">
                {" "}
                <i className="mdi mdi-delete"></i>
              </Link> */}
              {
                canExport &&
                <Button
                  // size="sm"
                  variant="outline-dark"
                  className="rounded-pill waves-effect waves-light text-nowrap "
                  onClick={() => openModalManage ? openModalManage("export", item) : null}
                >
                  <div className="d-flex align-items-center gap-1">
                    {/* <i className="mdi mdi-account-settings-outline"></i> */}
                    <span>Export</span>
                  </div>
                </Button>
              }
              {
                canDelete &&
                <Button
                  // size="sm"
                  variant={item.registrant_count > 0 ? "outline-dark" : "outline-danger"}
                  className="rounded-pill waves-effect waves-light text-nowrap "
                  onClick={() => openModalManage ? openModalManage("delete", item) : null}
                  disabled={item.registrant_count > 0}
                >
                  <div className="d-flex align-items-center gap-1">
                    {/* <i className="mdi mdi-account-settings-outline"></i> */}
                    <span>Delete</span>
                  </div>
                </Button>
              }
              {
                canEdit &&
                <Button
                  // size="sm"
                  variant="outline-primary"
                  className="rounded-pill waves-effect waves-light text-nowrap "
                  onClick={() => openModalManage ? openModalManage("edit", item) : null}
                >
                  <div className="d-flex align-items-center gap-1">
                    {/* <i className="mdi mdi-account-settings-outline"></i> */}
                    <span>Edit</span>
                  </div>
                </Button>
              }
              {
                canView &&
                <Button
                  // size="sm"
                  variant="primary"
                  className="rounded-pill waves-effect waves-light text-nowrap "
                  onClick={() => navigate(`/lms/events/${item.code}`)}
                >
                  <div className="d-flex align-items-center gap-1">
                    {/* <i className="mdi mdi-account-settings-outline"></i> */}
                    <span>View</span>
                  </div>
                </Button>
              }
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default CardItem;
