import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { VerticalForm, FormSelect, FormInput, FormInput2, FormDate, SingleUpload } from "components/";
import { faker } from "@faker-js/faker";

import {
  useGetStatusQuery,
  useChangeStatusLeadsMutation,
  useGetAutocompleteQuery,
  useGetAdmissionQuery,
} from "services/api";

import { toast } from "react-toastify";
import { IoWarningOutline } from "react-icons/io5";

const yearOptions = () => {
  let sy = 2017;
  let cy = new Date().getFullYear()
  let ey = cy + 5;
  let yrs = [];

  for (var i = sy; i <= ey; i++) {
    yrs.push({
      value: sy,
      label: sy,
    });
    sy++;
  }
  return yrs;
}

interface ModalStatusProps {
  show: boolean;
  data?: any;
  onHide: () => void;
  refetch?: any;
}

const ModalStatus = ({
  show,
  data,
  onHide,
  refetch,
}: ModalStatusProps) => {
  const [statusName, setStatusName] = useState('');
  const [tuitionType, setTuitionType] = useState('New');
  const {
    data: statusList,
    isFetching: isFetchingStatus,
    isLoading: isLoadingStatus,
    isUninitialized: isUninitializedStatus,
    isError: isErrorStatus,
    error: errorStatus,
    refetch: refetchStatus,
  } = useGetStatusQuery(
    {
      group: 'Leads',
      exclude: ['Unassigned','Unhandled','Succeed','Cancelled', data?.status_name],
      // search,
      // sort,
      // page: activePage,
      // per_page: perPage,
    },
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  const { data: institutionList, isLoading: isLoadingInstitution } = useGetAutocompleteQuery({type: 'INS'});
  const { data: courseLevelList, isLoading: isLoadingCourseLevel } = useGetAutocompleteQuery({type: 'CRL'});
  const { data: courseMajorList, isLoading: isLoadingCourseMajor } = useGetAutocompleteQuery({type: 'CMJ'});
  const { data: courseFullNameList, isLoading: isLoadingCourseFullName } = useGetAutocompleteQuery({type: 'CFN'});
  const { data: currencyList, isLoading: isLoadingCurrency } = useGetAutocompleteQuery({type: 'CRY'});
  
  const {
    data: admissionList,
    isFetching: isFetchingAdmission,
    isLoading: isLoadingAdmission,
    isUninitialized: isUninitializedAdmission,
    error: errorAdmission,
    isError: isErrorAdmission,
    refetch: refetchAdmission,
  } = useGetAdmissionQuery(
    {},
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  const [changeStatus, { isLoading: isLoadingUpdate }] = useChangeStatusLeadsMutation();

  const validation = yupResolver(
    yup.object().shape({
      leads_code: yup.mixed().required(''),
      status_code: yup.string().required(''),
      note: yup.string().required(''),
      application: yup.mixed().when('status_name', {
        is: 'Applied',
        then: yup.object().shape({
          institution_code: yup.string().label('Institution').required(''),
          planning_year: yup.string().label('Year of Study').required(''),
          course_level_code: yup.string().label('Course level').required(''),
          course_major_code: yup.string().label('Study Area').required(''),
          course_full_name_code: yup.string().label('Course full name').required(''),
          tuition_type: yup.string().label('Tuition Type').required(''),
          tuition_type_note: yup.mixed().default('').when('tuition_type', {
            is: 'Nomination',
            then: yup.string().label('Tuition Type Note').required(''),
          }),
          tuition_fee_currency: yup.string().label('Tuition Fee Currency').required(''),
          tuition_fee_amount: yup.number().transform((value) => (isNaN(value) ? undefined : value)).label('Tuition Fee Amount').required(''),
          scholarship_currency: yup.string().label('Scholarship Currency'),
          scholarship_amount: yup.mixed().transform((value) => (isNaN(value) ? undefined : value)).label('Scholarship Amount'),
          admission_code: yup.string().label('Admission').nullable(),
        }),
      }),
    })
  );
  
  const methods = useForm({ resolver: validation });
  const {
    handleSubmit,
    register,
    control,
    trigger,
    setValue,
    setError,
    clearErrors,
    getValues,
    getFieldState,
    formState: { errors },
    reset: resetForm,
  } = methods;

  const onSubmit = async (values: any) => {
    await changeStatus({
      ...values,
      leads_code: data.code,
    })
      .unwrap()
      .then((res) => {
        if(res.status){
          onHide()
          toast.success(res.message)
        }
      })
      .catch((error: any) => {
        console.log("error");
        if(error?.data?.validation){
          Object.keys(error?.data?.validation)?.forEach((field) => {
            error?.data?.validation[field]?.forEach((message: string) => {
              setError(field, { type: 'custom', message: message })
              console.log(field, message)
            });
          });
        }
      });
    refetch()
  };

  useEffect(() => {
    console.log(errors)
  }, [errors])

  useEffect(() => {
    if(data) {
      setValue('leads_code', data.code);
    }
  }, []);

  // Dummy Data
  useEffect(() => {
    if(getValues()['status_name'] == 'Applied'){
      // setValue('institution_code','INS2400'+faker.number.int({ min: 1, max: 9 }));
      // setValue('planning_year','202'+faker.number.int({ min: 4, max: 9 }));
      // setValue('course_level_code','CLE240'+faker.number.int({ min: 1, max: 9 }));
      // setValue('course_major_code','CMJ240'+faker.number.int({ min: 1, max: 9 }));
      // setValue('course_full_name_code','CFM240'+faker.number.int({ min: 1, max: 9 }));
      // setValue('marketing_source_code','MKS2400'+faker.number.int({ min: 1, max: 9 }));
    }
  }, [getValues()['status_name']]);

  return (
    <>
      <Modal
        backdrop="static"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header onHide={onHide} closeButton>
          <h4 className="modal-title">Update Leads Status</h4>
        </Modal.Header>
        <Modal.Body className="p-4">
          <form
            onSubmit={handleSubmit((values: any) => onSubmit(values))}
            noValidate
          >
            <div className="mb-3">
              <label className="form-label">Status</label>
              <div className="row">
                <div className="col-5">
                  <FormInput
                    className="bg-light"
                    register={register}
                    control={control}
                    trigger={trigger}
                    getFieldState={getFieldState}
                    errors={errors}
                    setValue={setValue}
                    label=""
                    type="text"
                    name="old_status"
                    placeholder=""
                    // containerClass={"mb-3"}
                    disabled
                    defaultValue={data.status_name}
                  />
                </div>
                <div className="col-auto text-center my-auto">
                  <i className="mdi mdi-arrow-right font-20"></i>
                </div>
                <div className="col-5">
                  <FormSelect
                    className="w-100"
                    label=""
                    name="status_code"
                    placeholder=""
                    containerClass={"w-100"}
                    defaultValue={getValues()['status_code'] || undefined}
                    options={(statusList?.result || []).map((item: any) => {
                      return {
                        label: item.name,
                        value: item.code,
                      }
                    })}
                    // disabled={isLoading}
                    register={register}
                    control={control}
                    trigger={trigger}
                    getFieldState={getFieldState}
                    errors={errors}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    onChange={(opt: any) => {
                      setValue('status_code', opt.value);
                      setValue('status_name', opt.label);
                      setStatusName(opt.label)
                    }}
                    disabled={isLoadingUpdate}
                  />
                </div>
              </div>
            </div>
            {
              statusName == 'Applied' &&
              <> 
                {/* <h4 className="header-title">Interested In</h4> */}
                {/* <p className="sub-header">Please select preferenced institution and course details</p> */}
                <FormSelect
                  label="Admission"
                  name="application.admission_code"
                  placeholder="e.g. Arjuna"
                  containerClass={"mb-3"}
                  defaultValue={getValues()['admission_code'] || undefined}
                  options={(admissionList?.result || []).map((item: any) => {
                    return {
                      label: item.name,
                      value: item.code,
                    };
                  })}
                  // disabled={isLoading}
                  register={register}
                  control={control}
                  trigger={trigger}
                  getFieldState={getFieldState}
                  errors={errors}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  // onChange={(opt: any) => {
                  //   setCalcMarketingBranding(opt.value);
                  // }}
                />
                <FormSelect
                  label="Institution"
                  name="application.institution_code"
                  // {...register('application.institution_code')}
                  placeholder="e.g. SIM - Singapore"
                  containerClass={"mb-3"}
                  defaultValue={getValues()['institution_code'] || undefined}
                  options={(institutionList?.result || [])}
                  // disabled={isLoading}
                  register={register}
                  control={control}
                  trigger={trigger}
                  getFieldState={getFieldState}
                  errors={errors}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  onChange={(opt: any) => {
                    console.log(opt)
                    // setCalcMarketingBranding(opt.value);
                  }}
                />
                <FormSelect
                  label="Year of Study"
                  type="text"
                  name="application.planning_year"
                  placeholder="e.g. 2024"
                  containerClass={"mb-3"}
                  defaultValue={getValues()['planning_year'] || undefined}
                  options={yearOptions()}
                  // disabled={isLoading}
                  register={register}
                  control={control}
                  trigger={trigger}
                  getFieldState={getFieldState}
                  errors={errors}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  // onChange={(opt: any) => {
                  //   setCalcMarketingBranding(opt.value);
                  // }}
                />
                <FormSelect
                  label="Course Level"
                  name="application.course_level_code"
                  placeholder="e.g. Foundation"
                  containerClass={"mb-3"}
                  defaultValue={getValues()['course_level_code'] || undefined}
                  options={(courseLevelList?.result || [])}
                  // disabled={isLoading}
                  register={register}
                  control={control}
                  trigger={trigger}
                  getFieldState={getFieldState}
                  errors={errors}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  // onChange={(opt: any) => {
                  //   setCalcMarketingBranding(opt.value);
                  // }}
                />
                <FormSelect
                  label="Study Area"
                  name="application.course_major_code"
                  placeholder="e.g. Science"
                  containerClass={"mb-3"}
                  defaultValue={getValues()['course_major_code'] || undefined}
                  options={(courseMajorList?.result || [])}
                  // disabled={isLoading}
                  register={register}
                  control={control}
                  trigger={trigger}
                  getFieldState={getFieldState}
                  errors={errors}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  // onChange={(opt: any) => {
                  //   setCalcMarketingBranding(opt.value);
                  // }}
                />
                <FormSelect
                  label="Course Full Name"
                  name="application.course_full_name_code"
                  placeholder="e.g. Sport Science"
                  containerClass={"mb-3"}
                  defaultValue={getValues()['course_full_name_code'] || undefined}
                  options={(courseFullNameList?.result || [])}
                  // disabled={isLoading}
                  register={register}
                  control={control}
                  trigger={trigger}
                  getFieldState={getFieldState}
                  errors={errors}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  // onChange={(opt: any) => {
                  //   setCalcMarketingBranding(opt.value);
                  // }}
                />
                <div>
                  <label className="form-label">Tuition Type</label>
                  <div className="row">
                    <div className="col-5">
                      <FormSelect
                        // label="Currency"
                        name="application.tuition_type"
                        placeholder="Type"
                        containerClass={"mb-3"}
                        defaultValue={getValues()['tuition_type'] || undefined}
                        options={[
                          {
                            label: 'New',
                            value: 'New',
                          },
                          {
                            label: 'Nomination',
                            value: 'Nomination',
                          }
                        ]}
                        // disabled={isLoading}
                        register={register}
                        control={control}
                        trigger={trigger}
                        getFieldState={getFieldState}
                        errors={errors}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        onChange={(opt: any) => {
                          setTuitionType(opt.value)
                        }}
                      />
                    </div>
                    {
                      tuitionType == 'Nomination' &&
                      <div className="col-7">
                        <FormInput
                          // label=""
                          type="text"
                          name="application.tuition_type_note"
                          placeholder="e.g other agency"
                          containerClass={"mb-3"}
                          defaultValue={data?.tuition_type_note || undefined}
                          register={register}
                          control={control}
                          trigger={trigger}
                          getFieldState={getFieldState}
                          errors={errors}
                          setValue={setValue}
                        />
                      </div>
                    }
                  </div>
                </div>
                <div>
                  <label className="form-label">Tuition Fee</label>
                  <div className="row">
                    <div className="col-4">
                      <FormSelect
                        // label="Currency"
                        name="application.tuition_fee_currency"
                        placeholder="Currency"
                        containerClass={"mb-3"}
                        defaultValue={getValues()['tuition_fee_currency'] || undefined}
                        options={(currencyList?.result || [])}
                        // disabled={isLoading}
                        register={register}
                        control={control}
                        trigger={trigger}
                        getFieldState={getFieldState}
                        errors={errors}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        // onChange={(opt: any) => {
                        //   setCalcMarketingBranding(opt.value);
                        // }}
                      />
                    </div>
                    <div className="col-8">
                      <FormInput
                        // label=""
                        type="number"
                        name="application.tuition_fee_amount"
                        placeholder=""
                        containerClass={"mb-3"}
                        defaultValue={data?.tuition_fee_amount || undefined}
                        register={register}
                        control={control}
                        trigger={trigger}
                        getFieldState={getFieldState}
                        errors={errors}
                        setValue={setValue}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <label className="form-label">Scholarship (Optional)</label>
                  <div className="row">
                    <div className="col-4">
                      <FormSelect
                        // label="Currency"
                        name="application.scholarship_currency"
                        placeholder="Currency"
                        containerClass={"mb-3"}
                        defaultValue={getValues()['scholarship_currency'] || undefined}
                        options={(currencyList?.result || [])}
                        // disabled={isLoading}
                        register={register}
                        control={control}
                        trigger={trigger}
                        getFieldState={getFieldState}
                        errors={errors}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        // onChange={(opt: any) => {
                        //   setCalcMarketingBranding(opt.value);
                        // }}
                      />
                    </div>
                    <div className="col-8">
                      <FormInput
                        // label=""
                        type="text"
                        name="application.scholarship_amount"
                        placeholder=""
                        containerClass={"mb-3"}
                        defaultValue={data?.scholarship_amount || undefined}
                        register={register}
                        control={control}
                        trigger={trigger}
                        getFieldState={getFieldState}
                        errors={errors}
                        setValue={setValue}
                      />
                    </div>
                  </div>
                </div>
              </>
            }
            <FormInput
              register={register}
              control={control}
              trigger={trigger}
              getFieldState={getFieldState}
              errors={errors}
              setValue={setValue}
              label="Notes"
              type="textarea"
              name="note"
              placeholder=""
              containerClass={"mb-3"}
            />
            <div className="text-end">
              <Button
                variant="success"
                type="submit"
                className="waves-effect waves-light me-1"
                disabled={isLoadingUpdate}
              >
                Submit
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalStatus;
