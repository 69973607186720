import React, {
  useState,
  useEffect,
  InputHTMLAttributes,
  forwardRef,
} from "react";
import { Form, InputGroup } from "react-bootstrap";
import classNames from "classnames";
import HyperDatepicker from "components/Datepicker";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MaskedInput from "react-text-mask";

import { FieldErrors, Control } from "react-hook-form";

interface DatepickerInputProps {
  onClick?: () => void;
  value?: string;
  inputClass: string;
  children?: React.ReactNode;
  onChange?: any;
}

/* Datepicker with Input */
const DatepickerInput = forwardRef<HTMLInputElement, DatepickerInputProps>(
  (props, ref) => {
    const onDateValueChange = () => {
      console.log("date value changed");
    };
    return (
      <input
        type="text"
        className="form-control"
        onClick={props.onClick}
        value={props.value}
        onChange={onDateValueChange}
        ref={ref}
      />
    );
  }
);

interface FormDateProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  type?: string;
  name: string;
  placeholder?: string;
  register?: any;
  errors?: FieldErrors;
  control?: Control<any>;
  trigger?: any;
  getFieldState?: any;
  setValue?: any;
  setError?: any;
  clearErrors?: any;
  getValues?: any;
  className?: string;
  labelClassName?: string;
  containerClass?: string;
  refCallback?: any;
  children?: any;
  rows?: string;
  options?: any;
  value?: any;
  defaultValue?: string;
  isDateMask?: boolean;
  onChange?: any;
}

const FormDate = ({
  label,
  type,
  name,
  placeholder,
  register,
  errors,
  control,
  trigger,
  getFieldState,
  setValue,
  setError,
  clearErrors,
  getValues,
  className,
  labelClassName,
  containerClass,
  refCallback,
  children,
  rows,
  options,
  value,
  defaultValue,
  isDateMask,
  onChange,
  ...otherProps
}: FormDateProps) => {
  // handle input type
  const comp =
    type === "textarea" ? "textarea" : type === "select" ? "select" : "input";
  const [currentValue, setCurrentValue] = useState("");
  const [selectedDate, setSelectedDate] = useState<Date>();

  const onDateChange = (date: Date) => {
    if (date) {
      setSelectedDate(date);
      const dateString = moment(date).format("YYYY-MM-DD");
      if(setValue){
        setValue(name,dateString,true)
      }
      setCurrentValue(dateString)
      if(dateString && clearErrors){
        clearErrors(name)
      }
    }
  };

  useEffect(() => {
    if(defaultValue){
      setSelectedDate(moment(defaultValue).toDate())
    }
  }, [])

  return (
    <>
      <Form.Group className={containerClass}>
        {label ? (
          <Form.Label className={labelClassName}>{label}</Form.Label>
        ) : null}
        {
          isDateMask ? (
            <MaskedInput
              mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
              // placeholder="__-__-____"
              placeholder={placeholder}
              className={`form-control  ${className} ${
                errors && errors[name] ? "is-invalid" : ""
              }`}
              defaultValue={defaultValue}
              onChange={(e: any) => {
                if(setValue){
                  setValue(name, e.target.value);
                }
                setCurrentValue(e.target.value);
                if (e.target.value) {
                  clearErrors(name);
                }
                if(onChange){
                  onChange(e)
                }
              }}
            />
          ) : (
            <>
              <Form.Control
                // type={type}
                type="hidden"
                defaultValue={defaultValue}
                placeholder={placeholder}
                name={name}
                id={name}
                as="input"
                ref={(r: HTMLInputElement) => {
                  if (refCallback) refCallback(r);
                }}
                className={className}
                isInvalid={errors && errors[name] ? true : false}
                {...(register ? register(name) : {})}
                rows={rows}
                {...otherProps}
                autoComplete={name}
              >
                {children ? children : null}
              </Form.Control>
              <HyperDatepicker
                placeholder={placeholder}
                selected={null}
                hideAddon={true}
                dateFormat="yyyy-MM-dd"
                momentFormat="D MMMM YYYY"
                value={selectedDate || undefined}
                onChange={(date) => {
                  onDateChange(date);
                }}
                inputClass={errors && errors[name] ? 'is-invalid' : ''}
              />
            </>
          )
        }
        
        {errors && errors[name] ? (
          <Form.Control.Feedback type="invalid">
            {errors[name]["message"]}
          </Form.Control.Feedback>
        ) : null}
      </Form.Group>
    </>
  );
};

export default FormDate;
