import React, { useEffect, useState } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// components
import { VerticalForm, FormSelect, FormInput, FormDate, SingleUpload } from "components/";
import Representative from "./Representative"

import {
  useGetCityQuery,
  useCreateRepresentativeMutation,
  useUpdateRepresentativeMutation,
  useDeleteRepresentativeMutation,
} from "services/api";

import { toast } from "react-toastify";
import { IoWarningOutline } from "react-icons/io5";
import { FaDivide } from "react-icons/fa6";

interface ModalRepresentativeProps {
  show: boolean;
  type: string;
  data?: any;
  onHide: () => void;
  refetch?: any;
}

const ModalRepresentative = ({
  show,
  type,
  data,
  onHide,
  refetch,
}: ModalRepresentativeProps) => {
  const [createRepresentative, { isLoading: isLoadingCreate }] = useCreateRepresentativeMutation();
  const [updateRepresentative, { isLoading: isLoadingUpdate }] = useUpdateRepresentativeMutation();
  const [deleteRepresentative, { isLoading: isLoadingDelete }] = useDeleteRepresentativeMutation();

  const validation = yupResolver(
    yup.object().shape({
      name: yup.string().required(''),
      email: yup.string().email("Please enter valid email").nullable(),
      phone: yup.string().max(20).nullable(),
    })
  );

  const validationDelete = yupResolver(
    yup.object().shape({
      code: yup.string().required(''),
    })
  );
  
  const methods = useForm({ resolver: type == 'delete' ? validationDelete : validation });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
    reset: resetForm,
  } = methods;

  const onSubmit = async (values: any) => {
    try {
      let formData = new FormData();
      Object.keys(values).forEach((key: any) => {
        if(values[key]){
          formData.append(key, values[key]);
        }
      });

      switch (type) {
        case 'add':
          await createRepresentative(formData)
          .unwrap()
          .then((res) => {
            if(res.status){
              onHide()
              toast.success(res.message)
            }
          })
          .catch((error: any) => {
            console.log("error");
          });
          break;

        case 'edit':
          await updateRepresentative(formData)
            .unwrap()
            .then((res) => {
              if(res.status){
                onHide()
                toast.success(res.message)
              }
            })
            .catch((error: any) => {
              console.log("error");
            });
          break;
          
        case 'delete':
          console.log(data)
          await deleteRepresentative({
            code: data?.code
          })
            .unwrap()
            .then((res) => {
              if(res.status){
                onHide()
                toast.success(res.message)
              }
            })
            .catch((error: any) => {
              console.log("error");
            });
          break;
      
        default:
          break;
      }
    } catch (err: any) {
      console.log(err);
      // if (err?.data?.errors) {
      //   Object.keys(err?.data?.errors)?.forEach((field) => {
      //     err.data.errors[field]?.forEach((message: string) => {
      //       toast.error(message, { autoClose: 10000 });
      //     });
      //   });
      // }
    }
  };

  useEffect(() => {
    console.log(errors)
  }, [errors])

  useEffect(() => {
    // reset();
    // setCurrent(data);
    console.log(data)
    setValue('type', type);
    if (data) {
      Object.keys(data).map((key) => {
        setValue(key, data[key]);
      });
    }
  }, []);

  return (
    <>
      <Modal
        backdrop="static"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {type != "delete" && (
          <Modal.Header className="bg-light" onHide={onHide} closeButton>
            <Modal.Title className="m-0">
              {`${type} ${data?.institution_name} Representative`.toUpperCase()}{" "}
            </Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body className="p-4">
          <form
            onSubmit={handleSubmit((values: any) => onSubmit({ ...values }))}
            noValidate
          >
            {(type == "add" || type == "edit") && (
              <>
                <FormInput
                  className="bg-light"
                  register={register}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  label="Institution Name"
                  type="text"
                  name="institution_name"
                  placeholder=""
                  containerClass={"mb-3"}
                  disabled={true}
                />
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  label="Name"
                  type="text"
                  name="name"
                  placeholder=""
                  containerClass={"mb-3"}
                />
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  label="Phone (Optional)"
                  type="text"
                  name="phone"
                  placeholder=""
                  containerClass={"mb-3"}
                />
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  label="Email (Optional)"
                  type="text"
                  name="email"
                  placeholder=""
                  containerClass={"mb-3"}
                />
                <div className="text-end">
                  <Button
                    variant="success"
                    type="submit"
                    className="waves-effect waves-light me-1"
                    disabled={
                      isLoadingCreate || isLoadingUpdate || isLoadingDelete
                    }
                  >
                    Submit
                  </Button>
                </div>
              </>
            )}
            {type == "delete" && (
              <div className="text-center">
                <div
                  onClick={onHide}
                  className="position-absolute top-0 end-0 m-2 cursor-pointer"
                >
                  <i className="mdi mdi-close font-22" />
                </div>
                <div
                  onClick={onHide}
                  className="position-absolute top-0 end-0 m-2 cursor-pointer"
                >
                  <i className="mdi mdi-close font-22" />
                </div>
                <IoWarningOutline size={64} className="text-danger" />
                <h4 className="mt-2">Please confirm your action</h4>
                <p className="mt-3 mb-0">
                  Are you sure to delete <strong>{data.name}</strong>?
                </p>
                <p>This action cannot be undone.</p>
                <Button
                  variant="danger"
                  type="submit"
                  className="waves-effect waves-light me-1"
                  disabled={
                    isLoadingCreate || isLoadingUpdate || isLoadingDelete
                  }
                >
                  Confirm
                </Button>
              </div>
            )}
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalRepresentative;
