import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Navigate, Link, useLocation, useNavigate } from "react-router-dom";

// components
import List from "./List";
// import ModalManage from "./ModalManage";

const MasterLeads = () => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState();
  // const [modalType, setModalType] = useState<string>('');
  // const [modalShow, setModalShow] = useState<boolean>(false);

  // const closeModal = () => setModalShow(false);
  // const openModal = (type: string, data?: any) => {
  //   setCurrent(data)
  //   setModalType(type)
  //   setModalShow(true)
  // };

  return (
    <>
      <Row className="mt-3">
        <Col xs md={'auto'}>
          <List onClickAction={(action: any , item: any) => { navigate(`/lms/md/master-leads/${item.code}`) }}/>
        </Col>
      </Row>

      {/* {modalShow && (
        <ModalManage
          show={modalShow}
          type={modalType}
          data={current}
          onHide={closeModal}
        />
      )} */}
    </>
  );
};

export default MasterLeads;
