import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// components
import { VerticalForm, FormSelect, FormInput, FormDate, SingleUpload } from "components/";

import {
  useCreateQuotesMutation,
  useUpdateQuotesMutation,
  useDeleteQuotesMutation,
} from "services/api";
import { toast } from "react-toastify";
import { IoWarningOutline } from "react-icons/io5";

interface ModalManageProps {
  show: boolean;
  type: string;
  data?: any;
  onHide: () => void;
}

const ModalManage = ({
  show,
  type,
  data,
  onHide,
}: ModalManageProps) => {
  const [avatar, setAvatar] = useState<File>();

  const [createQuotes, { isLoading: isLoadingCreate }] = useCreateQuotesMutation();
  const [updateQuotes, { isLoading: isLoadingUpdate }] = useUpdateQuotesMutation();
  const [deleteQuotes, { isLoading: isLoadingDelete }] = useDeleteQuotesMutation();

  const defaultValidation = yupResolver(
    yup.object().shape({
      content: yup.string().label('Content').required(''),
      creator: yup.string().label('Creator').nullable(),
    })
  );

  const deleteValidation = yupResolver(
    yup.object().shape({})
  );
  
  const methods = useForm({ resolver: type == 'delete' ? deleteValidation : defaultValidation });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
    reset: resetForm,
  } = methods;

  const onSubmit = async (values: any) => {
    try {
      switch (type) {
        case 'add':
          await createQuotes(values)
          .unwrap()
          .then((res) => {
            if(res.status){
              onHide()
              toast.success(res.message)
            }
          })
          .catch((error: any) => {
            console.log("error");
          });
          break;

        case 'edit':
          await updateQuotes(values)
            .unwrap()
            .then((res) => {
              if(res.status){
                onHide()
                toast.success(res.message)
              }
            })
            .catch((error: any) => {
              console.log("error");
            });
          break;
          
        case 'delete':
          await deleteQuotes(values)
            .unwrap()
            .then((res) => {
              if(res.status){
                onHide()
                toast.success(res.message)
              }
            })
            .catch((error: any) => {
              console.log("error");
            });
          break;
      
        default:
          break;
      }
    } catch (err: any) {
      console.log(err);
      // if (err?.data?.errors) {
      //   Object.keys(err?.data?.errors)?.forEach((field) => {
      //     err.data.errors[field]?.forEach((message: string) => {
      //       toast.error(message, { autoClose: 10000 });
      //     });
      //   });
      // }
    }
  };

  useEffect(() => {
    console.log(errors)
  }, [errors])

  useEffect(() => {
    // reset();
    // setCurrent(data);
    setValue('type', type);
    if (data) {
      Object.keys(data).map((key) => {
        setValue(key, data[key]);
      });
    }
  }, []);

  return (
    <>
      <Modal
        backdrop="static"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {
          type != 'delete' &&
          <Modal.Header className="bg-light" onHide={onHide} closeButton>
            <Modal.Title className="m-0">
              {`${type} Quotes`.toUpperCase()}{" "}
            </Modal.Title>
          </Modal.Header>
        }
        <Modal.Body className="p-4">
          <form
            onSubmit={handleSubmit((values: any) => onSubmit({ ...values }))}
            noValidate
          >
            {(type == "add" || type == "edit") && (
                <>
                  <FormInput
                    register={register}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    label="Content"
                    type="text"
                    name="content"
                    placeholder=""
                    containerClass={"mb-3"}
                  />
                  <FormInput
                    register={register}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    label="Creator"
                    type="text"
                    name="creator"
                    placeholder=""
                    containerClass={"mb-3"}
                  />
                  <div className="text-end">
                    <Button
                      variant="success"
                      type="submit"
                      className="waves-effect waves-light me-1"
                      disabled={isLoadingCreate || isLoadingUpdate || isLoadingDelete}
                    >
                      Submit
                    </Button>
                  </div>
                </>
              )
            }
            {type == "delete" && (
              <div className="text-center">
                <div onClick={onHide} className="position-absolute top-0 end-0 m-2 cursor-pointer">
                    <i className="mdi mdi-close font-22"/>
                  </div>
                  <IoWarningOutline size={64} className="text-danger"/>
                <h4 className="mt-2">Please confirm your action</h4>
                <p className="mt-3 mb-0">
                  Are you sure to delete <strong>{data.name}</strong>?
                </p>
                <p>This action cannot be undone.</p>
                <Button
                  variant="danger"
                  type="submit"
                  className="waves-effect waves-light me-1"
                  disabled={isLoadingCreate || isLoadingUpdate || isLoadingDelete}
                >
                  Confirm
                </Button>
              </div>
            )}
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalManage;
