import React from "react";
import { Row, Col, Card, Tab, Nav, Badge } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import moment from 'moment';

// components
import PageTitle from "../../../../components/PageTitle";
import Messages from "../../../../components/Messages";

import Biodata from "./Biodata";
import About from "./About";
import TimeLine from "./TimeLine";
import Settings from "./Settings";

import {
  useGetMasterLeadsQuery,
  useGetExpoRegistrantQuery,
} from "services/api";

const Profile = () => {
  let { code } = useParams();

  const { leads, isLoading: isLoadingLeads, isFetching: isFetchingLeads, isUninitialized: isUninitializedLeads } = useGetMasterLeadsQuery(
    {
      code: [code]
    },
    {
      selectFromResult: ({ data, isLoading, isFetching, isUninitialized }) => ({
        leads: data?.result
          ? data?.result.find((el: any) => el.code == code)
          : null,
        isLoading: isLoading,
        isFetching: isFetching,
        isUninitialized: isUninitialized,
      }),
      refetchOnMountOrArgChange: true,
      skip: code ? false : true,
    }
  );
  
  const {
    data: expoRegistrantList,
    isFetching: isFetchingExpoRegistrant,
    isLoading: isLoadingExpoRegistrant,
    isError: isErrorExpoRegistrant,
    isUninitialized: isUninitializedExpoRegistrant,
    error: errorKota,
    refetch: refetchKota,
  } = useGetExpoRegistrantQuery(
    {
      leads_code: code
    },
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  if(isLoadingLeads || isFetchingLeads || isUninitializedLeads){
    return <></>
  }

  return (
    <>
      <Row>
        <Col xs md={'auto'}>
          <div className="page-title-box">
            <div className="page-title-right">
            </div>
            <h4 className="page-title">Detail - {leads.full_name}</h4>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={4} lg={4}>
          <Biodata data={leads} />
          <Card>
            <Card.Body>
              <h5 className="mb-2 text-uppercase">
                <i className="mdi mdi-vector-point me-1"></i> Interested In
              </h5>
              <div className="">
                <div>
                  <h5 className="font-12 mb-0">Institution Name</h5>
                  <p className="mb-2">
                    {leads.institution_name || '-'}
                  </p>
                </div>
                      
                <div>
                  <h5 className="font-12 mb-0">Year of Study</h5>
                  <p className="mb-2">
                    {leads.planning_year || '-'}
                  </p>
                </div>
                      
                <div>
                  <h5 className="font-12 mb-0">Cours Level</h5>
                  <p className="mb-2">
                    {leads.course_level_name || '-'}
                  </p>
                </div>
                      
                <div>
                  <h5 className="font-12 mb-0">Course Full Name</h5>
                  <p className="mb-2">
                    {leads.course_full_name || '-'}
                  </p>
                </div>
                      
                <div>
                  <h5 className="font-12 mb-0">Study Area</h5>
                  <p className="mb-2">
                    {leads.course_major_name || '-'}
                  </p>
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <h5 className="mb-2 text-uppercase">
                <i className="mdi mdi-book-arrow-right-outline me-1"></i> Current Education
              </h5>
              <div className="">
                <div>
                  <h5 className="font-12 mb-0">School Name</h5>
                  <p className="mb-2">
                    {leads.school_name || '-'}
                  </p>
                </div>
                      
                <div>
                  <h5 className="font-12 mb-0">Grade</h5>
                  <p className="mb-2">
                    {leads.grade_name || '-'}
                  </p>
                </div>
              </div>

              <h5 className="mb-2 text-uppercase">
                <i className="mdi mdi-source-branch me-1"></i> Reference
              </h5>
              <div className="">
                <div>
                  <h5 className="font-12 mb-0">Marketing Source</h5>
                  <p className="mb-2">
                    {leads.marketing_source_name || '-'}
                  </p>
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <h5 className="mb-2 text-uppercase">
                <i className="mdi mdi-history me-1"></i> Date Logs
              </h5>      
              <div className="d-flex gap-3">
                <div>
                  <div>
                    <h5 className="font-12 mb-0">Registered Date :</h5>
                    <p className="mb-2">
                      {moment(leads.created_at).format('DD MMMM YYYY') || '-'}
                    </p>
                  </div>                  
                  <div>
                    <h5 className="font-12 mb-0">Assigned Date :</h5>
                    <p className="mb-2">
                      -
                    </p>
                  </div>                  
                  <div>
                    <h5 className="font-12 mb-0">First Progress Date :</h5>
                    <p className="mb-2">
                      -
                    </p>
                  </div>   
                </div>
                <div>
                  <div>
                    <h5 className="font-12 mb-0">Apply Date :</h5>
                    <p className="mb-2">
                      -
                    </p>
                  </div>                  
                  <div>
                    <h5 className="font-12 mb-0">Paid Date :</h5>
                    <p className="mb-2">
                      -
                    </p>
                  </div>                  
                  <div>
                    <h5 className="font-12 mb-0">Completed Date :</h5>
                    <p className="mb-2">
                      -
                    </p>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={8} lg={8}>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <h5 className="mb-2 text-uppercase">
                    <i className="mdi mdi-calendar-multiple-check me-1"></i> Joined Booth List
                  </h5>
                  <div className="table-responsive">
                    <table className="table table-borderless mb-0">
                      <thead className="table-light">
                        <tr>
                          <th className="align-baseline" style={{minWidth: '300px'}}>
                            <div className="d-flex flex-column">
                              <div className="fw-bold">
                                Event Name
                              </div>
                            </div>
                          </th>
                          <th className="align-baseline">
                            <div className="d-flex flex-column">
                              <div className="fw-bold">
                                Comments
                              </div>
                              <div className="font-12 fw-light">
                                Reps. Name | Assist. Reps. Name
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          (expoRegistrantList?.result || []).map((item: any) => {
                            return (
                              <tr>
                                <td>
                                  <div className="d-flex flex-column">
                                    {/* <div>
                                      <h5 className="m-0"><Badge bg="primary" className="">Study in UK Open Day with OIEG</Badge></h5>
                                    </div> */}
                                    <div className="fw-bold text-dark">
                                      {item.expo_name}
                                    </div>
                                    <div className="font-12 fst-italic">
                                      #{item.expo_code} | {item.expo?.date}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="mt-n2">
                                    {
                                      (item.expo_comment || []).map((comment: any) => {
                                        return (
                                          <div className="d-flex flex-column py-2 border-bottom">
                                            <div className="font-12 d-flex gap-1 mb-1">
                                              {/* <Badge bg="primary" className=""></Badge> */}
                                              <div className="font-15 fw-semibold">
                                                {comment.institution_name} #{comment.institution_code}
                                              </div>
                                            </div>
                                            <div className="font-12 d-flex gap-1 mb-1">
                                              <Badge bg="primary" className="">{comment.representative_name}</Badge>
                                              <Badge bg="soft-primary" className="text-dark">{comment.assistant_name}</Badge>
                                            </div>
                                            <div className="font-15 fst-italic">
                                              {comment.note}
                                            </div>
                                            <div className="font-12 d-flex gap-1 mb-1 mt-1">
                                              <Badge bg="transparent" className="badge-outline-secondary badge">CL: {comment.course_level_name}</Badge>
                                              <Badge bg="transparent" className="badge-outline-secondary badge">CM: {comment.course_major_name}</Badge>
                                              <Badge bg="transparent" className="badge-outline-secondary badge">CFM: {comment.course_full_name_name}</Badge>
                                            </div>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Profile;
