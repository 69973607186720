import React, { useLayoutEffect, useEffect, useState, useRef, forwardRef } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Line, defaults as ChartjsDefaults } from "react-chartjs-2";
import Skeleton from "react-loading-skeleton";
import useSize from '@react-hook/size'

import {
  useGetLeadsPerformanceQuery,
} from "services/api";
import { MdOutlineMonetizationOn } from "react-icons/md";
import moment from "moment";

const LeadsPerformance = () => {
  const ref = useRef(null);
  const refCard = useRef(null);
  
  const [width, height] = useSize(refCard)
  const [cWidth, setCWidth] = useState(0)

  const hSkeleton = [251,125,228,57,145,32,234,25,149,238,287,95,289,109,61,116,2,217,191,16,74,273,163,214,24,38,117,227,174,10,88,100,78,46,115,49,241,229,45,103,36,85,14,202,5,167,237,155,136,196,90,297,157,69,120,39,161,183,139,278,63,223,197,15,210,73,8,152,76,23,13,283,17,188,102,198,189,230,18,128,277,267,205,186,66,168,92,64,162,147,137,213,3,165,108,226,272,171,150,299];

  // const rNumber = random(100,300).oned(Math.floor(100 / 20), {round: true});
  
  const {
    data: leadsPerformanceList,
    isFetching: isFetchingLeadsPerformance,
    isLoading: isLoadingLeadsPerformance,
    isUninitialized: isUninitializedLeadsPerformance,
    isError: isErrorLeadsPerformance,
    error: errorLeadsPerformance,
    refetch: refetchExpoList,
  } = useGetLeadsPerformanceQuery(
    {},
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );
  ChartjsDefaults.color = "#8391a2";
  ChartjsDefaults.scale.grid.color = "#8391a2";

  // chart data
  const [LineChartData, setLineChartData] = useState({
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "Current Week",
        backgroundColor: "#1fa083",
        borderColor: "#1fa083",
        data: [32, 42, 42, 62, 52, 75, 62],
        tension: 0.4,
        fill: {
          target: "origin",
          above: "rgba(31, 160, 131, 0.3)",
        },
      },
      {
        label: "Previous Week",
        fill: true,
        backgroundColor: "transparent",
        borderColor: "#f1556c",
        borderDash: [5, 5],
        data: [42, 58, 66, 93, 82, 105, 92],
        tension: 0.4,
      },
    ],
  });

  // chart options
  const [LineChartOpts, setLineChartOpts] = useState({
    bezierCurve: false,
    maintainAspectRatio: false,
    tooltips: {
      intersect: false,
    },
    hover: {
      intersect: true,
    },
    plugins: {
      filler: {
        propagate: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          color: "rgba(0,0,0,0.05)",
        },
      },
      y: {
        ticks: {
          stepSize: 20,
        },
        display: true,
        borderDash: [5, 5],
        grid: {
          color: "rgba(0,0,0,0)",
          fontColor: "#fff",
        },
      },
    },
  });

  useEffect(() => {
    // ref?.current?.stop()
    if(leadsPerformanceList?.result){
      setLineChartData({
        ...LineChartData,
        labels: leadsPerformanceList?.result?.labels,
        datasets: leadsPerformanceList?.result?.datasets,
      })
    }
    // ref?.current?.render()
    // console.log('ref',ref?.current?.toBase64Image())
  }, [leadsPerformanceList])

  useEffect(() => {
    console.log('width', width)
  }, [width])
  

  // if(isLoadingLeadsPerformance){
  //   return <></>
  // }

  return (
    <>
      <Card className="h-100" ref={refCard}>
        <Card.Body>
          {/* <div className="float-end d-none d-md-inline-block">
            <div className="btn-group mb-2">
              <button type="button" className="btn btn-xs btn-secondary">
                Today
              </button>
              <button type="button" className="btn btn-xs btn-light">
                Weekly
              </button>
              <button type="button" className="btn btn-xs btn-light">
                Monthly
              </button>
            </div>
          </div> */}

          {/* <Row className="mt-4 text-center">
            <Col className="col-4">
              <ChartStatistics
                title="Target"
                icon="fe-arrow-down"
                stats="$7.8k"
                variant="danger"
              />
            </Col>
            <Col className="col-4">
              <ChartStatistics
                title="Last week"
                icon="fe-arrow-up"
                stats="$1.4k"
                variant="success"
              />
            </Col>
            <Col className="col-4">
              <ChartStatistics
                title="Last Month"
                icon="fe-arrow-down"
                stats="$15k"
                variant="danger"
              />
            </Col>
          </Row> */}
          {
            (isFetchingLeadsPerformance || isLoadingLeadsPerformance || isUninitializedLeadsPerformance) ? (
              <>
                <div className="row justify-content-center align-items-end overflow-hidden my-3">
                  {
                    [...Array(Math.floor(width / 35))].map((number, i) => {
                      return (
                        <div className="col-auto px-1">
                          <Skeleton height={`${hSkeleton[i]}px`} width="20px" />
                        </div>
                      )
                    })
                  }
                </div>
              </>
            ) : (
              <>
                <div className="header-title mb-3 d-flex flex-wrap justify-content-between align-items-center">
                  <div className="">
                    <span className="fw-bold font-17 text-dark">Leads Performance</span>
                  </div>
                  <div className="text-muted font-12">{moment().format('YYYY')}</div>
                </div>
                <div style={{ height: "300px", overflow: 'hidden' }} className="mt-3 chartjs-chart">
                  <Line ref={ref} redraw data={LineChartData} options={LineChartOpts} />
                </div>
              </>
            )
          }
        </Card.Body>
      </Card>
    </>
  );
};

export default LeadsPerformance;
