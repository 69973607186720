import classNames from "classnames";
import { Badge } from "react-bootstrap";

interface BadgeStatusProps {
  name: string;
  group: string;
  value?: any;
  className?: string;
  children?: any;
}

const BadgeStatus = ({
  name,
  group,
  value,
  className,
  children,
  ...otherProps
}: BadgeStatusProps) => {

  switch (true) {
    // Event
    case (name == 'Closed' && group == 'Event'):
      return <span className={classNames("me-1 badge badge-soft-dark", className)}>{children || (value == 0 ? "0" : value) || name}</span>;
      break;

    case (name == 'Open' && group == 'Event'):
      return <span className={classNames("me-1 badge badge-soft-success", className)}>{children || (value == 0 ? "0" : value) || name}</span>;
      break;

    case (name == 'Event Registrant' && group == 'Event'):
      return <span className={classNames("me-1 badge badge-gray rounded-pill", className)}>{children || (value == 0 ? "0" : value) || name}</span>;
      break;

    case (name == 'Event Attendance' && group == 'Event'):
      return <span className={classNames("me-1 badge badge-gray rounded-pill", className)}>{children || (value == 0 ? "0" : value) || name}</span>;
      break;

    case (name == 'Just Registered' && group == 'Event'):
      return <span className={classNames("me-1 badge badge-gray rounded-pill", className)}>{children || (value == 0 ? "0" : value) || name}</span>;
      break;

    case (name == 'Walk In' && group == 'Event'):
      return <span className={classNames("me-1 badge badge-gray rounded-pill", className)}>{children || (value == 0 ? "0" : value) || name}</span>;
      break;

    // Follow Up Leads
    case (name == 'Unassigned' && group == 'Leads'):
      return <Badge bg="transparent" className={classNames("me-1 badge badge-outline-unassigned", className)}>{children || (value == 0 ? "0" : value) || name}</Badge>;
      break;

    case (name == 'Unhandled' && group == 'Leads'):
      return <Badge bg="transparent" className={classNames("me-1 badge badge-outline-unhandled", className)}>{children || (value == 0 ? "0" : value) || name}</Badge>;
      break;

    case (name == 'In Progress' && group == 'Leads'):
      return <Badge bg="transparent" className={classNames("me-1 badge badge-outline-in-progress", className)}>{children || (value == 0 ? "0" : value) || name}</Badge>;
      break;

    case (name == 'Hot Prospect' && group == 'Leads'):
      return <Badge bg="transparent" className={classNames("me-1 badge badge-outline-hot-prospect", className)}>{children || (value == 0 ? "0" : value) || name}</Badge>;
      break;

    case (name == 'Future Prospect' && group == 'Leads'):
      return <Badge bg="transparent" className={classNames("me-1 badge badge-outline-future-prospect", className)}>{children || (value == 0 ? "0" : value) || name}</Badge>;
      break;

    case (name == 'Applied' && group == 'Leads'):
      return <Badge bg="transparent" className={classNames("me-1 badge badge-outline-applied", className)}>{children || (value == 0 ? "0" : value) || name}</Badge>;
      break;

    case (name == 'Succeed' && group == 'Leads'):
      return <Badge bg="transparent" className={classNames("me-1 badge badge-outline-succeed", className)}>{children || (value == 0 ? "0" : value) || name}</Badge>;
      break;

    case (name == 'Non-prospect' && group == 'Leads'):
      return <Badge bg="transparent" className={classNames("me-1 badge badge-outline-non-prospect", className)}>{children || (value == 0 ? "0" : value) || name}</Badge>;
      break;

    case (name == 'Aborted' && group == 'Leads'):
      return <Badge bg="transparent" className={classNames("me-1 badge badge-outline-aborted", className)}>{children || (value == 0 ? "0" : value) || name}</Badge>;
      break;

    case (name == 'Cancelled' && group == 'Leads'):
      return <Badge bg="transparent" className={classNames("me-1 badge badge-outline-cancelled", className)}>{children || (value == 0 ? "0" : value) || name}</Badge>;
      break;


    // Application
    case (name == 'Applying' && group == 'Application'):
      return <Badge bg="transparent" className={classNames("me-1 badge badge-applying", className)}>{children || (value == 0 ? "0" : value) || name}</Badge>;
      break;

    case (name == 'Paid' && group == 'Application'):
      return <Badge bg="transparent" className={classNames("me-1 badge badge-paid", className)}>{children || (value == 0 ? "0" : value) || name}</Badge>;
      break;

    case (name == 'Conditional Letter Offer' && group == 'Application'):
      return <Badge bg="transparent" className={classNames("me-1 badge badge-clo", className)}>{children || (value == 0 ? "0" : value) || name}</Badge>;
      break;

    case (name == 'Full Letter Offer' && group == 'Application'):
      return <Badge bg="transparent" className={classNames("me-1 badge badge-flo", className)}>{children || (value == 0 ? "0" : value) || name}</Badge>;
      break;

    case (name == 'Visa' && group == 'Application'):
      return <Badge bg="transparent" className={classNames("me-1 badge badge-visa", className)}>{children || (value == 0 ? "0" : value) || name}</Badge>;
      break;

    case (name == 'Completed' && group == 'Application'):
      return <Badge bg="transparent" className={classNames("me-1 badge badge-completed", className)}>{children || (value == 0 ? "0" : value) || name}</Badge>;
      break;

    case (name == 'Withdrawn' && group == 'Application'):
      return <Badge bg="transparent" className={classNames("me-1 badge badge-withdrawn", className)}>{children || (value == 0 ? "0" : value) || name}</Badge>;
      break;

    case (name == 'Cancelled' && group == 'Application'):
      return <Badge bg="transparent" className={classNames("me-1 badge badge-cancelled", className)}>{children || (value == 0 ? "0" : value) || name}</Badge>;
      break;

    case (name == 'Rejected' && group == 'Application'):
      return <Badge bg="transparent" className={classNames("me-1 badge badge-rejected", className)}>{children || (value == 0 ? "0" : value) || name}</Badge>;
      break;
  
    default:
      return <Badge bg="dark" className={classNames("me-1 badge bg-dark", className)}>{children || (value == 0 ? "0" : value) || name}</Badge>;
      break;
  }
};

export default BadgeStatus;
