import React, { useState } from "react";
import { Row, Col, Button, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from "react-toastify";

/**
 * Renders the preloader
 */
const CopyText = (props: any) => {
  const [show, setShow] = useState(false)

  return (
    <span onMouseEnter={() => { setShow(true) }} onMouseLeave={() => { setShow(false) }} style={{paddingRight: '20px'}}>
      <CopyToClipboard 
        text={props?.text || props?.children}
        onCopy={(e) => {
          console.log(e)
          toast.success('Copied', {autoClose: 100, hideProgressBar: true})
        }}>
          <span className="position-relative" onClick={(e: any) => {e.preventDefault(); e.stopPropagation();}} >
            <span onClick={(e: any) => {e.preventDefault(); e.stopPropagation();}}>{props.children}</span>
            {
              show &&
              <span className="position-absolute" style={{right:'-20px'}}><i className="mdi mdi-content-copy cursor-pointer"/></span>
            }
          </span>
      </CopyToClipboard>
    </span>
  );
};

export default CopyText;
