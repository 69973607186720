import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ScaleLoader from "components/ScaleLoader";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/store";

// components
import { VerticalForm, FormSelect, FormInput, FormDate, SingleUpload } from "components/";

import {
  useGetRoleQuery,
  useGetBranchQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} from "services/api";
import { toast } from "react-toastify";
import { IoWarningOutline } from "react-icons/io5";

interface ModalManageProps {
  show: boolean;
  type: string;
  data?: any;
  onHide: () => void;
}

const ModalManage = ({
  show,
  type,
  data,
  onHide,
}: ModalManageProps) => {
  const { roles } = useSelector(
    (state: RootState) => ({
      roles: state.Auth.user.roles,
    })
  );

  const [avatar, setAvatar] = useState<File>();
  const [roleName, setRoleName] = useState<string>('');

  const [createUser, { isLoading: isLoadingCreate }] = useCreateUserMutation();
  const [updateUser, { isLoading: isLoadingUpdate }] = useUpdateUserMutation();
  const [deleteUser, { isLoading: isLoadingDelete }] = useDeleteUserMutation();

  const {
    data: roleList,
    isFetching: isFetchingRole,
    isLoading: isLoadingRole,
    isUninitialized: isUninitializedRole,
    error: errorRole,
    isError: isErrorRole,
    refetch: refetchRole,
  } = useGetRoleQuery(
    {},
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  const {
    data: branchList,
    isFetching: isFetchingBranch,
    isLoading: isLoadingBranch,
    isUninitialized: isUninitializedBranch,
    error: errorBranch,
    isError: isErrorBranch,
    refetch: refetchBranch,
  } = useGetBranchQuery(
    {},
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  const validation = yupResolver(
    yup.object().shape({
      type: yup.string().required(''),
      avatar: yup.mixed().nullable(),
      name: yup.string().required(''),
      email: yup.string().required('').email("Please enter valid email"),
      phone: yup.string().min(12).max(20).required(''),
      password: yup.mixed().default('').when('type', {
        is: 'add',
        then: yup.string().required(''),
      }),
      password_confirmation: yup.mixed().default('').when('password', {
        is: (value: string) => value ? value != null : false,
        then: yup.string().label('Password confirmation').required('').oneOf([yup.ref('password'), null], 'Passwords must match'),
      }),
      role_name: yup.string().required(''),
      branch_code: yup.mixed().nullable().when('role_name', {
        is: (value: string) => ['Branch Manager' || 'Counselor' || 'Admission'].includes(value),
        then: yup.string().required(''),
      }),
      target: yup.mixed().default(0).when('role_name', {
        is: (value: string) => ['Branch Manager' || 'Counselor' || 'Admission'].includes(value),
        then: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required(''),
      }),
    })
  );
  
  const methods = useForm({ resolver: validation });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
    reset: resetForm,
  } = methods;

  const onSubmit = async (values: any) => {
    try {
      let formData = new FormData();
      Object.keys(values).forEach((key: any) => {
        if(values[key]){
          formData.append(key, values[key]);
        }
      });
      if(avatar) {
        formData.append("avatar", avatar)
      } else {
        formData.delete("avatar")
      }

      switch (type) {
        case 'add':
          await createUser(formData)
          .unwrap()
          .then((res) => {
            if(res.status){
              onHide()
              toast.success(res.message)
            }
          })
          .catch((error: any) => {
            console.log("error");
          });
          break;

        case 'edit':
          await updateUser(formData)
            .unwrap()
            .then((res) => {
              if(res.status){
                onHide()
                toast.success(res.message)
              }
            })
            .catch((error: any) => {
              console.log("error");
            });
          break;
          
        case 'delete':
          await deleteUser(formData)
            .unwrap()
            .then((res) => {
              if(res.status){
                onHide()
                toast.success(res.message)
              }
            })
            .catch((error: any) => {
              console.log("error");
            });
          break;
      
        default:
          break;
      }
    } catch (err: any) {
      console.log(err);
      // if (err?.data?.errors) {
      //   Object.keys(err?.data?.errors)?.forEach((field) => {
      //     err.data.errors[field]?.forEach((message: string) => {
      //       toast.error(message, { autoClose: 10000 });
      //     });
      //   });
      // }
    }
  };

  useEffect(() => {
    console.log(errors)
  }, [errors])

  useEffect(() => {
    // reset();
    // setCurrent(data);
    setValue('type', type);
    if (data) {
      setValue('id', data?.id);
      setValue('avatar', data?.avatar);
      setValue('name', data?.name);
      setValue('email', data?.email);
      setValue('phone', data?.phone);
      setValue('role_name', data?.role_name);
      setValue('branch_code', data?.branch_code);
      setValue('institution_code', data?.institution_code);
      if(data.role_name == 'Branch Manager'){
        setValue('target', data?.branch_target);
      } else {
        setValue('target', data?.target);
      }
      console.log(roles)
    }
    setRoleName(data?.role_name);
  }, []);

  return (
    <>
      <Modal
        backdrop="static"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="bg-light" onHide={onHide} closeButton>
          <Modal.Title className="m-0">
            {`${type} User`.toUpperCase()}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          {
            (
              isFetchingRole ||
              isLoadingRole || 
              isUninitializedRole || 
              isErrorRole || 
              isFetchingBranch || 
              isLoadingBranch || 
              isUninitializedBranch || 
              isErrorBranch
            ) ? (
                <>
                  <ScaleLoader />
                </>
              ) : (
                <form
                  onSubmit={handleSubmit((values: any) => onSubmit({ ...values }))}
                  noValidate
                >
                {(type == "add" || type == "edit") && (
                    <>
                      <div className="mb-3">
                        <label className="form-label">Avatar</label>
                        <SingleUpload
                          name="avatar"
                          errors={errors}
                          // style={{height: '150px', width: '150px'}}
                          onFileUpload={(f) => {
                            setValue("avatar", f);
                            setAvatar(f);
                          }}
                          onRemove={(f: any) => {
                            setValue("avatar", f);
                            setAvatar(f);
                            setValue("avatar_base64", null);
                          }}
                          // disabled={isLoadingCreate || isLoadingUpdate || isLoadingDelete}
                          imagePreview={data?.avatar}
                          imageName={data?.image_name}
                        />
                      </div>
                      <FormInput
                        register={register}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        label="Name"
                        type="text"
                        name="name"
                        placeholder=""
                        containerClass={"mb-3"}
                        disabled={isLoadingCreate || isLoadingUpdate || isLoadingDelete}
                      />
                      <FormInput
                        register={register}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        label="Email"
                        type="text"
                        name="email"
                        placeholder=""
                        containerClass={"mb-3"}
                        disabled={isLoadingCreate || isLoadingUpdate || isLoadingDelete}
                      />
                      <FormInput
                        register={register}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        label="Phone (e.g. +62813XXXXXXXXXX)"
                        type="text"
                        name="phone"
                        placeholder=""
                        containerClass={"mb-3"}
                      />
                      <div className="d-flex justify-content-between gap-2">
                        <FormInput
                          register={register}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          label={type == "add" ? "Password" : "New password"}
                          type="password"
                          name="password"
                          placeholder=""
                          containerClass={"mb-3"}
                          autoComplete="new-password"
                        />
                        <FormInput
                          register={register}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          label="Password confirmation"
                          type="password"
                          name="password_confirmation"
                          placeholder=""
                          containerClass={"mb-3"}
                        />
                      </div>
                      <FormSelect
                        label="Role"
                        name="role_name"
                        placeholder=""
                        containerClass={"mb-3"}
                        defaultValue={data?.role_name || undefined}
                        options={(roleList?.result || []).map((item: any) => {
                          return {
                            label: item.name,
                            value: item.name,
                          };
                        }).filter((item: any) => !['Super Admin','Representative'].includes(item.label))}
                        // disabled={isLoadingCreate || isLoadingUpdate || isLoadingDelete}
                        register={register}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        onChange={(opt: any) => {
                          setRoleName(opt.label)
                          setValue('role_name', opt.value)
                          if(!['Branch Manager','Counselor','Admission'].includes(opt.label)){
                            setValue('branch_code', null)
                            clearErrors('branch_code')
                            setValue('target', 0)
                            clearErrors('target')
                          }
                        }}
                      />
                      {
                        (['Branch Manager','Counselor'].includes(roleName)) &&
                        <>
                          <FormSelect
                            label="Branch Area"
                            name="branch_code"
                            placeholder=""
                            containerClass={"mb-3"}
                            defaultValue={data?.branch_code || undefined}
                            options={(branchList?.result || []).map((item: any) => {
                              return {
                                label: item.name,
                                value: item.code,
                              };
                            })}
                            // disabled={isLoadingCreate || isLoadingUpdate || isLoadingDelete}
                            register={register}
                            control={control}
                            errors={errors}
                            setValue={setValue}
                            clearErrors={clearErrors}
                            onChange={(opt: any) => {
                              setValue('branch_code', opt.value)
                              setValue('branch_name', opt.label)
                            }}
                          />
                          <FormInput
                            register={register}
                            control={control}
                            errors={errors}
                            setValue={setValue}
                            label="Annual Target"
                            type="number"
                            name="target"
                            placeholder=""
                            containerClass={"mb-3"}
                            defaultValue={data?.target || undefined}
                            disabled={data?.role_name == 'Branch Manager'}
                            className={`${data?.role_name == 'Branch Manager' && 'bg-light'}`}
                          />
                        </>
                      }
                      {/* {(getValues()?.role_name == "Counselor" ||
                        getValues()?.role_name == "Branch Manager") && (
                        <FormSelect
                          label="Branch"
                          name="branch_code"
                          placeholder=""
                          containerClass={"mb-3"}
                          // defaultValue={current?.fi_calculate_marketing || undefined}
                          options={(branchList?.result || []).map((item: any) => {
                            return {
                              label: item.name,
                              value: item.name,
                            };
                          })}
                          // disabled={isLoadingCreate || isLoadingUpdate || isLoadingDelete}
                          register={register}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          clearErrors={clearErrors}
                          // onChange={(opt: any) => {
                          //   setCalcMarketingBranding(opt.value);
                          // }}
                          isClearable
                        />
                      )} */}
                      <div className="text-end">
                        <Button
                          variant="success"
                          type="submit"
                          className="waves-effect waves-light me-1"
                          disabled={isLoadingCreate || isLoadingUpdate || isLoadingDelete}
                        >
                          Submit
                        </Button>
                      </div>
                    </>
                  )
                }
                {type == "delete" && (
                  <div className="text-center">
                    <div onClick={onHide} className="position-absolute top-0 end-0 m-2 cursor-pointer">
                    <i className="mdi mdi-close font-22"/>
                  </div>
                  <IoWarningOutline size={64} className="text-danger"/>
                    <h4 className="mt-2">Please confirm your action</h4>
                    <p className="mt-3 mb-0">
                      Are you sure to delete <strong>{data.name} ({data.email})</strong>?
                    </p>
                    <p>This action cannot be undone.</p>
                    <Button
                      variant="danger"
                      type="submit"
                      className="waves-effect waves-light me-1"
                      disabled={isLoadingCreate || isLoadingUpdate || isLoadingDelete}
                    >
                      Confirm
                    </Button>
                  </div>
                )}
                </form>
              )
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalManage;
