import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";

// components
import BTable from "components/BTable";

import {
  useGetStatusQuery,
} from "services/api";

const CellName = ({ data }:{ data: any }) => {
  return <>{data.name}</>
}

const columns = [
  {
    label: "Name",
    name: "name",
    width: "300px",
    CustomCell: (data: any) => <CellName data={data} />,
  },
  {
    label: "Group",
    name: "group",
    width: "200px",
    inputFilter: "select",
    filterOptions: {
      table: 'statuses',
      column: 'group',
    }
  },
  {
    label: "Count",
    name: "count",
    hideFilter: true,
  },
];

const List = ({ 
  onClickAction,
}: { 
  onClickAction?: any;
 }) => {
  const [search, setSearch] = useState({});
  const [sort, setSort] = useState<object>({});
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  
  const [current, setCurrent] = useState();
  const [modalType, setModalType] = useState<string>('');
  const [modalShow, setModalShow] = useState<boolean>(false);

  const closeModal = () => setModalShow(false);
  const openModal = (type: string, data?: any) => {
    setCurrent(data)
    setModalType(type)
    setModalShow(true)
  };

  const {
    data: lists,
    isFetching: isFetchingStatus,
    isLoading: isLoadingStatus,
    isUninitialized: isUninitializedStatus,
    isError: isErrorStatus,
    error: errorStatus,
    refetch: refetchStatus,
  } = useGetStatusQuery(
    {
      search,
      sort,
      page: activePage,
      per_page: perPage,
    },
    {
      // pollingInterval: 100,
      refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  return (
    <>
      <BTable 
        tableClassname={'table-centered'}
        isLoading={ isFetchingStatus || isLoadingStatus || isUninitializedStatus || isErrorStatus }
        data={lists?.result}
        meta={lists?.meta}
        columns={columns}
        search={search}
        setSearch={setSearch}
        sort={sort}
        setSort={setSort}
        activePage={activePage}
        setActivePage={setActivePage}
        perPage={perPage}
        setPerPage={setPerPage}
        onClickAction={onClickAction}
        hideAction={true}
      />
    </>
  );
};

export default List;
