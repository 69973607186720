import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ScaleLoader from "components/ScaleLoader";

// components
import { VerticalForm, FormSelect, FormInput, FormDate, SingleUpload } from "components/";

import {
  useGetBranchQuery,
  useGetProvinsiQuery,
  useCreateKecamatanMutation,
  useUpdateKecamatanMutation,
  useDeleteKecamatanMutation,
} from "services/api";

import { toast } from "react-toastify";
import { IoWarningOutline } from "react-icons/io5";

interface ModalManageProps {
  show: boolean;
  type: string;
  data?: any;
  onHide: () => void;
}

const ModalManage = ({
  show,
  type,
  data,
  onHide,
}: ModalManageProps) => {
  const [createKecamatan, { isLoading: isLoadingCreate }] = useCreateKecamatanMutation();
  const [updateKecamatan, { isLoading: isLoadingUpdate }] = useUpdateKecamatanMutation();
  const [deleteKecamatan, { isLoading: isLoadingDelete }] = useDeleteKecamatanMutation();

  const {
    data: branchList,
    isFetching: isFetchingBranch,
    isLoading: isLoadingBranch,
    isUninitialized: isUninitializedBranch,
    isError: isErrorBranch,
    error: errorBranch,
    refetch: refetchBranch,
  } = useGetBranchQuery(
    {},
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  const {
    data: provinceList,
    isFetching: isFetchingProvinsi,
    isLoading: isLoadingProvinsi,
    isUninitialized: isUninitializedProvinsi,
    isError: isErrorProvinsi,
    error: errorProvinsi,
    refetch: refetchProvinsi,
  } = useGetProvinsiQuery(
    {},
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  const validation = yupResolver(
    yup.object().shape({
      name: yup.string().required(''),
      provinsi_code: yup.string().required(''),
      branch_code: yup.string().nullable(),
    })
  );
  
  const methods = useForm({ resolver: validation });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
    reset: resetForm,
  } = methods;

  const onSubmit = async (values: any) => {
    try {
      let formData = new FormData();
      Object.keys(values).forEach((key: any) => {
        if(values[key]){
          formData.append(key, values[key]);
        }
      });

      switch (type) {
        case 'add':
          await createKecamatan(formData)
          .unwrap()
          .then((res) => {
            if(res.status){
              onHide()
              toast.success(res.message)
            }
          })
          .catch((error: any) => {
            console.log("error");
          });
          break;

        case 'edit':
          await updateKecamatan(formData)
            .unwrap()
            .then((res) => {
              if(res.status){
                onHide()
                toast.success(res.message)
              }
            })
            .catch((error: any) => {
              console.log("error");
            });
          break;
          
        case 'delete':
          await deleteKecamatan(formData)
            .unwrap()
            .then((res) => {
              if(res.status){
                onHide()
                toast.success(res.message)
              }
            })
            .catch((error: any) => {
              console.log("error");
            });
          break;
      
        default:
          break;
      }
    } catch (err: any) {
      console.log(err);
      // if (err?.data?.errors) {
      //   Object.keys(err?.data?.errors)?.forEach((field) => {
      //     err.data.errors[field]?.forEach((message: string) => {
      //       toast.error(message, { autoClose: 10000 });
      //     });
      //   });
      // }
    }
  };

  useEffect(() => {
    console.log(errors)
  }, [errors])

  useEffect(() => {
    setValue('type', type);
    if (data) {
      Object.keys(data).map((key) => {
        setValue(key, data[key]);
      });
    }
  }, []);

  return (
    <>
      <Modal
        backdrop="static"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {
          type != 'delete' &&
          <Modal.Header className="bg-light" onHide={onHide} closeButton>
            <Modal.Title className="m-0">
              {`${type} Coverage Area`.toUpperCase()}{" "}
            </Modal.Title>
          </Modal.Header>
        }
        <Modal.Body className="p-4">
          <form
            onSubmit={handleSubmit((values: any) => onSubmit({ ...values }))}
            noValidate
          >
            {(type == "add" || type == "edit") && (
                <>
                  {
                    (isFetchingProvinsi || isLoadingProvinsi || isUninitializedProvinsi || isErrorProvinsi) ? (
                      <>
                        <ScaleLoader />
                      </>
                    ) : (
                      <>
                        <FormSelect
                          label="Branch"
                          name="branch_code"
                          placeholder=""
                          containerClass={"mb-3"}
                          defaultValue={data?.branch_code || undefined}
                          options={(branchList?.result || []).map((item: any) => {
                            return {
                              label: item.name,
                              value: item.code,
                            };
                          })}
                          // disabled={isLoading}
                          register={register}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          clearErrors={clearErrors}
                          onChange={(opt: any) => {
                            setValue('branch_code', opt.value)
                          }}
                          isClearable
                        />
                        <FormInput
                          className="bg-light"
                          register={register}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          label="Subdistrict (Indonesia)"
                          type="text"
                          name="name"
                          placeholder=""
                          containerClass={"mb-3"}
                          disabled
                        />
                        <FormInput
                          className="bg-light"
                          register={register}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          label="City (Indonesia)"
                          type="text"
                          name="kota_name"
                          placeholder=""
                          containerClass={"mb-3"}
                          disabled
                        />
                        <FormSelect
                          className="bg-light"
                          label="Province (Indonesia)"
                          name="provinsi_code"
                          placeholder=""
                          containerClass={"mb-3"}
                          defaultValue={data?.provinsi_code || undefined}
                          options={(provinceList?.result || []).map((item: any) => {
                            return {
                              label: item.name,
                              value: item.code,
                            };
                          })}
                          // disabled={isLoading}
                          register={register}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          clearErrors={clearErrors}
                          onChange={(opt: any) => {
                            setValue('provinsi_code', opt.value)
                          }}
                          isDisabled
                        />
                      </>
                    )
                  }
                  <div className="text-end">
                    <Button
                      variant="success"
                      type="submit"
                      className="waves-effect waves-light me-1"
                      disabled={isLoadingCreate || isLoadingUpdate || isLoadingDelete}
                    >
                      Submit
                    </Button>
                  </div>
                </>
              )
            }
            {type == "delete" && (
              <div className="text-center">
                <div onClick={onHide} className="position-absolute top-0 end-0 m-2 cursor-pointer">
                    <i className="mdi mdi-close font-22"/>
                  </div>
                  <IoWarningOutline size={64} className="text-danger"/>
                <h4 className="mt-2">Please confirm your action</h4>
                <p className="mt-3 mb-0">
                  Are you sure to delete <strong>{data.name}</strong>?
                </p>
                <p>This action cannot be undone.</p>
                <Button
                  variant="danger"
                  type="submit"
                  className="waves-effect waves-light me-1"
                  disabled={isLoadingCreate || isLoadingUpdate || isLoadingDelete}
                >
                  Confirm
                </Button>
              </div>
            )}
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalManage;
