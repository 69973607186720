import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";

// components
import BTable from "components/BTable";

import {
  useGetMasterLeadsQuery,
} from "services/api";

const CellName = ({ data }:{ data: any }) => {
  return (
    <div className="d-flex justify-content-start align-items-start gap-1">
      {
        data.avatar &&
        <div className="me-1 mt-1" style={{height: '30px', width: '30px'}}>
          <img height={30} width={30} src={data.avatar} alt="" className="h-auto border rounded-circle" />
        </div>
      }
      <span className="text-body fw-semibold">
        {data.full_name}
      </span>
    </div>
  )
}

const CellExpoNames = ({ data }:{ data: any }) => {
  return (
    <div className="d-flex justify-content-start align-items-start flex-column gap-1">
      {
        (data.expo_names || []).map((name: string) => (
          <div className="d-flex">
            <Badge bg="secondary" className="fw-normal">
              {name}
            </Badge>
          </div>
        ))
      }
    </div>
  )
}

const columns = [
  {
    label: "Name",
    name: "full_name",
    width: "300px",
    CustomCell: (data: any) => <CellName data={data} />,
  },
  {
    label: "Leads ID",
    name: "code",
  },
  {
    label: "City",
    name: "kota_name",
    inputFilter: "select",
    filterOptions: {
      table: 'leads',
      column: 'kota_name',
    }
  },
  {
    label: "Province",
    name: "provinsi_name",
    inputFilter: "select",
    filterOptions: {
      table: 'leads',
      column: 'provinsi_name',
    }
  },
  {
    label: "Event Name",
    name: "expo_names",
    CustomCell: (data: any) => <CellExpoNames data={data} />,
  },
  {
    label: "Course Level",
    name: "course_level_name",
    inputFilter: "select",
    filterOptions: {
      table: 'leads',
      column: 'course_level_name',
    }
  },
  {
    label: "Course Full Name",
    name: "course_full_name_name",
  },
];

const List = ({ 
  onClickAction,
}: { 
  onClickAction?: any;
 }) => {
  const [search, setSearch] = useState({});
  const [sort, setSort] = useState<object>({});
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  
  const [current, setCurrent] = useState();
  const [modalType, setModalType] = useState<string>('');
  const [modalShow, setModalShow] = useState<boolean>(false);

  const closeModal = () => setModalShow(false);
  const openModal = (type: string, data?: any) => {
    setCurrent(data)
    setModalType(type)
    setModalShow(true)
  };

  const {
    data: lists,
    isFetching: isFetchingMasterLeads,
    isLoading: isLoadingMasterLeads,
    isUninitialized: isUninitializedMasterLeads,
    isError: isErrorMasterLeads,
    error: errorMasterLeads,
    refetch: refetchMasterLeads,
  } = useGetMasterLeadsQuery(
    {
      search,
      sort,
      page: activePage,
      per_page: perPage,
    },
    {
      // pollingInterval: 100,
      refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  return (
    <>
      <BTable 
        // tableClassname={'table-centered'}
        isLoading={ isFetchingMasterLeads || isLoadingMasterLeads || isUninitializedMasterLeads || isErrorMasterLeads }
        data={lists?.result}
        meta={lists?.meta}
        columns={columns}
        search={search}
        setSearch={setSearch}
        sort={sort}
        setSort={setSort}
        activePage={activePage}
        setActivePage={setActivePage}
        perPage={perPage}
        setPerPage={setPerPage}
        onClickAction={onClickAction}
      />
    </>
  );
};

export default List;
