import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Tab,
  Nav,
  ListGroup,
  Badge,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CopyText from "components/CopyText";
import ScaleLoader from "components/ScaleLoader";
import SimpleBar from "simplebar-react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { LuPackageOpen } from "react-icons/lu";
import { HasAccess } from "@permify/react-role";
import { RootState } from "redux/store";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// components
import { BadgeStatus } from "components"
import ModalManage from 'pages/LMS/Leads/ModalManage'
import ModalStatus from './Detail/ModalStatus'
import CommentInput from './CommentInput'

import {
  useGetLeadsQuery,
  useGetExpoRegistrantQuery,
  useGetCommentQuery,
  useCreateCommentMutation,
  useUpdateStatusMutation,
} from "services/api";

const CustomEdit = (props: any) => {
  const [isEdit, setIsEdit] = useState(false);
  const onClickEdit = () => {
    setIsEdit(!isEdit)
  }

  return (
    <div>
      <h5 className="font-12 mb-0">
        {props.label}
        <span onClick={(e) => onClickEdit()}>
          {
            isEdit ? (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip style={{position:"fixed"}}>Edit</Tooltip>}
              >
                <i className="ms-1 mdi mdi-square-edit-outline cursor-pointer"></i>
              </OverlayTrigger>
            ) : null
          }
        </span>
      </h5>
      <p className="mb-2">
        {
          isEdit ? (
            <>here</>
          ) : (
            <>{props.children}</>
          )
        }
      </p>
    </div>
  )
}

const Detail = ({
  current,
  setCurrent,
  layout,
  setLayout,
  refetchLeads,
}: {
  current?: LeadsProps;
  setCurrent: any;
  layout: any;
  setLayout: any;
  refetchLeads?: any;
}) => {
  let { leadsCode } = useParams();
  const navigate = useNavigate();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [contentState, setContentState] = useState(null);
  const handleContentStateChange = (contentState: any) => {
    console.log(draftToHtml(contentState));
    // setContentState(draftToHtml(contentState));
  };

  const [commentValue, setCommentValue] = useState('');

  const [modalTypeManage, setModalTypeManage] = useState('');
  const [modalShowManage, setModalShowManage] = useState<boolean>(false);
  const closeModalManage = () => setModalShowManage(false);
  const openModalManage = (type: string, data?: any) => {
    setModalTypeManage(type)
    setModalShowManage(true)
  };

  const [modalShowStatus, setModalShowStatus] = useState<boolean>(false);
  const closeModalStatus = () => setModalShowStatus(false);
  const openModalStatus = (data?: any) => {
    setModalShowStatus(true)
  };

  const [createComment, { isLoading: isLoadingCreateComment }] = useCreateCommentMutation();
  const [updateStatus, { isLoading: isLoadingUpdateStatus }] = useUpdateStatusMutation();

  const {
    leads,
    isUninitialized: isUninitializedLeads,
    isLoading: isLoadingLeads,
    isFetching: isFetchingLeads,
    isError: isErrorLeads,
  } = useGetLeadsQuery(
    {
      leads_code: leadsCode,
    },
    {
      selectFromResult: ({
        data,
        isUninitialized,
        isLoading,
        isFetching,
        isError,
      }) => ({
        leads: data?.result
          ? data.result.find((el: any) => el.code == leadsCode)
          : null,
        isUninitialized: isUninitialized,
        isLoading: isLoading,
        isFetching: isFetching,
        isError: isError,
      }),
      refetchOnMountOrArgChange: true,
      skip: leadsCode ? false : true,
    }
  );

  const {
    data: expoRegistrantList,
    isFetching: isFetchingExpoRegistrant,
    isLoading: isLoadingExpoRegistrant,
    isUninitialized: isUninitializedExpoRegistrant,
    isError: isErrorExpoRegistrant,
    error: errorExpoRegistrant,
    refetch: refetchExpoRegistrant,
  } = useGetExpoRegistrantQuery(
    {
      leads_code: leadsCode,
    },
    {
      // pollingInterval: 100,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      // refetchOnReconnect: true,
      skip: !leadsCode,
    }
  );

  const {
    data: commentList,
    isFetching: isFetchingComment,
    isLoading: isLoadingComment,
    isUninitialized: isUninitializedComment,
    error: errorComment,
    isError: isErrorComment,
    refetch: refetchComment,
  } = useGetCommentQuery(
    {
      leads_code: leadsCode,
    },
    {
      // pollingInterval: 100,
      refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      skip: !leadsCode,
    }
  );

  const onCloseDetail = () => {
    setLayout("default");
    setCurrent({});
    // navigate(`/lms/leads`)
    navigate(-1)
  };

  const onSubmitComment = async (comment: any, mention: any, notify: boolean) => {
    await createComment({
      leads_code: leadsCode,
      note: comment,
      mention: mention,
      notify: notify,
    })
    .unwrap()
    .then((res: any) => {
      if(res.status){
        setCommentValue('')
        toast.success("Success");
      }
    })
    .catch((error: any) => {
      console.log("error");
    });
  };

  const onChangeStatus = async (leads: LeadsProps) => {
    await updateStatus({
      leads_code: leadsCode,
      note: commentValue,
    })
    .unwrap()
    .then((res: any) => {
      if(res.status){
        setCommentValue('')
      }
    })
    .catch((error: any) => {
      console.log("error");
    });
  }

  const refetch = () => {
    refetchExpoRegistrant();
    refetchComment();
  }

  useEffect(() => {
    setCurrent(leads);
  }, [leads]);

  // useEffect(() => {
  //   console.log(editorState);
  // }, [editorState]);

  if (!leads) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "200px" }}
      >
        <ScaleLoader />
      </div>
    );
  }

  return (
    <>
      <Card className="mt-3 mt-sm-auto">
        <Card.Body>
          <div
            onClick={() => {
              onCloseDetail();
            }}
            className="position-absolute cursor-pointer"
            style={{ top: "22px", right: "20px" }}
          >
            <i className="mdi mdi-window-close font-22" />
          </div>
          <div className="d-flex flex-row align-items-start gap-2">
            <div className="flex-shrink-0 d-none d-sm-block">
              <img
                className="d-flex rounded avatar-md"
                src={leads?.avatar}
                alt=""
              />
            </div>
            <div className="w-100 flex-grow-0">
              <div className="d-flex justify-content-start align-items-start align-items-sm-start mt-0 mb-1 gap-1 me-4">
                <h4 className="my-0 align-self-center">
                  {leads?.full_name}
                </h4>
                <HasAccess
                    permissions={["can-delete-leads"]} 
                >
                  <i className="ms-1 mdi mdi-trash-can-outline cursor-pointer font-20 text-danger" onClick={() => {openModalManage('delete')}}></i>
                </HasAccess>
                <i className="ms-1 mdi mdi-square-edit-outline cursor-pointer font-20" onClick={() => {openModalManage('edit')}}></i>
                {/* <BadgeStatus name={leads?.status_name} group="Leads" className="font-12 d-none d-xl-block">
                  {
                    !['Unassigned','Applied','Succeed','Cancelled'].includes(leads?.status_name) ? (
                      <>
                        {leads?.status_name}
                        <i onClick={() => {openModalStatus(leads)}} className="ms-1 mdi mdi-square-edit-outline cursor-pointer"></i>
                      </>
                    ) : (
                      <>
                        {leads?.status_name}
                      </>
                    )
                  } 
                </BadgeStatus> */}
              </div>
              <div className="row gx-1">
                <div className="fw-normal col-auto">
                  <OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip style={{position:"fixed"}}>
                        Copy
                      </Tooltip>
                    }
                  >
                    <CopyToClipboard text={leads?.code}
                      onCopy={() => {
                        toast.success('Copied', {autoClose: 100, hideProgressBar: true})
                      }}>
                        <i className="mdi mdi-content-copy cursor-pointer me-1"/>
                    </CopyToClipboard>
                  </OverlayTrigger>
                  <span className="pe-1 pe-sm-0">#{leads?.code}</span>
                </div>
                {
                  leads?.email &&
                  <div className="fw-normal col-auto">
                    <Row className="gx-0">
                      <Col xs="auto" className="d-none d-sm-block">
                        <div className="pe-1">|</div>
                      </Col>
                      <Col xs="auto" className="d-sm-none">
                        <i className="mdi mdi-email-outline pe-1"></i>
                      </Col>
                      <Col xs="auto" className="">
                        <a className="text-body text-decoration-underline" target="_blank" href={`mailto:${leads?.email}`}>
                          {
                            leads?.email_is_valid == null ? (
                              <span>{leads?.email}</span>
                            ) : (
                              <>
                                {
                                  leads?.email_is_valid === true &&
                                  <span className="text-success"><i className="mdi mdi-email-check-outline me-1"></i>{leads?.email}</span>
                                }
                                {
                                  leads?.email_is_valid === false &&
                                  <span className="text-danger"><i className="mdi mdi-email-alert-outline me-1"></i>{leads?.email}</span>
                                }
                              </>
                            )
                          }
                        </a>
                      </Col>
                    </Row>
                  </div>
                }
                {
                  leads?.phone &&
                  <div className="fw-normal col-auto">
                    <Row className="gx-0">
                      <Col className="d-none d-sm-block">
                        <div className="pe-1">|</div>
                      </Col>
                      <Col className="d-sm-none">
                        <i className="mdi mdi-whatsapp pe-1"></i>
                      </Col>
                      <Col className="">
                        <a className="text-body text-decoration-underline" target="_blank" href={`https://wa.me/${leads?.phone}`}>{leads?.phone}</a>
                      </Col>
                    </Row>
                  </div>
                }

                {/* <div>
                  <span className="fw-normal">
                    <OverlayTrigger
                      placement={'top'}
                      overlay={
                        <Tooltip style={{position:"fixed"}}>
                          Copy
                        </Tooltip>
                      }
                    >
                      <CopyToClipboard text={leads?.code}
                        onCopy={() => {
                          toast.success('Copied', {autoClose: 100, hideProgressBar: true})
                        }}>
                          <i className="mdi mdi-content-copy cursor-pointer me-1"/>
                      </CopyToClipboard>
                    </OverlayTrigger>
                    #{leads?.code}{" "}
                  </span>
                </div>
                <div className="d-flex flex-wrap gap-1">
                  <span>
                    <i className="mdi mdi-email-outline me-1"></i> 
                    <span><a className="text-body text-decoration-underline" target="_blank" href={`mailto:${leads?.email}`}>{leads?.email}</a></span>
                  </span>
                </div>
                <div className="d-flex flex-wrap gap-1">
                  <span>
                    <i className="mdi mdi-whatsapp me-1"></i>
                    <span><a className="text-body text-decoration-underline" target="_blank" href={`https://wa.me/${leads?.phone}`}>{leads?.phone}</a></span>
                  </span>
                </div> */}
                <div className="d-flex flex-wrap align-items-center gap-1 mt-2">
                  <BadgeStatus name={leads?.status_name} group="Leads" className="font-12">
                    {
                      !['Unassigned','Applied','Succeed','Cancelled'].includes(leads?.status_name) ? (
                        <>
                          {leads?.status_name}
                          <i onClick={() => {openModalStatus(leads)}} className="ms-1 mdi mdi-square-edit-outline cursor-pointer"></i>
                        </>
                      ) : (
                        <>
                          {leads?.status_name}
                        </>
                      )
                    } 
                  </BadgeStatus>
                  {leads.counselor_name && (
                    <div className="badge bg-transparent shadow-none rounded-pill text-dark">
                      <img
                        className="rounded-circle ps-0 ms-n1"
                        src={leads.counselor_avatar}
                        alt=""
                        width="25"
                      />
                      <span className="ps-1">{leads.counselor_name}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap gap-1 align-items-center justify-content-start mt-1">
            {/* <div className="px-1 bg-primary badge rounded-pill bg-primary">
              {leads.city}
            </div> */}
            {/* <div className="me-1 badge-outline-primary badge rounded-pill">
              {leads.expo}
            </div> */}
          </div>
          <hr />

          <Tab.Container defaultActiveKey="detail">
            <Nav
              variant="pills"
              as="ul"
              className="nav nav-pills nav-fill navtab-bg nav-justified"
              navbarScroll={false}
            >
              <Nav.Item as="li" className="nav-item">
                <Nav.Link
                  href="#"
                  eventKey="detail"
                  className="nav-link cursor-pointer"
                >
                  Leads Detail
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link
                  href="#"
                  eventKey="event"
                  className="nav-link cursor-pointer"
                >
                  Event History
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item as="li" className="nav-item">
                <Nav.Link
                  href="#"
                  eventKey="comment"
                  className="nav-link cursor-pointer"
                >
                  Comments
                </Nav.Link>
              </Nav.Item> */}
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="detail">
                {/* <CustomEdit label="Full Name">
                  <CopyText text={`${leads?.title} ${leads.full_name}`}>{`${leads?.title} ${leads.full_name}`}</CopyText>
                </CustomEdit> */}
                <div>
                  <h5 className="font-12 mb-0">Full Name</h5>
                  <p className="mb-2">
                    <CopyText text={`${leads?.title || ''} ${leads.full_name}`}>{`${leads?.title || ''} ${leads.full_name}`}</CopyText>
                  </p>
                </div>
                <div>
                  <h5 className="font-12 mb-0">Address</h5>
                  <p className="mb-2">
                    {/* {leads?.address}, {leads?.kecamatan_name}, {leads?.kota_name}, {leads?.provinsi_name} {leads?.zip_code ? '('+leads.zip_code+')' : ''} */}
                    {
                      <>
                        
                      </>
                    }
                    <CopyText text={`${leads?.address}, ${leads?.kecamatan_name}, ${leads?.kota_name}, ${leads?.provinsi_name} ${leads?.zip_code ? '('+leads.zip_code+')' : ''}`}>
                      <>
                      {
                        leads?.address &&
                        <>
                          {leads?.address}
                        </>  
                      }
                      {
                        leads?.kecamatan_name &&
                        <>
                          , {leads?.kecamatan_name}
                        </>  
                      }
                      {
                        leads?.kota_name &&
                        <>
                          , {leads?.kota_name}
                        </>  
                      }
                      {
                        leads?.provinsi_name &&
                        <>
                          , {leads?.provinsi_name}
                        </>  
                      }
                      {
                        leads?.kecamatan_name &&
                        <>
                          {' '}({leads?.zip_code})
                        </>  
                      }
                      </>
                    </CopyText>
                  </p>
                </div>
                <div>
                  <h5 className="font-12 mb-0">Date of birth</h5>
                  <span className="mb-2">
                    <CopyText>{leads?.dob_dMY || '-'}</CopyText>
                  </span>
                </div>
                <div>
                  <h5 className="font-12 mb-0">Current education</h5>
                  <span className="mb-2">
                    <CopyText>{leads?.grade_name || '-'}</CopyText>
                  </span>
                </div>
                <div>
                  <h5 className="font-12 mb-0">School</h5>
                  <span className="mb-2">
                    <CopyText>{leads?.school_name || '-'}</CopyText>
                  </span>
                </div>
                <div>
                  <h5 className="font-12 mb-0">Interested in</h5>
                  <p className="mb-2">
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip style={{position:"fixed"}}>Course Level | Study Area | Course Full Name</Tooltip>}
                    >
                      <>{leads?.course || '-'}</>
                    </OverlayTrigger>
                  </p>
                </div>
                <div>
                  <h5 className="font-12 mb-0">Year of Study</h5>
                  <span className="mb-2">{leads?.planning_year || '-'}</span>
                </div>
                <div>
                  <h5 className="font-12 mb-1">Events</h5>
                  {/* {
                    // leads.
                  } */}
                  <div className="d-flex flex-wrap gap-1">
                    {
                      leads?.expo_name &&
                      <Badge bg="secondary" className="fw-normal px-2" pill>
                        {leads?.expo_name}
                      </Badge>
                    }
                    {
                      leads?.expo_names?.length ? (
                        <>
                          {
                            (leads.expo_names || []).map((name: string) => (
                              <div className="d-flex">
                                <Badge bg="secondary" className="fw-normal px-2" pill>
                                  {name}
                                </Badge>
                              </div>
                            ))
                          }
                        </>
                      ) : '-'
                    }
                  </div>
                </div>
                <div>
                  <h5 className="font-12 mb-0">Marketing Source</h5>
                  <span className="mb-2">{leads?.marketing_source_name || '-'}</span>
                </div>
                <div>
                  <h5 className="font-12 mb-0">Name (Emergency Contact)</h5>
                  <span className="mb-2">
                    <CopyText>{leads?.parents_name || '-'}</CopyText>
                  </span>
                </div>
                <div>
                  <h5 className="font-12 mb-0">Phone (Emergency Contact)</h5>
                  <span className="mb-2">
                    <CopyText>{leads?.parents_phone || '-'}</CopyText>
                  </span>
                </div>
                <div>
                  <h5 className="font-12 mb-0">Email (Emergency Contact)</h5>
                  <span className="mb-2">
                    <CopyText>{leads?.parents_email || '-'}</CopyText>
                  </span>
                </div>

                <hr />

                <div>
                  <div className="d-flex justify-content-between align-items-center bg-white border-bottom">
                    <h4 className="font-16">Discussion</h4>
                    {/* <div className="">
                      <select className="form-select form-select-sm">
                        <option defaultValue="0">Recent</option>
                        <option value="1">Old</option>
                      </select>
                    </div> */}
                  </div>
                  <div className="clerfix"></div>
                  {isFetchingComment ||
                  isLoadingComment ||
                  isUninitializedComment ||
                  isErrorComment ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: "200px" }}
                    >
                      <ScaleLoader />
                    </div>
                  ) : (
                    <div className="me-n2">
                      <SimpleBar
                        forceVisible="y"
                        autoHide={false}
                        style={{ maxHeight: 300 }}
                      >
                        <div className="me-3">
                        {(commentList.result || []).map((item: any) => (
                          <div className="d-flex align-items-start mt-3">
                            <img
                              className="me-2 rounded-circle"
                              src={item?.avatar}
                              alt=""
                              height="32"
                            />
                            <div className="w-100">
                              <h5 className="mt-0 mb-0">
                                {
                                  item.expoComment && (
                                    <div className="d-flex flex-wrap align-items-center gap-1 mb-1">
                                      {
                                        item?.expoComment?.is_hot &&
                                        <div className="d-flex">
                                          {/* <Badge bg="danger" className="">
                                            <i className="mdi mdi-fire cursor-pointer"></i> HOT
                                          </Badge> */}
                                          ⭐
                                        </div>
                                      }
                                      {item?.expoComment?.institution_name && (
                                        <div className="d-flex">
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip style={{position:"fixed"}}>Institution</Tooltip>}
                                          >
                                            <Badge bg="primary" className="font-10">
                                              {item?.expoComment?.institution_name}
                                            </Badge>
                                          </OverlayTrigger>
                                        </div>
                                      )}
                                      {item?.caused_by_name && (
                                        <div className="d-flex">
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip style={{position:"fixed"}}>Institution</Tooltip>}
                                          >
                                            <Badge bg="soft-primary" className="font-10 text-dark">
                                              {item?.caused_by_name}
                                            </Badge>
                                          </OverlayTrigger>
                                        </div>
                                      )}
                                      {item?.expoComment?.expo_name && (
                                        <div className="d-flex">
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip style={{position:"fixed"}}>Expo/Event</Tooltip>}
                                          >
                                            <Badge bg="warning" className="font-10">
                                              {item?.expoComment?.expo_name}
                                            </Badge>
                                          </OverlayTrigger>
                                        </div>
                                      )}
                                    </div>
                                  )
                                }
                                <small className="text-muted float-end mt-1">
                                  {moment(
                                    item.created_at,
                                    "YYYY-MM-DD HH:mm:ss"
                                  ).format('DD MMM YYYY, hh:mm A')}
                                </small>
                              </h5>
                              <div className="d-flex flex-wrap justify-content-start align-items-start gap-1">
                                {item?.expoComment?.course_level_name && (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip style={{position:"fixed"}}>Course Level</Tooltip>}
                                  >
                                    <Badge
                                      bg="transparent"
                                      className="badge-outline-secondary font-10 text-truncate fw-semibold"
                                    >
                                      {item?.expoComment?.course_level_name}
                                    </Badge>
                                  </OverlayTrigger>
                                )}
                                {item?.expoComment?.course_major_name && (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip style={{position:"fixed"}}>Study Area</Tooltip>}
                                  >
                                    <Badge
                                      bg="transparent"
                                      className="badge-outline-secondary font-10 text-truncate fw-semibold"
                                    >
                                      {item?.expoComment?.course_major_name}
                                    </Badge>
                                  </OverlayTrigger>
                                )}
                                {item?.expoComment?.course_full_name_name && (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip style={{position:"fixed"}}>Course Full Name</Tooltip>}
                                  >
                                    <Badge
                                      bg="transparent"
                                      className="badge-outline-secondary font-10 text-truncate fw-semibold"
                                    >
                                      {item?.expoComment?.course_full_name_name}
                                    </Badge>
                                  </OverlayTrigger>
                                )}
                              </div>
                              <div className="fw-semibold">{item?.caused_by_name}</div>
                              {
                                item.action_name ? (
                                  <>
                                    <div dangerouslySetInnerHTML={{__html: item.action_name}} />
                                    <div className="fst-italic font-12">Note: {item.note}</div>
                                  </>
                                ) : (
                                  <div>{item.note}</div>
                                )
                              }
                              {/* <a
                                className="text-muted font-13 d-inline-block mt-2"
                                href="/lms/dashboard"
                              >
                                <i className="mdi mdi-reply"></i> Reply
                              </a> */}
                            </div>
                          </div>
                        ))}
                        </div>
                        {/* {(commentList.result || []).map((item: any) => (
                          <div className="d-flex align-items-start my-3">
                            <div className="d-flex flex-column w-100">
                              <div className="d-flex align-items-start">
                                <img
                                  className="me-1 rounded-circle mt-2"
                                  src="https://ui-avatars.com/api/?length=2&font-size=0.4&size=48&rounded=0&uppercase=1&background=FCB9AA&color=666&bold=0&region=na&name=Pia+Kezia+Astuti"
                                  alt=""
                                  height="50"
                                />
                                <div
                                  className="d-flex flex-column border p-2 rounded-3 w-100"
                                  style={{
                                    background: "rgb(246 248 251 / 50%)",
                                  }}
                                >
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div className="font-14 fw-semibold">
                                      {item.caused_by_name}
                                    </div>
                                    <small className="text-muted float-end">
                                      {moment(
                                        item.created_at,
                                        "YYYY-MM-DD HH:mm:ss"
                                      ).fromNow()}
                                    </small>
                                  </div>
                                  <div className="d-flex gap-1 text-truncate mb-1">
                                    {item?.data?.expo_name && (
                                      <div>
                                        <Badge bg="primary" className="font-10">
                                          {item?.data?.institution_name}
                                        </Badge>
                                      </div>
                                    )}
                                  </div>
                                  <div className="rounded-3 mb-1">
                                    <div className="my-1 font-14 text-dark">
                                      {item.note}
                                    </div>
                                  </div>
                                  <div className="d-flex flex-wrap justify-content-start align-items-start gap-1">
                                    {item?.data?.course_level_name && (
                                      <Badge
                                        bg="transparent"
                                        className="badge-outline-secondary font-10 text-truncate fw-semibold"
                                      >
                                        CL: {item?.data?.course_level_name}
                                      </Badge>
                                    )}
                                    {item?.data?.course_major_name && (
                                      <Badge
                                        bg="transparent"
                                        className="badge-outline-secondary font-10 text-truncate fw-semibold"
                                      >
                                        CM: {item?.data?.course_major_name}
                                      </Badge>
                                    )}
                                    {item?.data?.course_full_name_name && (
                                      <Badge
                                        bg="transparent"
                                        className="badge-outline-secondary font-10 text-truncate fw-semibold"
                                      >
                                        CFM: {item?.data?.course_full_name_name}
                                      </Badge>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))} */}
                      </SimpleBar>
                    </div>
                  )}
                  <CommentInput onSubmit={onSubmitComment} />
                  {
                    leads?.status_name != 'Applied' &&
                    <div className="border rounded mt-2">
                      <form className="comment-area-box">
                        {/* <textarea
                          rows={3}
                          className="form-control border-0 resize-none"
                          placeholder="Your comment..."
                          value={commentValue}
                          onChange={(e: any) => { setCommentValue(e.target.value) }}
                        /> */}
                        {/* <Editor
                          editorClassName="comment-editor border-top border-right border-left px-2 py-0"
                          toolbarHidden
                          editorState={editorState}
                          onEditorStateChange={setEditorState}
                          onContentStateChange={handleContentStateChange}
                          mention={{
                            separator: " ",
                            trigger: "@",
                            suggestions: [
                              { text: "APPLE", value: "apple", url: "apple" },
                              { text: "BANANA", value: "banana", url: "banana" },
                              { text: "CHERRY", value: "cherry", url: "cherry" },
                              { text: "DURIAN", value: "durian", url: "durian" },
                              {
                                text: "EGGFRUIT",
                                value: "eggfruit",
                                url: "eggfruit",
                              },
                              { text: "FIG", value: "fig", url: "fig" },
                              {
                                text: "GRAPEFRUIT",
                                value: "grapefruit",
                                url: "grapefruit",
                              },
                              {
                                text: "HONEYDEW",
                                value: "honeydew",
                                url: "honeydew",
                              },
                            ],
                          }}
                        /> */}
                      </form>
                    </div>
                  }
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="event">
                {isFetchingExpoRegistrant ||
                isLoadingExpoRegistrant ||
                isUninitializedExpoRegistrant ||
                isErrorExpoRegistrant ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "200px" }}
                  >
                    <ScaleLoader />
                  </div>
                ) : (
                  <>
                  {
                    (!isLoadingExpoRegistrant && expoRegistrantList?.result?.length == 0) ? (
                      <div className="d-flex flex-column justify-content-center align-items-center mb-3" style={{minHeight: '200px'}}>
                        <LuPackageOpen size={32}/>
                        <p className="text-muted mt-1 mb-0">No data</p>
                      </div>
                    ) : (
                      <ListGroup variant="flush" className="mx-n3 mb-n3">
                        {(expoRegistrantList.result || []).map((item: any) => (
                          <ListGroup.Item className="px-3">
                            <div className="d-flex flex-column flex-wrap mb-3">
                              <div className="font-14 fw-semibold text-dark">{item.expo_name}</div>
                              {/* <div className="d-flex flex-wrap gap-1">
                                {item.course_level_name && (
                                  <Badge
                                    bg="transparent"
                                    className="badge-outline-secondary badge"
                                  >
                                    CL: {item.course_level_name}
                                  </Badge>
                                )}
                                {item.course_major_name && (
                                  <Badge
                                    bg="transparent"
                                    className="badge-outline-secondary badge"
                                  >
                                    CM: {item.course_major_name}
                                  </Badge>
                                )}
                                {item.course_full_name_name && (
                                  <Badge
                                    bg="transparent"
                                    className="badge-outline-secondary badge"
                                  >
                                    CFM: {item.course_full_name_name}
                                  </Badge>
                                )}
                              </div> */}
                              <div className="mt-n1">
                                {
                                  (item.comments || []).map((comment: any) => {
                                    return (
                                      <div className="d-flex flex-column py-2">
                                        <div className="d-flex flex-wrap justify-content-start align-items-center gap-1">
                                          {/* <Badge bg="primary" className=""></Badge> */}
                                          {
                                            comment.is_hot &&
                                            // <Badge bg="danger" className="">
                                            //   <i className="mdi mdi-fire cursor-pointer"></i> HOT
                                            // </Badge>
                                            <>⭐</>
                                          }
                                          {
                                            comment.institution_name &&
                                            <Badge bg="primary" className="">{comment.institution_name}</Badge>
                                          }
                                          {
                                            comment.representative_name &&
                                            <Badge bg="soft-primary" className="text-dark">{comment.representative_name}</Badge>
                                          }
                                          {
                                            comment.assistant_name &&
                                            <Badge bg="soft-primary" className="text-dark">{comment.assistant_name}</Badge>
                                          }
                                        </div>
                                        <div className="font-12 d-flex flex-wrap gap-1 mb-1 mt-1">
                                          <Badge bg="transparent" className="badge-outline-secondary badge">{comment.course_level_name}</Badge>
                                          <Badge bg="transparent" className="badge-outline-secondary badge">{comment.course_major_name}</Badge>
                                          <Badge bg="transparent" className="badge-outline-secondary badge">{comment.course_full_name_name}</Badge>
                                        </div>
                                        <div>
                                          <small className="text-muted">{comment?.date}</small>
                                        </div>
                                        <div className="">
                                          {comment.note || ''}
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    )
                  }
                  </>
                )}
              </Tab.Pane>
              {/* <Tab.Pane eventKey="comment">
                <div className="track-order-list mt-3">
                  <ul className="list-unstyled">
                    <li className="completed">
                      <span className="position-absolute" style={{top: '-20px'}}>01 November 2023 07:22 AM</span>
                      <h5 className="mt-0 mb-0">Filled form to attend the event</h5>
                      <p className="text-muted font-12 mb-3">
                        Leads ID: SC274304. Interested in Graphic Design. Bachelor degree.
                      </p>
                    </li>
                    <li className="completed ">
                      <span className="position-absolute" style={{top: '-20px'}}>15 November 2023 12:16 PM</span>
                      <h5 className="mt-0 mb-0">Consulted at Monash University booth. Representative: Ms. Jessica. Counselor: Rani</h5>
                      <p className="text-muted font-12 mb-3">
                        Already graduated. Want to study graphic design.
                      </p>
                    </li>
                    <li className="">
                      <span className="position-absolute" style={{top: '-20px'}}>15 November 2023 01:02 PM</span>
                      <span className="active-dot dot"></span>
                      <h5 className="mt-0 mb-0">Consulted at University of Amsterdam booth. Representative: Mr. Viktor. Counselor: Riman</h5>
                      <p className="text-muted font-12 mb-3">
                        Comparing the tution fee with Monash. Want to apply for graphic design program.
                      </p>
                    </li>
                  </ul>
                </div>
              </Tab.Pane> */}
            </Tab.Content>
          </Tab.Container>
        </Card.Body>
      </Card>

      {modalShowManage && (
        <ModalManage
          type={modalTypeManage}
          data={current}
          show={modalShowManage}
          onHide={closeModalManage}
          refetch={() => {
            refetchLeads()
            refetchExpoRegistrant()
            refetchComment()
          }}
          refetchLeads={() => {
            refetchLeads()
            onCloseDetail()
          }}
        />
      )}

      {modalShowStatus && (
        <ModalStatus
          data={current}
          show={modalShowStatus}
          onHide={closeModalStatus}
          refetch={() => { refetchLeads() }}
        />
      )}
    </>
  );
};

export default Detail;
