import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import Dropzone from "react-dropzone";
import PDFImage from 'assets/images/general/pdf.png'
import { FieldErrors, Control } from "react-hook-form";

interface FileType extends File {
  preview?: string;
  formattedSize?: string;
}

interface SingleUploadProps {
  name: string;
  onFileUpload?: (file: FileType) => void;
  onRemove?: any;
  showPreview?: boolean;
  disabled?: boolean;
  imagePreview?: string;
  imageName?: string;
  errors?: FieldErrors;
}

const SingleUpload = (props: SingleUploadProps) => {
  const [selectedFile, setSelectedFile] = useState<FileType>();
  const [currentPreview, setCurrentPreview] = useState("");

  useEffect(() => {
    if (props.imagePreview) {
      setCurrentPreview(props.imagePreview);
    } else {
      setCurrentPreview('');
    }
  }, [props.imagePreview]);

  /**
   * Handled the accepted file and shows the preview
   */
  const handleAcceptedFile = (file: FileType[]) => {
    var singleFile = file[0] || undefined;

    Object.assign(singleFile, {
      preview:
        singleFile["type"].split("/")[0] === "image"
          ? URL.createObjectURL(singleFile)
          : null,
      formattedSize: formatBytes(singleFile.size),
    });
    setSelectedFile(singleFile);
    if (props.onFileUpload) props.onFileUpload(singleFile);
  };

  /**
   * Formats the size
   */
  const formatBytes = (bytes: number, decimals: number = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  return (
    <>
      {!selectedFile && !currentPreview && (
        <Dropzone
          {...props}
          onDrop={(acceptedFile) => handleAcceptedFile(acceptedFile)}
          // maxSize={50000} // 500kb
        >
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone d-flex justify-content-center align-items-center" style={{ border: props?.errors && props?.errors[props?.name] && '2px dashed #c14456'}}>
              <div className="dz-message needsclick" {...getRootProps()}>
                <input {...getInputProps()} multiple={false} accept=".png, .jpg, .jpeg, .pdf"/>
                <i className="h3 text-muted dripicons-cloud-upload"></i>
                <h5 className="my-0">Drop file here or click to upload.</h5>
                <span className="text-muted font-12">
                  (Upload image only .jpg or .png. Max. 500kb)
                </span>
              </div>
            </div>
          )}
        </Dropzone>
      )}
      {
        currentPreview && (
          <Card className="shadow-none" style={{ border: '2px dashed #dee2e6', width: '150px' }}>
            <div className="p-2">
              <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                <Col xs={'auto'} className="position-relative">
                  <img
                    className="img-fluid rounded bg-light"
                    src={currentPreview}
                    width={120}
                  />
                  <div className="position-absolute bottom-0 start-50 translate-middle-x">
                    <Button
                      size="sm"
                      variant="danger"
                      className="btn btn-danger mb-1"
                      disabled={props?.disabled}
                      style={{width: '80px'}}
                      onClick={() => {
                        setSelectedFile(undefined);
                        setCurrentPreview("");
                        props.onRemove(selectedFile);
                        // if (props.onFileUpload) props.onFileUpload();
                      }}
                    >
                      {/* <i
                        className="dripicons-cross"
                        onClick={() => {
                          setSelectedFile(undefined);
                          setCurrentPreview("");
                          props.onRemove(selectedFile);
                          // if (props.onFileUpload) props.onFileUpload();
                        }}
                      ></i> */}
                      Remove
                    </Button>
                  </div>
                </Col>
                {/* {
                  props.imageName &&
                  <div className="ps-0 col">
                    <span
                      className="text-muted fw-bold"
                    >
                      {props.imageName}
                    </span>
                  </div>
                } */}
                {/* <Col className="text-end col-auto">
                  <button
                    type="button"
                    className="btn btn-link btn-lg text-muted shadow-none px-0"
                    disabled={props?.disabled}
                  >
                    <i
                      className="dripicons-cross"
                      onClick={() => {
                        setSelectedFile(undefined);
                        setCurrentPreview("");
                        props.onRemove(selectedFile);
                        // if (props.onFileUpload) props.onFileUpload();
                      }}
                    ></i>
                  </button>
                </Col> */}
              </div>
            </div>
          </Card>
        ) 
      }
      {/* {
        !currentPreview && selectedFile?.name &&
        <Card className="shadow-none" style={{ border: '2px dashed #dee2e6' }}>
          <div className="p-2">
            <div className="d-flex flex-row justify-content-between align-items-center gap-2">
              <Col className="col-auto">
                <img
                  data-dz-thumbnail=""
                  className="avatar-sm rounded bg-light"
                  src={PDFImage}
                />
              </Col>
              {
                selectedFile?.name &&
                <div className="ps-0 col">
                  <span
                    className="text-muted fw-bold"
                  >
                    {selectedFile?.name}
                  </span>
                </div>
              }
              <Col className="text-end col-auto">
                <button
                  type="button"
                  className="btn btn-link btn-lg text-muted shadow-none px-0"
                  disabled={props?.disabled}
                >
                  <i
                    className="dripicons-cross"
                    onClick={() => {
                      setSelectedFile(undefined);
                      setCurrentPreview("");
                      props.onRemove(selectedFile);
                      // if (props.onFileUpload) props.onFileUpload();
                    }}
                  ></i>
                </button>
              </Col>
            </div>
          </div>
        </Card>
      } */}
      {selectedFile?.preview ? (
          <div className="dropzone-previews" id="uploadPreviewTemplate">
            <Card className="shadow-none" style={{ border: '2px dashed #dee2e6' }}>
              <div className="p-2">
                <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                  {selectedFile?.preview && (
                    <Col className="col-auto">
                      <img
                        data-dz-thumbnail=""
                        className="avatar-sm rounded bg-light"
                        alt={selectedFile?.name}
                        src={selectedFile?.preview}
                        width={120}
                      />
                    </Col>
                  )}
                  {!selectedFile?.preview && (
                    <Col className="col-auto">
                      <div className="avatar-sm">
                        <span className="avatar-title bg-primary rounded">
                          {selectedFile?.type.split("/")[0]}
                        </span>
                      </div>
                    </Col>
                  )}
                  <Col className="ps-0 flex-fill">
                    <Link to="#" className="text-muted fw-bold">
                      {selectedFile?.name}
                    </Link>
                    <p className="mb-0">
                      <strong>{selectedFile?.formattedSize}</strong>
                    </p>
                  </Col>
                  <Col className="text-end col-auto">
                    <button
                      type="button"
                      className="btn btn-link btn-lg text-muted shadow-none px-0"
                      disabled={props?.disabled}
                    >
                      <i
                        className="dripicons-cross"
                        onClick={() => {
                          setSelectedFile(undefined);
                          setCurrentPreview("");
                          props.onRemove(selectedFile);
                          // if (props.onFileUpload) props.onFileUpload();
                        }}
                      ></i>
                    </button>
                  </Col>
                </div>
              </div>
            </Card>
          </div>
        ) : (
          <>
            {
              !currentPreview && selectedFile?.name &&
              <Card className="shadow-none" style={{ border: '2px dashed #dee2e6' }}>
                <div className="p-2">
                  <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                    <Col className="col-auto">
                      <img
                        data-dz-thumbnail=""
                        className="avatar-sm rounded bg-light"
                        src={PDFImage}
                        width={120}
                      />
                    </Col>
                    {
                      selectedFile?.name &&
                      <div className="ps-0 col">
                        <span
                          className="text-muted fw-bold"
                        >
                          {selectedFile?.name}
                        </span>
                      </div>
                    }
                    <Col className="text-end col-auto">
                      <button
                        type="button"
                        className="btn btn-link btn-lg text-muted shadow-none px-0"
                        disabled={props?.disabled}
                      >
                        <i
                          className="dripicons-cross"
                          onClick={() => {
                            setSelectedFile(undefined);
                            setCurrentPreview("");
                            props.onRemove(selectedFile);
                            // if (props.onFileUpload) props.onFileUpload();
                          }}
                        ></i>
                      </button>
                    </Col>
                  </div>
                </div>
              </Card>
            }
          </>
        )
      }
      {props?.errors && props?.errors[props?.name] ? (
        <div className="font-12 mt-1" style={{color: '#c14456'}}>Required field</div>
      ) : null}
    </>
  );
};

SingleUpload.defaultProps = {
  showPreview: true,
};

export default SingleUpload;
