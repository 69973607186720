import React from "react";
import ReactDOM from "react-dom/client";

import "./i18n";

import App from "./App";
import CacheBuster from 'react-cache-buster';

import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { BrowserRouter } from "react-router-dom";

// RTK Query
import { store } from "./redux-rtk/store";

import * as Sentry from "@sentry/react";
const nodeEnv: string = (process.env.NODE_ENV as string);
if(nodeEnv == "production"){
  Sentry.init({
    dsn: "https://b0ca4ea815b9adeb870e44599e1d6b58@o197366.ingest.sentry.io/4506703649505280",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["https://crm.jackstudy.co.id"],
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  // Old Redux
  // <Provider store={configureStore({})}>
  //   <React.Fragment>
  //     <BrowserRouter basename={process.env.PUBLIC_URL}>
  //       <App />
  //     </BrowserRouter>
  //   </React.Fragment>
  // </Provider>
  <Provider store={store}>
    <React.Fragment>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <CacheBuster
          reloadOnDowngrade
          currentVersion={process.env.REACT_APP_VERSION}
          isEnabled={true}
          isVerboseMode={false}
          // loadingComponent={<Loading />}
        >
          <App />
        </CacheBuster>
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);
console.log('Version :'+ process.env.REACT_APP_VERSION);
