import React from "react";
import { Card, ListGroup, Badge, Row, Col } from "react-bootstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { faker } from '@faker-js/faker';
import { LuPackageOpen } from "react-icons/lu";
import ScaleLoader from "components/ScaleLoader";
import { FormSelect, BadgeStatus } from "components"
import moment from "moment"
import { useSelector } from "react-redux";
import { RootState } from "redux-rtk/store";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import {
  useGetTodayBirthdayQuery,
} from "services/api";

const TodayBirthday = () => {
  const navigate = useNavigate()
  const { user } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
    })
  );

  const {
    data: birthdayList,
    isFetching: isFetchingBirthday,
    isLoading: isLoadingBirthday,
    isUninitialized: isUninitializedBirthday,
    isError: isErrorBirthday,
    error: errorBirthday,
    refetch: refetchBirthday,
  } = useGetTodayBirthdayQuery(
    {},
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  return (
    <>
      <Card className="h-100 mt-3 mt-sm-auto">
        <Card.Body>
          {
            (isFetchingBirthday || isLoadingBirthday || isUninitializedBirthday || isErrorBirthday) ? (
              <>
                <Row className="mb-3">
                  <Col>
                    <Skeleton height="25px"/>
                  </Col>
                  <Col>
                    <Skeleton height="25px"/>
                  </Col>
                  <Col>
                    <Skeleton height="25px"/>
                  </Col>
                </Row>
                {
                  [...Array(4)].map((number, i) => {
                    return (
                      <Row className="align-items-center mb-3">
                        <Col>
                          <Row className="align-items-center">
                            <Col xs="auto">
                              <Skeleton circle height="40px" width="40px"/>
                            </Col>
                            <Col>
                              <Skeleton/>
                              <Skeleton height={10} width="75%"/>
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Skeleton/>
                        </Col>
                        <Col>
                          <Skeleton/>
                        </Col>
                        <Col>
                          <Skeleton/>
                        </Col>
                      </Row>
                    )
                  })
                }
              </>
            ) : (
              <>
                <h4 className="header-title mb-3 d-flex justify-content-between">
                  <span>Today Birthday</span>
                  <div className="d-flex align-items-center gap-1">
                    <div className="text-muted font-12 fw-normal">{moment().format('DD MMMM YYYY')}</div>
                    {/* <span className="font-14 text-danger">{}</span> */}
                    {
                      user.branch_name &&
                      <Badge bg="soft-pink" className="text-pink">
                        <span>{user.branch_name}</span>
                      </Badge>
                    }
                  </div>
                </h4>
                <ListGroup variant="flush">
                {(birthdayList?.result || []).map((item: any, index: number) => {
                  return (
                    <ListGroup.Item className="px-0 cursor-pointer" onClick={() => {navigate(`/lms/leads/${item.code}`)}}>
                      <div className="d-flex align-items-center">
                        <img
                          src={item.avatar}
                          alt={item.full_name}
                          className="avatar-sm rounded-circle me-2"
                        />
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center gap-1">
                            <span className="font-12 fw-semibold">{item.full_name}</span>
                            <BadgeStatus
                              name={item?.status_name}
                              group="Leads"
                            />
                            <Badge>{item.branch_name}</Badge>
                          </div>
                          <span className="font-11 text-muted">
                            {item.age} years old
                          </span>
                        </div>
                      </div>
                    </ListGroup.Item>
                  );
                })}
                </ListGroup>
              </>
            )
          }
        </Card.Body>
      </Card>
    </>
  );
};

export default TodayBirthday;
