import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Button, Row, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import avatar3 from "../../../assets/images/users/user-3.jpg";
import avatar5 from "../../../assets/images/users/user-5.jpg";
import avatar6 from "../../../assets/images/users/user-9.jpg";

import {
  useGetMentionQuery,
} from "services/api";

const Discussion = () => {
  const navigate = useNavigate();

  const {
    data: mentionList,
    isFetching: isFetchingMention,
    isLoading: isLoadingMention,
    isUninitialized: isUninitializedMention,
    isError: isErrorMention,
    error: errorMention,
    refetch: refetchMention,
  } = useGetMentionQuery(
    {},
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  return (
    <>
      <Card className="h-100 mt-5 mt-sm-auto">
        <Card.Body>
          {
            (isFetchingMention || isLoadingMention || isUninitializedMention || isErrorMention) ? (
              <>
                <Row className="mb-3">
                  <Col>
                    <Skeleton height="25px"/>
                  </Col>
                  <Col>
                    <Skeleton height="25px"/>
                  </Col>
                  <Col>
                    <Skeleton height="25px"/>
                  </Col>
                </Row>
                {
                  [...Array(4)].map((number, i) => {
                    return (
                      <Row className="align-items-center mb-3">
                        <Col>
                          <Row className="align-items-center">
                            <Col xs="auto">
                              <Skeleton circle height="40px" width="40px"/>
                            </Col>
                            <Col>
                              <Skeleton/>
                              <Skeleton height={10} width="75%"/>
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Skeleton/>
                        </Col>
                        <Col>
                          <Skeleton/>
                        </Col>
                        <Col>
                          <Skeleton/>
                        </Col>
                      </Row>
                    )
                  })
                }
              </>
            ) : (
              <>
                <h4 className="mb-4 mt-0 font-16">Discussion</h4>
                {
                  (!isLoadingMention && mentionList?.result.length == 0) ? (
                    <p className="text-center text-muted">No discussion yet</p>
                  ) : (
                    <>
                      {
                        (mentionList?.result || []).map((item: any) => {
                          return (
                            <div className="d-flex align-items-start mt-3">
                              <img
                                className="me-2 rounded-circle"
                                src={item.from_user_avatar}
                                alt={item.from_user_name}
                                height="32"
                              />
                              <div className="w-100">
                                <h5 className="mt-0 mb-1">
                                  {item.from_user_name}{" "}
                                  <small className="text-muted float-end">{item.created_at}</small>
                                </h5>
                                {item.note}
                                <br />
                                <Button variant="link" onClick={() => navigate(`/lms/leads/${item.leads_code}`)} className="text-muted font-13 d-inline-block mt-2 p-0">
                                  <i className="mdi mdi-reply"></i> Reply
                                </Button>
                              </div>
                            </div>
                          )
                        })
                      }
                    </>
                  )
                }
              </>
            )
          }
          {/* <div className="float-end">
            <select className="form-select form-select-sm">
              <option defaultValue="0">Recent</option>
              <option value="1">Old</option>
            </select>
          </div> */}

          {/* <div className="text-center mt-2">
            <Link to="#" className="text-danger">
              <i className="mdi mdi-spin mdi-loading me-1"></i> Load more
            </Link>
          </div> */}

          {/* <div className="border rounded mt-4">
            <form className="comment-area-box">
              <textarea
                rows={3}
                className="form-control border-0 resize-none"
                placeholder="Your comment..."
              />
              <div className="p-2 bg-light d-flex justify-content-between align-items-center">
                <div>
                  <Link to="#" className="btn btn-sm px-1 btn-light">
                    <i className="mdi mdi-upload"></i>
                  </Link>
                  <Link to="#" className="btn btn-sm px-1 btn-light">
                    <i className="mdi mdi-at"></i>
                  </Link>
                </div>
                <button type="submit" className="btn btn-sm btn-success">
                  <i className="mdi mdi-send me-1"></i>Submit
                </button>
              </div>
            </form>
          </div> */}
        </Card.Body>
      </Card>
    </>
  );
};

export default Discussion;
