import React, { useEffect, useState, useRef } from "react";
import { useEffectOnce } from "usehooks-ts";
import { Card, Row, Col, Button, Nav, Tab, Form, Alert } from "react-bootstrap";
import { Navigate, Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import moment from 'moment';
import SimpleBar from "simplebar-react";
import { useParams } from "react-router-dom";
import ScaleLoader from "components/ScaleLoader";
import { HasAccess } from "@permify/react-role";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/store";

// components
import PageTitle from "components/PageTitle";
import { FormSelect } from "components/";
import Select from "react-select";
import List from "./List";
import Detail from "./Detail";
import Selection from "./Selection";
import ModalManage from "./ModalManage";

import {
  useGetBranchQuery,
  useGetStatusQuery,
  useGetUserQuery,
  useGetExpoQuery,
  useGetLeadsQuery,
  useExportLeadsMutation,
  useGetAutocompleteQuery,
} from "services/api";

const ButtonStatus = ({
  activeTab,
  tabName,
  onClick,
  children,
  isActive,
  count,
}: {
  activeTab: string,
  tabName: string,
  onClick: any,
  children?: any,
  isActive?: boolean,
  count?: number,
}) => {
  return (
    <Button
      variant={tabName == activeTab ? 'primary' : 'light'}
      className={classNames(
        "fw-semibold font-12 py-1",
        tabName != activeTab && 'text-secondary'
      )}
      onClick={() => onClick(tabName)}
    >
      {/* <i className="mdi mdi-book-marker me-1"></i> */}
      <span>{ tabName }</span>
      {/* {children || ''} */}
      <span className={`${isActive ? 'bg-white' : ''} badge-outline-secondary badge ms-1`} style={{paddingTop: '2px', paddingBottom: '2px'}}>{count}</span>
    </Button>
  )
}

const Leads = () => {
  const navigate = useNavigate();
  let { tabName = 'All', leadsCode } = useParams();
  const defExStatusName = ['Non-prospect','Aborted'];

  const { user } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      roles: state.Auth.user.roles,
    })
  );

  const [current, setCurrent] = useState<LeadsProps>();
  const [activeTab, setActiveTab] = useState(tabName || 'All');
  const [layout, setLayout] = useState('default');
  const [allLeadsCount, setAllLeadsCount] = useState(0);
  const [leadsCount, setLeadsCount] = useState({});

  const [checked, setChecked] = useState<any[]>(() => [])

  const [search, setSearch] = useState("");
  const [sort, setSort] = useState<object>({});
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [filterStatus, setFilterStatus] = useState<null | string>();
  const [filterBranch, setFilterBranch] = useState<[]>();
  const [filterCounselor, setFilterCounselor] = useState<[]>();
  const [filterExpo, setFilterExpo] = useState<[]>();
  const [filterRegistrantType, setFilterRegistrantType] = useState();
  const [excludeStatusName, setExcludeStatusName] = useState(defExStatusName);
  
  const [modalType, setModalType] = useState<string>('');
  const [modalShow, setModalShow] = useState<boolean>(false);
  
  const { data: institutionList, isLoading: isLoadingInstitution, isUninitialized: isUninitializedInstitution, isFetching: isFetchingInstitution } = useGetAutocompleteQuery({type: 'INS'});
  const { data: courseLevelList, isLoading: isLoadingCourseLevel, isUninitialized: isUninitializedCourseLevel, isFetching: isFetchingCourseLevel } = useGetAutocompleteQuery({type: 'CRL'});
  const { data: courseMajorList, isLoading: isLoadingCourseMajor, isUninitialized: isUninitializedCourseMajor, isFetching: isFetchingCourseMajor } = useGetAutocompleteQuery({type: 'CMJ'});
  const { data: courseFullNameList, isLoading: isLoadingCourseFullName, isUninitialized: isUninitializedCourseFullName, isFetching: isFetchingCourseFullName } = useGetAutocompleteQuery({type: 'CFN'});
  const { data: marketingSourceList, isLoading: isLoadingMarketingSource, isUninitialized: isUninitializedMarketingSource, isFetching: isFetchingMarketingSource } = useGetAutocompleteQuery({type: 'MRS'});
  const { data: schoolList, isLoading: isLoadingSchool, isUninitialized: isUninitializedSchool, isFetching: isFetchingSchool } = useGetAutocompleteQuery({type: 'SCH'});
  const { data: gradeList, isLoading: isLoadingGrade, isUninitialized: isUninitializedGrade, isFetching: isFetchingGrade } = useGetAutocompleteQuery({type: 'GRD'});
  
  const {
    data: statusList,
    isFetching: isFetchingStatus,
    isLoading: isLoadingStatus,
    isUninitialized: isUninitializedStatus,
    isError: isErrorStatus,
    error: errorStatus,
    refetch: refetchStatus,
  } = useGetStatusQuery(
    {
      group: 'Leads',
    },
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  const {
    data: branchList,
    isFetching: isFetchingBranch,
    isLoading: isLoadingBranch,
    isUninitialized: isUninitializedBranch,
    isError: isErrorBranch,
    error: errorBranch,
    refetch: refetchBranch,
  } = useGetBranchQuery(
    {},
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );
  
  const {
    data: counselorList,
    isFetching: isFetchingCounselor,
    isLoading: isLoadingCounselor,
    isUninitialized: isUninitializedCounselor,
    error: errorCounselor,
    isError: isErrorCounselor,
    refetch: refetchCounselor,
  } = useGetUserQuery(
    {
      search: {
        role_name: 'Counselor'
      },
      branch_code: user?.branch_code ? [user?.branch_code] : filterBranch
    },
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );
  
  const {
    data: expoList,
    isFetching: isFetchingExpo,
    isLoading: isLoadingExpo,
    isUninitialized: isUninitializedExpo,
    error: errorExpo,
    isError: isErrorExpo,
    refetch: refetchExpo,
  } = useGetExpoQuery(
    {},
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  const {
    data: leadsList,
    isFetching: isFetchingLeads,
    isLoading: isLoadingLeads,
    isUninitialized: isUninitializedLeads,
    isError: isErrorLeads,
    error,
    refetch: refetchLeads,
  } = useGetLeadsQuery(
    {
      // type,
      search,
      branch_code: filterBranch,
      counselor_code: filterCounselor,
      expo_code: filterExpo,
      type: filterRegistrantType,
      status_code: filterStatus,
      exclude_status_name: excludeStatusName,
      // order_by: orderBy,
      // sort_by: sortBy,
      page: activePage,
      per_page: perPage,
    },
    {
      // pollingInterval: 100,
      refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  const [exportLeads, { isLoading: isLoadingExport }] = useExportLeadsMutation();
  
  const onSelectTab = (eventKey: any, e: any) => {
    setActiveTab(eventKey)
    // e.stopPropagation();
    // e.preventDefault();
    navigate(`/lms/leads/${eventKey}`)
  }

  const onClickStatus = (status_name: string) => {
    resetPage()
    setActiveTab(status_name)
    navigate(`/lms/leads/${status_name}`)
  }

  const closeModal = () => setModalShow(false);
  const openModal = (type: string) => {
    setModalType(type)
    setModalShow(true)
  };

  const resetPage = () => {
    setLayout('default')
    setCurrent(undefined)
    setSearch("")
    setSort({})
    setActivePage(1)
    // setPerPage(10)
    setFilterStatus(null)
    // calculateCount()
  }

  const resetLayout = () => {
    setLayout('default')
    setCurrent(undefined)
  }

  // const calculateCount = () => {
  //   let all = 0;
  //   let arr: any;
  //   (statusList?.result || []).map((item: any) => {
  //     all += item.count;
  //     arr[item.name] = item.count
  //   })
  //   setAllLeadsCount(all);
  //   arr['All'] = all;
  //   setLeadsCount(arr)
  // }

  const onSelectCard = (item: any, status: boolean) => {
    const arrIndex = checked.findIndex(
      (el: any) => el.code === item?.code
    );
    if(status && arrIndex){
      setChecked([...checked, item]);
    } else if(!status){
      setChecked(checked.filter((el: any) => el.code !== item?.code));
    }
  };

  const isSelectedCard = (item: any) => {
    return checked.find((el: any) => el.code === item?.code);
  };

  const onClickExport = async () => {
    toast.info('Processing your request...', {hideProgressBar: true, autoClose: false, closeButton: false, closeOnClick: false})

    await exportLeads({
      // type,
      search,
      branch_code: filterBranch,
      counselor_code: filterCounselor,
      expo_code: filterExpo,
      type: filterRegistrantType,
      status_code: filterStatus,
      // order_by: orderBy,
      // sort_by: sortBy,
      page: activePage,
      per_page: perPage,
    })
      .unwrap()
      .then((res: any) => {
        toast.dismiss();
        if(res?.result?.url){
          // toast.success(res.message)
          window.open(res?.result?.url, "_blank");
        }
      })
      .catch((error: any) => {
        toast.dismiss();
        // console.log("error");
        toast.error("Failed...")
      });
  };

  useEffectOnce(() => {
    if(leadsCode){
      // setCurrent(item);
      setLayout('with-detail')
      navigate(`/lms/leads/${leadsCode}`)
    }
  })

  useEffect(() => {
    // calculateCount()
  }, [statusList])

  if(isUninitializedStatus){
    return <></>
  }

  return (
    <>
      <Card
        className={classNames(
          "mb-2 mt-3",
          layout == "with-detail" && "d-none d-sm-block"
        )}
       >
        <Card.Body>
          <div className="d-flex flex-wrap justify-content-between gap-1">
            <div className="">
              <div className="d-flex flex-wrap justify-content-start align-items-center gap-1">
                <div className="flex-shrink-1">
                  <label htmlFor="inputPassword2" className="visually-hidden">
                    Search
                  </label>
                  <div className="me-3 w-100">
                    <input
                      type="search"
                      className="form-control my-1 my-lg-0"
                      id="inputPassword2"
                      placeholder="Search leads..."
                      onChange={(e: any) => setSearch(e.target.value)}
                      value={search}
                    />
                  </div>
                </div>
                <div className="flex-grow-1">
                  <div className="me-e w-100" style={{minWidth: '200px'}}>
                    <Select
                      className="react-select react-select-container"
                      classNamePrefix="react-select"
                      placeholder="Filter by Status..."
                      styles={{
                        control: (provided: any, state: any) => ({
                          ...provided,
                          boxShadow: "none",
                          border: "1px solid #dee2e6",
                          color: "#6c757d",
                          "&:hover": {
                            border: "1px solid #dee2e6",
                          }
                        }),
                      }}
                      // defaultValue={options && defaultValue ? options.find((opt: any) => opt.value == defaultValue) : null}
                      options={(statusList?.result || []).map((item: any) => {
                        return {
                          label: item.name,
                          value: item.code,
                        };
                      })}
                      onChange={(opt: any, actionType: any) => {
                        resetLayout()
                        let statusCodes = (opt || []).map((item: any) => {
                          return item.value;
                        })
                        setActivePage(1)
                        setFilterStatus(statusCodes)
                      }}
                      isClearable
                      isMulti
                      // isDisabled={isDisabled}
                    ></Select>
                  </div>
                </div>
                <HasAccess
                    permissions={["filter-branch"]} 
                >
                  <div className="flex-grow-1">
                    <div className="me-e w-100" style={{minWidth: '200px'}}>
                      <Select
                        className="react-select react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Filter by Branch..."
                        styles={{
                          control: (provided: any, state: any) => ({
                            ...provided,
                            boxShadow: "none",
                            border: "1px solid #dee2e6",
                            color: "#6c757d",
                            "&:hover": {
                              border: "1px solid #dee2e6",
                            }
                          }),
                        }}
                        // defaultValue={options && defaultValue ? options.find((opt: any) => opt.value == defaultValue) : null}
                        options={(branchList?.result || []).map((item: any) => {
                          return {
                            label: item.name,
                            value: item.code,
                          };
                        })}
                        onChange={(opt: any, actionType: any) => {
                          resetLayout()
                          let branchCodes = (opt || []).map((item: any) => {
                            return item.value;
                          })
                          setActivePage(1)
                          setFilterBranch(branchCodes)
                        }}
                        isClearable
                        isMulti
                        // isDisabled={isDisabled}
                      ></Select>
                    </div>
                  </div>
                </HasAccess>
                <HasAccess
                    permissions={["filter-counselor"]} 
                >
                  <div className="flex-grow-1">
                    <div className="me-e w-100" style={{minWidth: '200px'}}>
                      <Select
                        className="react-select react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Filter by Counselor..."
                        styles={{
                          control: (provided: any, state: any) => ({
                            ...provided,
                            boxShadow: "none",
                            border: "1px solid #dee2e6",
                            color: "#6c757d",
                            "&:hover": {
                              border: "1px solid #dee2e6",
                            }
                          }),
                        }}
                        // defaultValue={options && defaultValue ? options.find((opt: any) => opt.value == defaultValue) : null}
                        options={(counselorList?.result || []).map((item: any) => {
                          return {
                            label: item.name,
                            value: item.code,
                          };
                        })}
                        onChange={(opt: any, actionType: any) => {
                          resetLayout()
                          let counselorCodes = (opt || []).map((item: any) => {
                            return item.value;
                          })
                          setActivePage(1)
                          setFilterCounselor(counselorCodes)
                        }}
                        isClearable
                        isMulti
                        // isDisabled={isDisabled}
                      ></Select>
                    </div>
                  </div>
                </HasAccess>
                <div className="flex-grow-1">
                  <div className="me-e w-100" style={{minWidth: '200px'}}>
                    <Select
                      className="react-select react-select-container"
                      classNamePrefix="react-select"
                      placeholder="Filter by Event..."
                      styles={{
                        control: (provided: any, state: any) => ({
                          ...provided,
                          boxShadow: "none",
                          border: "1px solid #dee2e6",
                          color: "#6c757d",
                          "&:hover": {
                            border: "1px solid #dee2e6",
                          }
                        }),
                      }}
                      // defaultValue={options && defaultValue ? options.find((opt: any) => opt.value == defaultValue) : null}
                      options={(expoList?.result || []).map((item: any) => {
                        return {
                          label: item.name,
                          value: item.code,
                        };
                      })}
                      onChange={(opt: any, actionType: any) => {
                        resetLayout()
                        let expoCodes = (opt || []).map((item: any) => {
                          return item.value;
                        })
                        setActivePage(1)
                        setFilterExpo(expoCodes)
                      }}
                      isClearable
                      isMulti
                      // isDisabled={isDisabled}
                    ></Select>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <div className="me-e w-100" style={{minWidth: '200px'}}>
                    <Select
                      className="react-select react-select-container"
                      classNamePrefix="react-select"
                      placeholder="Filter by Attendance..."
                      styles={{
                        control: (provided: any, state: any) => ({
                          ...provided,
                          boxShadow: "none",
                          border: "1px solid #dee2e6",
                          color: "#6c757d",
                          "&:hover": {
                            border: "1px solid #dee2e6",
                          }
                        }),
                      }}
                      // defaultValue={options && defaultValue ? options.find((opt: any) => opt.value == defaultValue) : null}
                      options={[
                        {
                          label: 'Event Registrant',
                          value: 'registrant',
                        },
                        {
                          label: 'Event Attendance',
                          value: 'attendees',
                        },
                        {
                          label: 'Just Registered',
                          value: 'registered-only',
                        },
                        {
                          label: 'Walk In',
                          value: 'walk-in',
                        }
                      ]}
                      onChange={(opt: any, actionType: any) => {
                        resetLayout()
                        // let regTypes = (opt || []).map((item: any) => {
                        //   return item.value;
                        // })
                        setActivePage(1)
                        setFilterRegistrantType(opt?.value)
                      }}
                      isClearable
                      // isMulti
                      // isDisabled={isDisabled}
                    ></Select>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="d-flex mt-1 mt-lg-0 gap-1">
                {/* <Button
                  variant="secondary"
                  className="waves-effect waves-light me-1"
                >
                  <i className="mdi mdi-cog"></i>
                </Button> */}
                <Button
                  variant="success"
                  className="waves-effect waves-light"
                  onClick={() => openModal('add')}
                >
                  <i className="mdi mdi-plus-circle me-1"></i> Add New Leads
                </Button>
                <HasAccess
                  permissions={["export-leads"]} 
                >
                  <Button
                    variant="dark"
                    className="waves-effect waves-light"
                    onClick={(e) => onClickExport()}
                    disabled={isLoadingExport}
                  >
                    <div className="d-flex">
                      <i className="mdi mdi-file-export me-1"></i> 
                      <span>Export</span>
                    </div>
                  </Button>
                </HasAccess>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>

      {
        checked.length > 0 &&
        <Alert variant="primary">
          <div className="d-flex flex-column flex-sm-row align-items-center gap-1">
            <div><strong>{checked.length}</strong> leads have been selected</div>
            <div className="d-inline-flex gap-1">
              <Button variant="dark" size="sm" onClick={() => { setLayout('with-selection') }}>Assign to Counselor</Button>
              <Button variant="secondary" size="sm" onClick={() => { setLayout('default'); setChecked([]); }}>Cancel</Button>
            </div>
          </div>
        </Alert>
      }

      <Row>
        <Col
          xxl={layout == "default" ? 12 : 8}
          xl={layout == "default" ? 12 : 7}
          lg={layout == "default" ? 12 : 7}
        >
          <List
            isLoading={isFetchingLeads || isLoadingLeads || isUninitializedLeads || isErrorLeads}
            data={leadsList || []}
            current={current}
            setCurrent={setCurrent}
            search={search}
            setSearch={setSearch}
            filterBranch={filterBranch}
            setFilterBranch={setFilterBranch}
            sort={sort}
            setSort={setSort}
            activePage={activePage}
            setActivePage={setActivePage}
            perPage={perPage}
            setPerPage={setPerPage}
            setLayout={setLayout}
            layout={layout}
            isChecked={(item: any) => isSelectedCard(item)}
            setIsChecked={(item: any, status: boolean) => onSelectCard(item, status)}
            leadsCount={leadsCount}
            setLeadsCount={setLeadsCount}
            refetchLeads={refetchLeads}
          />
        </Col>
        <Col xxl={4} xl={5} lg={5}>
          {layout == "with-detail" && (
            <Detail
              current={current}
              setCurrent={setCurrent}
              layout={layout}
              setLayout={setLayout}
              refetchLeads={refetchLeads}
            />
          )}
          {layout == "with-selection" && (
            <Selection 
              current={current}
              setCurrent={setCurrent}
              layout={layout}
              setLayout={setLayout}
              checked={checked}
              setChecked={setChecked}
            />
          )}
        </Col>
      </Row>

      {modalShow && (
        <ModalManage
          type={modalType}
          show={modalShow}
          onHide={closeModal}
        />
      )}
    </>
  );
};

export default Leads;
