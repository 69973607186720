import React, { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip, Dropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { TbEdit } from "react-icons/tb";
import ScaleLoader from "components/ScaleLoader";

import {
  useGetLogoQuery,
} from "services/api";

import { getMenuItems } from "../helpers/menu";

// store
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

// constants
import { LayoutTypes } from "../constants/layout";

// components
import AppMenu from "./Menu";
import ModalLogo from "./ModalLogo";

import profileImg from "../assets/images/users/user-1.jpg";
import logoSm from "../assets/images/logo-sm.png";
import logoDark from "../assets/images/logo-dark.png";
import logoDark2 from "../assets/images/logo-dark-2.png";
import logoLight from "../assets/images/logo-light.png";
import logoLight2 from "../assets/images/logo-light-2.png";

const baseURL: string = (process.env.REACT_APP_API_BASE as string);

/* user box */
const UserBox = () => {
  // get the profilemenu
  const ProfileMenus = [
    {
      label: "My Account",
      icon: "fe-user",
      redirectTo: "#",
    },
    {
      label: "Settings",
      icon: "fe-settings",
      redirectTo: "#",
    },
    {
      label: "Lock Screen",
      icon: "fe-lock",
      redirectTo: "/auth/lock-screen",
    },
    {
      label: "Logout",
      icon: "fe-log-out",
      redirectTo: "/auth/logout",
    },
  ];

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  /*
   * toggle dropdown
   */
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="user-box text-center">
      <img
        src={profileImg}
        alt=""
        title="Mat Helme"
        className="rounded-circle avatar-md"
      />
      <Dropdown show={dropdownOpen} onToggle={toggleDropdown}>
        <Dropdown.Toggle
          id="dropdown-notification"
          as="a"
          onClick={toggleDropdown}
          className="cursor-pointer text-dark h5 mt-2 mb-1 d-block"
        >
          Geneva Kennedy
        </Dropdown.Toggle>
        <Dropdown.Menu className="user-pro-dropdown">
          <div onClick={toggleDropdown}>
            {(ProfileMenus || []).map((item, index) => {
              return (
                <Link
                  to={item.redirectTo}
                  className="dropdown-item notify-item"
                  key={index + "-profile-menu"}
                >
                  <i className={`${item.icon} me-1`}></i>
                  <span>{item.label}</span>
                </Link>
              );
            })}
          </div>
        </Dropdown.Menu>
      </Dropdown>
      <p className="text-muted">Admin Head</p>
    </div>
  );
};

/* sidebar content */
const SideBarContent = () => {
  return (
    <>
      <UserBox />

      {/* <div id="sidebar-menu"> */}
      <AppMenu menuItems={getMenuItems()} />
      {/* </div> */}

      <div className="clearfix" />
    </>
  );
};

interface LeftSidebarProps {
  isCondensed: boolean;
  hideLogo?: boolean;
}

const LeftSidebar = ({ isCondensed, hideLogo }: LeftSidebarProps) => {
  const { roles } = useSelector(
    (state: RootState) => ({
      roles: state.Auth.user.roles,
    })
  );
  
  const {
    data: base64Logo,
    isFetching: isFetchingLogo,
    isLoading: isLoadingLogo,
    isUninitialized: isUninitializedLogo,
    isError: isErrorLogo,
    error: errorLogo,
    refetch: refetchLogo,
  } = useGetLogoQuery(
    {},
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: isSkipped(),
    }
  );

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [isShown, setIsShown] = useState(false);
  const menuNodeRef: any = useRef(null);

  const closeModal = () => {
    setModalShow(false)
  };
  const openModal = () => {
    setModalShow(true)
  };

  const { layoutType } = useSelector((state: RootState) => ({
    layoutType: state.Layout.layoutType,
    leftSideBarType: state.Layout.leftSideBarType,
  }));

  /**
   * Handle the click anywhere in doc
   */
  const handleOtherClick = (e: any) => {
    if (
      menuNodeRef &&
      menuNodeRef.current &&
      menuNodeRef.current.contains(e.target)
    )
      return;
    // else hide the menubar
    if (document.body) {
      document.body.classList.remove("sidebar-enable");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOtherClick, false);

    return () => {
      document.removeEventListener("mousedown", handleOtherClick, false);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="app-menu" ref={menuNodeRef}>
        {!hideLogo && (
          <div className="logo-box"
            onMouseEnter={() => {
              if(roles?.includes('Admin')){
                setIsShown(true)
              }
            }}
            onMouseLeave={() => {
              if(roles?.includes('Admin')){
                setTimeout(() => {
                  setIsShown(false)
                }, 1000);
              }
            }}
          >
            <Link to="/" className="logo logo-dark text-center">
              {/* <span className="logo-event position-absolute" style={{left: '40px'}}>
                <img
                  src={baseURL+'/logo-event.png'}
                  alt=""
                  // height="50"
                  style={{maxHeight: '40px'}}
                />
              </span> */}
              {isShown && (
                <OverlayTrigger
                  placement={'bottom'}
                  overlay={
                    <Tooltip style={{position:"fixed"}}>
                      Change logo
                    </Tooltip>
                  }
                >
                  <div className="position-absolute top-50 start-50 translate-middle" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    openModal();
                  }}>
                    <div className="bg-dark rounded-circle border d-flex align-items-center">
                      <TbEdit color="white" className="m-1" />
                    </div>
                  </div>
                </OverlayTrigger>
              )}
              <span className="logo-sm">
                <img src={logoSm} alt="" height="22" />
              </span>
              <span className="logo-lg">
                {
                  isLoadingLogo ? (
                    <div className="d-flex justify-content-center align-items-center">
                      {/* <ScaleLoader /> */}
                      <ScaleLoader height={12} />
                    </div>
                  ) : (
                    <img
                      // src={
                      //   layoutType === LayoutTypes.LAYOUT_TWO_COLUMN
                      //     ? logoDark2
                      //     : logoDark
                      // }
                      src={base64Logo}
                      alt=""
                      height="50"
                      onMouseEnter={() => {
                        if(roles?.includes('Admin')){
                          setIsShown(true)
                        }
                      }}
                      onMouseLeave={() => {
                        if(roles?.includes('Admin')){
                          setTimeout(() => {
                            setIsShown(false)
                          }, 1000);
                        }
                      }}
                    />
                  )
                }
              </span>
            </Link>
            <Link to="/" className="logo logo-light text-center">
              <span className="logo-sm">
                <img src={logoSm} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img
                  src={
                    layoutType === LayoutTypes.LAYOUT_TWO_COLUMN
                      ? logoLight2
                      : logoLight
                  }
                  alt=""
                  height="50"
                />
              </span>
            </Link>
          </div>
        )}

        {!isCondensed && (
          <SimpleBar
            className="scrollbar show h-100"
            // style={{ maxHeight: '100%' }}
            // timeout={500}
            scrollbarMaxSize={320}
          >
            <SideBarContent />
          </SimpleBar>
        )}
        {isCondensed && <SideBarContent />}
      </div>

      {
        modalShow &&
        <ModalLogo
          show={modalShow}
          onHide={closeModal}
        />
      }
    </React.Fragment>
  );
};

LeftSidebar.defaultProps = {
  isCondensed: false,
};

export default LeftSidebar;
