import React, { useEffect, useState } from "react";
import { Row, Col, Card, Tab, Nav, ListGroup, Button, Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";
import moment from "moment";
import { FormSelect, BadgeStatus } from "components"
import Add from "./Add";
import { MdOutlineSettings } from "react-icons/md";
import { Navigate, Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ScaleLoader from "components/ScaleLoader";

import {
  useGetBranchQuery,
  useGetExpoQuery,
  useAssignExpoMutation,
} from "services/api";

// components
import PageTitle from "../../../../components/PageTitle";
import Messages from "../../../../components/Messages";
import CardItem from "pages/LMS/Event/CardItem";

import Header from "./Header";
import LeadsList from "./LeadsList";
import { toast } from "react-toastify";

export declare type SelectCallback = (
  eventKey: string | null,
  e: React.SyntheticEvent<unknown>,
) => void;

const eventStatuses = [
  'Unassigned',
  'In Progress',
  'Hot',
  'Prospect',
  'Future Prospect',
  'Non-prospect',
  'Aborted',
]

const validation = yupResolver(
  yup.object().shape({
    branch_code: yup.string().required('Branch must be filled'),
  })
);

const Detail = () => {
  const navigate = useNavigate();
  let { eventId, tabName } = useParams();

  const [activeTab, setActiveTab] = useState(tabName || 'registrant');
  const [layout, setLayout] = useState('default');
  const [status, setStatus] = useState('');

  const [search, setSearch] = useState('');

  const [checked, setChecked] = useState<any[]>(() => [])

  const [registrantCount, setRegistrantCount] = useState(0);
  const [attendeestCount, setAttendeestCount] = useState(0);
  const [unhandledtCount, setUnhandledtCount] = useState(0);
  
  const [showModalAdd, setShowModalAdd] = useState<boolean>(false);
  const onCloseModal = () => setShowModalAdd(false);
  const onOpenModalAdd = () => setShowModalAdd(true);
  const onHandleSubmit = () => {
    onCloseModal();
  };

  const [assignExpo, { isLoading: isLoadingAssign }] = useAssignExpoMutation();
  
  const methods = useForm({ resolver: validation });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
    reset: resetForm,
  } = methods;

  const onSubmit = async (values: any) => {
    // try {
      await assignExpo({
        ...getValues(),
        leads: (checked || []).map(item => {
          return {
            code: item.code,
          }
        }),
      })
        .unwrap()
        .then((res: any) => {
          if(res.status){
            toast.success(res.message)
          }
          console.log(res)
          setChecked([]);
          // refetchLeads()
          // onCloseDetail()
        })
        .then((error: any) => {
          // console.log("error");
        });
    // } catch (err: any) {
    //   if (err.data.errors) {
    //     Object.keys(err.data.errors)?.forEach((field) => {
    //       err.data.errors[field]?.forEach((message: string) => {
    //         toast.error(message, { autoClose: 10000 });
    //       });
    //     });
    //   }
    // }
  };

  const {
    data: branchList,
    isFetching: isFetchingBranch,
    isLoading: isLoadingBranch,
    isUninitialized: isUninitializedBranch,
    error: errorBranch,
    isError: isErrorBranch,
    refetch: refetchBranch,
  } = useGetBranchQuery(
    {},
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  const { expo, isUninitialized: isUninitializedEvent, isLoading: isLoadingEvent, isFetching: isFetchingEvent, isError: isErrorEvent } = useGetExpoQuery(
    {
      code: [eventId]
    },
    {
      selectFromResult: ({ data, isUninitialized, isLoading, isFetching, isError }) => ({
        expo: data?.result
          ? data.result.find((el: any) => el.code == eventId)
          : null,
        isUninitialized : isUninitialized ,
        isLoading: isLoading,
        isFetching: isFetching,
        isError: isError,
      }),
      // refetchOnMountOrArgChange: true,
      skip: eventId ? false : true,
    }
  );

  const onSelectTab = (eventKey: any, e: any) => {
    setActiveTab(eventKey)
    // e.stopPropagation();
    // e.preventDefault();
    navigate(`/lms/events/${eventId}/${eventKey}`)
  }

  useEffect(() => {
    let loading = true;
    if(checked.length){
      resetForm()
      setLayout('selecting')
    } else {
      setLayout('default')
    }
    return () => {
      loading = false;
    }
  }, [checked])
  
  if(isUninitializedEvent || isLoadingEvent || isFetchingEvent || isErrorEvent){
    return (
      <div style={{height: 'calc(100vh - 130px)'}}>
        <ScaleLoader />
      </div>
    )
  }

  return (
    <>
      <Row className="mt-3">
        <Col>
          {/* <Header data={expo} /> */}
          <CardItem item={expo} />
        </Col>
      </Row>

      <Tab.Container
        defaultActiveKey={activeTab}
        onSelect={(eventKey: string | null, e: React.SyntheticEvent<unknown>) =>
          onSelectTab(eventKey, e)
        }
      >
        <Card className="mb-2">
          <Card.Body>
            <div className="d-flex flex-wrap justify-content-between align-items-center">
              <div className="flex-grow-1 flex-sm-shrink-2">
                <label htmlFor="inputPassword2" className="visually-hidden">
                  Search
                </label>
                <div className="me-3">
                  <input
                    type="search"
                    className="form-control my-1 my-lg-0"
                    id="inputPassword2"
                    placeholder="Search..."
                    onChange={(e: any) => setSearch(e.target.value)}
                    style={{minWidth: '220px'}}
                  />
                </div>
              </div>
              <div className="d-flex flex-nowrap">
                <Nav variant="pills" as="ul" className="nav nav-pills navtab-bg">
                  <Nav.Item as="li" className="nav-item" style={{width: '11rem'}} onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation()
                  }}>
                    <Nav.Link
                      href="#"
                      eventKey="registrant"
                      className="nav-link cursor-pointer"
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <i className="mdi mdi-book-arrow-up-outline me-1"></i>
                          Registrant
                        </div>
                        <div className="text-dark ms-1 badge bg-light">{registrantCount}</div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="nav-item mt-1 mt-sm-auto" style={{width: '11rem'}} onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation()
                  }}>
                    <Nav.Link
                      href="#"
                      eventKey="attendees"
                      className="nav-link cursor-pointer"
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <i className="mdi mdi-book-marker me-1"></i>Attendees
                        </div>
                        <div className="text-dark ms-1 badge bg-light">{attendeestCount}</div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item as="li" className="nav-item" style={{width: '11rem'}} onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation()
                  }}>
                    <Nav.Link
                      href="#"
                      eventKey="unassigned"
                      className="nav-link cursor-pointer"
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <i className="mdi mdi-book-account-outline me-1"></i>
                          Unassigned
                        </div>
                        <div className="text-dark ms-1 badge bg-light">{unhandledtCount}</div>
                      </div>
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>
              </div>
              {/* <div className="text-lg-end mt-3 mt-lg-0">
                <Button
                  variant="dark"
                  className="waves-effect waves-light"
                >
                  <div className=" d-flex align-items-center justify-content-center gap-1">
                    <MdOutlineSettings className="font-16"/>
                    <span>Bulk Assignment</span>
                  </div>
                </Button>
                <Button
                  variant="success"
                  className="waves-effect waves-light"
                  onClick={onOpenModalAdd}
                >
                  <i className="mdi mdi-plus-circle me-1"></i> Add Participant
                </Button>
              </div> */}
            </div>
          </Card.Body>
        </Card>

        <Row>
          <Col
            xl={layout == "default" ? 12 : 8}
            lg={layout == "default" ? 12 : 8}
          >
            <Tab.Content className="pt-0">
              <Tab.Pane eventKey="registrant">
                <LeadsList
                  type="expo-registrant"
                  search={search}
                  setSearch={setSearch}
                  checked={checked}
                  setChecked={setChecked}
                  layout={layout}
                  setLayout={setLayout}
                  status={eventStatuses}
                  setCount={setRegistrantCount}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="attendees">
                <LeadsList
                  type="expo-attendees"
                  search={search}
                  setSearch={setSearch}
                  checked={checked}
                  setChecked={setChecked}
                  layout={layout}
                  setLayout={setLayout}
                  status={eventStatuses}
                  orderBy="created_at"
                  sortBy="desc"
                  setCount={setAttendeestCount}
                />
              </Tab.Pane>
              {/* <Tab.Pane eventKey="unassigned">
                <LeadsList
                  type="expo-unassigned"
                  checked={checked}
                  setChecked={setChecked}
                  layout={layout}
                  setLayout={setLayout}
                  status={eventStatuses}
                  orderBy="created_at"
                  sortBy="desc"
                  setCount={setUnhandledtCount}
                  canAssign
                />
              </Tab.Pane> */}
            </Tab.Content>
          </Col>
          <Col xl={4} lg={4}>
            {layout == "selecting" && (
              <Card className="sticky-top" style={{ top: "80px" }}>
                <Card.Body>
                  <span
                    className="float-end noti-close-btn text-muted cursor-pointer"
                    onClick={() => {setLayout('default'); setChecked([])}}
                  >
                    <i className="mdi mdi-close"></i>
                  </span>
                  <div className="font-16 fw-bold text-dark">
                    Selected Leads
                  </div>
                  <form
                    onSubmit={handleSubmit((values: any) => onSubmit({...values}))}
                    noValidate
                  >
                    <Form.Group>
                      <Form.Label className="mb-2" htmlFor="Button">Assign Selected Leads to branch</Form.Label>
                      <div className="d-flex flex-row gap-1">
                        <div className="flex-fill">
                          <FormSelect
                            type="text"
                            name="branch_code"
                            placeholder=""
                            containerClass={"mb-3"}
                            // defaultValue={getValues()?.branch_code || undefined}
                            options={(branchList.result || []).map((item: any) => {
                              return {
                                label: `${item.code} - ${item.name}`,
                                value: item.code,
                              };
                            })}
                            // disabled={isLoadingExport}
                            register={register}
                            control={control}
                            errors={errors}
                            setValue={setValue}
                            clearErrors={clearErrors}
                            // onChange={(date: any) => {
                            //   onChangeFilterDate(date);
                            // }}
                          />
                        </div>
                        <div>
                          <Button
                            variant="primary"
                            className="waves-effect waves-light"
                            type="submit"
                          >
                            Assign
                          </Button>
                        </div>
                      </div>
                    </Form.Group>
                  </form>
                  <SimpleBar autoHide={false} style={{ height: "calc(100vh - 400px)" }} className="border-top border-2 border-primary mt-3">
                    {(checked || []).reverse().map((item) => (
                      <div className="d-flex align-items-center border-bottom border-1 py-1 my-2">
                        <div className="w-100">
                          <h5 className="mt-0 mb-0 font-14">{item?.full_name} <BadgeStatus name={item?.status_name} group="Event"/><span className="fw-semibold">#{item?.code}</span></h5>
                          <div className="text-truncate">
                            <span className="fw-semibold">{item?.email}</span>
                            <span className="px-1">|</span>
                            <span className="fw-semibold">{item?.phone}</span>
                            <span className="px-1">|</span>
                            <span className="fw-semibold">{moment(item?.dob).format('DD MMM YYYY')}</span>
                            <span className="px-1">|</span>
                            <span className="fw-semibold">{item?.program_interested}</span>
                          </div>
                          {/* <p className="mb-0">
                            <b>Location:</b> {item?.location}
                          </p>
                          <p className="mb-0">
                            <b>Rank: #</b> {Math.floor(Math.random() * 10)}
                          </p> */}
                        </div>
                      </div>
                    ))}
                  </SimpleBar>
                </Card.Body>
              </Card>
            )}
            {/* <Card>
              <Card.Body>
                <h4 className="header-title mb-3">Branches</h4>
                <ListGroup>
                  <ListGroup.Item href="#" action className="active">
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Tangerang</span>
                      <div className="px-1 badge-outline-light badge rounded">0</div>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item href="#" action>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Jakarta Utara</span>
                      <div className="px-1 badge-outline-light badge rounded">0</div>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item as="button" action>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Jakarta Selatan</span>
                      <div className="px-1 badge-outline-light badge rounded">0</div>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item as="button" action>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Jakarta Timur</span>
                      <div className="px-1 badge-outline-light badge rounded">0</div>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item as="button" action>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Jakarta Barat</span>
                      <div className="px-1 badge-outline-light badge rounded">0</div>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card> */}

            {/* User's recent messages */}
            {/* <Messages /> */}
          </Col>
        </Row>
      </Tab.Container>
      {
        showModalAdd &&
        <Add
          show={showModalAdd}
          onHide={onCloseModal}
          onSubmit={onHandleSubmit}
        />
      }
    </>
  );
};

export default Detail;
