import rootReducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { api } from "services/api";
import localforage from 'localforage';

// import { api } from 'features/api'

import { setupListeners } from "@reduxjs/toolkit/query";

const persistConfig = {
  key: "lms",
  // keyPrefix: "",
  storage,
  // storage: localforage,
  // whitelist: ['auth']
  blacklist: ['api']
};

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer()),
  devTools: process.env.NODE_ENV !== "production",
  //   reducer: {
  //     // Add the generated reducer as a specific top-level slice
  //     [api.reducerPath]: api.reducer,
  //   },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(api.middleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
