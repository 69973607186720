import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Card, Tab, Nav, Button, OverlayTrigger, Tooltip, ListGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import ScaleLoader from "components/ScaleLoader";
import { LuPackageOpen } from "react-icons/lu";

import {
  useGetAttachmentQuery,
} from "services/api";

import { toast } from "react-toastify";
import { IoWarningOutline } from "react-icons/io5";

interface ModalManageProps {
  show: boolean;
  type?: any;
  data?: any;
  onHide: () => void;
  refetch?: any;
}

const validation = yupResolver(
  yup.object().shape({
  })
);

const AttachmentItem = ({data, refetch} : {data: any, refetch: any}) => {
  const ext = data.name.substr(data.name.lastIndexOf(".") + 1);
  const [layout, setLayout] = useState('default');

  return (
      <Row className="align-items-center">
        <Col className="col-auto">
          <div className="avatar-sm">
            <span className="avatar-title badge-soft-primary text-primary rounded">
              .{ext}
            </span>
          </div>
        </Col>
        <Col className="ps-0">
          <Link to="#" className="text-muted fw-semibold">
            {data.name}
          </Link>
          {/* <p className="mb-0 font-12">{data.name}</p> */}
          <p className="mb-0 font-10 fst-italic">{data.date}</p>
        </Col>
        <Col className="col-auto">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip style={{position:"fixed"}}>Download</Tooltip>}
          >
            <Link
              to=""
              id="btn-download"
              className="btn btn-link font-16 text-muted"
              onClick={() => {
                window.open(data.url, "_blank");
              }}
            >
              <i className="dripicons-download"></i>
            </Link>
          </OverlayTrigger>
        </Col>
      </Row>
  )
}


const ModalManage = ({
  show,
  type,
  data,
  onHide,
  refetch,
}: ModalManageProps) => {
  const {
    data: attachmentList,
    isFetching: isFetchingAttachment,
    isLoading: isLoadingAttachment,
    isUninitialized: isUninitializedAttachment,
    isError: isErrorAttachment,
    error: errorAttachment,
    refetch: refetchAttachment,
  } = useGetAttachmentQuery(
    {},
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  return (
    <>
      <Modal
        backdrop="static"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header onHide={onHide} closeButton>
          <Modal.Title className="m-0">
            <h4 className="modal-title">Essentials Docs</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
           {
             (isFetchingAttachment || isLoadingAttachment || isUninitializedAttachment || isErrorAttachment) ? (
                <div className="d-flex justify-content-center align-items-center" style={{minHeight: '200px'}}>
                  <ScaleLoader height={20} width={1} color="#b7b1dd" />
                </div>
             ) : (
               <>
                  {!isLoadingAttachment && attachmentList?.result?.length == 0 ? (
                    <div
                      className="d-flex flex-column justify-content-center align-items-center mb-3"
                      style={{ minHeight: "200px" }}
                    >
                      <LuPackageOpen size={32} />
                      <p className="text-muted mt-1 mb-0">No data</p>
                    </div>
                  ) : (
                    <>
                    <ListGroup variant="flush">
                      {(attachmentList?.result || []).map(
                        (item: any, index: number) => {
                          return (
                            <ListGroup.Item>
                              <AttachmentItem
                                data={item}
                                refetch={() => {
                                  // refetchDocument();
                                }}
                              />
                            </ListGroup.Item>
                          );
                        }
                      )}
                    </ListGroup>
                    </>
                  )}
               </>
             )
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalManage;
