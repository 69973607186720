import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ScaleLoader from "components/ScaleLoader";

// components
import { VerticalForm, FormSelect, FormSelectAsync, FormInput, FormDate, SingleUpload } from "components/";

import {
  useGetGradeQuery,
  useGetKotaQuery,
  // useSearchKotaMutation,
  useCreateSchoolMutation,
  useUpdateSchoolMutation,
  useDeleteSchoolMutation,
} from "services/api";

import { toast } from "react-toastify";
import { IoWarningOutline } from "react-icons/io5";

interface ModalManageProps {
  show: boolean;
  type: string;
  data?: any;
  onHide: () => void;
}

const ModalManage = ({
  show,
  type,
  data,
  onHide,
}: ModalManageProps) => {
  const [logo, setLogo] = useState<File>();
  // const [selectedArea, setSelectedArea] = useState<File>();
  // const [kotaOptions, setKotaOptions] = useState([]);
  // const [defaultKota, setDefaultKota] = useState<object>();

  // const [searchKota, { isLoading: isLoadingSearch }] = useSearchKotaMutation();
  const [createSchool, { isLoading: isLoadingCreate }] = useCreateSchoolMutation();
  const [updateSchool, { isLoading: isLoadingUpdate }] = useUpdateSchoolMutation();
  const [deleteSchool, { isLoading: isLoadingDelete }] = useDeleteSchoolMutation();

  // const {
  //   data: gradeList,
  //   isFetching: isFetchingGrade,
  //   isLoading: isLoadingGrade,
  //   isUninitialized: isUninitializedGrade,
  //   error: errorGrade,
  //   isError: isErrorGrade,
  //   refetch: refetchGrade,
  // } = useGetGradeQuery(
  //   {
  //     'response_type': 'select-option'
  //   },
  //   {
  //     // pollingInterval: 100,
  //     // refetchOnMountOrArgChange: true,
  //     // refetchOnFocus: true,
  //     // refetchOnReconnect: true,
  //     // skip: false,
  //   }
  // );

  // const {
  //   data: kotaList,
  //   isFetching: isFetchingKota,
  //   isLoading: isLoadingKota,
  //   isUninitialized: isUninitializedKota,
  //   error: errorKota,
  //   isError: isErrorKota,
  //   refetch: refetchKota,
  // } = useGetKotaQuery(
  //   {
  //     'response_type': 'select-option',
  //     search: data?.kota_name.split(' ')[0]
  //   },
  //   {
  //     // pollingInterval: 100,
  //     // refetchOnMountOrArgChange: true,
  //     // refetchOnFocus: true,
  //     // refetchOnReconnect: true,
  //     // skip: false,
  //   }
  // );

  // const {
  //   data: selectedKotaList,
  //   // isFetching: isFetchingKota,
  //   isLoading: isLoadingSelectedKota,
  //   // isUninitialized: isUninitializedKota,
  //   // error: errorKota,
  //   // isError: isErrorKota,
  //   // refetch: refetchKota,
  // } = useGetKotaQuery(
  //   {
  //     'response_type': 'select-option',
  //     'selected_option': String(data?.coverage_area).split(',') || [],
  //   },
  //   {
  //     // pollingInterval: 100,
  //     // refetchOnMountOrArgChange: true,
  //     // refetchOnFocus: true,
  //     // refetchOnReconnect: true,
  //     // skip: false,
  //   }
  // );

  const validation = yupResolver(
    yup.object().shape({
      name: yup.string().required(''),
      city_province_name: yup.string().required(''),
      country_name: yup.string().required(''),
      // grade_code: yup.string().required(''),
      // kota_id: yup.string().required(''),
    })
  );
  
  const methods = useForm({ resolver: validation });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
    reset: resetForm,
  } = methods;

  const onSubmit = async (values: any) => {
    try {
      let formData = new FormData();
      Object.keys(values).forEach((key: any) => {
        if(values[key]){
          formData.append(key, values[key]);
        }
      });
      if(logo) {
        formData.append("logo", logo)
      } else {
        formData.delete("logo")
      }

      switch (type) {
        case 'add':
          await createSchool(formData)
          .unwrap()
          .then((res) => {
            if(res.status){
              onHide()
              toast.success(res.message)
            }
          })
          .catch((error: any) => {
            console.log("error");
          });
          break;

        case 'edit':
          await updateSchool(formData)
            .unwrap()
            .then((res) => {
              if(res.status){
                onHide()
                toast.success(res.message)
              }
            })
            .catch((error: any) => {
              console.log("error");
            });
          break;
          
        case 'delete':
          await deleteSchool(formData)
            .unwrap()
            .then((res) => {
              if(res.status){
                onHide()
                toast.success(res.message)
              }
            })
            .catch((error: any) => {
              console.log("error");
            });
          break;
      
        default:
          break;
      }
    } catch (err: any) {
      console.log(err);
      // if (err?.data?.errors) {
      //   Object.keys(err?.data?.errors)?.forEach((field) => {
      //     err.data.errors[field]?.forEach((message: string) => {
      //       toast.error(message, { autoClose: 10000 });
      //     });
      //   });
      // }
    }
  };

  const onDelete = async () => {
    try {
      await deleteSchool({
        code: data?.code
      })
        .unwrap()
        .then((res) => {
          if(res.status){
            onHide()
            toast.success(res.message)
          }
        })
    } catch (err: any) {
      console.log(err);
      // if (err?.data?.errors) {
      //   Object.keys(err?.data?.errors)?.forEach((field) => {
      //     err.data.errors[field]?.forEach((message: string) => {
      //       toast.error(message, { autoClose: 10000 });
      //     });
      //   });
      // }
    }
  };

  // const loadOptions = (
  //   inputValue: string,
  //   callback: (options: any) => void
  // ) => {
  //   // if(inputValue.length >= 2 ){
  //     searchKota({
  //       search: inputValue,
  //       response_type: 'select-option',
  //     })
  //       .unwrap()
  //       .then((res: any) => {
  //         callback(res?.result);
  //       })
  //       .then((error: any) => {
  //         console.log("error");
  //         callback([]);
  //       });
  //   // }
  // };

  // useEffect(() => {
  //   console.log(errors)
  // }, [errors])

  useEffect(() => {
    // reset();
    // setCurrent(data);
    setValue('type', type);
    if (data) {
      Object.keys(data).map((key) => {
        setValue(key, data[key]);
      });
      // setDefaultKota({
      //   value: data?.kota_id,
      //   label: data?.kota_name
      // })
      // console.log(data?.kota_id)
    } else {
      // setDefaultKota(undefined)
    }
  }, []);

  return (
    <>
      <Modal
        backdrop="static"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {
          type != 'delete' &&
          <Modal.Header className="bg-light" onHide={onHide} closeButton>
            <Modal.Title className="m-0">
              {`${type} School`.toUpperCase()}{" "}
            </Modal.Title>
          </Modal.Header>
        }
        <Modal.Body className="p-4">
        {
          // (isLoadingKota && !isLoadingSelectedKota) ? (
          //   <div className="d-flex justify-content-center align-items-center" style={{minHeight: '200px'}}>
          //     <ScaleLoader height={20} width={1} color="#b7b1dd" />
          //   </div>
          // ) : (
            <form
              onSubmit={handleSubmit((values: any) => onSubmit({ ...values }))}
              noValidate
            >
              {(type == "add" || type == "edit") && (
                  <>
                    <FormInput
                      register={register}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      label="School Name"
                      type="text"
                      name="name"
                      placeholder=""
                      containerClass={"mb-3"}
                    />
                    {/* <FormSelect
                      label="Grade"
                      name="grade_code"
                      placeholder=""
                      containerClass={"mb-3"}
                      defaultValue={data?.grade_code || undefined}
                      options={(gradeList?.result || []).map((item: any) => {
                        console.log(data?.grade_code == item.code)
                        return {
                          label: item.name,
                          value: item.code,
                        };
                      })}
                      // disabled={isLoading}
                      register={register}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      // onChange={(opt: any) => {
                      //   setCalcMarketingBranding(opt.value);
                      // }}
                    /> */}
                    <FormInput
                      register={register}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      label="City & Province"
                      type="text"
                      name="city_province_name"
                      placeholder=""
                      containerClass={"mb-3"}
                    />
                    <FormInput
                      register={register}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      label="Country"
                      type="text"
                      name="country_name"
                      placeholder=""
                      containerClass={"mb-3"}
                    />
                    {/* <FormSelectAsync
                      label="Kota & Provinsi"
                      name="kota_id"
                      placeholder=""
                      containerClass={"mb-3"}
                      defaultValue={data?.kota_id ? {
                        value: data?.kota_id,
                        label: data?.kota_name
                      } : undefined}
                      defaultOptions={data?.kota_id ? [{
                        value: data?.kota_id,
                        label: data?.kota_name
                      }] : kotaList?.result}
                      loadOptions={loadOptions}
                      // disabled={isLoading}
                      register={register}
                      control={control}
                      errors={errors}
                      // setValue={setValue}
                      setError={setError}
                      clearErrors={clearErrors}
                      onChange={(option: any) => {
                        console.log(option)
                        setValue('kota_id', option.value)
                      }}
                      isClearable
                    /> */}
                    <div className="text-end">
                      <Button
                        variant="success"
                        type="submit"
                        className="waves-effect waves-light me-1"
                        disabled={isLoadingCreate || isLoadingUpdate || isLoadingDelete}
                      >
                        Submit
                      </Button>
                    </div>
                  </>
                )
              }
              {type == "delete" && (
                <div className="text-center">
                  <div onClick={onHide} className="position-absolute top-0 end-0 m-2 cursor-pointer">
                    <i className="mdi mdi-close font-22"/>
                  </div>
                  <IoWarningOutline size={64} className="text-danger"/>
                  <h4 className="mt-2">Please confirm your action</h4>
                  <p className="mt-3 mb-0">
                    Are you sure to delete <strong>{data.name}</strong>?
                  </p>
                  <p>This action cannot be undone.</p>
                  <Button
                    variant="danger"
                    type="button"
                    className="waves-effect waves-light me-1"
                    disabled={isLoadingCreate || isLoadingUpdate || isLoadingDelete}
                    onClick={() => {onDelete()}}
                  >
                    Confirm
                  </Button>
                </div>
              )}
            </form>
          // )
        }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalManage;
