import React, { useEffect, useState } from "react";
import { useEffectOnce } from "usehooks-ts";
import { Card, Row, Col, Button, Nav, Tab, Form, Alert } from "react-bootstrap";
import { Navigate, Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import moment from 'moment';
import SimpleBar from "simplebar-react";
import { useParams } from "react-router-dom";
import ScaleLoader from "components/ScaleLoader";
import { HasAccess } from "@permify/react-role";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/store";
import { toast } from "react-toastify";

// components
import Select from "react-select";
import List from "./List";
import Detail from "./Detail";

// dummy data
import { companyInfo } from "./data";

import {
  useGetBranchQuery,
  useGetStatusQuery,
  useGetUserQuery,
  useGetApplicationQuery,
  useExportApplicationMutation,
} from "services/api";

const Application = () => {
  const navigate = useNavigate();
  let { tabName = 'All', applicationCode } = useParams();

  const { user } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      roles: state.Auth.user.roles,
    })
  );

  // const [current, setCurrent] = useState<ApplicationProps>();
  const [current, setCurrent] = useState();
  const [activeTab, setActiveTab] = useState(tabName || 'All');
  const [layout, setLayout] = useState('default');
  const [showModalAdd, setModalAdd] = useState<boolean>(false);
  const [allApplicationCount, setAllApplicationCount] = useState(0);
  const [applicationCount, setApplicationCount] = useState({});

  const [checked, setChecked] = useState<any[]>(() => [])

  const [search, setSearch] = useState("");
  const [sort, setSort] = useState<object>({});
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [filterStatus, setFilterStatus] = useState<null | string>();
  const [filterBranch, setFilterBranch] = useState<[]>();
  const [filterCounselor, setFilterCounselor] = useState<[]>();

  const {
    data: branchList,
    isFetching: isFetchingBranch,
    isLoading: isLoadingBranch,
    isUninitialized: isUninitializedBranch,
    isError: isErrorBranch,
    error: errorBranch,
    refetch: refetchBranch,
  } = useGetBranchQuery(
    {},
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );
  
  const {
    data: statusList,
    isFetching: isFetchingStatus,
    isLoading: isLoadingStatus,
    isUninitialized: isUninitializedStatus,
    isError: isErrorStatus,
    error: errorStatus,
    refetch: refetchStatus,
  } = useGetStatusQuery(
    {
      group: 'Application',
    },
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );
  
  const {
    data: counselorList,
    isFetching: isFetchingCounselor,
    isLoading: isLoadingCounselor,
    isUninitialized: isUninitializedCounselor,
    error: errorCounselor,
    isError: isErrorCounselor,
    refetch: refetchCounselor,
  } = useGetUserQuery(
    {
      search: {
        role_name: 'Counselor'
      },
      branch_code: user?.branch_code ? [user?.branch_code] : filterBranch
    },
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  const {
    data: applicationList,
    isFetching: isFetchingApplication,
    isLoading: isLoadingApplication,
    isUninitialized: isUninitializedApplication,
    isError: isErrorApplication,
    error,
    refetch: refetchApplication,
  } = useGetApplicationQuery(
    {
      // type,
      search,
      branch_code: filterBranch,
      // expo_code: eventId,
      status_code: filterStatus,
      counselor_code: filterCounselor,
      // order_by: orderBy,
      // sort_by: sortBy,
      page: activePage,
      per_page: perPage,
      with:[ 'leads'],
    },
    {
      // pollingInterval: 100,
      refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  const [exportApplication, { isLoading: isLoadingExport }] = useExportApplicationMutation();

  const resetPage = () => {
    setLayout('default')
    setCurrent(undefined)
    setSearch("")
    setSort({})
    setActivePage(1)
    // setPerPage(10)
    setFilterStatus(null)
    // calculateCount()
  }

  const resetLayout = () => {
    setLayout('default')
    setCurrent(undefined)
  }

  const onClickExport = async () => {
    toast.info('Processing your request...', {hideProgressBar: true})
    await exportApplication({
      // type,
      search,
      branch_code: filterBranch,
      // expo_code: eventId,
      status_code: filterStatus,
      // order_by: orderBy,
      // sort_by: sortBy,
      page: activePage,
      per_page: perPage,
    })
      .unwrap()
      .then((res: any) => {
        toast.dismiss();
        if(res?.result?.url){
          // toast.success(res.message)
          window.open(res?.result?.url, "_blank");
        }
      })
      .catch((error: any) => {
        toast.dismiss();
        // console.log("error");
      });
  };

  return (
    <>
      <Card
        className={classNames(
          "mb-2 mt-3",
          layout == "with-detail" && "d-none d-sm-block"
        )}
       >
        <Card.Body>
          <div className="d-flex flex-wrap justify-content-between gap-1">
            <div className="">
              <div className="d-flex flex-wrap justify-content-start align-items-center gap-1">
                <div className="flex-shrink-1">
                  <label htmlFor="inputPassword2" className="visually-hidden">
                    Search
                  </label>
                  <div className="me-3 w-100">
                    <input
                      type="search"
                      className="form-control my-1 my-lg-0"
                      id="inputPassword2"
                      placeholder="Search application..."
                      onChange={(e: any) => setSearch(e.target.value)}
                      value={search}
                    />
                  </div>
                </div>
                <div className="flex-grow-1">
                  <div className="me-e w-100" style={{minWidth: '200px'}}>
                    <Select
                      className="react-select react-select-container"
                      classNamePrefix="react-select"
                      placeholder="Filter by Status..."
                      styles={{
                        control: (provided: any, state: any) => ({
                          ...provided,
                          boxShadow: "none",
                          border: "1px solid #dee2e6",
                          color: "#6c757d",
                          "&:hover": {
                            border: "1px solid #dee2e6",
                          }
                        }),
                      }}
                      // defaultValue={options && defaultValue ? options.find((opt: any) => opt.value == defaultValue) : null}
                      options={(statusList?.result || []).map((item: any) => {
                        return {
                          label: item.name,
                          value: item.code,
                        };
                      })}
                      onChange={(opt: any, actionType: any) => {
                        resetLayout()
                        let statusCodes = (opt || []).map((item: any) => {
                          return item.value;
                        })
                        setActivePage(1)
                        setFilterStatus(statusCodes)
                      }}
                      isClearable
                      isMulti
                      // isDisabled={isDisabled}
                    ></Select>
                  </div>
                </div>
                <HasAccess
                    permissions={["filter-branch"]} 
                >
                  <div className="flex-grow-1">
                    <div className="me-e w-100" style={{minWidth: '200px'}}>
                      <Select
                        className="react-select react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Filter by Branch..."
                        styles={{
                          control: (provided: any, state: any) => ({
                            ...provided,
                            boxShadow: "none",
                            border: "1px solid #dee2e6",
                            color: "#6c757d",
                            "&:hover": {
                              border: "1px solid #dee2e6",
                            }
                          }),
                        }}
                        // defaultValue={options && defaultValue ? options.find((opt: any) => opt.value == defaultValue) : null}
                        options={(branchList?.result || []).map((item: any) => {
                          return {
                            label: item.name,
                            value: item.code,
                          };
                        })}
                        onChange={(opt: any, actionType: any) => {
                          resetLayout()
                          let branchCodes = (opt || []).map((item: any) => {
                            return item.value;
                          })
                          setActivePage(1)
                          setFilterBranch(branchCodes)
                        }}
                        isClearable
                        isMulti
                        // isDisabled={isDisabled}
                      ></Select>
                    </div>
                  </div>
                </HasAccess>
                <HasAccess
                    permissions={["filter-counselor"]} 
                >
                  <div className="flex-grow-1">
                    <div className="me-e w-100" style={{minWidth: '200px'}}>
                      <Select
                        className="react-select react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Filter by Counselor..."
                        styles={{
                          control: (provided: any, state: any) => ({
                            ...provided,
                            boxShadow: "none",
                            border: "1px solid #dee2e6",
                            color: "#6c757d",
                            "&:hover": {
                              border: "1px solid #dee2e6",
                            }
                          }),
                        }}
                        // defaultValue={options && defaultValue ? options.find((opt: any) => opt.value == defaultValue) : null}
                        options={(counselorList?.result || []).map((item: any) => {
                          return {
                            label: item.name,
                            value: item.code,
                          };
                        })}
                        onChange={(opt: any, actionType: any) => {
                          resetLayout()
                          let counselorCodes = (opt || []).map((item: any) => {
                            return item.value;
                          })
                          setActivePage(1)
                          setFilterCounselor(counselorCodes)
                        }}
                        isClearable
                        isMulti
                        // isDisabled={isDisabled}
                      ></Select>
                    </div>
                  </div>
                </HasAccess>
              </div>
            </div>
            <div className="">
              <div className="d-flex mt-1 mt-lg-0 gap-1">
                {/* <Button
                  variant="success"
                  className="waves-effect waves-light"
                  onClick={onOpenModal}
                >
                  <i className="mdi mdi-plus-circle me-1"></i> Add New
                </Button> */}
                <HasAccess
                  permissions={["export-application"]} 
                >
                  <Button
                    variant="dark"
                    className="waves-effect waves-light"
                    onClick={(e) => onClickExport()}
                    disabled={isLoadingExport}
                  >
                    <div className="d-flex">
                      <i className="mdi mdi-file-export me-1"></i> 
                      <span>Export</span>
                    </div>
                  </Button>
                </HasAccess>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    
      <Row>
        <Col
          xxl={layout == "default" ? 12 : 8}
          xl={layout == "default" ? 12 : 7}
          lg={layout == "default" ? 12 : 7}
        >
          <List
            companyInfo={companyInfo}
            isLoading={isFetchingApplication || isLoadingApplication || isUninitializedApplication || isErrorApplication}
            data={applicationList || []}
            current={current}
            setCurrent={setCurrent}
            search={search}
            setSearch={setSearch}
            filterBranch={filterBranch}
            setFilterBranch={setFilterBranch}
            sort={sort}
            setSort={setSort}
            activePage={activePage}
            setActivePage={setActivePage}
            perPage={perPage}
            setPerPage={setPerPage}
            setLayout={setLayout}
            layout={layout}
            // isChecked={(item: any) => isSelectedCard(item)}
            // setIsChecked={(item: any, status: boolean) => onSelectCard(item, status)}
            // applicationCount={applicationCount}
            // setApplicationCount={setApplicationCount}
          />
        </Col>
        <Col xxl={4} xl={5} lg={5}>
          {layout == "with-detail" && (
            <Detail
              current={current}
              setCurrent={setCurrent}
              layout={layout}
              setLayout={setLayout}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default Application;
