import React from "react";
import { Card } from "react-bootstrap";

const Biodata = ({data}:{data: any}) => {
  return (
    <Card className="text-center">
      <Card.Body>
        <img
          src={data.avatar}
          className="rounded-circle avatar-lg img-thumbnail"
          alt=""
        />
        <h4 classNamefst-italic ="mb-0">{data.full_name || '-'}</h4>
        <p className="text-muted">#{data.code || '-'}</p>
        {/* <button
          type="button"
          className="btn btn-success btn-xs waves-effect mb-2 waves-light"
        >
          Follow
        </button>{" "}
        <button
          type="button"
          className="btn btn-danger btn-xs waves-effect mb-2 waves-light"
        >
          Message
        </button> */}
        <div className="text-start mt-3">
          <div>
            <h5 className="font-12 mb-0">Leads ID :</h5>
            <p className="mb-2">
              #{data.code || '-'}
            </p>
          </div>
          <div>
            <h5 className="font-12 mb-0">Title :</h5>
            <p className="mb-2">
              {data.title || '-'}
            </p>
          </div>
          <div>
            <h5 className="font-12 mb-0">Name :</h5>
            <p className="mb-2">
              {data.full_name || '-'}
            </p>
          </div>
          <div>
            <h5 className="font-12 mb-0">Email :</h5>
            <p className="mb-2">
              {data.email || '-'}
            </p>
          </div>
          <div>
            <h5 className="font-12 mb-0">Date of Birth :</h5>
            <p className="mb-2">
              {data.dob || '-'}
            </p>
          </div>
          <div>
            <h5 className="font-12 mb-0">Phone :</h5>
            <p className="mb-2">
              {data.phone || '-'}
            </p>
          </div>
          <div>
            <h5 className="font-12 mb-0">Address :</h5>
            <p className="mb-2">
              {data.address || '-'}
            </p>
          </div>
          <div>
            <h5 className="font-12 mb-0">City :</h5>
            <p className="mb-2">
              {data.city_name || '-'}
            </p>
          </div>
          <div>
            <h5 className="font-12 mb-0">Province :</h5>
            <p className="mb-2">
              {data.province_name || '-'}
            </p>
          </div>
          <div>
            <h5 className="font-12 mb-0">Postcode :</h5>
            <p className="mb-2">
              {data.zip_code || '-'}
            </p>
          </div>
          <div>
            <h5 className="font-12 mb-0">Parents Name :</h5>
            <p className="mb-2">
              {data.parents_name || '-'}
            </p>
          </div>
          <div>
            <h5 className="font-12 mb-0">Phone :</h5>
            <p className="mb-2">
              {data.parents_phone || '-'}
            </p>
          </div>
          <div>
            <h5 className="font-12 mb-0">Email :</h5>
            <p className="mb-2">
              {data.parents_email || '-'}
            </p>
          </div>
          {/* <div>
            <h5 className="font-12 mb-0">Upload pics :</h5>
            <p className="mb-2">
              {data.full_name || '-'}
            </p>
          </div> */}
        </div>
      </Card.Body>
    </Card>
  );
};

export default Biodata;
