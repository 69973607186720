import React, { useEffect, useState, useRef } from "react";
import { useEffectOnce } from "usehooks-ts";
import { Card, Row, Col, Button, Nav, Tab, Form, Alert } from "react-bootstrap";
import { Navigate, Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import moment from 'moment';
import SimpleBar from "simplebar-react";
import { useParams } from "react-router-dom";
import ScaleLoader from "components/ScaleLoader";
import { HasAccess } from "@permify/react-role";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/store";

// components
import PageTitle from "components/PageTitle";
import { FormSelect } from "components/";
import Select from "react-select";
import List from "./List";
import Detail from "./Detail";
import ModalFilter from "./ModalFilter";
import ModalManage from "./ModalManage";
import ModalExport from "./ModalExport";

import {
  useGetExpoQuery,
  useExportExpoMutation,
} from "services/api";

const Event = () => {
  const [current, setCurrent] = useState();
  // const [activeTab, setActiveTab] = useState(tabName || 'All');
  const [layout, setLayout] = useState('default');
  const [showModalAdd, setModalAdd] = useState<boolean>(false);
  const [allLeadsCount, setAllLeadsCount] = useState(0);
  const [leadsCount, setLeadsCount] = useState({});

  const [checked, setChecked] = useState<any[]>(() => [])
  
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState<object>({});
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [filterStatus, setFilterStatus] = useState<null | string>();
  const [filterBranch, setFilterBranch] = useState<[]>();
  const [filterCounselor, setFilterCounselor] = useState<[]>();
  const [modalTypeManage, setModalTypeManage] = useState<string>('');
  const [modalShowManage, setModalShowManage] = useState<boolean>(false);
  const [modalShowExport, setModalShowExport] = useState<boolean>(false);
  
  const closeModalManage = () => {
    setModalShowManage(false)
  };
  const openModalManage = (type: string, data?: any) => {
    setCurrent(data)
    if(type == 'export'){
      onExport(data)
    } else {
      setModalTypeManage(type)
      setModalShowManage(true)
    }
  };
  const closeModalExport = () => {
    setModalShowExport(false)
  };

  const openModalExport = () => {
    setModalShowExport(true)
  };

  const onExport = async (data: any) => {
    toast.info('Processing your request...', {hideProgressBar: true, autoClose: false, closeButton: false, closeOnClick: false})
    
    await exportExpo({
      code:[data?.code],
    })
      .unwrap()
      .then((res: any) => {
        toast.dismiss();
        if(res?.result?.url){
          // toast.success(res.message)
          window.open(res?.result?.url, "_blank");
        }
      })
      .then((error: any) => {
        toast.dismiss();
        // console.log("error");
      });
  };

  const [exportExpo, { isLoading: isLoadingExport }] = useExportExpoMutation();

  const {
    data: expoList,
    isFetching: isFetchingExpo,
    isLoading: isLoadingExpo,
    isUninitialized: isUninitializedExpo,
    isError: isErrorExpo,
    error,
    refetch: refetchExpo,
  } = useGetExpoQuery(
    {
      // type,
      search,
      branch_code: filterBranch,
      counselor_code: filterCounselor,
      // expo_code: eventId,
      status_code: filterStatus,
      // order_by: orderBy,
      // sort_by: sortBy,
      page: activePage,
      per_page: perPage,
    },
    {
      // pollingInterval: 100,
      refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  return (
    <>
      <Card className="mb-2 mt-3">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center gap-1">
            <div>
              <label htmlFor="inputPassword2" className="visually-hidden">
                Search
              </label>
              <div className="me-3 w-100">
                <input
                  type="search"
                  className="form-control my-1 my-lg-0"
                  id="inputPassword2"
                  placeholder="Search event..."
                  onChange={(e: any) => setSearch(e.target.value)}
                  value={search}
                />
              </div>
            </div>
            <div>
              <div className="d-flex align-items-center gap-1">
                <Button
                  variant="success"
                  className="d-flex flex-nowrap align-items-center waves-effect waves-light"
                  onClick={(e) => openModalManage('add')}
                >
                  <i className="mdi mdi-calendar-plus"></i> 
                  <span className="ms-1 d-none d-sm-block">Create Event</span>
                </Button>
                <Button
                  variant="dark"
                  className="d-flex flex-nowrap align-items-center waves-effect waves-light"
                  onClick={(e) => openModalExport()}
                >
                  <div className="d-flex">
                    <i className="mdi mdi-file-export"></i> 
                    <span className="ms-1 d-none d-sm-block">Export</span>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <div className="mt-3">
        <Row>
          <Col>
            <List
              isLoading={isFetchingExpo || isLoadingExpo || isUninitializedExpo || isErrorExpo}
              data={expoList || []}
              current={current}
              setCurrent={setCurrent}
              search={search}
              setSearch={setSearch}
              filterBranch={filterBranch}
              setFilterBranch={setFilterBranch}
              sort={sort}
              setSort={setSort}
              activePage={activePage}
              setActivePage={setActivePage}
              perPage={perPage}
              setPerPage={setPerPage}
              setLayout={setLayout}
              layout={layout}
              // isChecked={(item: any) => isSelectedCard(item)}
              // setIsChecked={(item: any, status: boolean) => onSelectCard(item, status)}
              leadsCount={leadsCount}
              setLeadsCount={setLeadsCount}
              openModalManage={openModalManage}
            />
          </Col>
          {/* <Col xl={{ span: 8, order: 1 }} xs={{ span: 12, order: 2 }}>
            <List companyInfo={companyInfo} />
          </Col>
          <Col xl={{ span: 4, order: 2 }} xs={{ span: 12, order: 1 }}>
            <Detail />
          </Col> */}
        </Row>
      </div>

      {
        modalShowManage &&
        <ModalManage
          show={modalShowManage}
          type={modalTypeManage}
          data={current}
          onHide={closeModalManage}
        />
      }
      {
        modalShowExport &&
        <ModalExport
          show={modalShowExport}
          onHide={closeModalExport}
        />
      }
    </>
  );
};

export default Event;
