import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { VerticalForm, FormSelect, FormInput, FormDate, SingleUpload } from "components/";
import { faker } from "@faker-js/faker";
import DocumentUploader from "components/DocumentUploader";

import {
  useCreateAttachmentMutation,
  useDeleteAttachmentMutation,
} from "services/api";

import { toast } from "react-toastify";
import { IoWarningOutline } from "react-icons/io5";

interface ModalManageProps {
  show: boolean;
  type?: any;
  data?: any;
  onHide: () => void;
  refetch?: any;
}

const validation = yupResolver(
  yup.object().shape({
  })
);

const ModalManage = ({
  show,
  type,
  data,
  onHide,
  refetch,
}: ModalManageProps) => {
  const [files, setFiles] = useState<File[]>()

  const [createAttachment, { isLoading: isLoadingCreate }] = useCreateAttachmentMutation();
  const [deleteAttachment, { isLoading: isLoadingDelete }] = useDeleteAttachmentMutation();
  
  
  const methods = useForm({ resolver: validation });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
    reset: resetForm,
  } = methods;

  const onSubmit = async (values: any) => {
    // await changeDocument({
    //   ...values,
    //   application_code: data,
    // })
    //   .unwrap()
    //   .then((res) => {
    //     if(res.Document){
    //       onHide()
    //       toast.success(res.message)
    //     }
    //   })
    //   .catch((error: any) => {
    //     console.log("error");
    //   });
    // refetch()
  };

  useEffect(() => {
    console.log(errors)
  }, [errors])

  // useEffect(() => {
  //   console.log(application)
  // }, []);

  const onFileUpload = (files: any[]) => {
    setFiles(files)
  }

  const onClickUpload = async () => {
    let formData = new FormData();
    (files || []).map((file: any, index: number) => {
      formData.append("attachment["+index+"][file]", file)
      formData.append("attachment["+index+"][name]", file.name)
    });
    
    await createAttachment(formData)
    .unwrap()
    .then((res: any) => {
      if(res.status){
        onHide()
        toast.success(res.message)
      }
    })
    .catch((error: any) => {
      console.log("error");
    });
  }

  const onClickDelete = async () => {
    await deleteAttachment({
      code: data?.code
    })
      .unwrap()
      .then((res: any) => {
        if(res.status){
          onHide()
          toast.success(res.message)
        }
      })
      .catch((error: any) => {
        console.log("error");
      });
  };

  useEffect(() => {
    console.log(type)
  }, [type])

  return (
    <>
      <Modal
        backdrop="static"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {
          type != 'delete' &&
          <Modal.Header className="bg-light" onHide={onHide} closeButton>
            <Modal.Title className="m-0">
              <h4 className="modal-title">Upload Attachments</h4>
            </Modal.Title>
          </Modal.Header>
        }
        <Modal.Body className="p-4">
          <form
            onSubmit={handleSubmit((values: any) => onSubmit(values))}
            noValidate
          >
            {
              type == 'add' &&
              <>
                <DocumentUploader onFileUpload={onFileUpload}/>
                <div className="text-end mt-3">
                  <Button
                    variant="success"
                    type="submit"
                    className="waves-effect waves-light me-1"
                    disabled={isLoadingCreate}
                    onClick={() => {onClickUpload()}}
                  >
                    Upload
                  </Button>
                </div>
              </>
            }
            {type == "delete" && (
              <div className="text-center">
                <div onClick={onHide} className="position-absolute top-0 end-0 m-2 cursor-pointer">
                    <i className="mdi mdi-close font-22"/>
                  </div>
                  <IoWarningOutline size={64} className="text-danger"/>
                <h4 className="mt-2">Please confirm your action</h4>
                <p className="mt-3 mb-0">
                  Are you sure to delete <strong>{data.name}</strong>?
                </p>
                <p>This action cannot be undone.</p>
                <Button
                  variant="danger"
                  type="button"
                  className="waves-effect waves-light me-1"
                  disabled={isLoadingCreate || isLoadingDelete}
                  onClick={() => {onClickDelete()}}
                >
                  Confirm
                </Button>
              </div>
            )}
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalManage;
