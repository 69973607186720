import { faker } from '@faker-js/faker';
import moment from 'moment';

export interface CompanyInfoItems {
  id: string;
  image: string;
  name: string;
  email: string;
  phone: string;
  dob: string;
  degree: string;
  address: string;
  city: string;
  expo: string;
  status: any;
  counselor: {
    image: string;
    name: string;
  }
}

const cityName = faker.helpers.arrayElement([
  'Jakarta Barat',
  'Jakarta Utara',
  'Jakarta Selatan',
  'Jakarta Timur',
  'Tangerang',
  'Bekasi',
]);

function createRandomLeads(): CompanyInfoItems {
  return {
    id: 'JSC'+faker.string.numeric(5),
    image: faker.internet.avatar(),
    name: faker.person.fullName(),
    email: faker.internet.email(),
    phone: '+6281'+faker.string.numeric(9),
    dob: moment(new Date(faker.date.birthdate())).format('DD MMMM YYYY'),
    degree: 'S1/Bachelor',
    address: `${faker.location.streetAddress()} ${cityName}`,
    city: faker.helpers.arrayElement([
      'Jakarta Barat',
      'Jakarta Utara',
      'Jakarta Selatan',
      'Jakarta Timur',
      'Tangerang',
      'Bekasi',
    ]),
    expo: 'Study Aboard Exxpo 2024',
    status: faker.helpers.arrayElement([
      <span className="me-1 badge-soft-dark badge">Unassigned</span>,
      <span className="me-1 badge-soft-warning badge">In Progress</span>,
      <span className="me-1 badge bg-success badge bg-primary">Hot </span>,
      <span className="me-1 badge-soft-success badge">Prospect</span>,
      <span className="me-1 badge-soft-info badge">Future Prospect</span>,
      <span className="me-1 badge-soft-danger badge">Non-prospect</span>,
      <span className="me-1 badge bg-secondary badge bg-primary">Aborted</span>,
    ]),
    counselor: {
      image: faker.internet.avatar(),
      name: faker.person.firstName(),
    },
  };
}

export const companyInfo: CompanyInfoItems[] = faker.helpers.multiple(createRandomLeads, {
  count: 100,
});
