import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { IoWarningOutline } from "react-icons/io5";
import moment from "moment";

// components
import { VerticalForm, FormSelect, FormInput, FormDate, SingleUpload } from "components/";

import {
  useCreateExpoMutation,
  useUpdateExpoMutation,
  useDeleteExpoMutation,
} from "services/api";
import { StringKey } from "react-table";

interface ModalManageProps {
  show: boolean;
  type: string;
  data?: any;
  onHide: () => void;
}

const timeOptions = [
  {
    label: '07:00',
    value: '07:00',
  },
  {
    label: '07:30',
    value: '07:30',
  },
  {
    label: '08:00',
    value: '08:00',
  },
  {
    label: '08:30',
    value: '08:30',
  },
  {
    label: '09:00',
    value: '09:00',
  },
  {
    label: '09:30',
    value: '09:30',
  },
  {
    label: '10:00',
    value: '10:00',
  },
  {
    label: '10:30',
    value: '10:30',
  },
  {
    label: '11:00',
    value: '11:00',
  },
  {
    label: '11:30',
    value: '11:30',
  },
  {
    label: '12:00',
    value: '12:00',
  },
  {
    label: '12:30',
    value: '12:30',
  },
  {
    label: '13:00',
    value: '13:00',
  },
  {
    label: '13:30',
    value: '13:30',
  },
  {
    label: '14:00',
    value: '14:00',
  },
  {
    label: '14:30',
    value: '14:30',
  },
  {
    label: '15:00',
    value: '15:00',
  },
  {
    label: '15:30',
    value: '15:30',
  },
  {
    label: '16:00',
    value: '16:00',
  },
  {
    label: '16:30',
    value: '16:30',
  },
  {
    label: '17:00',
    value: '17:00',
  },
  {
    label: '17:30',
    value: '17:30',
  },
  {
    label: '18:00',
    value: '18:00',
  },
  {
    label: '18:30',
    value: '18:30',
  },
  {
    label: '19:00',
    value: '19:00',
  },
  {
    label: '19:30',
    value: '19:30',
  },
  {
    label: '20:00',
    value: '20:00',
  },
  {
    label: '20:30',
    value: '20:30',
  },
  {
    label: '21:00',
    value: '21:00',
  },
];

const ModalManage = ({
  show,
  type,
  data,
  onHide,
}: ModalManageProps) => {
  const [image, setImage] = useState<File>();
  const [emailInvitation, setEmailInvitation] = useState(data?.invitation_notification ? 'true' : 'false');

  const [createExpo, { isLoading: isLoadingCreate }] = useCreateExpoMutation();
  const [updateExpo, { isLoading: isLoadingUpdate }] = useUpdateExpoMutation();
  const [deleteExpo, { isLoading: isLoadingDelete }] = useDeleteExpoMutation();

  const validation = yupResolver(
    yup.object().shape({
      name: yup.string().label('Name').required(''),
      venue: yup.string().label('Venue').required(''),
      address: yup.string().label('Address').required(''),
      date: yup.string().label('Date').required(''),
      start_time: yup.string().label('Start time').required(''),
      end_time: yup.string().label('End time').required(''),
    })
  );
  
  const methods = useForm({ resolver: validation });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
    reset: resetForm,
  } = methods;

  const onSubmit = async (values: any) => {
    try {
      let formData = new FormData();
      Object.keys(values).forEach((key: any) => {
        if(values[key]){
          formData.append(key, values[key]);
        }
      });
      if(image) {
        formData.append("image", image)
      } else {
        formData.delete("image")
      }
      formData.append("invitation_notification", emailInvitation)

      switch (type) {
        case 'add':
          await createExpo(formData)
          .unwrap()
          .then((res) => {
            if(res.status){
              onHide()
              toast.success(res.message)
            }
          })
          .catch((error: any) => {
            console.log("error");
          });
          break;

        case 'edit':
          await updateExpo(formData)
            .unwrap()
            .then((res) => {
              if(res.status){
                onHide()
                toast.success(res.message)
              }
            })
            .catch((error: any) => {
              console.log("error");
            });
          break;
          
        case 'delete':
          await deleteExpo(formData)
            .unwrap()
            .then((res) => {
              if(res.status){
                onHide()
                toast.success(res.message)
              }
            })
            .catch((error: any) => {
              console.log("error");
            });
          break;
      
        default:
          break;
      }
    } catch (err: any) {
      console.log(err);
      // if (err?.data?.errors) {
      //   Object.keys(err?.data?.errors)?.forEach((field) => {
      //     err.data.errors[field]?.forEach((message: string) => {
      //       toast.error(message, { autoClose: 10000 });
      //     });
      //   });
      // }
    }
  };

  useEffect(() => {
    console.log(errors)
  }, [errors])

  useEffect(() => {
    // reset();
    // setCurrent(data);
    console.log(data)
    if (data) {
      Object.keys(data).map((key) => {
        setValue(key, data[key]);
      });
    }
  }, []);

  return (
    <>
      <Modal
        backdrop="static"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {
          type != 'delete' &&
          <Modal.Header className="bg-light" onHide={onHide} closeButton>
            <Modal.Title className="m-0">
              {`${type} Event`.toUpperCase()}{" "}
            </Modal.Title>
          </Modal.Header>
        }
        <Modal.Body className="p-4">
            <form
              onSubmit={handleSubmit((values: any) => onSubmit({...values}))}
              noValidate
            >
            {(type == "add" || type == "edit") && (
              <>
                <div className="mb-3">
                  <label className="form-label">Image</label>
                  <SingleUpload
                    name="image"
                    errors={errors}
                    // style={{height: '150px', width: '150px'}}
                    onFileUpload={(f) => {
                      setValue("image", f);
                      setImage(f);
                    }}
                    onRemove={(f: any) => {
                      setValue("image", f);
                      setImage(f);
                      setValue("image_base64", null);
                    }}
                    // disabled={isLoading}
                    // imagePreview={data?.image || 'https://placehold.co/100x100.png'}
                    imagePreview={data?.image || undefined}
                    // imageName={data?.image_name}
                  />
                </div>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  label="Event Name"
                  type="text"
                  name="name"
                  placeholder=""
                  containerClass={"mb-3"}
                />
                <div className="d-flex gap-3 justify-content-start">
                  <FormDate
                    label="Event Date"
                    type="text"
                    name="date"
                    placeholder=""
                    containerClass={"mb-3"}
                    defaultValue={data?.date || undefined}
                    // value={moment(data?.date, 'YYYY-MM-DD')}
                    className=""
                    // disabled={isLoadingAdd || isLoadingUpdate}
                    register={register}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    clearErrors={clearErrors}
                  />
                  <div style={{width: '150px'}}>
                    <FormSelect
                      className="w-100"
                      label="Start Time"
                      name="start_time"
                      placeholder=""
                      containerClass={"mb-3"}
                      defaultValue={data?.start_time || undefined}
                      options={timeOptions}
                      // disabled={isLoading}
                      register={register}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      // onChange={(opt: any) => {
                      //   setCalcMarketingBranding(opt.value);
                      // }}
                    />
                  </div>
                  <div style={{width: '150px'}}>
                    <FormSelect
                      label="End Time"
                      name="end_time"
                      placeholder=""
                      containerClass={"mb-3"}
                      defaultValue={data?.end_time || undefined}
                      options={timeOptions}
                      // disabled={isLoading}
                      register={register}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      // onChange={(opt: any) => {
                      //   setCalcMarketingBranding(opt.value);
                      // }}
                    />
                  </div>
                </div>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  label="Venue"
                  type="text"
                  name="venue"
                  placeholder=""
                  containerClass={"mb-3"}
                />
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  label="Address"
                  type="text"
                  name="address"
                  placeholder=""
                  containerClass={"mb-3"}
                />
                {
                  type == 'add' &&
                  <div className="mt-n2 mb-3">
                    <div className="form-check form-switch">
                      <input
                        type="checkbox"
                        id="custom-switch"
                        className="form-check-input border-application"
                        defaultChecked={emailInvitation == 'true' ? true : false}
                        // disabled
                        onChange={(e) => {
                          setEmailInvitation(e.target.checked ? 'true' : 'false');
                        }}
                      />
                      <label
                        title=""
                        htmlFor="custom-switch"
                        className="form-check-label fw-normal"
                      >
                        Send email invitation about this event to all leads
                      </label>
                    </div>
                  </div>
                }
                <div className="text-end border-top mt-3 pt-3">
                  <Button
                    variant="success"
                    type="submit"
                    className="waves-effect waves-light me-1"
                    disabled={
                      isLoadingCreate || isLoadingUpdate || isLoadingDelete
                    }
                  >
                    Submit
                  </Button>
                </div>
                
              </>
            )}
            {type == "delete" && (
                <div className="text-center">
                  <div
                    onClick={onHide}
                    className="position-absolute top-0 end-0 m-2 cursor-pointer"
                  >
                    <i className="mdi mdi-close font-22" />
                  </div>
                  <div
                    onClick={onHide}
                    className="position-absolute top-0 end-0 m-2 cursor-pointer"
                  >
                    <i className="mdi mdi-close font-22" />
                  </div>
                  <IoWarningOutline size={64} className="text-danger" />
                  <h4 className="mt-2">Please confirm your action</h4>
                  <p className="mt-3 mb-0">
                    Are you sure to delete <strong>{data.name}</strong>?
                  </p>
                  <p>This action cannot be undone.</p>
                  <Button
                    variant="danger"
                    type="submit"
                    className="waves-effect waves-light me-1"
                    disabled={
                      isLoadingCreate || isLoadingUpdate || isLoadingDelete
                    }
                  >
                    Confirm
                  </Button>
                </div>
              )}
            </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalManage;
