import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { faker } from '@faker-js/faker';
import Skeleton from "react-loading-skeleton";
import moment from "moment";

import {
  useGetTopCounselorQuery,
} from "services/api";

const TopCounselor = ({filterYear} : {filterYear: any;}) => {
  const {
    data: topCounselorList,
    isFetching: isFetchingTopCounselor,
    isLoading: isLoadingTopCounselor,
    isUninitialized: isUninitializedTopCounselor,
    isError: isErrorTopCounselor,
    error: errorTopCounselor,
    refetch: refetchTopCounselor,
  } = useGetTopCounselorQuery(
    {
      // filter_year: filterYear || null,
    },
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  return (
    <>
      <Card className="h-100">
        <Card.Body>
          {
            (isFetchingTopCounselor || isLoadingTopCounselor || isUninitializedTopCounselor) ? (
              <>
                <Row className="mb-3">
                  <Col>
                    <Skeleton height="25px"/>
                  </Col>
                  <Col>
                    <Skeleton height="25px"/>
                  </Col>
                  <Col>
                    <Skeleton height="25px"/>
                  </Col>
                  <Col>
                    <Skeleton height="25px"/>
                  </Col>
                </Row>
                {
                  [...Array(4)].map((number, i) => {
                    return (
                      <Row className="align-items-center mb-3">
                        <Col>
                          <Row className="align-items-center">
                            <Col xs="auto">
                              <Skeleton circle height="40px" width="40px"/>
                            </Col>
                            <Col>
                              <Skeleton/>
                              <Skeleton height={10} width="75%"/>
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Skeleton/>
                        </Col>
                        <Col>
                          <Skeleton/>
                        </Col>
                        <Col>
                          <Skeleton/>
                        </Col>
                      </Row>
                    )
                  })
                }
              </>
            ) : (
              <>
                <h4 className="header-title mb-3 d-flex justify-content-between">
                  <span>Top Counselor Performance</span>
                  <div className="d-flex align-items-center gap-1">
                    {/* <div className="text-muted font-12 fw-normal">{filterYear ? filterYear : 'All Time'}</div> */}
                    <div className="text-muted font-12 fw-normal">{moment().format('YYYY')}</div>
                  </div>
                </h4>
                <div className="table-responsive">
                  <table className="table table-centered table-nowrap table-hover mb-0">
                    <thead>
                      <tr>
                        <th className="border-top-0">Name</th>
                        <th className="border-top-0">Leads</th>
                        <th className="border-top-0">Applied</th>
                        <th className="border-top-0">Completed</th>
                        {/* <th className="border-top-0"></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {(topCounselorList?.result || []).map((item: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-start">
                                <img
                                  src={item.avatar}
                                  alt={item.name}
                                  title="contact-img"
                                  className="avatar-sm rounded-circle me-3"
                                  height="20"
                                />
                                <div className="d-flex flex-column">
                                  <span className="font-12 fw-semibold">{item.name}</span>
                                  <span className="font-11 text-muted">
                                    Counselor
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>{item.leads}</td>
                            <td>{item.applied}</td>
                            <td>{item.completed}</td>
                            {/* <td>
                              <i className="mdi mdi-eye font-18 text-muted"></i>
                            </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            )
          }
        </Card.Body>
      </Card>
    </>
  );
};

export default TopCounselor;
