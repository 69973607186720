import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { useGetLeadsQuery } from "services/api";
import Select from "react-select";
import queryString from 'query-string';
import ScaleLoader from "components/ScaleLoader";
import { LuPackageOpen } from "react-icons/lu";

// components
import LeadsItem from './LeadsItem';
import { PaginationCard, ModalComment } from "components";
import { companyInfo as dd, CompanyInfoItems } from "./data";

interface LeadsProps {
  companyInfo: CompanyInfoItems[];
}

const LeadsList = ({
  type,
  status,
  search,
  setSearch,
  orderBy,
  sortBy,
  checked,
  setChecked,
  canComment = true,
  canAssign = false,
  layout,
  setLayout,
  setCount,
}: {
  type: any;
  status: any;
  search?: any,
  setSearch?: any,
  orderBy?: any,
  sortBy?: any,
  checked: any;
  setChecked: any;
  canComment?: boolean;
  canAssign?: boolean;
  layout: any;
  setLayout: any;
  setCount: any;
}) => {
  let { eventId } = useParams();
  const [companyInfo, setCompanyInfo] = useState<Array<CompanyInfoItems>>(dd);
  const [show, setShow] = useState<boolean>(false);
  const [current, setCurrent] = useState<null | {code: any}>(null);

  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [filterStatus, setFilterStatus] = useState<null | string>();

  const [showModalComment, setShowModalComment] = useState(false);

  const {
    data: lists,
    isFetching,
    isLoading,
    isUninitialized,
    error,
    isError,
    refetch,
  } = useGetLeadsQuery(
    {
      type,
      search,
      expo_code: eventId,
      status: filterStatus,
      order_by: orderBy,
      sort_by: sortBy,
      page: activePage,
      per_page: perPage,
    },
    {
      // pollingInterval: 100,
      refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  const onCloseModal = () => setShow(false);
  const onOpenModal = () => setShow(true);

  const onSelectCard = (item: any, status: boolean) => {
    const arrIndex = checked.findIndex(
      (el: any) => el.leads_id === item?.leads_id
    );
    if(status && arrIndex){
      setChecked([...checked, item]);
    } else if(!status){
      setChecked(checked.filter((el: any) => el.leads_id !== item?.leads_id));
    }
  };

  const isSelectedCard = (item: any) => {
    return checked.find((el: any) => el.leads_id === item?.leads_id);
  };

  const onClickComment = (item: any) => {
    setCurrent(item);
    setShowModalComment(true);
  };

  useEffect(() => {
    setCount(lists?.meta?.total || 0)
  }, [lists?.meta?.total]);

  return (
    <>
      {
        (!isLoading && lists?.result?.length == 0) ? (
          <div className="d-flex flex-column justify-content-center align-items-center mb-3" style={{minHeight: '200px'}}>
            <LuPackageOpen size={64}/>
            <p>No data</p>
          </div>
        ) : (
          <>
            {
              (isUninitialized || isLoading || isFetching || isError) ? (
                <div style={{height: 'calc(100vh - 200px)'}}>
                  <ScaleLoader />
                </div>
              ) : (
                <>
                  {(lists?.result || []).map((item: any, index: number) => (
                    <LeadsItem
                      item={item}
                      isChecked={isSelectedCard(item)}
                      setIsChecked={(status: boolean) => onSelectCard(item, status)}
                      canComment={canComment}
                      canAssign={canAssign}
                      onDoubleClick={() => { onSelectCard(item, !isSelectedCard(item))}}
                      onClickComment={() => onClickComment(item)}
                    />
                  ))}
                </>
              )
            }
            {lists?.meta && (
              <PaginationCard
                stickyBottom
                meta={lists.meta}
                activePage={activePage}
                setActivePage={setActivePage}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            )}

            {
              showModalComment && current!.code &&
              <ModalComment leadsCode={current!.code} show={showModalComment} setShow={setShowModalComment}/>
            }
          </>
        )
      }
    </>
  );
};

export default LeadsList;
