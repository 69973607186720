import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";

// components
import BTable from "components/BTable";

import {
  useGetCountryQuery,
} from "services/api";

const CountryColumn = ({ data }: { data: any }) => {
  return (
    <div className="d-flex justify-content-start align-items-center gap-1">
      {/* <div style={{height: '16px', width: '16px'}} className="align-self-center"> */}
        <img src={data.flag} alt="" className="h-auto img-fluid" style={{height: '16px', width: '16px'}} />
      {/* </div> */}
      <Link to="#" className="text-body fw-semibold">
        {data.name}
      </Link>
    </div>
  );
};

const columns = [
  {
    label: "#",
    name: "alias",
    width: '170px',
  },
  {
    label: "Name",
    name: "name",
    width: '200px',
    CustomCell: (data: any) => <CountryColumn data={data} />,
  },
  // {
  //   label: "Cities",
  //   name: "city_count",
  // },
  {
    label: "Continent",
    name: "continent",
    width: '200px',
    inputFilter: "select",
    filterOptions: {
      table: 'countries',
      column: 'continent',
    }
  },
  {
    label: "Currrency ID",
    name: "currency_code",
    width: '180px',
  },
  {
    label: "Currrency Symbol",
    name: "currency_symbol",
    width: '200px',
  },
  {
    label: "Institutions",
    name: "institution_count",
    width: '100px',
    hideFilter: true,
  },
  {
    label: "Students",
    name: "application_count",
    width: '100px',
    hideFilter: true,
  },
];

const List = ({ 
  onClickAction,
}: { 
  onClickAction?: any;
 }) => {
  const [search, setSearch] = useState({});
  const [sort, setSort] = useState<object>({});
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  
  const [current, setCurrent] = useState();
  const [modalType, setModalType] = useState<string>('');
  const [modalShow, setModalShow] = useState<boolean>(false);

  const closeModal = () => setModalShow(false);
  const openModal = (type: string, data?: any) => {
    setCurrent(data)
    setModalType(type)
    setModalShow(true)
  };

  const {
    data: lists,
    isFetching: isFetchingCountry,
    isLoading: isLoadingCountry,
    isUninitialized: isUninitializedCountry,
    isError: isErrorCountry,
    error: errorCountry,
    refetch: refetchCountry,
  } = useGetCountryQuery(
    {
      search,
      sort,
      page: activePage,
      per_page: perPage,
    },
    {
      // pollingInterval: 100,
      refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  return (
    <>
      <BTable 
        tableClassname={'table-centered'}
        isLoading={ isFetchingCountry || isLoadingCountry || isUninitializedCountry || isErrorCountry }
        data={lists?.result}
        meta={lists?.meta}
        columns={columns}
        search={search}
        setSearch={setSearch}
        sort={sort}
        setSort={setSort}
        activePage={activePage}
        setActivePage={setActivePage}
        perPage={perPage}
        setPerPage={setPerPage}
        onClickAction={onClickAction}
      />
    </>
  );
};

export default List;
