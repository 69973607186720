import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { VerticalForm, FormSelect, FormInput, FormDate, SingleUpload } from "components/";
import ScaleLoader from "components/ScaleLoader";
import { toast } from "react-toastify";

import {
  useGetStatusQuery,
  useCreateApplicationMutation,
  useUpdateApplicationMutation,
  useGetAdmissionQuery,
  useGetAutocompleteQuery,
} from "services/api";

const yearOptions = () => {
  let sy = 2017;
  let cy = new Date().getFullYear()
  let ey = cy + 5;
  let yrs = [];

  for (var i = sy; i <= ey; i++) {
    yrs.push({
      value: sy,
      label: sy,
    });
    sy++;
  }
  return yrs;
}
interface ModalApplicationProps {
  leads?: any;
  type: string;
  show: boolean;
  data?: any;
  onHide: () => void;
  refetch?: any;
}

const ModalApplication = ({
  leads,
  show,
  type,
  data,
  onHide,
  refetch,
}: ModalApplicationProps) => {
  const [statusName, setStatusName] = useState('');
  const [tuitionType, setTuitionType] = useState('New');
  const [emailNotification, setEmailNotification] = useState(true);

  const {
    data: statusList,
    isFetching: isFetchingStatus,
    isLoading: isLoadingStatus,
    isUninitialized: isUninitializedStatus,
    isError: isErrorStatus,
    error: errorStatus,
    refetch: refetchStatus,
  } = useGetStatusQuery(
    {
      group: 'Application',
      exclude: [],
      // search,
      // sort,
      // page: activePage,
      // per_page: perPage,
    },
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  const {
    data: admissionList,
    isFetching: isFetchingAdmission,
    isLoading: isLoadingAdmission,
    isUninitialized: isUninitializedAdmission,
    error: errorAdmission,
    isError: isErrorAdmission,
    refetch: refetchAdmission,
  } = useGetAdmissionQuery(
    {},
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  const { data: institutionList, isLoading: isLoadingInstitution } = useGetAutocompleteQuery({type: 'INS'});
  const { data: courseLevelList, isLoading: isLoadingCourseLevel } = useGetAutocompleteQuery({type: 'CRL'});
  const { data: courseMajorList, isLoading: isLoadingCourseMajor } = useGetAutocompleteQuery({type: 'CMJ'});
  const { data: courseFullNameList, isLoading: isLoadingCourseFullName } = useGetAutocompleteQuery({type: 'CFN'});
  const { data: currencyList, isLoading: isLoadingCurrency } = useGetAutocompleteQuery({type: 'CRY'});

  const [createApplication, { isLoading: isLoadingCreate }] = useCreateApplicationMutation();
  const [updateApplication, { isLoading: isLoadingUpdate }] = useUpdateApplicationMutation();

  const validation = yupResolver(
    yup.object().shape({
      admission_code: yup.string().label('Admission').nullable(),
      institution_code: yup.string().label('Institution').required(''),
      planning_year: yup.string().label('Year of Study').required(''),
      course_level_code: yup.string().label('Course level').required(''),
      course_major_code: yup.string().label('Study Area').required(''),
      course_full_name_code: yup.string().label('Course full name').required(''),
      tuition_type: yup.string().label('Tuition Type').required(''),
      tuition_type_note: yup.mixed().default('').when('tuition_type', {
        is: 'Nomination',
        then: yup.string().label('Tuition Type Note').required(''),
      }),
      tuition_fee_currency: yup.string().label('Tuition Fee Currency').required(''),
      tuition_fee_amount: yup.number().transform((value) => (isNaN(value) ? undefined : value)).label('Tuition Fee Amount').required(''),
      scholarship_currency: yup.string().transform((value) => (value == null ? '' : value)).label('Scholarship Currency'),
      scholarship_amount: yup.number().transform((value) => (isNaN(value) ? undefined : value)).label('Scholarship Amount'),
      note: yup.string().transform((value) => (value == null ? '' : value)).default('').when('type', {
        is: 'add',
        then: yup.string().label('Note').required(''),
      }),
    })
  );
  
  const methods = useForm({ resolver: validation });
  const {
    handleSubmit,
    register,
    control,
    trigger,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
    reset: resetForm,
  } = methods;

  const onSubmit = async (values: any) => {
    switch (type) {
      case 'add':
        await createApplication({
          ...values,
          leads_code: leads.code,
          email_notification: emailNotification
        })
          .unwrap()
          .then((res) => {
            if(res.status){
              onHide()
              toast.success(res.message)
              refetch()
            }
          })
          .catch((error: any) => {
            console.log("error");
          });
        break;

      case 'edit':
        await updateApplication({
          ...values,
          leads_code: leads.code,
          email_notification: emailNotification,
          tuition_type_note: values.tuition_type == 'New' ? '' : values.tuition_type_note,
        })
          .unwrap()
          .then((res) => {
            if(res.status){
              onHide()
              toast.success(res.message)
              refetch()
            }
          })
          .catch((error: any) => {
            console.log("error");
          });
        break;
        
      default:
        break;
    }
  };

  useEffect(() => {
    console.log('errors', errors)
  }, [errors])

  // useEffect(() => {
  //   if(application) {
  //     setValue('application_code', application.code);
  //   }
  // }, []);

  // // Dummy Data
  // useEffect(() => {
  //   if(getValues()['status_name'] == 'Applied'){
  //     // setValue('institution_code','INS2400'+faker.number.int({ min: 1, max: 9 }));
  //     // setValue('planning_year','202'+faker.number.int({ min: 4, max: 9 }));
  //     // setValue('course_level_code','CLE240'+faker.number.int({ min: 1, max: 9 }));
  //     // setValue('course_major_code','CMJ240'+faker.number.int({ min: 1, max: 9 }));
  //     // setValue('course_full_name_code','CFM240'+faker.number.int({ min: 1, max: 9 }));
  //     // setValue('marketing_source_code','MKS2400'+faker.number.int({ min: 1, max: 9 }));
  //   }
  // }, [getValues()['status_name']]);
  

  useEffect(() => {
    // reset();
    // setCurrent(data);
    console.log(data)
    setValue('type', type);
    if (data) {
      Object.keys(data).map((key) => {
        setValue(key, data[key]);
      });
    }
    console.log(getValues())
  }, []);
  
  return (
    <>
      <Modal
        backdrop="static"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header onHide={onHide} closeButton>
          <h4 className="modal-title">{`${type == 'add' ? 'Add New' : type} Application`.toUpperCase()}</h4>
        </Modal.Header>
        <Modal.Body className="p-4">
          {
            isLoadingInstitution || isLoadingCourseLevel || isLoadingCourseMajor || isLoadingCourseFullName || isLoadingCurrency ? (
              <div className="d-flex justify-content-center align-items-center" style={{minHeight: '200px'}}>
                <ScaleLoader />
              </div>
            ) : (
              <form
                onSubmit={handleSubmit((values: any) => onSubmit(values))}
                noValidate
              >
                {
                  type == 'add' ? (
                    <FormInput
                      className="bg-light"
                      label="JSA ID"
                      type="text"
                      name="code"
                      placeholder=""
                      containerClass={"mb-3"}
                      defaultValue={leads?.code}
                      disabled
                    />
                  ) : (
                    <FormInput
                      className="bg-light"
                      label="Application ID"
                      type="text"
                      name="code"
                      placeholder=""
                      containerClass={"mb-3"}
                      defaultValue={data?.code}
                      disabled
                    />
                  )
                }
                <FormInput
                  className="bg-light"
                  label="Student Name"
                  type="text"
                  name="full_name"
                  placeholder=""
                  containerClass={"mb-3"}
                  defaultValue={leads.full_name}
                  disabled
                />
                {/* <h4 className="header-title">Interested In</h4> */}
                {/* <p className="sub-header">Please select preferenced institution and course details</p> */}
                <FormSelect
                  label="Admission"
                  name="admission_code"
                  placeholder="e.g. Arjuna"
                  containerClass={"mb-3"}
                  defaultValue={getValues()['admission_code'] || data?.admission_code || undefined}
                  options={(admissionList?.result || []).map((item: any) => {
                    return {
                      label: item.name,
                      value: item.code,
                    };
                  })}
                  disabled={isLoadingCreate || isLoadingUpdate}
                  register={register}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  // onChange={(opt: any) => {
                  //   setCalcMarketingBranding(opt.value);
                  // }}
                />
                <FormSelect
                  label="Institution"
                  name="institution_code"
                  placeholder="e.g. SIM - Singapore"
                  containerClass={"mb-3"}
                  defaultValue={getValues()['institution_code'] || data?.institution_code || undefined}
                  options={(institutionList?.result || [])}
                  disabled={isLoadingCreate || isLoadingUpdate}
                  register={register}
                  control={control}
                  trigger={trigger}
                  errors={errors}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  onChange={(opt: any) => {
                    console.log(opt)
                    // setCalcMarketingBranding(opt.value);
                  }}
                />
                <FormSelect
                  label="Year of Study"
                  type="text"
                  name="planning_year"
                  placeholder="e.g. 2024"
                  containerClass={"mb-3"}
                  defaultValue={getValues()['planning_year'] || data?.planning_year || undefined}
                  options={yearOptions()}
                  disabled={isLoadingCreate || isLoadingUpdate}
                  register={register}
                  control={control}
                  trigger={trigger}
                  errors={errors}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  // onChange={(opt: any) => {
                  //   setCalcMarketingBranding(opt.value);
                  // }}
                />
                <FormSelect
                  label="Course Level"
                  name="course_level_code"
                  placeholder="e.g. Foundation"
                  containerClass={"mb-3"}
                  defaultValue={getValues()['course_level_code'] || data?.course_level_code || undefined}
                  options={(courseLevelList?.result || [])}
                  disabled={isLoadingCreate || isLoadingUpdate}
                  register={register}
                  control={control}
                  trigger={trigger}
                  errors={errors}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  // onChange={(opt: any) => {
                  //   setCalcMarketingBranding(opt.value);
                  // }}
                />
                <FormSelect
                  label="Study Area"
                  name="course_major_code"
                  placeholder="e.g. Science"
                  containerClass={"mb-3"}
                  defaultValue={getValues()['course_major_code'] || data?.course_major_code || undefined}
                  options={(courseMajorList?.result || [])}
                  disabled={isLoadingCreate || isLoadingUpdate}
                  register={register}
                  control={control}
                  trigger={trigger}
                  errors={errors}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  // onChange={(opt: any) => {
                  //   setCalcMarketingBranding(opt.value);
                  // }}
                />
                <FormSelect
                  label="Course Full Name"
                  name="course_full_name_code"
                  placeholder="e.g. Sport Science"
                  containerClass={"mb-3"}
                  defaultValue={getValues()['course_full_name_code'] || data?.course_full_name_code || undefined}
                  options={(courseFullNameList?.result || [])}
                  disabled={isLoadingCreate || isLoadingUpdate}
                  register={register}
                  control={control}
                  trigger={trigger}
                  errors={errors}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  // onChange={(opt: any) => {
                  //   setCalcMarketingBranding(opt.value);
                  // }}
                />
                <div>
                  <label className="form-label">Tuition Type</label>
                  <div className="row">
                    <div className="col-5">
                      <FormSelect
                        // label="Currency"
                        name="tuition_type"
                        placeholder="Type"
                        containerClass={"mb-3"}
                        defaultValue={getValues()['tuition_type'] || data?.tuition_type || undefined}
                        options={[
                          {
                            label: 'New',
                            value: 'New',
                          },
                          {
                            label: 'Nomination',
                            value: 'Nomination',
                          }
                        ]}
                        disabled={isLoadingCreate || isLoadingUpdate}
                        register={register}
                        control={control}
                        trigger={trigger}
                        errors={errors}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        onChange={(opt: any) => {
                          setTuitionType(opt.value)
                        }}
                      />
                    </div>
                    {
                      tuitionType == 'Nomination' &&
                      <div className="col-7">
                        <FormInput
                          // label=""
                          type="text"
                          name="tuition_type_note"
                          placeholder="e.g other agency"
                          containerClass={"mb-3"}
                          // defaultValue={application?.tuition_type_note || undefined}
                          register={register}
                          control={control}
                          trigger={trigger}
                          errors={errors}
                          setValue={setValue}
                        />
                      </div>
                    }
                  </div>
                </div>
                <div>
                  <label className="form-label">Tuition Fee</label>
                  <div className="row">
                    <div className="col-4">
                      <FormSelect
                        // label="Currency"
                        name="tuition_fee_currency"
                        placeholder="Currency"
                        containerClass={"mb-3"}
                        defaultValue={getValues()['tuition_fee_currency'] || data?.tuition_fee_currency || undefined}
                        options={(currencyList?.result || [])}
                        disabled={isLoadingCreate || isLoadingUpdate}
                        register={register}
                        control={control}
                        trigger={trigger}
                        errors={errors}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        // onChange={(opt: any) => {
                        //   setCalcMarketingBranding(opt.value);
                        // }}
                      />
                    </div>
                    <div className="col-8">
                      <FormInput
                        // label=""
                        type="number"
                        name="tuition_fee_amount"
                        placeholder=""
                        containerClass={"mb-3"}
                        defaultValue={getValues()['tuition_fee_amount'] || data?.tuition_fee_amount || undefined}
                        register={register}
                        control={control}
                        trigger={trigger}
                        errors={errors}
                        setValue={setValue}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <label className="form-label">Scholarship (Optional)</label>
                  <div className="row">
                    <div className="col-4">
                      <FormSelect
                        // label="Currency"
                        name="scholarship_currency"
                        placeholder="Currency"
                        containerClass={"mb-3"}
                        defaultValue={getValues()['scholarship_currency'] || data?.scholarship_currency || undefined}
                        options={(currencyList?.result || [])}
                        // disabled={isLoading}
                        register={register}
                        control={control}
                        trigger={trigger}
                        errors={errors}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        // onChange={(opt: any) => {
                        //   setCalcMarketingBranding(opt.value);
                        // }}
                      />
                    </div>
                    <div className="col-8">
                      <FormInput
                        // label=""
                        type="text"
                        name="scholarship_amount"
                        placeholder=""
                        containerClass={"mb-3"}
                        defaultValue={getValues()['scholarship_amount'] || data?.scholarship_amount || undefined}
                        register={register}
                        control={control}
                        trigger={trigger}
                        errors={errors}
                        setValue={setValue}
                      />
                    </div>
                  </div>
                </div>
                {
                  type == "add" &&
                  <>
                    <FormInput
                      label="Notes"
                      type="textarea"
                      name="note"
                      placeholder=""
                      containerClass={"mb-3"}
                      defaultValue={getValues()['note'] || data?.note || undefined}
                      register={register}
                      control={control}
                      trigger={trigger}
                      errors={errors}
                      setValue={setValue}
                    />
                    <div className="form-check form-switch">
                      <input
                        type="checkbox"
                        id="custom-switch"
                        className="form-check-input border-application"
                        // defaultChecked
                        onChange={(e) => {
                          setEmailNotification(e.target.checked);
                        }}
                      />
                      <label
                        title=""
                        htmlFor="custom-switch"
                        className="form-check-label fw-normal"
                      >
                        Notify this application status to the student
                      </label>
                    </div>
                  </>
                }
              </form>
              )
            }
        </Modal.Body>
        <Modal.Footer>
          <div className="text-end">
            <Button
              variant="success"
              type="button"
              className="waves-effect waves-light me-1"
              disabled={isLoadingCreate || isLoadingUpdate}
              onClick={
                handleSubmit((values: any) => onSubmit(values))
              }
            >
              Submit
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalApplication;
