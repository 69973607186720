import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";

// components
import BTable from "components/BTable";
import ModalRepresentative from "./ModalRepresentative";

import {
  useGetInstitutionQuery,
  useGetRepresentativeQuery,
} from "services/api";

const CellName = ({ data, openModalReps }:{ data: any, openModalReps: any }) => {
  const {
    data: repsLists,
    isFetching: isFetchingReps,
    isLoading: isLoadingReps,
    isUninitialized: isUninitializedReps,
    isError: isErrorReps,
    error: errorReps,
    refetch: refetchReps,
  } = useGetRepresentativeQuery(
    {
      institution_code: data.code
    },
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  return (
    <div className="d-flex justify-content-start align-items-start gap-1">
      <div className="me-1" style={{width: '30px', height: '30px'}}>
        {
          data.logo &&
          <div className="me-1 mt-1" style={{height: '30px', width: '30px'}}>
            <img src={data.logo} alt="" className="h-auto border img-fluid" />
          </div>
        }
      </div>
      <div className="text-body fw-semibold d-flex flex-column">
        <div>{data.name}</div>
        <div className="d-flex flex-column justify-content-start align-items-start gap-1">
          {
            (repsLists?.result || []).map((item: any) => {
              return (
                <div>
                  <Badge bg="transparent" className="fw-normal badge-outline-secondary">{item.name} ({item.phone}/{item.email}){' '}<i className="mdi mdi-square-edit-outline cursor-pointer" onClick={() => { openModalReps('edit', { ...item, institution_code: data?.code, institution_name: data?.name}) }}></i>{' '}<i onClick={() => { openModalReps('delete', { ...item, institution_code: data?.code, institution_name: data?.name}) }} className="mdi mdi-trash-can-outline cursor-pointer"></i></Badge>
                </div>
              )
            })
          }
          <Button onClick={() => openModalReps('add', { institution_code: data?.code, institution_name: data?.name})} variant="link" className="p-0 mt-n1 font-12">Add representative...</Button>
        </div>
      </div>
    </div>
  )
}

const columns = ({openModalReps} : { openModalReps: any }) => {
  return [
    {
      label: "Name",
      name: "name",
      width: "400px",
      CustomCell: (data: any) => <CellName data={data} openModalReps={openModalReps} />,
    },
    {
      label: "Alias",
      name: "abbr",
      width: "180px",
    },
    {
      label: "Is Partner?",
      name: "is_partner",
      width: "130px",
      inputFilter: "select",
      filterOptions: [
        {
          label: "Yes", value: "Yes",
        },
        {
          label: "No", value: "No",
        },
      ],
    },
    {
      label: "Fee?",
      name: "application_fee",
      width: "130px",
      inputFilter: "select",
      filterOptions: [
        {
          label: "Yes", value: "Yes",
        },
        {
          label: "No", value: "No",
        },
      ],
    },
    {
      label: "Country",
      name: "country_name",
      width: "150px",
      inputFilter: "select",
      filterOptions: {
        table: 'institutions',
        column: 'country_name',
      }
    },
    {
      label: "City",
      name: "city_name",
      width: "150px",
      inputFilter: "select",
      filterOptions: {
        table: 'institutions',
        column: 'city_name',
      }
    },
    {
      label: "Leads",
      name: "leads_count",
      width: "100px",
      hideFilter: true,
    },
    {
      label: "Students",
      name: "application_count",
      width: "100px",
      hideFilter: true,
    },
  ];
}

const List = ({ 
  onClickAction,
}: { 
  onClickAction?: any;
 }) => {
  const [search, setSearch] = useState({});
  const [sort, setSort] = useState<object>({});
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  
  const [current, setCurrent] = useState();
  const [modalTypeReps, setModalTypeReps] = useState<string>('');
  const [modalShowReps, setModalShowReps] = useState<boolean>(false);

  const closeModalReps = () => setModalShowReps(false);
  const openModalReps = (type: string, data?: any) => {
    setCurrent(data)
    setModalTypeReps(type)
    setModalShowReps(true)
  };

  const {
    data: lists,
    isFetching: isFetchingInstitution,
    isLoading: isLoadingInstitution,
    isUninitialized: isUninitializedInstitution,
    isError: isErrorInstitution,
    error: errorInstitution,
    refetch: refetchInstitution,
  } = useGetInstitutionQuery(
    {
      search,
      sort,
      page: activePage,
      per_page: perPage,
    },
    {
      // pollingInterval: 100,
      refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  return (
    <>
      <BTable 
        tableClassname={'table-centered'}
        isLoading={ isFetchingInstitution || isLoadingInstitution || isUninitializedInstitution || isErrorInstitution }
        data={lists?.result}
        meta={lists?.meta}
        columns={columns({openModalReps})}
        search={search}
        setSearch={setSearch}
        sort={sort}
        setSort={setSort}
        activePage={activePage}
        setActivePage={setActivePage}
        perPage={perPage}
        setPerPage={setPerPage}
        onClickAction={onClickAction}
        // customAction={(data: any) => 
        //   <button
        //     onClick={() => openModalReps("edit", data)}
        //     type="button"
        //     className="btn btn-link action-icon"
        //   >
        //     <i className="mdi mdi-account-plus-outline"></i>
        //   </button>
        // }
        openModal={openModalReps}
      />

      {modalShowReps && (
        <ModalRepresentative
          show={modalShowReps}
          type={modalTypeReps}
          data={current}
          onHide={closeModalReps}
        />
      )}
    </>
  );
};

export default List;
