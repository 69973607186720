import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";

// components
import BTable from "components/BTable";

import {
  useGetUserQuery,
} from "services/api";

const CellName = ({ data }:{ data: any }) => {
  return (
    <div className="d-flex justify-content-start align-items-center gap-1">
      <div style={{height: '30px', width: '30px'}}>
        <img width={30} height={30} src={data.avatar} alt="" className="h-auto border rounded-circle me-1" />
      </div>
      <Link to="#" className="text-body fw-semibold">
        {data.name}
      </Link>
    </div>
  )
}

const CellRole = ({ data }:{ data: any }) => {
  return (
    <>
      {
        <div className="d-flex justify-content-start align-items-center">
          <Badge bg="primary" text="white" className="px-1 me-1">
            {data.role_name}
          </Badge>
          {
            ['Branch Manager','Counselor'].includes(data.role_name) &&
            <>
              <Badge bg="soft-primary" text="dark" className="px-1 me-1">
                {data.branch_name}
              </Badge>
              <Badge bg="soft-secondary" text="dark" className="px-1 me-1">
                Target: {data.target}
              </Badge>
            </>
          }
          {
            data.role_name == 'Representative' &&
            <Badge bg="soft-secondary" text="dark" className="px-1 me-1">
              {data.institution_name}
            </Badge>
          }
        </div>
      }
    </>
  );
};

const columns = [
  {
    label: "Name",
    name: "name",
    width: "300px",
    CustomCell: (data: any) => <CellName data={data} />,
  },
  {
    label: "Email",
    name: "email",
    width: "200px",
  },
  {
    label: "Phone",
    name: "phone",
    width: "200px",
  },
  {
    label: "Role",
    name: "role_name",
    width: "200px",
    inputFilter: "select",
    filterOptions: [
      {
        label: "Admin", value: "Admin",
      },
      {
        label: "Branch Manager", value: "Branch Manager",
      },
      {
        label: "Counselor", value: "Counselor",
      },
      {
        label: "Admission", value: "Admission",
      },
    ],
    CustomCell: (data: any) => <CellRole data={data} />,
  },
];

const List = ({ 
  onClickAction,
}: { 
  onClickAction?: any;
 }) => {
  const [search, setSearch] = useState({});
  const [sort, setSort] = useState<object>({});
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  
  const [current, setCurrent] = useState();
  const [modalType, setModalType] = useState<string>('');
  const [modalShow, setModalShow] = useState<boolean>(false);

  const closeModal = () => setModalShow(false);
  const openModal = (type: string, data?: any) => {
    setCurrent(data)
    setModalType(type)
    setModalShow(true)
  };

  const {
    data: lists,
    isFetching: isFetchingUser,
    isLoading: isLoadingUser,
    isUninitialized: isUninitializedUser,
    isError: isErrorUser,
    error: errorUser,
    refetch: refetchUser,
  } = useGetUserQuery(
    {
      search,
      sort,
      page: activePage,
      per_page: perPage,
    },
    {
      // pollingInterval: 100,
      refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  return (
    <>
      <BTable 
        tableClassname={'table-centered'}
        isLoading={ isFetchingUser || isLoadingUser || isUninitializedUser || isErrorUser }
        data={lists?.result}
        meta={lists?.meta}
        columns={columns}
        search={search}
        setSearch={setSearch}
        sort={sort}
        setSort={setSort}
        activePage={activePage}
        setActivePage={setActivePage}
        perPage={perPage}
        setPerPage={setPerPage}
        onClickAction={onClickAction}
      />
    </>
  );
};

export default List;
