import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { HasAccess } from "@permify/react-role";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/store";
import { toast } from "react-toastify";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import Skeleton from "react-loading-skeleton";

import {
  useGetLeadsGeneratedQuery,
  useGetStudentApplyingQuery,
  useGetBranchPerformanceQuery,
} from 'services/api'
import { useEffectOnce } from "usehooks-ts";

const options: ApexOptions = {
  chart: {
    height: 100,
    type: "radialBar",
  },
  plotOptions: {
    radialBar: {
      track: {
        // background: "#e7e7e7",
        // strokeWidth: '100%',
        margin: 1, // margin is in pixels
        // dropShadow: {
        //   enabled: true,
        //   top: 2,
        //   left: 0,
        //   color: '#999',
        //   opacity: 1,
        //   blur: 2
        // }
      },
      startAngle: -135,
      endAngle: 135,
      dataLabels: {
        name: {
          fontSize: "10px",
          color: undefined,
          offsetY: -10,
        },
        value: {
          offsetY: -12,
          fontSize: "10px",
          color: undefined,
          formatter: (val) => {
            return val + "%";
          },
        },
      },
    },
  },
  fill: {
    gradient: {
      shade: "dark",
      shadeIntensity: 30,
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 50, 65, 91],
    },
  },
  stroke: {
    dashArray: 1.2,
  },
  colors: ["#5ef67e"],
  labels: [""],
  // responsive: [
  //   {
  //     breakpoint: 380,
  //     options: {
  //       chart: {
  //         height: 180,
  //       },
  //     },
  //   },
  // ],
};

const BranchPerformanceItem = ({data} : {data: any;}) => {
  const [series, setSeries] = useState<any>();

  useEffectOnce(() => {
    if(data){
      let pct = ((100 * data.achieve)  /  data.annual_target).toFixed(1)
      setSeries(pct)
    }
  })

  return (
    <div className="d-inline-flex justify-content-between justify-content-md-start align-items-center">
      <div className="">
        <div className="text-muted">{data?.branch_name}</div>
        <div className="font-18 fw-bold">
          <span className="text-success">{data?.achieve}</span>
          <span className="px-1">/</span>
          <span>{data?.annual_target}</span>
        </div>
      </div>
      <div className="">
        <Chart
          className="ms-n2 mt-n2 me-n3 me-md-auto"
          options={options}
          series={[series]}
          type="radialBar"
          height="100"
          width="100"
        />
      </div>
    </div>
  )
}
const Statistics = ({filterYear} : {filterYear: any;}) => {
  const { leadsGenerated, isUninitialized: isUninitializedLeadsGenerated, isLoading: isLoadingLeadsGenerated, isFetching: isFetchingLeadsGenerated, isError: isErrorLeadsGenerated } = useGetLeadsGeneratedQuery(
    {
      filter_year: filterYear || '',
    },
    {
      selectFromResult: ({ data, isUninitialized, isLoading, isFetching, isError }) => ({
        leadsGenerated: data?.result,
        isUninitialized : isUninitialized ,
        isLoading: isLoading,
        isFetching: isFetching,
        isError: isError,
      }),
      refetchOnMountOrArgChange: true,
      skip: false,
    }
  );

  const { studentApplying, isUninitialized: isUninitializedStudentApplying, isLoading: isLoadingStudentApplying, isFetching: isFetchingStudentApplying, isError: isErrorStudentApplying } = useGetStudentApplyingQuery(
    {
      filter_year: filterYear || '',
    },
    {
      selectFromResult: ({ data, isUninitialized, isLoading, isFetching, isError }) => ({
        studentApplying: data?.result,
        isUninitialized : isUninitialized ,
        isLoading: isLoading,
        isFetching: isFetching,
        isError: isError,
      }),
      refetchOnMountOrArgChange: true,
      skip: false,
    }
  );
  
  const { branchPerformance, isUninitialized: isUninitializedBranchPerformance, isLoading: isLoadingBranchPerformance, isFetching: isFetchingBranchPerformance, isError: isErrorBranchPerformance } = useGetBranchPerformanceQuery(
    {
      filter_year: filterYear || '',
    },
    {
      selectFromResult: ({ data, isUninitialized, isLoading, isFetching, isError }) => ({
        branchPerformance: data?.result,
        isUninitialized : isUninitialized ,
        isLoading: isLoading,
        isFetching: isFetching,
        isError: isError,
      }),
      refetchOnMountOrArgChange: true,
      skip: false,
    }
  );

  return (
    <>
      <div className="d-flex flex-column flex-md-wrap flex-md-row gap-2 gap-md-3 align-items-stretch">
        <div className="flex-fill order-0 order-sm-1">
          <Card className="h-100 m-0">
            <Card.Body>
              {
                (isUninitializedLeadsGenerated || isLoadingLeadsGenerated || isFetchingLeadsGenerated) ? (
                  <div className="row">
                    <div className="col-9">
                      <Skeleton width="100%" />
                      <Skeleton width="30%" />
                    </div>
                    <div className="col-3">
                      <Skeleton width="100%" />
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-between">
                    <div className="">
                      <div className="font-16 fw-bold">Leads</div>
                      {
                        leadsGenerated?.is_all_time ? (
                          <div className="text-muted">All Time</div>
                        ) : (
                          <div className="text-muted">{leadsGenerated?.filter_year}</div>
                        )
                      }
                    </div>
                    
                    <div className="text-end">
                      <div className="font-22 fw-bold">
                        <NumericFormat displayType="text" value={leadsGenerated?.count} thousandSeparator="." decimalSeparator="," />
                      </div>
                      {/* <div><i className="me-1 fa fa-caret-up text-success"></i> 10.72%</div> */}
                    </div>
                  </div>
                )
              }
            </Card.Body>
          </Card>
        </div>
        <div className="flex-fill order-1 order-sm-2">
          <Card className="h-100 m-0">
            <Card.Body>
              {
                (isUninitializedStudentApplying || isLoadingStudentApplying || isFetchingStudentApplying) ? (
                  <div className="row">
                    <div className="col-9">
                      <Skeleton width="100%" />
                      <Skeleton width="30%" />
                    </div>
                    <div className="col-3">
                      <Skeleton width="100%" />
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-between">
                    <div className="">
                      <div className="font-16 fw-bold">Ongoing Applications</div>
                      {
                        studentApplying?.is_all_time ? (
                          <div className="text-muted">All Time</div>
                        ) : (
                          <div className="text-muted">{studentApplying?.filter_year}</div>
                        )
                      }
                    </div>
                    
                    <div className="text-end">
                      <div className="font-22 fw-bold">
                        <NumericFormat displayType="text" value={studentApplying?.count} thousandSeparator="." decimalSeparator="," />
                      </div>
                      {/* <div><i className="me-1 fa fa-caret-up text-success"></i> 10.72%</div> */}
                    </div>
                  </div>
                )
              }
            </Card.Body>
          </Card>
        </div>
        <HasAccess
          roles={["Super Admin","Admin","Branch Manager"]} 
        >
        <div className="flex-fill order-2 order-sm-3">
          <Card className="h-100 m-0">
            <Card.Body>
              {
                (isUninitializedStudentApplying || isLoadingStudentApplying || isFetchingStudentApplying) ? (
                  <>
                    <div className="row mb-2">
                      <div className="col-10">
                        <Skeleton width="100%" />
                      </div>
                      <div className="col-2">
                        <Skeleton width="100%" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <div className="row">
                          <div className="col-6">
                            <Skeleton width="100%" />
                            <Skeleton width="60%" />
                          </div>
                          <div className="col-3">
                            <Skeleton circle width="40px" height="40px" />
                          </div>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="row">
                          <div className="col-6">
                            <Skeleton width="100%" />
                            <Skeleton width="60%" />
                          </div>
                          <div className="col-3">
                            <Skeleton circle width="40px" height="40px" />
                          </div>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="row">
                          <div className="col-6">
                            <Skeleton width="100%" />
                            <Skeleton width="60%" />
                          </div>
                          <div className="col-3">
                            <Skeleton circle width="40px" height="40px" />
                          </div>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="row">
                          <div className="col-6">
                            <Skeleton width="100%" />
                            <Skeleton width="60%" />
                          </div>
                          <div className="col-3">
                            <Skeleton circle width="40px" height="40px" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex justify-content-between">
                      <div className="">
                        <div className="font-16 fw-bold">Branch Target Performance</div>
                      </div>
                      <div className="text-end">
                        <div className="text-muted">Leads Completed</div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between flex-column flex-nowrap flex-sm-row flex-sm-wrap gap-2 mt-2">
                      {
                        isFetchingBranchPerformance ? (
                          <></>
                        ) : (
                          <>
                            {
                              (branchPerformance || []).map((item: any) => {
                                return (
                                  <BranchPerformanceItem data={item} />
                                )
                              })
                            }
                          </>
                        )
                      }
                    </div>
                  </>
                )
              }
            </Card.Body>
          </Card>
        </div>
        </HasAccess>
        {/* <div className="flex-fill order-3 order-sm-3">
          <Card className="h-100 m-0">
            <Card.Body>
              <div className="d-flex justify-content-between">
                <div className="">
                  <div className="font-16 fw-bold">Annual Target Reach</div>
                  <div className="text-muted">1 Nov 2023 - 30 Nov 2023</div>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <select className="form-select my-1" id="quantityinput">
                  <option disabled value="Select Branch">Select branch...</option>
                  <option value="Jakarta">Jakarta Utara</option>
                  <option value="Jakarta">Jakarta Selatan</option>
                  <option value="Tangerang">Tangerang</option>
                  <option value="Bandung">Medan</option>
                </select>
              </div>
            </Card.Body>
          </Card>
        </div> */}
      </div>
    </>
  );
};

export default Statistics;
