import React, { useState, useEffect, InputHTMLAttributes, useRef, forwardRef } from "react";
import { Form, InputGroup } from "react-bootstrap";
import classNames from "classnames";
import Select from 'react-select';
import PulseLoader from "react-spinners/PulseLoader";

import { FieldErrors, Control } from "react-hook-form";
interface FormSelectProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  type?: string;
  name: string;
  placeholder?: string;
  register?: any;
  errors?: FieldErrors;
  control?: Control<any>;
  trigger?: any;
  getFieldState?: any;
  setValue?: any;
  setError?: any;
  clearErrors?: any;
  getValues?: any;
  className?: string;
  labelClassName?: string;
  containerClass?: string;
  refCallback?: any;
  children?: any;
  rows?: string;
  options?: any;
  onChange?: any;
  value?: any;
  defaultValue?: any;
  isClearable?: boolean;
  isMulti?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const FormSelect = forwardRef<HTMLInputElement, FormSelectProps>(
  (props, ref) => {
    const {
      label,
      type,
      name,
      placeholder,
      register,
      errors,
      control,
      trigger,
      getFieldState,
      setValue,
      setError,
      clearErrors,
      getValues,
      className,
      labelClassName,
      containerClass,
      refCallback,
      children,
      rows,
      options,
      onChange,
      value,
      defaultValue,
      isClearable,
      isMulti,
      isDisabled,
      isLoading,
    } = props
    const selectRef = useRef<any>();
  
    // const selectRef = useRef(ref);
    // handle input type
    const comp =
      type === "textarea" ? "textarea" : type === "select" ? "select" : "input";
    const [currentValue, setCurrentValue] = useState("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isError, setIsError] = useState<boolean>(false);
    
    const checkError = () => {
      if(errors){
        if(name?.includes('.')){
          if(typeof errors[name.split('.')[0]] !== 'undefined'){
            if(typeof errors[name.split('.')[0]][name.split('.')[1]] !== 'undefined'){
              // setErrorMessage(errors[name.split('.')[0]][name.split('.')[1]]["message"] || '')
              setErrorMessage('')
              // setIsError(true)
              return true;
            }
          }
        } else {
          if(errors[name]){
            setErrorMessage(errors[name]['message'])
            // setIsError(true)
            return true;
          }
        }
      }

      // setIsError(false)
      return false;
    }
  
    const onChangeOption = (opt: any, actionType: any) => {
      if(actionType.name == 'clear'){
        clearValue()
      }
      if (onChange !== undefined) {
        onChange(opt,actionType);
      }
      if (setValue !== undefined) {
        setValue(name, opt.value, true);
      }
      setCurrentValue(opt.value);
      if (opt.value) {
        clearErrors(name);
      }
      setIsError(checkError())
    };
  
    const clearValue = () => {
      if(!selectRef.current.clearValue()){
        selectRef.current.clearValue()
      }
    };
  
    // useEffect(() => {
    //   // console.log(defaultValue)
    //   // clearValue()
    //   if(!defaultValue){
    //     clearValue()
    //   }
    //   setValue(name, defaultValue, true);
    //   setCurrentValue(defaultValue);
    //   if (defaultValue) {
    //     clearErrors(name);
    //   }
    // }, [defaultValue]);
  
    // useEffect(() => {
    //   console.log(defaultValue)
    // }, [defaultValue]);

    useEffect(() => {
      setIsError(checkError())
    }, [errors])
  
    return (
      <>
        <Form.Group className={containerClass}>
          {label ? (
            <Form.Label className={labelClassName}>{label}</Form.Label>
          ) : null}
  
          <Form.Control
            {...props}
            // type={type}
            defaultValue={defaultValue}
            placeholder={placeholder}
            // name={name}
            id={name}
            as="input"
            ref={(r: HTMLInputElement) => {
              if (refCallback) refCallback(r);
            }}
            className={className}
            isInvalid={errors && errors[name] ? true : false}
            {...(register ? register(name) : {})}
            rows={rows}
            autoComplete={name}
            type="hidden"
          >
            {children ? children : null}
          </Form.Control>

          {
            isLoading ? (
              <div className="position-relative">
                <Form.Control
                  type="text"
                  placeholder=""
                  value=""
                  disabled
                  readOnly
                />
                <div className="position-absolute top-50 start-0 translate-middle">
                  <div className="ms-5 d-flex justify-content-center align-items-center">
                    <PulseLoader size={10} color="#eee" />
                  </div>
                </div>
              </div>
            ) : (
              <Select
                ref={selectRef}
                menuPortalTarget={document.body}
                className="react-select react-select-container"
                classNamePrefix="react-select"
                placeholder={placeholder}
                // value={
                //   options.filter((opt: any) => opt.value == value) || undefined
                // }
                defaultValue={options && defaultValue ? options.find((opt: any) => opt.value == defaultValue) : ''}
                // defaultInputValue={defaultValue}
                styles={{
                  control: (base: any, state: any) => ({
                    ...base,
                    boxShadow: state.isFocused ? (((errors && errors[name]) || (getFieldState && getFieldState(name).invalid))  ? "0px 0px 4px 1px rgba(241, 85, 108, 0.4)" : "#f1556c") : "#dee2e6",
                    // state.isFocused can display different borderColor if you need it
                    borderColor: ((errors && errors[name]) || (getFieldState && getFieldState(name).invalid))  ? "#f1556c" : "#dee2e6",
                    // overwrittes hover style
                    "&:hover": {
                      borderColor: ((errors && errors[name]) || (getFieldState && getFieldState(name).invalid))  ? "#f1556c" : "#dee2e6",
                    },
                    backgroundColor: state.isDisabled ? '#f3f7f9' : 'unset',
                  }),
                  menuPortal: (base: any, state: any) => ({
                    ...base,
                    zIndex: 9999
                  }),
                  placeholder: (provided: any, state: any) => ({
                    ...provided,
                    color: '#aab0b9'
                  }),
                }}
                options={options}
                onChange={(opt: any, actionType: any) => {
                  onChangeOption(opt || {},actionType);
                }}
                isClearable={isClearable}
                isMulti={isMulti}
                isDisabled={isDisabled}
                // isLoading={isLoading}
              ></Select>
            )
          }

          {isError && errorMessage ? (
            <Form.Control.Feedback type="invalid">
              {errorMessage}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
      </>
    );
});

export default FormSelect;
