import React, { useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux-rtk/store";
import classNames from "classnames";
import moment from "moment";
import { useEffectOnce } from "usehooks-ts";
import Skeleton from "react-loading-skeleton";

// components
import { BadgeStatus } from "components"
import Select, { createFilter } from 'react-select';

import PageTitle from "../../../components/PageTitle";
import Messages from "../../../components/Messages";
import TodoList from "../../../components/TodoList";
import ChatList from "../../../components/ChatList";

import Statistics from "./Statistics";
import EventExpo from "./EventExpo";
import LeadsPerformance from "./LeadsPerformance";
import LatestLeadsActivity from "./LatestLeadsActivity";
import TopCounselor from "./TopCounselor";
import Discussion from "./Discussion";
import TodayBirthday from "./TodayBirthday";
import ModalAttachment from "./ModalAttachment";
import FilterDate from "./FilterDate";

import { FaFire } from "react-icons/fa";

import {
  useGetLeadsGeneratedQuery,
  useGetLeadsOverviewQuery,
  useGetApplicationOverviewQuery,
  useGetDQuotesQuery,
} from 'services/api'

const Dashboard = () => {
  const { user } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
    })
  );

  const [greeting, setGreeting] = useState('');

  const [filterYear, setFilterYear] = useState(moment().format('YYYY'));

  const {
    data: leadsOverviewList,
    isFetching: isFetchingLeadsOverview,
    isLoading: isLoadingLeadsOverview,
    isUninitialized: isUninitializedLeadsOverview,
    isError: isErrorLeadsOverview,
    error: errorLeadsOverview,
    refetch: refetchLeadsOverview,
  } = useGetLeadsOverviewQuery(
    {},
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  const {
    data: applicationOverviewList,
    isFetching: isFetchingApplicationOverview,
    isLoading: isLoadingApplicationOverview,
    isUninitialized: isUninitializedApplicationOverview,
    isError: isErrorApplicationOverview,
    error: errorApplicationOverview,
    refetch: refetchApplicationOverview,
  } = useGetApplicationOverviewQuery(
    {},
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  const { quote, isLoading: isLoadingQuote, isFetching: isFetchingQuote, isUninitialized: isUninitializedQuote } = useGetDQuotesQuery(
    {},
    {
      selectFromResult: ({ data, isLoading, isFetching, isUninitialized }) => ({
        quote: data?.result ? data?.result[0]: null,
        isLoading: isLoading,
        isFetching: isFetching,
        isUninitialized: isUninitialized,
      }),
      refetchOnMountOrArgChange: true,
      skip: false,
    }
  );
  
  const [modalShow, setModalShow] = useState<boolean>(false);
  const closeModal = () => setModalShow(false);
  const openModal = () => {
    setModalShow(true)
  };

  useEffectOnce(() => {
    const time = new Date().getHours();
    if (time < 12) {
      setGreeting(`Good morning ${user.name},`);
    } else if (time < 18) {
      setGreeting(`Good afternoon ${user.name},`);
    } else {
      setGreeting(`Good evening ${user.name},`);
    }
  })
  
  return (
    <div className="mt-3">
      {/* <div className="d-flex flex-wrap flex-xxl-nowrap gap-2 gap-md-3 my-3">
      </div> */}
      <div className="row justify-content-between align-items-center">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5 mb-3">
          <h3 className="d-flex justify-content-between align-items-center mb-2">
            <span>{greeting}</span>
            <Button
              className="d-block d-sm-none"
              onClick={() => {
                openModal()
              }}
            >
              <div className="d-flex flex-wrap gap-1">
                <i className="mdi mdi-download-multiple"></i>
              </div>
            </Button>
          </h3>
          <div className="row mb-0 fst-italic font-14 mt-n1">
            <div className="col-10 col-sm-12">
              {
                quote?.content &&
                <>"{quote?.content}"</>
              }
              {
                quote?.creator &&
                <>{" "}~{quote?.creator}</>
              }
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 mb-3">
          <div className="row justify-content-between gap-1">
            {/* <div className="col-12 col-sm-auto">
              <Button
                onClick={() => {
                  openModal()
                }}
              >
                <div className="d-flex flex-wrap gap-1">
                  <span>Essentials Docs</span>
                  <i className="mdi mdi-download-multiple"></i>
                </div>
              </Button>
            </div> */}
            <div className="col-12">
              <div className="d-flex justify-content-start justify-content-sm-end align-items-center flex-wrap gap-2">
                <FilterDate 
                  onFilter={(filterYear: any, eYear: any) => {
                    setFilterYear(filterYear)
                  }}
                />
                <Button
                  className="d-none d-sm-block"
                  onClick={() => {
                    openModal()
                  }}
                >
                  <div className="d-flex flex-wrap gap-1">
                    <span>Essentials Docs</span>
                    <i className="mdi mdi-download-multiple"></i>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Statistics
        filterYear={filterYear || ''}
      />
      
      <Row>
        <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={3} className="order-md-1 my-2">
          <Card className="h-100">
            <Card.Body>
              {
                (isFetchingLeadsOverview || isLoadingLeadsOverview || isErrorLeadsOverview) ? (
                  <>
                    <div className="row mb-3">
                      <div className="col-4 col-md-3">
                        <Skeleton height="20px" width="100%" />
                      </div>
                      <div className="col-8 col-md-9">
                        <Skeleton height="20px" width="100%" />
                      </div>
                    </div>
                    {
                      [...Array(10)].map(() => {
                        return (
                          <div className="row mb-1">
                            <div className="col-4 col-md-3">
                              <Skeleton width="100%" />
                            </div>
                            <div className="col-8 col-md-9">
                              <Skeleton width="100%" />
                            </div>
                          </div>
                        )
                      })
                    }
                  </>
                ) : (
                  <>
                    <div className="header-title mb-3 d-flex flex-wrap justify-content-between align-items-center">
                      <div className="">
                        <span className="fw-bold font-17 text-dark">Leads</span>
                        <span className="ps-1">Overview</span>
                      </div>
                      <div className="text-muted font-12">All Time</div>
                    </div>
                    <div className="d-flex flex-column gap-1">
                      {
                        (leadsOverviewList?.result || []).map((item: any, index: number) => {
                          return (
                            <div key={`leads-overview-${index}`} className="d-inline-flex">
                              <div style={{ minWidth: '60px' }}>
                                <BadgeStatus name={item.name} group="Leads" value={item.count}/>
                              </div>
                              <div className="fw-semibold text-dark">{item.name}</div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </>
                )
              }
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={3} className="order-md-2 my-2">
          <Card className="h-100">
            <Card.Body>
              {
                (isFetchingLeadsOverview || isLoadingLeadsOverview || isErrorLeadsOverview) ? (
                  <>
                    <div className="row mb-3">
                      <div className="col-4 col-md-3">
                        <Skeleton height="20px" width="100%" />
                      </div>
                      <div className="col-8 col-md-9">
                        <Skeleton height="20px" width="100%" />
                      </div>
                    </div>
                    {
                      [...Array(10)].map(() => {
                        return (
                          <div className="row mb-1">
                            <div className="col-4 col-md-3">
                              <Skeleton width="100%" />
                            </div>
                            <div className="col-8 col-md-9">
                              <Skeleton width="100%" />
                            </div>
                          </div>
                        )
                      })
                    }
                  </>
                ) : (
                  <>
                    <div className="header-title mb-3 d-flex flex-wrap justify-content-between align-items-center">
                      <div className="">
                        <span className="fw-bold font-17 text-dark">Application</span>
                        <span className="ps-1">Overview</span>
                      </div>
                      <div className="text-muted font-12">All Time</div>
                    </div>
                    <div className="d-flex flex-column gap-1">
                      {
                        (applicationOverviewList?.result || []).map((item: any, index: number) => {
                          return (
                            <div key={`application-overview-${index}`} className="d-inline-flex">
                              <div style={{ minWidth: '60px' }}>
                                <BadgeStatus name={item.name} group="Application" value={item.count}/>
                              </div>
                              <div className="fw-semibold text-dark">{item.name}</div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </>
                )
              }
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={12} lg={12} xl={12} xxl={6} className="order-md-3 my-2">
          <LeadsPerformance />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={12} xxl={8} className="order-md-1 my-2">
          <LatestLeadsActivity />
        </Col>
        <Col xs={12} md={6} lg={6} xxl={4} className="order-md-4 my-2">
          <EventExpo />
        </Col>
        <Col xs={12} lg={12} xxl={4} className="order-md-2 my-2">
          <TopCounselor filterYear={filterYear} />
        </Col>
        <Col xs={12} md={6} lg={6} xxl={4} className="order-md-3 my-2">
          <TodayBirthday />
        </Col>
        <Col xs={12} md={12} lg={12} xxl={4} className="order-md-5 my-2">
          <div className="flex-fill flex-sm-fill flex-md-auto flex-lg-fill flex-xxl-auto order-2 order-sm-3">
            <Discussion />
          </div>
        </Col>
        {/* <Col lg={6} xxl={4}>
          <Card className="h-100">
            <Card.Body>
            </Card.Body>
          </Card>
        </Col> */}
      </Row>
      {/* <Row>
        <Col lg={6} xl={4}>
          <Messages />
        </Col>
        <Col lg={6} xl={4}>
          <TodoList addTodo={true} height={"310px"} />
        </Col>
        <Col lg={12} xl={4}>
          <ChatList messages={chatMessages} />
        </Col>
      </Row> */}

      {modalShow && (
        <ModalAttachment
          show={modalShow}
          onHide={closeModal}
        />
      )}
    </div>
  );
};

export default Dashboard;
